import * as THREE from "three";
import { Object3D, Vector3 } from "three";
import { COLLISION_TYPE, ThrowableBehaviorInterface} from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import EventBus from "../../behaviors/event/EventBus";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import RangeDetector from "../../behaviors/range/RangeDetector";
import CameraUtils from "../../utils/CameraUtils";
import { CollisionFlag, IPhysics, SphereData } from "../../physics/common/types";

export default class ThrowableBehaviorUpdaterBase implements BehaviorUpdater {

    private physics: IPhysics | null = null;
    private game?: GameManager;
    public target: Object3D;
    private usingPhysics: boolean = false;
    private isDynamic: boolean = false;
    private behavior: ThrowableBehaviorInterface;
    private removed: boolean = false;
    private currentIndex: number = 0;
    private isKeyListenerAdded: boolean = false;
    private currentSelectedObject: Object3D | null = null;
    private rangeDetector: RangeDetector;
    playerCollisionListenerId: string | undefined;
    isPhysicsEnabled: boolean = false;
    throwObject: boolean = false;
    private cachedDirection: THREE.Vector3 | null = null;
    targetCollection: THREE.Object3D[] = [];
    isThrowing: boolean = false;

    constructor(target: Object3D, behavior: ThrowableBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.rangeDetector = null as unknown as RangeDetector;
        this.target.userData.isInventoryItem = false;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);
        this.addCollisionListener();
        this.addKeyListener();
        this.isPhysicsEnabled = PhysicsUtil.isPhysicsEnabled(this.target);
        //TODO possible future press E for throwables
        //this.rangeDetector = new RangeDetector(gameManager);
        //this.addRangeDetector();
        CameraUtils.disableFromCameraCollision(this.target);
        this.physics = this.game!.scene!.userData.physics;
      
    }

    addCollisionListener() {
        this.playerCollisionListenerId =
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: true,
                },
                this.isDynamic
            );

    }

    updateGameState() {
       
    }

    addKeyListener() {
        if (this.isKeyListenerAdded) return;

        document.body.addEventListener("mousedown", (event) => {
            if (event.button === 0 && this.target.userData.isCollected && !this.isThrowing) { 
                this.isThrowing = true;
                this.throw(); 
                setTimeout(() => {
                    this.target.userData.isCollected = false;
                    this.isThrowing = false;
                }, 3000);
            }
        });

        this.isKeyListenerAdded = true;
    }

    onCollisionWithPlayer() {
      
        if (!this.target || !this.target.userData || !this.target.userData.behaviors) return;

        this.target.userData.isCollected = true;
        //this.target.visible = this.target.userData.uiInventorySelected;
        this.target.visible = true
    }


    addRangeDetector() {
        this.rangeDetector.setPlayer(this.game!.player!);
        this.rangeDetector.addTargets(this.target);
    }

    toggleNextObject() {
        const visibleObjects: Object3D[] = [];

        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.isCollected && !object.userData.isDropped) {
                if (object.userData.isInventoryItem) {
                    visibleObjects.push(object);
                }
            }
        });

        this.currentIndex = (this.currentIndex + 1) % (visibleObjects.length + 1);

        if (this.currentIndex === visibleObjects.length) {
            this.currentSelectedObject = null;

            visibleObjects.forEach((object) => {
                object.visible = false;
            });

            return;
        }

        this.currentSelectedObject = visibleObjects[this.currentIndex];

        visibleObjects.forEach((object, index) => {
            if (index === this.currentIndex) {
                object.visible = true;
            } else {
                object.visible = false;
            }
        });
    }


    removeCollectableFromInventory() {

        if (!this.currentSelectedObject) {
            return;
        }

        const object = this.currentSelectedObject;

        if (object.userData.originalPosition ) {
             object.position.copy(object.userData.originalPosition);
        }
        object.userData.isDropped = true;
        object.userData.isCollected = false;
        object.visible = true;

        this.currentSelectedObject = null;
    }

    update(clock: THREE.Clock, delta: number) {

        if (this.target.userData.isCollected && !this.isThrowing) {
            if (this.target.userData.isDropped ) {
                //this.target.position.copy(this.target.userData.originalPosition);
            } else {
                 this.target.position.copy(this.game!.player!.position);
                 const offset = new THREE.Vector3(0, 0, 1);
                 offset.applyQuaternion(this.game!.player!.quaternion);
                 this.target.position.add(offset);
            }
        }

        //this.rangeDetector.update();

    }


    throw() {

        let playerRotation = this.game!.player!.quaternion.clone();
        this.cachedDirection = new THREE.Vector3();
        this.game!.player!.getWorldDirection(this.cachedDirection);
        let direction = this.game!.player!.getWorldDirection(this.cachedDirection);
        let projectileSpawnPosition = this.game!.player!.position.clone();
      
        let targetMesh = this.createProjectileAndAddToPhysics(
            projectileSpawnPosition,
            playerRotation,
            direction,
            this.behavior.throwableMass,
            this.behavior.throwableSpeed,
        );

        if (targetMesh) {
            if (!targetMesh.userData) {
                targetMesh.userData = {};
            }
        }

        targetMesh!.userData.direction = direction;
        targetMesh!.userData.speed = this.behavior.throwableSpeed;
        targetMesh!.userData.life = this.behavior.throwableLife;
        this.targetCollection.push(targetMesh!);

        setTimeout(() => {
            this.disposeProjectile(targetMesh);
        }, this.behavior.throwableLife * 1000);

    }
    
    createProjectileAndAddToPhysics(
        position: Vector3,
        rotation: THREE.Quaternion,
        direction: Vector3,
        mass: number,
        speed: number,
    ): Object3D | null {
        const offsetDistance = 1 + 1 / 2;
        let startPosition = new THREE.Vector3().copy(position);
        startPosition.addScaledVector(direction, offsetDistance);

        //let projectileObject = this.target.clone(); (for ammo not throwables)
        let projectileObject = this.target; 

        if (projectileObject) {
          
            projectileObject.position.copy(startPosition);
            let angle = Math.atan2(direction.x, direction.z);
            projectileObject.rotation.set(0, angle, 0);

            this.game!.scene!.add(projectileObject);

            let startTime = Date.now();
            const duration = 25;
            const interval = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const progress = elapsed / duration;

                if (progress >= 1) {
                    clearInterval(interval);
                } else {
                   
                    projectileObject.position.lerpVectors(projectileObject.position, position, progress);
                }
            }, 16);

            const bbox = new THREE.Box3();
            bbox.setFromObject(projectileObject);
            let radius = bbox.getSize(new THREE.Vector3).length() * 0.2;
            let sphereData: SphereData = {
                uuid: projectileObject.uuid,
                radius: radius,
                position: {
                    x: startPosition.x,
                    y: startPosition.y,
                    z: startPosition.z,
                },
                quaternion: {
                    x: rotation.x,
                    y: rotation.y,
                    z: rotation.z,
                    w: rotation.w,
                },
                mass: mass,
                friction: this.behavior.throwableFriction,
                restitution: this.behavior.throwableRestitution,
                collision_flag: CollisionFlag.DYNAMIC,
                template: projectileObject.uuid,
            } as SphereData;

            this.physics!.addSphere(projectileObject, sphereData);
            this.physics!.addCollidableObject(projectileObject.uuid);
            this.physics!.setLinearVelocity(projectileObject.uuid, {
                x: direction.x * speed,
                y: direction.y * speed,
                z: direction.z * speed,
            } as Vector3);

            return projectileObject;
        }

        return null;
    }

  
    disposeProjectile(targetMesh: any) {
        if (this.game!.scene! && targetMesh && this.physics) {
            if (targetMesh.userData.isAmmoForWeapon) {
                targetMesh.userData.firedAmmo = false;
                this.game!.scene!.remove(targetMesh);
            }
            try {
                this.physics!.remove(targetMesh.uuid);
            } catch { }
            this.targetCollection = this.targetCollection.filter(o => o.uuid !== targetMesh.uuid);
        }
    }

    reset() {
        if (this.removed) {
            if (this.usingPhysics) {
                this.game!.app.addPhysicsObject(this.target);
            } else {
                this.game!.scene?.add(this.target);
            }
            this.addCollisionListener();
            this.removed = false;
        }
    }

}
