import {useEffect, useState} from "react";
import styled from "styled-components";
import global from "../../../global";
import Application from "../../../Application";
import {AvatarCreatorPanel} from "../../../editor/assets/v2/AvatarCreatorPanel/AvatarCreatorPanel";

import successIcon from "./images/success.svg";
import errorIcon from "./images/error.svg";
import {regularFont} from "../../../assets/style";

import {useAuthorizationContext} from "../../../context";
import {TextInput} from "../../../editor/assets/v2/common/TextInput";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {Separator} from "../../../editor/assets/v2/RightPanel/common/Separator";
import {Bottom, LoginBox, Top} from "./LoginPage.style";

interface Props {
    onCorrectAuth: () => void;
    setOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showLoading: boolean;
}

export const Onboarding = ({onCorrectAuth, setOnboarding, showLoading, setShowLoading}: Props) => {
    const {validateUsername, saveUsernameInFirebase, dbUser} = useAuthorizationContext();
    const [username, setUsername] = useState("");
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [tooShort, setTooShort] = useState(false);
    const [showValidationBorder, setShowValidationBorder] = useState(false);
    const [avatarCreator, setAvatarCreator] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const metaverse = (global.app as Application)?.options.metaverse;

    useEffect(() => {
        const handlevalidateUsername = async () => {
            const usernameAvailable = await validateUsername(username);
            setIsUsernameValid(usernameAvailable);
            setTooShort(false);
            setShowValidationBorder(true);
        };
        if (username.length >= 3) {
            handlevalidateUsername();
        } else {
            setIsUsernameValid(false);
            setTooShort(true);
        }
    }, [username]);

    const saveUsername = async () => {
        setDisabled(true);
        const success = await saveUsernameInFirebase(username);
        if (success) {
            metaverse ? setAvatarCreator(true) : finishOnboarding();
        }
        setDisabled(false);
        setShowLoading(false);
    };

    const finishOnboarding = () => {
        setAvatarCreator(false);
        setOnboarding(false);
        onCorrectAuth();
    };

    useEffect(() => {
        if (dbUser?.username) {
            metaverse ? setAvatarCreator(true) : finishOnboarding();
        }
        setShowLoading(false);
    }, [dbUser]);

    if (showLoading) return null;

    return avatarCreator ? (
        <AvatarCreatorPanel onClose={finishOnboarding} mainAvatar setShowLoading={setShowLoading} />
    ) : (
        <LoginBox>
            <Top>
                <div className="logo">Welcome to Erth.ai</div>
                <div className="pageName">What should we call you</div>
                <div className="description">Set your username below</div>
            </Top>
            <Bottom>
                <InputWrapper>
                    <UsernameInput
                        value={username}
                        setValue={value => setUsername(value)}
                        height="40px"
                        placeholder="Username"
                        $valueCorrect={showValidationBorder ? isUsernameValid : undefined}
                    />
                    {showValidationBorder && (
                        <img
                            className="icon"
                            src={isUsernameValid ? successIcon : errorIcon}
                            alt={isUsernameValid ? "username is available" : "username is not available"}
                        />
                    )}
                </InputWrapper>
                {!isUsernameValid && showValidationBorder && (
                    <ErrorMessage>
                        {tooShort ? "Username is too short." : "Sorry! That username is already taken."}
                    </ErrorMessage>
                )}
                <Separator margin="16px 0 0 0" invisible />
                <StyledButton
                    width="100%"
                    height="40px"
                    style={{color: "#fafafa", fontSize: "14px"}}
                    isBlue={!metaverse}
                    isPink={metaverse}
                    disabled={!isUsernameValid || disabled}
                    onClick={saveUsername}>
                    Next
                </StyledButton>
            </Bottom>
        </LoginBox>
    );
};

const InputWrapper = styled.div`
    position: relative;
    .icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const ErrorMessage = styled.div`
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-secondary-color);
    margin-top: 8px;
`;

const UsernameInput = styled(TextInput)<{$valueCorrect?: boolean}>`
    font-size: var(--theme-font-size-s) !important;
    font-weight: var(--theme-font-medium);
    color: var(--theme-font-main-selected-color);
    &::placeholder {
        font-weight: var(--theme-font-medium);
    }

    ${({$valueCorrect}) => $valueCorrect && "border: 2px solid #34D399;"};
    ${({$valueCorrect}) => $valueCorrect === false && "border: 2px solid #FB7185; color: var(--theme-font-red);"};
`;
