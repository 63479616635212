import React from "react";
import global from "../../../../global";
import {useLightingContext} from "../../../../context";

import {Separator} from "./common/Separator";
import {PanelCheckbox} from "./common/PanelCheckbox";
import {LIGHT_NAME} from "../LeftPanel/MainTabs/AssetsTab/SubTabs/LightsTab";

export const StartOnTriggerLight = () => {
    const app = global.app;
    const editor = app?.editor;
    const {lightState, setLightState} = useLightingContext();

    const handleTrigger = () => {
        const selected = editor.selected;
        selected.userData.startOnTrigger = !selected.userData.startOnTrigger;
        setLightState(prevState => ({
            ...prevState,
            startOnTrigger: !prevState.startOnTrigger,
        }));
        app?.call(`objectChanged`, selected, selected);
    };

    return (
        (lightState.label === LIGHT_NAME.SPOT || lightState.label === LIGHT_NAME.POINT) && (
            <>
                <Separator margin="8px 0 0 0" invisible />
                <PanelCheckbox
                    text="Start on Trigger"
                    checked={!!(editor?.selected as any)?.userData.startOnTrigger}
                    onChange={handleTrigger}
                />
                <Separator margin="8px 0 8px 0" />
            </>
        )
    );
};
