import {useState, useEffect, useRef, useCallback} from "react";
import {getModels} from "../AiImageGenerator.service";
import {List, ListItem, LoadingWrapper, Wrapper} from "../AiImageGenerator.styles";
import {Model} from "../AiImageGenerator.types";
import {toast} from "react-toastify";
import {Oval} from "react-loader-spinner";
import {AssetItem} from "../Components/AssetItem";

type Props = {
    isOpen: boolean;
    setSelectedModel: (asset: Model | null) => void;
    selectedModel: Model | null;
};

export const ModelsList = ({isOpen, setSelectedModel, selectedModel}: Props) => {
    const [models, setModels] = useState<Model[]>([]);
    const [paginationToken, setPaginationToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const observer = useRef<IntersectionObserver | null>(null);
    const lastAssetRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreModels();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore],
    );

    const loadMoreModels = async (firstPage?: boolean) => {
        setLoading(true);
        const token = firstPage ? "" : paginationToken;
        try {
            const response = await getModels(token ?? "", "12");

            if (response && response.models) {
                setModels(prev => [...prev, ...response.models]);
                setPaginationToken(response.nextPaginationToken || null);
                setHasMore(!!response.nextPaginationToken);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            toast.error("Error loading assets.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setModels([]);
        setPaginationToken(null);
        setHasMore(true);
        loadMoreModels(true);
    }, []);

    if (!isOpen) return null;

    return (
        <Wrapper>
            <List className="asset-list">
                {models.map((model, index) => {
                    if (models.length === index + 1) {
                        return (
                            <ListItem ref={lastAssetRef} key={model.id} className="asset-item">
                                <AssetItem model={model} onSelectClick={setSelectedModel} />
                            </ListItem>
                        );
                    } else {
                        return <AssetItem key={model.id} model={model} onSelectClick={setSelectedModel} />;
                    }
                })}
            </List>
            {loading && (
                <LoadingWrapper>
                    <Oval
                        visible
                        height="40"
                        width="40"
                        color="#0284c7"
                        secondaryColor="#333"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass="loaderWrapper"
                    />
                </LoadingWrapper>
            )}
        </Wrapper>
    );
};
