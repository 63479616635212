import React from "react";
import Helmet from "react-helmet";

// Define the base type for CSP directives
type CSPDirective =
    | "default-src"
    | "script-src"
    | "connect-src"
    | "worker-src"
    | "img-src"
    | "style-src"
    | "font-src"
    | "media-src"
    | "object-src"
    | "frame-src";

// Define the type for the policies object
type CSPPolicies = {
    [K in CSPDirective]?: string[];
};

const CSPMetaTag = ({customPolicies = {}}) => {
    // Default CSP policies
    const defaultPolicies = {
        "default-src": ["'self'"],
        "script-src": [
            "'self'",
            "https://unpkg.com",
            "https://www.googletagmanager.com",
            "https://cdn.jsdelivr.net/",
            "'unsafe-inline'",
            "'unsafe-eval'",
        ],
        "connect-src": [
            "'self'",
            "https://*.googleapis.com",
            "https://*.google-analytics.com",
            "https://unpkg.com",
            "https://cdn.discordapp.com",
            "https://discord.com",
            "blob:",
            "https://models.readyplayer.me",
        ],
        "worker-src": ["'self'", "blob:"],
        "img-src": ["*", "data:", "blob:"],
        "style-src": ["*", "'unsafe-inline'"],
        "font-src": ["*", "data:"],
        "media-src": ["'self'", "blob:"],
        "object-src": ["'none'"],
        "frame-src": ["*"],
    };

    // Merge custom policies with defaults
    const mergedPolicies = {...defaultPolicies, ...customPolicies};

    // Convert policies object to CSP string
    const buildCSPString = (policies: CSPPolicies) => {
        return Object.entries(policies)
            .map(([key, values]) => `${key} ${values.join(" ")}`)
            .join("; ");
    };

    const cspContent = buildCSPString(mergedPolicies);

    return (
        <Helmet>
            <meta httpEquiv="Content-Security-Policy" content={cspContent} />
        </Helmet>
    );
};

export default CSPMetaTag;

export const customCSPPolicies = {
    "script-src": [
        "'self'",
        `${process.env.REACT_APP_SERVER_HOST}/`,
        `${process.env.REACT_APP_SERVER_HOST}/*`,
        `${process.env.REACT_APP_ORIGIN}/`,
        `${process.env.REACT_APP_ORIGIN}/*`,
        "'unsafe-inline'",
        "'unsafe-eval'",
        //below this is for web not for discord
        "https://unpkg.com",
        "https://www.googletagmanager.com",
        "https://cdn.jsdelivr.net/",
        "https://apis.google.com",
    ],
    "connect-src": [
        "'self'",
        `${process.env.REACT_APP_SERVER_HOST}/`,
        `${process.env.REACT_APP_SERVER_HOST}/*`,
        `${process.env.REACT_APP_ORIGIN}/`,
        `${process.env.REACT_APP_ORIGIN}/*`,
        //below this is for web not for discord
        "http://localhost:2567/",
        "http://localhost:2567/*",
        "ws://localhost:2567/",
        "https://*.googleapis.com",
        "https://*.google-analytics.com",
        "https://unpkg.com",
        "https://cdn.discordapp.com",
        "https://discord.com",
        "https://models.readyplayer.me",
        "blob:",
        "https://cdn.cloud.scenario.com",
    ],
    "frame-src": [
        "'self'",
        "*",
        `${process.env.REACT_APP_ORIGIN}/`,
        `${process.env.REACT_APP_ORIGIN}/*`,
        `${process.env.REACT_APP_SERVER_HOST}/`,
        `${process.env.REACT_APP_SERVER_HOST}/*`,
        `${process.env.REACT_APP_AUTH_IFRAME_URL}/`,
        `${process.env.REACT_APP_AUTH_IFRAME_URL}/*`,
    ],
    "worker-src": [
        "blob:",
        "'self'",
        `${process.env.REACT_APP_ORIGIN}/`,
        `${process.env.REACT_APP_ORIGIN}/*`,
        `${process.env.REACT_APP_SERVER_HOST}/`,
        `${process.env.REACT_APP_SERVER_HOST}/*`,
    ],
};
