import {useEffect, useState} from "react";
import {StyledButton} from "../../../editor/assets/v2/common/StyledButton";
import {IBasicGameInterface} from "../../../v2/pages/types";

interface Props {
    visibilityCounter: number;
    itemsToLoad: any[];
    setVisibleResults: React.Dispatch<React.SetStateAction<IBasicGameInterface[]>>;
}

export const LoadMore = ({visibilityCounter, itemsToLoad, setVisibleResults}: Props) => {
    const [visibleGamesCount, setVisibleGamesCount] = useState(visibilityCounter);
    const hasMoreGamesToLoad = visibleGamesCount < itemsToLoad.length;

    const loadMoreGames = () => {
        setVisibleGamesCount(prevCount => prevCount + visibilityCounter);
    };
    useEffect(() => {
        // Prevent unnecessary updates by only setting when the slice changes
        const newVisibleGames = itemsToLoad.slice(0, visibleGamesCount);
        setVisibleResults(prevResults => {
            if (prevResults.length !== newVisibleGames.length) {
                return newVisibleGames;
            }
            return prevResults;
        });
    }, [itemsToLoad, visibleGamesCount]);

    return (
        hasMoreGamesToLoad && (
            <StyledButton isPink width="133px" height="40px" onClick={loadMoreGames} className="loadeMoreBtn">
                Load more
            </StyledButton>
        )
    );
};
