import * as THREE from "three";
import { Object3D } from "three";
import GameManager from "../../behaviors/game/GameManager";
import { COLLISION_TYPE, OBJECT_TYPES, WEAPON_TYPES, WEAPON_EFFECTS, WEAPON_AIMERS, CAMERA_TYPES } from "../../types/editor";
import global from "../../global";
import { IPhysics } from "src/physics/common/types";
import CameraUtils from "../../utils/CameraUtils";

enum GAME_STATE {
    NOT_STARTED = 0,
    STARTED,
    FINISHED,
    PAUSED,
}

class WeaponBehaviorUpdater {
    target: Object3D;
    removed = false;
    gunAimerImageBase64: string | null = null;
    game?: GameManager;
    weaponHUDAimerSize: string | null = null;
    imageVisibilityToken: string | null = null;
    weaponShowHUDAimerInGame: boolean | null = null;
    endingSession = false;
    aimerUIImageName: string | null = null;
    weaponAimerImage: HTMLImageElement | null = null;
    weaponType: WEAPON_TYPES;
    usingPhysics = false;
    isDynamic = false;
    physics?: IPhysics;
    playerCollisionListenerId: string | undefined;
    private collisionCooldown: boolean = false;
    private img: HTMLImageElement | null = null;

    constructor(target: Object3D, weaponType: WEAPON_TYPES) {
        this.target = target;
        this.target = target;
        this.weaponType = weaponType;
        this.usingPhysics =
            this.target.userData.physics && this.target.userData.physics.body;

        if (global && global.app) {
            global.app.on("removeGunAimer", this.removeGunAimer.bind(this));
        }
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;

        this.addCollisionListener();

        const weaponObjects: { object: Object3D; weaponBehavior: any }[] = [];
        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.behaviors) {
                const weaponBehavior = object.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
                );
                if (weaponBehavior) {
                    weaponObjects.push({ object, weaponBehavior });
                }
            }
        });

        CameraUtils.disableFromCameraCollision(this.target);
    }


    addCollisionListener() {
        this.playerCollisionListenerId =
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: false,
                },
                this.isDynamic
            );

    }

    onCollisionWithPlayer() {
        if (!this.game || !this.game.player || !this.game.scene || this.game.state !== GAME_STATE.STARTED) return;

        if (this.collisionCooldown && this.target.userData.weaponDropped && !this.target.userData.allowPickUp) return;

        if (this.target.userData && this.target.userData.behaviors) {
            const weaponBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
            );

            if (weaponBehavior && !weaponBehavior.weaponStarting) {

                weaponBehavior.weaponStarting = true;
                this.game!.behaviorManager!.collisionDetector.deleteListener(this.target)
                this.aimerUIImageName = weaponBehavior.aimerUIImageName;
                this.weaponShowHUDAimerInGame = weaponBehavior.weaponShowHUDAimerInGame;
                this.gunAimerImageBase64 = weaponBehavior.aimerUIImage;
                this.weaponHUDAimerSize = weaponBehavior.weaponHUDAimerSize;
              
                this.collisionCooldown = true;
                this.game.scene!.traverse(object => {
                    if (object.userData && object.userData.behaviors) {
                        const otherWeaponBehavior = object.userData.behaviors.find(
                            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON
                        );
                        if (!this.target && otherWeaponBehavior && otherWeaponBehavior.starting_weapon) {
                            otherWeaponBehavior.starting_weapon = false;
                        }
                    }
                });

                if (!this.target.userData.fpsEnabled) {
                    setTimeout(() => {
                        this.collisionCooldown = false;
                        this.addCollisionListener();
                    }, 4000);
                } else {
                    this.collisionCooldown = false;
                    this.addCollisionListener();
                }
            }
        }
    }


    update(clock: THREE.Clock, delta: number) {
       
    }


    reset() {
        if (this.removed) {
            this.removed = false;
            this.addCollisionListener();
        }
    }


    toggleImageVisibility(visible: boolean) {
        if (this.aimerUIImageName) {
            this.weaponAimerImage = document.getElementById(
                this.aimerUIImageName,
            ) as HTMLImageElement;
        }
        if (this.weaponAimerImage) {
            this.weaponAimerImage.style.display = visible ? "block" : "none";
        }
    }

    removeGunAimer() {

        const images = document.querySelectorAll('img');
        images.forEach((img) => {
            if (img.id && img.id.includes(WEAPON_EFFECTS.WEAPON_HUD_AIMER_IMG_NAME)) {
                img.remove();
            }
        });

        // Remove labels with the ID 'weapon-state--label'
        const labels = document.querySelectorAll('div#weapon-state--label');
        labels.forEach((label) => {
            if (label instanceof HTMLDivElement) {
                label.remove();
            }
        });

    }
}

export default WeaponBehaviorUpdater;
