import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { IPhysics } from "src/physics/common/types";
import { COLLISION_TYPE, OBJECT_TYPES, SPAWNPOINT_TYPES, TriggerBehaviorInterface, SpawnPointBehaviorInterface } from "../../types/editor";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import { PlayerBones } from "../../player/Skeleton/PlayerBones";
import CameraUtils from "../../utils/CameraUtils";

class SpawnPointBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;
    physics?: IPhysics;
    objectNameToSpawn?: string;
    spawnType?: SPAWNPOINT_TYPES;
    spawnPointBehavior: any;
    spawningObject: THREE.Object3D | undefined;
    triggerObjects: [] = [];
 
    constructor(target: THREE.Object3D, objectNameToSpawn: string, spawnType: SPAWNPOINT_TYPES) {
        this.target = target;
        this.objectNameToSpawn = objectNameToSpawn;
        this.spawnType = spawnType;
        this.spawnPointBehavior = this.target.userData.behaviors.find((object: any) => object.type === OBJECT_TYPES.SPAWNPOINT);
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;

        if (!this.objectNameToSpawn || this.objectNameToSpawn === "") {
            console.warn('No object name to spawn provided');
            return;
        }

        const objectToSpawn = this.game?.scene?.getObjectByName(this.objectNameToSpawn) as THREE.Object3D;
        this.spawningObject = objectToSpawn;
        if (this.spawnPointBehavior) {
            const objectBones = new PlayerBones(this.spawningObject);
            const { bones } = objectBones.getPlayerBones();
            if (bones) {
                this.moveObjectToSpawn(objectToSpawn);
                if (this.spawnPointBehavior.startOnTrigger) {
                    this.spawningObject.visible = false;
                    this.physics?.removeCollidableObject(this.spawningObject.name);
                }
            } else {
                if (this.spawnPointBehavior.spawnType == SPAWNPOINT_TYPES.CLONE) {
                    const clonedObject = this.cloneObject(objectToSpawn);
                    this.moveObjectToSpawn(clonedObject);
                    this.game?.scene?.add(clonedObject);
                    this.spawningObject = clonedObject;
                    if (this.spawnPointBehavior.startOnTrigger) {
                        this.spawningObject.visible = false;
                        this.physics?.removeCollidableObject(clonedObject.uuid);
                    }
                } else if (this.spawnPointBehavior.spawnType == SPAWNPOINT_TYPES.MOVE) {
                    this.moveObjectToSpawn(objectToSpawn);
                    if (this.spawnPointBehavior.startOnTrigger) {
                        this.spawningObject.visible = false;
                        this.physics?.removeCollidableObject(objectToSpawn.uuid);
                    }
                }
            }
        }

        this.target.visible = false;
        this.physics?.removeCollidableObject(this.target.uuid);
        CameraUtils.disableFromCameraCollision(this.target);

        if (!this.triggerObjects || this.triggerObjects.length === 0) {
            this.triggerObjects = [];
            this.game!.scene!.traverse((object: THREE.Object3D) => {
                if (object.userData?.behaviors?.some((b: any) => b.type === OBJECT_TYPES.TRIGGER)) {
                    this.triggerObjects.push(object);
                }
            });
        }

    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
                distanceThreshold: 10.0,
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }

    private spawnOnTrigger() {

        if (!this.target.userData.isSpawnedFromTrigger && this.spawnPointBehavior.startOnTrigger) {
            if (this.target.userData && this.target.userData.behaviors) {
                const spawnPointBehavior = this.target.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.SPAWNPOINT,
                ) as SpawnPointBehaviorInterface;

                if (spawnPointBehavior && spawnPointBehavior.objectNameToSpawn
                    && spawnPointBehavior.startOnTrigger) {

                    this.triggerObjects.forEach((triggerObject: THREE.Object3D) => {
                        if (!triggerObject.userData?.behaviors) return;

                        const triggerBehavior = triggerObject.userData.behaviors.find(
                            (behavior: any) => behavior.type === OBJECT_TYPES.TRIGGER
                        ) as TriggerBehaviorInterface;

                        if (!triggerBehavior) return;

                        if (triggerObject.userData.player_touches || triggerObject.userData.pressE) {

                            if (triggerBehavior) {

                                const behaviorObjectName = triggerBehavior.then_object || triggerBehavior.else_object;
                                const spawnObject = this.game?.scene?.getObjectByName(behaviorObjectName);

                                if (behaviorObjectName === spawnPointBehavior.objectNameToSpawn) {

                                    const objectBox = new THREE.Box3().setFromObject(triggerObject)
                                    const playerBox = new THREE.Box3().setFromObject(this.game!.player!);
                                    if (objectBox!.intersectsBox(playerBox)) {

                                        this.physics?.addCollidableObject(spawnObject!.uuid);

                                        if (triggerObject.userData.pressE && this.game!.scene!.userData.pressE) {
                                            spawnObject!.visible = true;
                                            this.target.userData.isSpawnedFromTrigger = true;
                                        }
                                        if (triggerObject.userData.player_touches) {
                                            spawnObject!.visible = true;
                                            this.target.userData.isSpawnedFromTrigger = true;
                                        }

                                        //TODO work on object touches

                                    }
                                }
                            }
                        }
                    });
                }
            }

        }

    }

    private moveObjectToSpawn(object: THREE.Object3D) {
       
        object.position.copy(this.target.position);
        object.quaternion.copy(this.target.quaternion);

        const targetBoundingBox = new THREE.Box3().setFromObject(this.target);
        const objectBoundingBox = new THREE.Box3().setFromObject(object);

        const targetBottomY = targetBoundingBox.min.y;
        const objectBottomY = objectBoundingBox.min.y;

        const yOffset = targetBottomY - objectBottomY;
        object.position.y += yOffset;

        if (object.userData.physics?.enabled) {
            const targetPosition = PhysicsUtil.calculatePhysicsPositionFromObject(
                object.position,
                object.quaternion,
                object.userData.physics?.anchorOffset
            ) as THREE.Vector3;

            this.physics!.setOrigin(object.uuid, targetPosition);
            this.physics!.setRotation(object.uuid, object.quaternion);
        }
    }


    private cloneObject(object: THREE.Object3D): THREE.Object3D {
        const clonedObject = object.clone();

        for (let i = 0; i < object.children.length; i++) {
            clonedObject.add(this.cloneObject(object.children[i]));
        }

        clonedObject.uuid = THREE.MathUtils.generateUUID();

        if (object.userData.physics) {
            clonedObject.userData.physics = { ...object.userData.physics };
        }

        return clonedObject;
    }

    update(clock: THREE.Clock, delta: number): void {

        if (this.game && this.game.scene && this.target) {
            this.spawnOnTrigger();
        }

    }

    onCollisionWithPlayer() {

    }

    reset() {

    }
}

export default SpawnPointBehaviorUpdater;