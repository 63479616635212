import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import {OBJECT_TYPES, COLLISION_TYPE, AnimationBehaviorInterface} from "../../types/editor";
import RangeDetector from "../../behaviors/range/RangeDetector";
import {IPhysics} from "../../physics/common/types";

class AnimationBehaviorUpdater implements BehaviorUpdater {
    physics?: IPhysics;
    private game?: GameManager;
    target: THREE.Object3D;
    private originalPosition: THREE.Vector3;
    private originalRotation: THREE.Euler;
    private originalScale: THREE.Vector3;
    triggerTouchedActivateObject: THREE.Object3D | undefined;
    triggerObjectTouched: boolean = false;
    triggerDelay: number = 0;
    addedCollider: boolean = false;
    triggerAssociated3DObject: THREE.Object3D | undefined;
    rangeDetector: RangeDetector;
    physicsEnabled = false;
    playerColliding: boolean = false;
    physicsRemoved: boolean = false;
    behavior: AnimationBehaviorInterface;

    constructor(target: THREE.Object3D, behavior: AnimationBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.originalPosition = target.position.clone();
        this.originalRotation = target.rotation.clone();
        this.originalScale = target.scale.clone();
        this.triggerDelay;
        this.physicsEnabled = this.target.userData.physics && this.target.userData.physics.enabled;
        this.rangeDetector = null as unknown as RangeDetector;
        this.triggerDelay = 0;
        target.userData.triggerMovement = false;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        if (gameManager.scene) {
            this.rangeDetector = new RangeDetector(gameManager);
        }
        this.addRangeDetector();
        this.addCollisionListener();
        if (this.target.userData && this.target.userData.behaviors) {
            const animationBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.ANIMATION,
            ) as AnimationBehaviorInterface;

            if (animationBehavior.startOnTrigger) {
                this.target.userData.startOnTrigger = true;
            }
        }
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
                distanceThreshold: 10.0,
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }

    onCollisionWithPlayer() {
        this.playerColliding = true;
        setTimeout(() => (this.playerColliding = this.triggerObjectTouched = false), 500);
    }

    addRangeDetector() {
        if (this.target.userData.behaviors.find((el: any) => el.type === OBJECT_TYPES.ANIMATION && el.pressE)) {
            this.rangeDetector.setPlayer(this.game!.player!);
            this.rangeDetector.addTargets(this.target);
        }
    }

    update(clock: THREE.Clock, delta: number) {
        if (!this.target) {
            return;
        }

        if (this.playerColliding) {
            this.addedCollider = false;
        }
    }

    private addCollisionAndPhysics() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.app.addPhysicsObjectBody(targetObject);
    }

    private removeCollisionAndPhysics() {
        const targetObject = this.target;
        this.game!.app.removePhysicsObjectBody(targetObject);
    }

    reset() {}
}

export default AnimationBehaviorUpdater;
