export const EVENTS = {
    INVENTORY_ADD: "inventoryAdd",
    INVENTORY_DELETE: "inventoryDelete",
} as const;

export type EventName = keyof typeof EVENTS;

export interface EventDetail {
    [key: string]: any;
}

export const dispatchCustomEvent = <T extends EventName>(
    eventName: (typeof EVENTS)[T],
    detail: EventDetail = {},
): void => {
    const event = new CustomEvent(eventName, {detail});
    window.dispatchEvent(event);
};
