import React, {useState} from "react";
import trashIcon from "../../icons/trash.svg";
import {CloseIconWrapper, StyledUploadButton} from "./UploadField.style";
import I18n from "i18next";
import {FileData} from "../../types/file";
import global from "../../../../../global";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {StyledButton} from "../StyledButton";
import uploadIcon from "./icons/upload.svg";

interface Props {
    width: string;
    height: string;
    uploadedFile?: FileData | null | string;
    setUploadedFile: React.Dispatch<React.SetStateAction<FileData | null | string>>;
    handleFileName?: (name: string) => void;
    disabled?: boolean;
    size?: {minWidth: number; minHeight: number};
    deleteHandler?: () => void;
    style?: React.CSSProperties;
    uploadHandler?: (arg: any) => void;
    withButton?: boolean;
    v2?: boolean;
    savedFileName?: string;
    label?: string;
}

export const UploadField = ({
    width,
    height,
    uploadedFile,
    setUploadedFile,
    disabled,
    deleteHandler,
    size,
    uploadHandler,
    style,
    withButton,
    v2,
    label,
    handleFileName,
    savedFileName,
}: Props) => {
    const app = (global as any).app;
    const [hover, setHover] = useState(false);
    const [fileName, setFileName] = useState<null | string>(savedFileName || null);

    const removeImage = (e: any) => {
        e.stopPropagation();
        setUploadedFile(null);
        deleteHandler && deleteHandler();
    };

    const handleAdd = () => {
        app.upload(
            backendUrlFromPath(`/api/Upload/Upload`),
            (obj: any) => {
                if (obj.Code === 200) {
                    setUploadedFile(obj.Data.url);
                    uploadHandler && uploadHandler(obj.Data.url);
                    setFileName(obj.Data.fileName);
                    handleFileName && handleFileName(obj.Data.fileName);
                }
                app.toast(I18n.t(obj.Msg));
            },
            size,
        );
    };

    const renderButton = () => {
        return (
            <StyledButton isGrey width="78px" style={{height: "24px"}}>
                <img src={uploadIcon} />
                {label || "Upload"}
            </StyledButton>
        );
    };

    return v2 ? (
        <StyledButton isGreySecondary width="100%" style={{height: "32px"}} onClick={handleAdd}>
            <img src={uploadIcon} />
            {label || "Upload"}
        </StyledButton>
    ) : (
        <StyledUploadButton
            style={style}
            className="uploadButton"
            onClick={handleAdd}
            $bgImage={uploadedFile ? backendUrlFromPath(uploadedFile) : undefined}
            width={width}
            height={height}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            $disabled={disabled}>
            {withButton ? (
                <>
                    {!uploadedFile && <>{renderButton()}</>}
                    {uploadedFile && hover && <>{renderButton()}</>}
                    {uploadedFile && (
                        <CloseIconWrapper className="closeIconWrapper" onClick={removeImage}>
                            <img src={trashIcon} alt="remove file" />
                        </CloseIconWrapper>
                    )}
                </>
            ) : (
                <>
                    {!uploadedFile && <span className="plus">+</span>}
                    {uploadedFile && (
                        <CloseIconWrapper className="closeIconWrapper" onClick={removeImage}>
                            <img src={trashIcon} alt="remove file" />
                        </CloseIconWrapper>
                    )}
                </>
            )}
        </StyledUploadButton>
    );
};
