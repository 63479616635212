import styled from "styled-components";

export const H2 = styled.h2`
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
    color: #fff;
    padding: 0;
    margin: 0;
    text-align: center;

    @media only screen and (max-width: 767px) {
        font-size: 40px;
    }
`;
