import {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {ILightState} from "../../../../../types/editor";
import {LightingSection} from "../sections/LightingSection";
import {useLightingContext} from "../../../../../context";
import global from "../../../../../global";

const LightingPanel = () => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const {colorChangeActivated, skyColorChangeActivated, groundColorChangeActivated, lightState, setLightState} =
        useLightingContext();
    const [isLocked, setIsLocked] = useState(false);
    const [selectedObj, setSelectedObj] = useState<any>(null);
    const app = (global as any).app;
    const editor = app.editor;

    useEffect(() => {
        handleLightUpdate();
        app.on(`objectSelected.LightingPanel`, handleLightUpdate);
        app.on(`objectChanged.LightingPanel`, handleLightUpdate);

        return () => {
            app.on(`objectSelected.LightingPanel`, null);
            app.on(`objectChanged.LightingPanel`, null);
        };
    }, []);

    const handleLightUpdate = () => {
        setIsLocked(editor.sceneLockedItems?.includes(editor.selected?.uuid));

        const selected = editor.selected;
        if (!selected || !selected.isLight) return;

        setSelectedObj(selected);

        let newState: ILightState = {
            show: true,
            showIntensity: true,
            intensity: selected.intensity,
            showCastShadow: true,
            castShadow: false,
            showColor: false,
            showDistance: false,
            showDecay: false,
            showSkyColor: false,
            showGroundColor: false,
            showAngle: false,
            showPenumbra: false,
            showWidth: false,
            showHeight: false,
            showTarget: false,
            target: undefined,
        };

        if (selected instanceof THREE.AmbientLight) {
            newState.label = "Ambient Light";
            newState.showColor = true;
            newState.color = `#${selected.color?.getHexString()}`;
            newState.castShadow = selected.castShadow;
        }

        if (selected instanceof THREE.DirectionalLight) {
            newState.label = "Directional Light";
            newState.showColor = true;
            newState.color = `#${selected.color?.getHexString()}`;
            newState.showCastShadow = true;
            newState.castShadow = selected.castShadow;
        }

        if (selected instanceof THREE.HemisphereLight) {
            newState.showColor = false;
            newState.label = "Hemisphere Light";
            newState.showSkyColor = true;
            newState.showGroundColor = true;
            newState.skyColor = `#${selected.color?.getHexString()}`;
            newState.groundColor = `#${selected.groundColor?.getHexString()}`;
        }

        if (selected instanceof THREE.PointLight || selected instanceof THREE.SpotLight) {
            newState.label = selected instanceof THREE.PointLight ? "Point Light" : "Spot Light";
            newState.showColor = true;
            newState.color = `#${selected.color?.getHexString()}`;
            newState.showDistance = true;
            newState.distance = selected.distance;
            newState.showDecay = true;
            newState.decay = selected.decay;
            newState.showCastShadow = true;
            newState.castShadow = selected.castShadow;
            newState.startOnTrigger = selected.userData.startOnTrigger;
        }

        if (selected instanceof THREE.SpotLight) {
            newState.showAngle = true;
            newState.angle = selected.angle;
            newState.showPenumbra = true;
            newState.penumbra = selected.penumbra;
            newState.showCastShadow = true;
            newState.castShadow = selected.castShadow;
            newState.showTarget = true;
            newState.target = selected.target;
        }

        if (selected instanceof THREE.RectAreaLight) {
            newState.label = "Rect Area Light";
            newState.showColor = true;
            newState.color = `#${selected.color?.getHexString()}`;
            newState.showWidth = true;
            newState.width = selected.width;
            newState.showHeight = true;
            newState.height = selected.height;
        }

        setLightState(prevState => ({
            ...prevState,
            ...newState,
        }));
    };

    useEffect(() => {
        if (colorChangeActivated || skyColorChangeActivated || groundColorChangeActivated) {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        }
    }, [colorChangeActivated, skyColorChangeActivated, groundColorChangeActivated]);

    return lightState.show ? (
        <LightingSection lightState={lightState} isLocked={isLocked} selectedObj={selectedObj} />
    ) : null;
};

export default LightingPanel;
