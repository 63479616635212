import {useEffect, useState} from "react";
import * as THREE from "three";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {PanelSectionTitleSecondary} from "../RightPanel.style";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {StyledRange} from "../../common/StyledRange";
import {SelectRow} from "../common/SelectRow";
import {MATERIAL_TYPES} from "../../../../../types/editor";
import {MaterialMapSection} from "../common//MaterialMapSection";
import {MaterialTileRange} from "../common/MateriaTileRange";
import {MaterialBumpSection} from "../common/MaterialBumpSection";
import {MaterialDisplacementSection} from "../common/MaterialDisplacementSection";
import {MaterialNormalSection} from "../common/MaterialNormalSection";

const generateMaterialTypeOptions = () => {
    return Object.entries(MATERIAL_TYPES).map(([key, value], index) => ({
        key: index.toString(),
        value: value,
        label: key.replace(/_/g, " ").replace(/^\w/, c => c.toUpperCase()),
    }));
};

const materialTypeOptions = generateMaterialTypeOptions();

export const MaterialRenderingSection = () => {
    const app = global.app as Application;
    const editor = app.editor;
    const [isDoubleSided, setIsDoubleSided] = useState(false);
    const [isWireframe, setIsWireframe] = useState(false);
    const [isTransparent, setIsTransparent] = useState(false);
    const [opacity, setOpacity] = useState(1);
    const [roughness, setRoughness] = useState(0.5);
    const [materialType, setMaterialType] = useState<string>(MATERIAL_TYPES.MESH_STANDARD);
    const [metalness, setMetalness] = useState(0.5);
    const [bumpScale, setBumpScale] = useState(0.1);
    const [normalScale, setNormalScale] = useState(0.1);
    const [displacementScale, setDisplacementScale] = useState(0.1);
    const [isTiling, setIsTiling] = useState(false);
    const [tileRepeatX, setTileRepeatX] = useState(1);
    const [tileRepeatZ, settileRepeatZ] = useState(1);
    const [materialMapImage, setMaterialMapImage] = useState<string>("");
    const [materialBumpImage, setMaterialBumpImage] = useState<string>("");
    const [materialNormalImage, setMaterialNormalImage] = useState<string>("");
    const [materialDisplacementImage, setMaterialDisplacementImage] = useState<string>("");

    const getSelectedObject = (): THREE.Object3D => {
        return editor?.selected as THREE.Object3D;
    };

    const selected = getSelectedObject();

    const getAllChildrenWithMaterials = (object: THREE.Object3D) => {
        const result: THREE.Mesh[] = [];
        object.traverse(child => {
            if (child instanceof THREE.Mesh && child.material) {
                result.push(child);
            }
        });
        return result;
    };

    const toggleTiling = () => {
        const selected = getSelectedObject();
        if (!selected) return;

        const newTiling = !isTiling;
        setIsTiling(newTiling);

        selected.userData.materialSettings = {
            ...(selected.userData.materialSettings || {}),
            isTiling: newTiling,
        };

        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleMaterialTypeChange = (newValue: string) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const validMaterialType = materialTypeOptions.find(option => option.value === newValue);

        if (!validMaterialType) {
            console.error(`Invalid material type selected: ${newValue}`);
            return;
        }

        setMaterialType(newValue);

        selected.userData.materialSettings = {
            ...(selected.userData.materialSettings || {}),
            materialType: newValue,
        };

        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const toggleDoubleSided = () => {
        const selected = getSelectedObject();
        if (!selected) return;

        const newValue = !isDoubleSided;
        setIsDoubleSided(newValue);

        selected.userData.materialSettings = {
            ...(selected.userData.materialSettings || {}),
            isDoubleSided: newValue,
        };

        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const toggleWireframe = () => {
        const selected = getSelectedObject();
        if (!selected) return;

        const newValue = !isWireframe;
        setIsWireframe(newValue);

        selected.userData.materialSettings = {
            ...(selected.userData.materialSettings || {}),
            isWireframe: newValue,
        };

        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const toggleTransparent = () => {
        const selected = getSelectedObject();
        if (!selected) return;

        const newValue = !isTransparent;
        setIsTransparent(newValue);

        selected.userData.materialSettings = {
            ...(selected.userData.materialSettings || {}),
            isTransparent: newValue,
        };

        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleOpacityChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.opacity = value;

        selected.userData.materialSettings = materialSettings;

        setOpacity(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleRoughnessChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.roughness = value;

        selected.userData.materialSettings = materialSettings;

        setRoughness(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleMetalnessChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.metalness = value;

        selected.userData.materialSettings = materialSettings;

        setMetalness(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleBumpScaleChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.bumpScale = value;

        selected.userData.materialSettings = materialSettings;

        setBumpScale(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleNormalScaleChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};

        if (!materialSettings.normalScale) {
            materialSettings.normalScale = new THREE.Vector2(value, value);
        }
        selected.userData.materialSettings = materialSettings;

        const material = selected.material;
        if (material && material.normalMap) {
            material.normalScale.set(value, value);
        }

        setNormalScale(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleDisplacementScaleChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.displacementScale = value;

        selected.userData.materialSettings = materialSettings;

        setDisplacementScale(value);
        updateMaterialProperties(selected);
        app.call("objectChanged", app.editor, selected);
    };

    const handleTileRepeatXChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.tileRepeatX = value;

        selected.userData.materialSettings = materialSettings;
        setTileRepeatX(value);
        updateMaterialSettings("tileRepeatX", value);
    };

    const handletileRepeatZChange = (value: number) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings.tileRepeatZ = value;

        settileRepeatZ(value);
        updateMaterialSettings("tileRepeatZ", value);
    };

    const updateMaterialSettings = (key: string, value: any) => {
        const selected = getSelectedObject();
        if (!selected) return;

        const materialSettings = selected.userData.materialSettings || {};
        materialSettings[key] = value;

        selected.userData.materialSettings = materialSettings;
        app.call("objectChanged", app.editor, selected);
        updateMaterialProperties(selected);
    };

    const applyTextureWithTiling = (
        loader: THREE.TextureLoader,
        textureMapBase64: string,
        material: THREE.Material,
        mapType: string,
        materialSettings: any,
        tileRepeatX: number,
        tileRepeatZ: number,
    ) => {
        loader.load(textureMapBase64, loadedTexture => {
            // Set texture wrapping and repeat based on tiling settings
            if (materialSettings.isTiling) {
                loadedTexture.wrapS = THREE.RepeatWrapping;
                loadedTexture.wrapT = THREE.RepeatWrapping;
                loadedTexture.repeat.set(tileRepeatX, tileRepeatZ);
            } else {
                loadedTexture.wrapS = THREE.ClampToEdgeWrapping;
                loadedTexture.wrapT = THREE.ClampToEdgeWrapping;
            }

            loadedTexture.needsUpdate = true;

            // Check if the material is a type that supports texture maps
            if (
                material instanceof THREE.MeshStandardMaterial ||
                material instanceof THREE.MeshPhongMaterial ||
                material instanceof THREE.MeshLambertMaterial
            ) {
                if (mapType === "bumpMap") {
                    material.bumpMap = loadedTexture;
                    material.bumpScale = materialSettings.bumpScale || 0.1;
                } else if (mapType === "displacementMap") {
                    material.displacementMap = loadedTexture;
                    material.displacementScale = materialSettings.displacementScale || 1;
                } else if (mapType === "map") {
                    material.map = loadedTexture;
                } else if (mapType === "normalMap") {
                    material.normalMap = loadedTexture;
                    material.normalScale = new THREE.Vector2(normalScale, normalScale);
                }

                material.needsUpdate = true;
            } else {
                console.warn(
                    "This material type does not support texture maps like bumpMap, displacementMap, or normalMap.",
                );
            }

            material.needsUpdate = true;
        });
    };

    const applyBumpAndDisplacementMaps = (material: THREE.Material, materialSettings: any) => {
        const tileRepeatX = materialSettings.tileRepeatX || 1;
        const tileRepeatZ = materialSettings.tileRepeatZ || 1;
        const loader = new THREE.TextureLoader();

        if (materialSettings.textureMapBase64) {
            applyTextureWithTiling(
                loader,
                materialSettings.textureMapBase64,
                material,
                "map",
                materialSettings,
                tileRepeatX,
                tileRepeatZ,
            );
        }

        if (materialSettings.textureBumpBase64) {
            applyTextureWithTiling(
                loader,
                materialSettings.textureBumpBase64,
                material,
                "bumpMap",
                materialSettings,
                tileRepeatX,
                tileRepeatZ,
            );
        }

        if (materialSettings.textureDisplacementBase64) {
            applyTextureWithTiling(
                loader,
                materialSettings.textureDisplacementBase64,
                material,
                "displacementMap",
                materialSettings,
                tileRepeatX,
                tileRepeatZ,
            );
        }

        if (materialSettings.textureNormalBase64) {
            applyTextureWithTiling(
                loader,
                materialSettings.textureNormalBase64,
                material,
                "normalMap",
                materialSettings,
                tileRepeatX,
                tileRepeatZ,
            );
        }
    };

    const updateMaterialProperties = (selected: THREE.Object3D) => {
        const objectsWithMaterials = getAllChildrenWithMaterials(selected);

        objectsWithMaterials.forEach(object => {
            const material = object.material;
            const materialSettings = selected.userData.materialSettings || {};

            if (material instanceof THREE.MeshStandardMaterial || material instanceof THREE.MeshPhysicalMaterial) {
                material.side = materialSettings.isDoubleSided ? THREE.DoubleSide : THREE.FrontSide;
                material.wireframe = materialSettings.isWireframe || false;
                material.transparent = materialSettings.isTransparent || false;
                material.opacity = materialSettings.opacity !== undefined ? materialSettings.opacity : 1;
                material.roughness = materialSettings.roughness !== undefined ? materialSettings.roughness : 0.5;
                material.metalness = materialSettings.metalness !== undefined ? materialSettings.metalness : 0;

                applyBumpAndDisplacementMaps(material, materialSettings);

                material.needsUpdate = true;
            } else if (material instanceof THREE.MeshBasicMaterial || material instanceof THREE.MeshPhongMaterial) {
                material.side = materialSettings.isDoubleSided ? THREE.DoubleSide : THREE.FrontSide;
                material.wireframe = materialSettings.isWireframe || false;
                material.transparent = materialSettings.isTransparent || false;
                material.opacity = materialSettings.opacity !== undefined ? materialSettings.opacity : 1;

                material.needsUpdate = true;
            } else {
                console.warn(`Material type does not support bump or displacement maps: ${material.type}`);
            }
        });
    };

    const handleMapImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = getSelectedObject();
        if (!selected) return;

        if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = upload => {
                const result = upload.target?.result;
                const base64Image = result as string;

                setMaterialMapImage(base64Image);

                selected.userData.materialSettings = {
                    ...(selected.userData.materialSettings || {}),
                    textureMapBase64: base64Image,
                };

                updateMaterialProperties(selected);
                app.call("objectChanged", app.editor, selected);
            };
            reader.readAsDataURL(file);
        } else {
            setMaterialMapImage("");
            selected.userData.materialSettings = {
                ...(selected.userData.materialSettings || {}),
                textureMapBase64: null,
            };

            updateMaterialProperties(selected);
            app.call("objectChanged", app.editor, selected);
        }
    };

    const handleBumpImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = getSelectedObject();
        if (!selected) return;

        if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = upload => {
                const result = upload.target?.result;
                const base64Image = result as string;

                setMaterialBumpImage(base64Image);

                selected.userData.materialSettings = {
                    ...(selected.userData.materialSettings || {}),
                    textureBumpBase64: base64Image,
                };

                updateMaterialProperties(selected);
                app.call("objectChanged", app.editor, selected);
            };
            reader.readAsDataURL(file);
        } else {
            setMaterialBumpImage("");
            selected.userData.materialSettings = {
                ...(selected.userData.materialSettings || {}),
                textureBumpBase64: null,
            };

            updateMaterialProperties(selected);
            app.call("objectChanged", app.editor, selected);
        }
    };

    const handleNormalImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = getSelectedObject();
        if (!selected) return;

        if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = upload => {
                const result = upload.target?.result;
                const base64Image = result as string;

                setMaterialNormalImage(base64Image);

                selected.userData.materialSettings = {
                    ...(selected.userData.materialSettings || {}),
                    textureNormalBase64: base64Image,
                };

                updateMaterialProperties(selected);
                app.call("objectChanged", app.editor, selected);
            };
            reader.readAsDataURL(file);
        } else {
            setMaterialNormalImage("");
            selected.userData.materialSettings = {
                ...(selected.userData.materialSettings || {}),
                textureNormalBase64: null,
            };

            updateMaterialProperties(selected);
            app.call("objectChanged", app.editor, selected);
        }
    };

    const handleDisplacementImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = getSelectedObject();
        if (!selected) return;

        if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = upload => {
                const result = upload.target?.result;
                const base64Image = result as string;

                setMaterialDisplacementImage(base64Image);

                selected.userData.materialSettings = {
                    ...(selected.userData.materialSettings || {}),
                    textureDisplacementBase64: base64Image,
                };

                updateMaterialProperties(selected);
                app.call("objectChanged", app.editor, selected);
            };
            reader.readAsDataURL(file);
        } else {
            setMaterialBumpImage("");
            selected.userData.materialSettings = {
                ...(selected.userData.materialSettings || {}),
                textureDisplacementBase64: null,
            };

            updateMaterialProperties(selected);
            app.call("objectChanged", app.editor, selected);
        }
    };

    useEffect(() => {
        const selected = getSelectedObject();

        if (selected) {
            const materialSettings = selected.userData.materialSettings || {};
            const objectsWithMaterials = getAllChildrenWithMaterials(selected);

            if (objectsWithMaterials.length > 0) {
                const material = objectsWithMaterials[0].material as THREE.MeshStandardMaterial;
                setIsDoubleSided(materialSettings.isDoubleSided ?? material.side === THREE.DoubleSide);
                setIsWireframe(materialSettings.isWireframe ?? material.wireframe === true);
                setIsTransparent(materialSettings.isTransparent ?? material.transparent === true);
                setOpacity(materialSettings.opacity ?? material.opacity ?? 1);
                setRoughness(materialSettings.roughness ?? material.roughness ?? 0.5);
                setMetalness(materialSettings.metalness ?? material.metalness ?? 0.5);
                setBumpScale(materialSettings.bumpScale ?? material.bumpScale ?? 0.5);
                setDisplacementScale(materialSettings.displacementScale ?? material.displacementScale ?? 0.5);
                setIsTiling(materialSettings.isTiling || false);
                setMaterialType(materialSettings.materialType || MATERIAL_TYPES.MESH_PHONG);
                setMaterialMapImage(materialSettings.textureMapBase64 || "");
                setMaterialBumpImage(materialSettings.textureBumpBase64 || "");
                setMaterialNormalImage(materialSettings.textureNormalBase64 || "");
                setMaterialDisplacementImage(materialSettings.textureDisplacementBase64 || "");
            } else {
                setIsDoubleSided(false);
                setIsWireframe(false);
                setIsTransparent(false);
                setOpacity(1);
                setRoughness(0.5);
                setMetalness(0.5);
                setBumpScale(0.5);
                setDisplacementScale(0.5);
                setIsTiling(false);
                setMaterialType(MATERIAL_TYPES.MESH_PHONG);
                setMaterialMapImage("");
                setMaterialBumpImage("");
                setMaterialNormalImage("");
                setMaterialDisplacementImage("");
            }
        }
    }, [editor?.selected]);

    const geometry = selected?.geometry;
    let isComplexModel = false;
    if (geometry && geometry.attributes && geometry.attributes.position) {
        isComplexModel = geometry.attributes.position.count >= 6;
    }

    return (
        <div className="Section MaterialSection">
            <SelectRow
                $margin="0"
                label="Material Type"
                data={materialTypeOptions}
                value={
                    materialTypeOptions.find(item => item.value === materialType) || {
                        key: "0",
                        value: MATERIAL_TYPES.MESH_BASIC,
                    }
                }
                onChange={item => handleMaterialTypeChange(item?.value || MATERIAL_TYPES.MESH_BASIC)}
            />

            <PanelCheckbox v2 text="Double Sided" checked={isDoubleSided} isGray regular onChange={toggleDoubleSided} />
            <PanelCheckbox v2 text="Transparent" checked={isTransparent} isGray regular onChange={toggleTransparent} />
            <PanelCheckbox
                v2
                text="Wireframe Preview"
                checked={isWireframe}
                isGray
                regular
                onChange={toggleWireframe}
            />
            {selected && isComplexModel && (
                <PanelCheckbox v2 text="Tile Texture" checked={isTiling} isGray regular onChange={toggleTiling} />
            )}

            {isTiling && isComplexModel && (
                <>
                    <label>
                        <PanelSectionTitleSecondary>Repeat X ({tileRepeatX})</PanelSectionTitleSecondary>
                        <MaterialTileRange volume={tileRepeatX} setVolume={value => handleTileRepeatXChange(value)} />
                    </label>

                    <label>
                        <PanelSectionTitleSecondary>Repeat Z ({tileRepeatZ})</PanelSectionTitleSecondary>
                        <MaterialTileRange volume={tileRepeatZ} setVolume={value => handletileRepeatZChange(value)} />
                    </label>
                </>
            )}

            {isTransparent && (
                <label>
                    <PanelSectionTitleSecondary>Opacity ({opacity})</PanelSectionTitleSecondary>
                    <StyledRange volume={opacity} setVolume={value => handleOpacityChange(value)} />
                </label>
            )}

            <label>
                <PanelSectionTitleSecondary>Roughness ({roughness})</PanelSectionTitleSecondary>
                <StyledRange volume={roughness} setVolume={value => handleRoughnessChange(value)} />
            </label>

            <label>
                <PanelSectionTitleSecondary>Metalness ({metalness})</PanelSectionTitleSecondary>
                <StyledRange volume={metalness} setVolume={value => handleMetalnessChange(value)} />
            </label>

            {selected && isComplexModel && (
                <MaterialMapSection
                    label="Map"
                    image={materialMapImage}
                    setImage={setMaterialMapImage}
                    handleMapImageChange={handleMapImageChange}
                    imageKeyName="materialMapImage"
                    inputId="materialMapInput"
                    selected={selected}
                    updateMaterialProperties={updateMaterialProperties}
                />
            )}

            {selected && isComplexModel && (
                <MaterialNormalSection
                    label="Normal"
                    image={materialNormalImage}
                    setImage={setMaterialNormalImage}
                    handleNormalImageChange={handleNormalImageChange}
                    imageKeyName="materialNormalImage"
                    inputId="materialNormalInput"
                    selected={selected}
                    updateMaterialProperties={updateMaterialProperties}
                />
            )}

            {selected && isComplexModel && (
                <label>
                    <PanelSectionTitleSecondary>Normal Scale ({normalScale})</PanelSectionTitleSecondary>
                    <StyledRange min={0} max={50} step={1} value={normalScale} setValue={handleNormalScaleChange} />
                </label>
            )}

            {selected && isComplexModel && (
                <MaterialBumpSection
                    label="Bump"
                    image={materialBumpImage}
                    setImage={setMaterialBumpImage}
                    handleBumpImageChange={handleBumpImageChange}
                    imageKeyName="materialBumpImage"
                    inputId="materialBumpInput"
                    selected={selected}
                    updateMaterialProperties={updateMaterialProperties}
                />
            )}

            {selected && isComplexModel && (
                <label>
                    <PanelSectionTitleSecondary>Bump Scale ({bumpScale})</PanelSectionTitleSecondary>
                    <StyledRange min={0} max={10} step={0.25} value={bumpScale} setValue={handleBumpScaleChange} />
                </label>
            )}

            {selected && isComplexModel && (
                <MaterialDisplacementSection
                    label="Displacement"
                    image={materialDisplacementImage}
                    setImage={setMaterialDisplacementImage}
                    handleDisplacementImageChange={handleDisplacementImageChange}
                    imageKeyName="materialDisplacementImage"
                    inputId="materialDisplacementInput"
                    selected={selected}
                    updateMaterialProperties={updateMaterialProperties}
                />
            )}

            {selected && isComplexModel && (
                <label>
                    <PanelSectionTitleSecondary>Displacement Scale ({displacementScale})</PanelSectionTitleSecondary>
                    <StyledRange
                        min={0}
                        max={50}
                        step={1}
                        value={displacementScale}
                        setValue={handleDisplacementScaleChange}
                    />
                </label>
            )}
        </div>
    );
};
