import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import { COLLISION_TYPE, SpriteBehaviorInterface, OBJECT_TYPES, MOVEMENT_STATES, GAME_STATE } from "../../types/editor";
import { Object3D } from "three";
import SpriteMixer from "../../utils/SpriteMixer";
import CameraUtils from "../../utils/CameraUtils";

class SpriteBehaviorUpdater implements BehaviorUpdater {
    game?: GameManager;
    target: Object3D;
    usingPhysics: boolean = false;
    isDynamic: boolean = false;
    behavior: SpriteBehaviorInterface;

    spriteMixer: any;
    actionSprite: THREE.Sprite | null = null;
    actions: any = {}; 

    isKeyListenerAdded: boolean = false;

    public direction: string = ''; 
    private currentDirection: string = '';  
    previousPosition = new THREE.Vector3();

    constructor(target: Object3D, behavior: SpriteBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.spriteMixer = SpriteMixer(THREE);  
        this.previousPosition = new THREE.Vector3(this.target.position.x, this.target.position.y, this.target.position.z);

    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);

        new THREE.TextureLoader().load(this.behavior.uiImage, (texture) => {

            this.actionSprite = this.spriteMixer.ActionSprite(texture, this.behavior.frameColumns, this.behavior.frameRows);

            this.actions.moveLeft = this.spriteMixer.Action(this.actionSprite,
                this.behavior.moveLeftStart,
                this.behavior.moveLeftEnd,
                this.behavior.frameRate); 

            this.actions.moveRight = this.spriteMixer.Action(this.actionSprite,
                this.behavior.moveRightStart,
                this.behavior.moveRightEnd,
                this.behavior.frameRate); 

            this.actions.moveForward = this.spriteMixer.Action(this.actionSprite,
                this.behavior.moveForwardStart,
                this.behavior.moveForwardEnd,
                this.behavior.frameRate); 

            this.actions.moveBackward = this.spriteMixer.Action(this.actionSprite,
                this.behavior.moveBackwardStart,
                this.behavior.moveBackwardEnd,
                this.behavior.frameRate); 

            this.actionSprite!.scale.copy(this.target.scale);

            this.actionSprite!.position.copy(this.target.position);

            this.target.visible = false;
            this.game!.scene!.add(this.actionSprite!);

            this.actions.moveRight.playLoop();
            this.actions.moveRight.clampWhenFinished = false;
            this.actions.moveRight.hideWhenFinished = false;

            this.spriteMixer.addEventListener('finished', (event: any) => {
                console.log('Animation Finished:', event.action);
            });

        });

        CameraUtils.disableFromCameraCollision(this.target);
    }

    update(clock: THREE.Clock, delta: number): void {
      
        if (!this.game || !this.game.player || !this.game.scene ||
            !this.spriteMixer || !this.actionSprite ||
            this.game.state == GAME_STATE.PAUSED) {
            return;
        }

        this.actionSprite.position.copy(this.target.position);

        // Handle character sprites
        if (this.target.userData.behaviors.find((object: any) => object.type === OBJECT_TYPES.CHARACTER)) {
            this.direction = this.target.userData.movementDirection || MOVEMENT_STATES.STOPPED; // Set STOPPED if no direction
        } else { // Handle all other sprites if moving
            const targetDirection = new THREE.Vector3();
            this.target.getWorldDirection(targetDirection);

            const direction2D = new THREE.Vector2(targetDirection.x, targetDirection.z);
            direction2D.normalize();

            const angle = Math.atan2(direction2D.y, direction2D.x);

            if (angle > -Math.PI / 4 && angle <= Math.PI / 4) {
           
                if (targetDirection.x > 0) {
                    this.direction = MOVEMENT_STATES.FORWARD_RIGHT;
                   
                } else if (targetDirection.x < 0) {
                    this.direction = MOVEMENT_STATES.FORWARD_LEFT;
                } else {
                    this.direction = MOVEMENT_STATES.FORWARD;
                }
            } else if (angle > Math.PI / 4 && angle <= 3 * Math.PI / 4) {
                this.direction = MOVEMENT_STATES.RIGHT;
            } else if (angle > -3 * Math.PI / 4 && angle <= -Math.PI / 4) {
                this.direction = MOVEMENT_STATES.LEFT;
            } else {
               
                if (targetDirection.x > 0) {
                    this.direction = MOVEMENT_STATES.BACKWARD_RIGHT;
                } else if (targetDirection.x < 0) {
                    this.direction = MOVEMENT_STATES.BACKWARD_LEFT;
                } else {
                    this.direction = MOVEMENT_STATES.BACKWARD;
                }
            }

            this.target.userData.movementDirection = this.direction || MOVEMENT_STATES.STOPPED

        }

        this.moveAction();

        this.spriteMixer.update(delta);
    }

    moveAction(): void {

        if (this.direction === MOVEMENT_STATES.STOPPED) {
            // Handle the STOPPED state
            if (this.currentDirection == MOVEMENT_STATES.RIGHT) {
                this.actions.moveRight.pause();
                this.actions.moveRight.actionSprite.setFrame(this.behavior.frameRightStanding);
            }

            if (this.currentDirection == MOVEMENT_STATES.LEFT) {
                this.actions.moveLeft.pause();
                this.actions.moveLeft.actionSprite.setFrame(this.behavior.frameLeftStanding);
            }

            if (this.currentDirection == MOVEMENT_STATES.FORWARD) {
                this.actions.moveForward.pause();
                this.actions.moveForward.actionSprite.setFrame(this.behavior.frameForwardStanding);
            }

            if (this.currentDirection == MOVEMENT_STATES.BACKWARD) {
                this.actions.moveBackward.pause();
                this.actions.moveBackward.actionSprite.setFrame(this.behavior.frameBackwardStanding);
            }

            return;
        } else {
            this.actions.moveRight.resume();
            this.actions.moveLeft.resume();
            this.actions.moveForward.resume();
            this.actions.moveBackward.resume();
        }

       
        if (this.direction === MOVEMENT_STATES.RIGHT && this.currentDirection !== MOVEMENT_STATES.RIGHT) {
            this.actions.moveRight.playLoop();
            this.currentDirection = MOVEMENT_STATES.RIGHT;

        } else if (this.direction === MOVEMENT_STATES.LEFT && this.currentDirection !== MOVEMENT_STATES.LEFT) {
            this.actions.moveLeft.playLoop();
            this.currentDirection = MOVEMENT_STATES.LEFT;

        } else if (this.direction === MOVEMENT_STATES.FORWARD_LEFT && this.currentDirection !== MOVEMENT_STATES.FORWARD_LEFT) {
            this.actions.moveLeft.playLoop();
            this.currentDirection = MOVEMENT_STATES.FORWARD_LEFT;

        } else if (this.direction === MOVEMENT_STATES.FORWARD_RIGHT && this.currentDirection !== MOVEMENT_STATES.FORWARD_RIGHT) {
            this.actions.moveRight.playLoop();
            this.currentDirection = MOVEMENT_STATES.FORWARD_RIGHT;

        } else if (this.direction === MOVEMENT_STATES.BACKWARD_LEFT && this.currentDirection !== MOVEMENT_STATES.BACKWARD_LEFT) {
            this.actions.moveLeft.playLoop();
            this.currentDirection = MOVEMENT_STATES.BACKWARD_LEFT;

        } else if (this.direction === MOVEMENT_STATES.BACKWARD_RIGHT && this.currentDirection !== MOVEMENT_STATES.BACKWARD_RIGHT) {
            this.actions.moveRight.playLoop();
            this.currentDirection = MOVEMENT_STATES.BACKWARD_RIGHT;

        } else if (this.direction === MOVEMENT_STATES.FORWARD && this.currentDirection !== MOVEMENT_STATES.FORWARD) {
            this.actions.moveForward.playLoop();
            this.currentDirection = MOVEMENT_STATES.FORWARD;

        } else if (this.direction === MOVEMENT_STATES.BACKWARD && this.currentDirection !== MOVEMENT_STATES.BACKWARD) {
            this.actions.moveBackward.playLoop();
            this.currentDirection = MOVEMENT_STATES.BACKWARD;
        }
    }

    reset() { }

}


export default SpriteBehaviorUpdater;
