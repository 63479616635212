import {useState} from "react";
import {ThrowableBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {ImageSection} from "../common/ImageSection";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {THROWABLE_MAIN_PROPS} from "./helpers/throwableUIData";

type Props = {
    behavior: ThrowableBehaviorInterface;
};

export const ThrowableBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find((userBehavior: any) => userBehavior.id === behavior.id);

            return objBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior[name] = value;
                setBehavior({...objBehavior, [name]: value});
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start With Throwable Object"
                v2
                isGray
                regular
                checked={!!behavior.throwableStarting}
                onChange={() => handleInputChange(!behavior.throwableStarting, "throwableStarting")}
            />
            <Separator invisible margin="8px 0" />
            <PanelCheckbox
                text="Show Object"
                v2
                isGray
                regular
                checked={!!behavior.throwableVisible}
                onChange={() => handleInputChange(!behavior.throwableVisible, "throwableVisible")}
            />
            <Separator />
            <ImageSection
                text="Throwable UI Image"
                uploadedFile={behavior.uiImage || null}
                setUploadedFile={value => handleInputChange(value, "uiImage")}
            />
            {THROWABLE_MAIN_PROPS.map(({label, key}) => (
                <NumericInputRow
                    width="75px"
                    label={label}
                    value={behavior[key as keyof ThrowableBehaviorInterface] as number}
                    setValue={value => handleInputChange(value, key)}
                />
            ))}
            <Separator margin="0 0 8px" />
            <SelectionOfButtons margin="0 auto">
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlueSecondary={behavior.aimer}
                    isActive={!behavior.aimer}
                    onClick={() => handleInputChange(!behavior.aimer, "aimer")}>
                    <span>Aimer</span>
                </StyledButton>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlueSecondary={behavior.aimerGuide}
                    isActive={!behavior.aimerGuide}
                    onClick={() => handleInputChange(!behavior.aimerGuide, "aimerGuide")}>
                    <span>Aimer Guide</span>
                </StyledButton>
            </SelectionOfButtons>
        </>
    );
};
