import {useEffect, useState} from "react";
import * as THREE from "three";

import {roundNumber} from "../../utils/roundNumber";
import {BILLBOARD_TYPES, BillboardBehaviorInterface, ITransformValue, OBJECT_TYPES} from "../../../../../types/editor";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {FileData} from "../../types/file";

import {TransformationSection} from "../sections/TransformationSection";
import {Separator} from "../common/Separator";
import {TextInputRow} from "../common/TextInputRow";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {SelectRow} from "../common/SelectRow";
import {Item} from "../../common/BasicCombobox";
import {UploadField} from "../../common/UploadField/UploadField";
import styled from "styled-components";
import AddObjectCommand from "../../../../../command/AddObjectCommand.js";
import RemoveObjectCommand from "../../../../../command/RemoveObjectCommand.js";
import Billboard from "../../../../../utils/Billboard";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";

async function checkFileType(url: string): Promise<"video" | "gif" | "image" | "unknown"> {
    try {
        const response = await fetch(url, {method: "HEAD"});
        const contentType = response.headers.get("Content-Type");

        if (!contentType) {
            return "unknown";
        }

        // Sprawdź typ MIME i zwróć odpowiedni typ pliku
        if (contentType.startsWith("video/")) {
            return "video";
        } else if (contentType === "image/gif") {
            return "gif";
        } else if (contentType.startsWith("image/")) {
            return "image";
        } else {
            return "unknown";
        }
    } catch (error) {
        console.error("Error fetching file type:", error);
        return "unknown";
    }
}

const bilboardModeOptions: Item[] = [
    {
        key: "0",
        value: BILLBOARD_TYPES.IMAGE,
    },
    {
        key: "1",
        value: BILLBOARD_TYPES.WEB,
    },
    {
        key: "2",
        value: BILLBOARD_TYPES.YT_VIDEO,
    },
];

export const BillboardPanel = () => {
    const app = (global.app as Application) || null;
    const editor = app?.editor;
    const [uploadedFile, setUploadedFile] = useState<FileData | string | null>(null);
    const [behavior, setBehavior] = useState<BillboardBehaviorInterface | null>(null);
    const [positionValue, setPositionValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [rotationValue, setRotationValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const [scaleValue, setScaleValue] = useState<ITransformValue>({
        x: 0,
        y: 0,
        z: 0,
    });
    const isAssetProvided = !!uploadedFile || !!behavior?.assetFile;

    const updateTransformationValues = () => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;

        setPositionValue({
            x: roundNumber(selected.position.x, 4),
            y: roundNumber(selected.position.y, 4),
            z: roundNumber(selected.position.z, 4),
        });
        setRotationValue({
            x: roundNumber((selected.rotation as any)._x * THREE.MathUtils.RAD2DEG, 2),
            y: roundNumber((selected.rotation as any)._y * THREE.MathUtils.RAD2DEG, 2),
            z: roundNumber((selected.rotation as any)._z * THREE.MathUtils.RAD2DEG, 2),
        });
        setScaleValue({
            x: roundNumber(selected.scale.x, 4),
            y: roundNumber(selected.scale.y, 4),
            z: roundNumber(selected.scale.z, 4),
        });
    };

    const getObjectBehavior = () => {
        const selected = editor?.selected;
        if (selected && !app?.editor?.selected) {
            app.editor!.select(selected);
        }
        if (selected) {
            const obj = app?.editor?.objectByUuid((selected as any).uuid as string);
            let objBehavior;
            if (obj) {
                if (obj.userData.behaviors && !Array.isArray(obj.userData.behaviors)) return;
                objBehavior = obj.userData.behaviors?.find((behavior: any) => behavior.type === OBJECT_TYPES.BILLBOARD);
            }
            return objBehavior;
        }
        return null;
    };

    const handleBehaviorChange = (name: keyof BillboardBehaviorInterface, value: any) => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;
        const targetBehavior: any = getObjectBehavior();
        if (targetBehavior) {
            targetBehavior[name] = value;
        }
        setBehavior({...targetBehavior, [name]: value});

        if (
            name === "assetFile" ||
            name === "loop" ||
            name === "faceCamera" ||
            name === "twoSided" ||
            name === "billboardMode" ||
            name === "urlLink" ||
            name === "transparent"
        ) {
            replaceBillbaord(targetBehavior);
        }
    };

    const update = () => {
        setBehavior(getObjectBehavior());
        updateTransformationValues();
    };

    useEffect(() => {
        update();
    }, []);

    useEffect(() => {
        if (app) {
            app.on(`objectSelected.BillboardPanel`, () => {
                update();
                setUploadedFile(null);
            });
            app.on(`objectChanged.BillboardPanel`, update);
        }

        return () => {
            app?.on(`objectSelected.BillboardPanel`, null);
            app?.on(`objectChanged.BillboardPanel`, null);
        };
    }, [app]);

    const uploadHandler = (fileUrl: string) => {
        handleBehaviorChange("assetFile", fileUrl);
    };

    const checkYTandImageProps = () => {
        const mode = behavior?.billboardMode;
        if (mode === BILLBOARD_TYPES.YT_VIDEO) {
            return true;
        } else if (mode === BILLBOARD_TYPES.IMAGE && isAssetProvided) {
            return true;
        } else if (mode === BILLBOARD_TYPES.WEB) {
            return true;
        } else {
            return false;
        }
    };
    const replaceBillbaord = async (objBehavior: BillboardBehaviorInterface) => {
        const selected = editor?.selected;
        if (!selected || Array.isArray(selected)) return;

        const type = await checkFileType(objBehavior.assetFile || "");

        let color = "#ffffff";

        if (selected instanceof THREE.Mesh && selected.material instanceof THREE.MeshBasicMaterial) {
            color = `#${selected.material.color.getHexString()}`;
        }

        if (selected instanceof THREE.Sprite && selected.material instanceof THREE.SpriteMaterial) {
            color = `#${selected.material.color.getHexString()}`;
        }

        const billbaord = new Billboard();

        await billbaord.create(
            objBehavior.billboardMode || BILLBOARD_TYPES.IMAGE,
            backendUrlFromPath(objBehavior.assetFile || "") || "",
            objBehavior.urlLink || "",
            10,
            10,
            !!objBehavior.faceCamera,
            type,
            !!objBehavior.loop,
            !!objBehavior.twoSided,
            !!objBehavior.transparent,
            color,
        );

        if (billbaord.mesh) {
            billbaord.mesh.name = selected.name;
            Object.assign(billbaord.mesh.userData, selected.userData);
            Object.assign(billbaord.mesh.position, selected.position);

            if (billbaord.mesh instanceof THREE.Sprite && selected instanceof THREE.Sprite) {
                Object.assign(billbaord.mesh.scale, selected.scale);
            }

            if (billbaord.mesh instanceof THREE.Sprite && selected instanceof THREE.Mesh) {
                const scale = selected.scale;
                billbaord.mesh.scale.set(10 * scale.x, 10 * scale.y, 1);
            }

            if (billbaord.mesh instanceof THREE.Mesh && selected instanceof THREE.Sprite) {
                const scale = selected.scale;
                billbaord.mesh.scale.set(scale.x / 10, scale.y / 10, 1);
            }

            if (billbaord.mesh instanceof THREE.Mesh && selected instanceof THREE.Mesh) {
                Object.assign(billbaord.mesh.scale, selected.scale);
            }

            billbaord.mesh.rotation.x = selected!.rotation.x;
            billbaord.mesh.rotation.y = selected!.rotation.y;
            billbaord.mesh.rotation.z = selected!.rotation.z;

            app.editor?.execute(new (AddObjectCommand as any)(billbaord.mesh));
            if (selected.children.length === 1) {
                app.editor?.execute(new (RemoveObjectCommand as any)(selected.children[0]));
            }
            app.editor?.execute(new (RemoveObjectCommand as any)(selected));
        }
    };

    return (
        <>
            <TransformationSection
                positionValue={positionValue}
                setPositionValue={setPositionValue}
                rotationValue={rotationValue}
                setRotationValue={setRotationValue}
                scaleValue={scaleValue}
                setScaleValue={setScaleValue}
                hideVisibility
                hideVisibilitySection
            />
            <Separator />
            <span className="common-text white-bold">Content</span>
            <Separator invisible margin="8px 0" />
            <SelectRow
                width="120px"
                label="Content Type"
                disableTyping
                data={bilboardModeOptions}
                value={
                    bilboardModeOptions.find(item => item.value === behavior?.billboardMode) || bilboardModeOptions[0]
                }
                onChange={item => handleBehaviorChange("billboardMode", item.value)}
            />
            <Separator invisible />
            {behavior?.billboardMode !== BILLBOARD_TYPES.IMAGE ? (
                <TextInputRow
                    label="URL"
                    value={behavior?.urlLink || ""}
                    setValue={value => handleBehaviorChange("urlLink", value)}
                    margin="0"
                    height="24px"
                    width="120px"
                    placeholder="https://www..."
                />
            ) : (
                <>
                    {isAssetProvided && (
                        <>
                            <div className="common-text">Current Content</div>
                            <ImgPreview $bgImage={behavior.assetFile || (uploadedFile as string)} />
                        </>
                    )}
                    <UploadField
                        width="100%"
                        height="32px"
                        setUploadedFile={setUploadedFile}
                        uploadHandler={uploadHandler}
                        style={{backgroundColor: "var(--theme-grey-bg)"}}
                        v2
                        label={isAssetProvided ? "Replace Image" : "Upload Image"}
                    />
                    <Separator invisible margin="8px 0 0 0" />
                    <div className="common-text">Allowed filetypes: .jpeg, .png, and .gif</div>
                </>
            )}
            {checkYTandImageProps() && (
                <>
                    <Separator />
                    <span className="common-text white-bold">Content Settings</span>
                    {behavior?.billboardMode !== BILLBOARD_TYPES.WEB && (
                        <>
                            <Separator invisible />
                            <PanelCheckbox
                                isGray
                                regular
                                text={
                                    behavior?.billboardMode === BILLBOARD_TYPES.IMAGE ? "Loop Animations" : "Loop Video"
                                }
                                checked={!!behavior?.loop}
                                onChange={() => handleBehaviorChange("loop", !behavior?.loop)}
                                v2
                            />
                        </>
                    )}
                    <Separator invisible />
                    <PanelCheckbox
                        isGray
                        regular
                        text="Always Face Camera"
                        checked={!!behavior?.faceCamera}
                        onChange={() => handleBehaviorChange("faceCamera", !behavior?.faceCamera)}
                        v2
                    />
                    <Separator invisible />
                    <PanelCheckbox
                        isGray
                        regular
                        text="2 Sided"
                        checked={!!behavior?.twoSided}
                        onChange={() => handleBehaviorChange("twoSided", !behavior?.twoSided)}
                        v2
                    />
                    {behavior?.billboardMode === BILLBOARD_TYPES.IMAGE && (
                        <>
                            <Separator invisible />
                            <PanelCheckbox
                                isGray
                                regular
                                text="Transparent"
                                checked={!!behavior?.transparent}
                                onChange={() => handleBehaviorChange("transparent", !behavior?.transparent)}
                                v2
                            />
                        </>
                    )}
                </>
            )}
            <Separator />
        </>
    );
};

const ImgPreview = styled.div<{$bgImage?: string}>`
    border-radius: 8px;
    background-color: var(--theme-grey-bg);
    wifth: 100%;
    height: 126px;
    margin: 8px 0;
    ${({$bgImage}) =>
        $bgImage &&
        `
        background-image: url('${$bgImage}');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    
    `}
`;
