import PlatformBehaviorConverter from "./PlatformBehaviorConverter";
import SpawnPointBehaviorConverter from "./SpawnPointBehaviorConverter";
import JumpPadBehaviorConverter from "./JumpPadBehaviorConverter";
import FollowBehaviorConverter from "./FollowBehaviorConverter";
import TeleportBehaviorConverter from "./TeleportBehaviorConverter";
import CharacterBehaviorConverter from "./CharacterBehaviorConverter";
import AnimationBehaviorConverter from "./AnimationBehaviorConverter";
import EnemyBehaviorConverter from "./EnemyBehaviorConverter";
import ThrowableBehaviorConverter from "./ThrowableBehaviorConverter";
import ConsumableBehaviorConverter from "./ConsumableBehaviorConverter";
import VolumeBehaviorConverter from "./VolumeBehaviorConverter";
import WeaponBehaviorConverter from "./WeaponBehaviorConverter";
import ScriptBehaviorConverter from "./ScriptBehaviorConverter";
import CheckPointBehaviorAssetConverter from "./CheckPointBehaviorAssetConverter";
import SceneVolumeBehaviorAssetConverter from "./SceneVolumeBehaviorAssetConverter";
import SpawnPointBehaviorAssetConverter from "./SpawnPointBehaviorAssetConverter";
import WeaponAmmoBehaviorConverter from "./WeaponAmmoBehaviorConverter";
import AiNPCBehaviorConverter from "./AiNpcBehaviorsConverter";
import {BehaviorInterface, INVENTORY_TYPES, OBJECT_TYPES} from "../../types/editor";
import BillboardBehaviorConverter from "./BillboardBehaviorConverter";
import PropAnimationBehaviorConverter from "./PropAnimationBehaviorConverter";
import TriggerBehaviorConverter from "./TriggerBehaviorConverter";
import CameraBehaviorConverter from "./CameraBehaviorConverter";
import InventoryManagerBehaviorConverter from "./InventoryManagerBehaviorConverter";
import NPCBehaviorConverter from "./NPCBehaviorConverter";
import NPCReceiverBehaviorConverter from "./NPCReceiverBehaviorConverter";
import SpriteBehaviorConverter from "./SpriteBehaviorConverter"; 
import MobileTouchControlBehaviorConverter from "./MobileTouchControlBehaviorConverter";


class BehaviorConverter {
    obj: any;

    //converters
    private platformBehaviorConverter?: PlatformBehaviorConverter;
    private spawnPointBehaviorConverter?: SpawnPointBehaviorConverter;
    private jumpPadBehaviorConverter?: JumpPadBehaviorConverter;
    private followBehaviorConverter?: FollowBehaviorConverter;
    private teleportBehaviorConverter?: TeleportBehaviorConverter;
    private triggerBehaviorConverter?: TriggerBehaviorConverter;
    private characterBehaviorConverter?: CharacterBehaviorConverter;
    private animationBehaviorConverter?: AnimationBehaviorConverter;
    private enemyBehaviorConverter?: EnemyBehaviorConverter;
    private throwableBehaviorConverter?: ThrowableBehaviorConverter;
    private consumableBehaviorConverter?: ConsumableBehaviorConverter;
    private volumeBehaviorConverter?: VolumeBehaviorConverter;
    private weaponBehaviorConverter?: WeaponBehaviorConverter;
    private scriptBehaviorConverter?: ScriptBehaviorConverter;
    private checkPointBehaviorAssetConverter?: CheckPointBehaviorAssetConverter;
    private spawnPointBehaviorAssetConverter?: SpawnPointBehaviorAssetConverter;
    private sceneVolumeBehaviorAssetConverter?: SceneVolumeBehaviorAssetConverter;
    private billboardBehaviorConverter?: BillboardBehaviorConverter;
    private weaponAmmoBehaviorConverter?: WeaponAmmoBehaviorConverter;
    private aiNpcBehaviorConverter?: AiNPCBehaviorConverter;
    private propAnimationBehaviorConverter?: PropAnimationBehaviorConverter;
    private cameraBehaviorConverter?: CameraBehaviorConverter;
    private inventoryManagerBehaviorConverter?: InventoryManagerBehaviorConverter;
    private npcBehaviorConverter?: NPCBehaviorConverter;
    private npcReceiverBehaviorConverter?: NPCReceiverBehaviorConverter;
    private spriteBehaviorConverter?: SpriteBehaviorConverter;
    private mobileTouchControlBehaviorConverter?: MobileTouchControlBehaviorConverter;
   
    constructor(obj: any) {
        this.obj = obj;
    }

    processBehaviours() {
        let scene = this.obj.scene;
        this.obj.behaviors = this.obj.behaviors || [];
        new CharacterBehaviorConverter(this.obj).resetCameraControl();
        new ScriptBehaviorConverter(this.obj).clearBehaviorScripts();

        scene.traverse((child: any) => {
            if (child.userData && (!child.userData.behaviors || child.userData.behaviors?.length === 0)) {
                new PlatformBehaviorConverter(this.obj).clearAnimationLayer(child);
                new AnimationBehaviorConverter(this.obj).clearAnimationLayer(child);
            }

            if (child.userData && child.userData.behaviors && Array.isArray(child.userData.behaviors)) {
                child.userData.behaviors.forEach((behavior: BehaviorInterface) => {
                    if (behavior.enabled) {
                        this.createBehavior(child, behavior);
                    }
                });
            }
        });
    }

    createBehavior(target: any, behaviorConfig: any) {
        let type = behaviorConfig.type;
        let converter = this.createConverter(type);
        let behavior = null;

        if (converter) {
            behavior = converter.convert(target, behaviorConfig);
            this.obj.behaviors.push(behavior);
        } else {
            console.warn(behaviorConfig);
        }
    }

    createConverter(type: OBJECT_TYPES | INVENTORY_TYPES) {
        if (type === OBJECT_TYPES.PLATFORM) {
            return (
                this.platformBehaviorConverter ||
                (this.platformBehaviorConverter = new PlatformBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.SPAWNPOINT) {
            return (
                this.spawnPointBehaviorConverter ||
                (this.spawnPointBehaviorConverter = new SpawnPointBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.INVENTORY) {
            return (
                this.inventoryManagerBehaviorConverter ||
                (this.inventoryManagerBehaviorConverter = new InventoryManagerBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.JUMPPAD) {
            return (
                this.jumpPadBehaviorConverter ||
                (this.jumpPadBehaviorConverter = new JumpPadBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.MOBILE_TOUCH_CONTROL) {
            return (
                this.mobileTouchControlBehaviorConverter ||
                (this.mobileTouchControlBehaviorConverter = new MobileTouchControlBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.NPC) {
            return this.npcBehaviorConverter || (this.npcBehaviorConverter = new NPCBehaviorConverter(this.obj));
        } else if (type === OBJECT_TYPES.NPC_RECEIVER) {
            return (
                this.npcReceiverBehaviorConverter ||
                (this.npcReceiverBehaviorConverter = new NPCReceiverBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.SPRITE) {
            return (
                this.spriteBehaviorConverter ||
                (this.spriteBehaviorConverter = new SpriteBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.TELEPORT) {
            return (
                this.teleportBehaviorConverter ||
                (this.teleportBehaviorConverter = new TeleportBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.TRIGGER) {
            return (
                this.triggerBehaviorConverter ||
                (this.triggerBehaviorConverter = new TriggerBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.FOLLOW) {
            return (
                this.followBehaviorConverter || (this.followBehaviorConverter = new FollowBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.CONSUMABLE) {
            return (
                this.consumableBehaviorConverter ||
                (this.consumableBehaviorConverter = new ConsumableBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.THROWABLE) {
            return (
                this.throwableBehaviorConverter ||
                (this.throwableBehaviorConverter = new ThrowableBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.CHARACTER) {
            return (
                this.characterBehaviorConverter ||
                (this.characterBehaviorConverter = new CharacterBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.ANIMATION) {
            return (
                this.animationBehaviorConverter ||
                (this.animationBehaviorConverter = new AnimationBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.ENEMY) {
            return this.enemyBehaviorConverter || (this.enemyBehaviorConverter = new EnemyBehaviorConverter(this.obj));
        } else if (type === OBJECT_TYPES.VOLUME) {
            return (
                this.volumeBehaviorConverter || (this.volumeBehaviorConverter = new VolumeBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.WEAPON) {
            return (
                this.weaponBehaviorConverter || (this.weaponBehaviorConverter = new WeaponBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.SCRIPT) {
            return (
                this.scriptBehaviorConverter || (this.scriptBehaviorConverter = new ScriptBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.CAMERA) {
            return (
                this.cameraBehaviorConverter || (this.cameraBehaviorConverter = new CameraBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.CHECK_POINT) {
            return (
                this.checkPointBehaviorAssetConverter ||
                (this.checkPointBehaviorAssetConverter = new CheckPointBehaviorAssetConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.SPAWN_POINT) {
            return (
                this.spawnPointBehaviorAssetConverter ||
                (this.spawnPointBehaviorAssetConverter = new SpawnPointBehaviorAssetConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.SCENE_VOLUME) {
            return (
                this.sceneVolumeBehaviorAssetConverter ||
                (this.sceneVolumeBehaviorAssetConverter = new SceneVolumeBehaviorAssetConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.BILLBOARD) {
            return (
                this.billboardBehaviorConverter ||
                (this.billboardBehaviorConverter = new BillboardBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.WEAPON_AMMO) {
            return (
                this.weaponAmmoBehaviorConverter ||
                (this.weaponAmmoBehaviorConverter = new WeaponAmmoBehaviorConverter(this.obj))
            );
        } else if (type === OBJECT_TYPES.AI_NPC) {
            return this.aiNpcBehaviorConverter || (this.aiNpcBehaviorConverter = new AiNPCBehaviorConverter(this.obj));
        } else if (type === OBJECT_TYPES.PROP_ANIMATION) {
            return (
                this.propAnimationBehaviorConverter ||
                (this.propAnimationBehaviorConverter = new PropAnimationBehaviorConverter(this.obj))
            );
        } else {
            console.warn(`BehaviourConverter: unknown behavior type: ${type}`);
        }

        return null;
    }
}

export default BehaviorConverter;
