import {ListItem, Menu, MenuItem} from "../AiImageGenerator.styles";
import {Asset, Model} from "../AiImageGenerator.types";
import {useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "usehooks-ts";

type Props = {
    asset?: Asset;
    model?: Model;
    onEditClick?: (asset: Asset) => void;
    onDownloadClick?: (asset: Asset) => void;
    onDeleteClick?: (asset: Asset) => void;
    onSelectClick?: (model: Model) => void;
};
export const AssetItem = ({asset, onEditClick, onDeleteClick, onDownloadClick, model, onSelectClick}: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef(null);

    useOnClickOutside(ref, () => {
        setIsMenuOpen(false);
    });

    const handleEditClick = () => {
        if (!asset || !onEditClick) return;
        onEditClick(asset);
        setIsMenuOpen(false);
    };

    const handleDownloadClick = () => {
        if (!asset || !onDownloadClick) return;
        onDownloadClick(asset);
        setIsMenuOpen(false);
    };

    const handleDeleteClick = () => {
        if (!asset || !onDeleteClick) return;
        onDeleteClick(asset);
        setIsMenuOpen(false);
    };

    const handleSelectClick = () => {
        if (!model || !onSelectClick) return;
        onSelectClick(model);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (ref.current) {
            const element = ref.current as HTMLElement;
            element.addEventListener("contextmenu", e => {
                e.preventDefault();
                setIsMenuOpen(!isMenuOpen);
            });
        }
    }, [ref]);

    return (
        <ListItem ref={ref}>
            <img src={asset?.url || model?.thumbnail.url || ""} alt={asset?.metadata.name || model?.name || ""} />
            {isMenuOpen && (
                <Menu>
                    {onEditClick && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
                    {onSelectClick && <MenuItem onClick={handleSelectClick}>Select</MenuItem>}
                    {onDownloadClick && <MenuItem onClick={handleDownloadClick}>Download</MenuItem>}
                    {onDeleteClick && (
                        <MenuItem onClick={handleDeleteClick} $isDelete>
                            Delete
                        </MenuItem>
                    )}
                </Menu>
            )}
        </ListItem>
    );
};
