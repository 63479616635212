import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";

import {IUser} from "../../../../../v2/pages/types";
import {useAuthorizationContext} from "../../../../../context";
import {getThumbnail} from "../../../../..//services";
import {getProfilePath} from "../../../../../v2/pages/services";
import {FileData} from "../../types/file";
import gamePlaceholder from "../../../../../v2/assets/game-controller.svg";

import {Avatar} from "../../Avatar/Avatar";
import {EditedText, ListItem, SceneDetailsWrapper, SceneImage, SceneName, SelectedBorder} from "./SceneList.style";
import {SceneListProps} from "./SceneList";
import {SceneMenu} from "./SceneMenu";

export const SceneListItem = ({
    onClick,
    onDelete,
    onDoubleClick,
    selectedItemsIds,
    item,
    isSound,
    noOptions,
    noEditText,
    left,
    isCommunityGame,
    setShowLoading,
    reload,
}: Partial<SceneListProps> & {
    item: FileData;
    left: boolean;
    isCommunityGame?: boolean;
    setShowLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    reload?: () => void;
}) => {
    const {getUser} = useAuthorizationContext();
    const isSelected = selectedItemsIds?.includes(item.ID);

    const [isLoaded, setIsLoaded] = useState(false);
    const [gameOwner, setGameOwner] = useState<IUser | null>(null);
    const navigate = useNavigate();
    const isOwnerSetted = !!item.UserID;
    const username = gameOwner?.username || gameOwner?.name;

    const thumbnail = getThumbnail(item.Thumbnail);

    const debouncedOnClick = useCallback(
        debounce((id: string) => {
            if (onClick) onClick(id);
        }, 200),
        [onClick],
    );

    useEffect(() => {
        return () => {
            debouncedOnClick.cancel();
        };
    }, [debouncedOnClick]);

    const trimSceneName = (name: string) => {
        const allowedLength = 60;
        return name.length > allowedLength ? name.substring(0, allowedLength) + "..." : name;
    };

    const getRelativeTimeText = (date: Date): string => {
        const now = new Date();
        const diffTime = Math.abs(now.getTime() - date.getTime());
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
            const diffMinutes = Math.floor(diffTime / (1000 * 60));

            if (diffMinutes < 1) {
                return "just now";
            } else if (diffHours < 1) {
                return `${diffMinutes} minute(s) ago`;
            } else if (diffHours === 1) {
                return `${diffHours} hour ago`;
            } else {
                return `${diffHours} hours ago`;
            }
        } else if (diffDays === 1) {
            return "yesterday";
        } else {
            return `${diffDays} days ago`;
        }
    };

    const editedMessage = (dateString: string): string => {
        const inputDate: Date = new Date(dateString);
        return `Edited ${getRelativeTimeText(inputDate)}`;
    };

    useEffect(() => {
        if (isCommunityGame) {
            const getOwner = async () => {
                const response = await getUser(item.UserID);
                response && setGameOwner(response);
                setIsLoaded(true);
            };
            getOwner();
        }
    }, [isCommunityGame]);

    const handleOpen = (e: any) => {
        if (e.target.name === "options") {
            return;
        }

        onDoubleClick && onDoubleClick(item.ID);
    };

    const handleGoToProfile = () => {
        if (gameOwner) {
            if (!username) return console.error("Username field doesn't exist.");
            navigate(getProfilePath(username));
        }
    };

    return (
        <ListItem onClick={() => debouncedOnClick(item.ID)} onDoubleClick={handleOpen}>
            {!isSound && (
                <SceneImage $bgImage={thumbnail}>
                    {isSelected && <SelectedBorder className="selectedBorder" />}
                    {!thumbnail && <img className="default-img" src={gamePlaceholder} alt="" />}
                </SceneImage>
            )}

            <SceneDetailsWrapper $flex={isCommunityGame && isLoaded}>
                {isCommunityGame && isLoaded ? (
                    <>
                        <Avatar
                            name={username ? username : "Unknown"}
                            image={gameOwner?.avatar}
                            size={32}
                            onClick={handleGoToProfile}
                        />
                        <div className="textContainer">
                            <SceneName>{item.Name}</SceneName>
                            {!noEditText &&
                                (isCommunityGame ? (
                                    <EditedText>
                                        Created by <span onClick={handleGoToProfile}>{username || "Unknown"}</span>
                                    </EditedText>
                                ) : (
                                    <EditedText>{editedMessage(item.UpdateTime)}</EditedText>
                                ))}
                        </div>
                    </>
                ) : (
                    <div className="textContainer">
                        <SceneName>{trimSceneName(item.Name)}</SceneName>
                        {!noEditText && <EditedText>{editedMessage(item.UpdateTime)}</EditedText>}
                    </div>
                )}
                <SceneMenu
                    onDoubleClick={onDoubleClick}
                    isCloneable={!!item.IsCloneable}
                    canDuplicate={!item.isCommunity}
                    noOptions={!!noOptions}
                    scene={item}
                    setShowLoading={setShowLoading}
                    left={left}
                    onDelete={onDelete}
                    isCommunityGame={!!isCommunityGame}
                    isOwnerSetted={isOwnerSetted}
                    reload={reload}
                />
            </SceneDetailsWrapper>
        </ListItem>
    );
};
