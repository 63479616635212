import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";

export const Wrapper = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    ${flexCenter};
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 40px;

    .loadeMoreBtn {
        font-size: 14px;
        font-weight: 500;
    }
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--theme-font-regular);
    font-size: var(--theme-font-size-xl);
    color: white;
    font-family: "Inter";
    height: calc(100% - 64px - 64px);
`;

export const GamesContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: start;
    gap: 20px;
    padding: 0 20px;

    @media only screen and (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        max-width: 640px;
        margin: 0 auto;
    }
`;
