import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {TriggerBehaviorInterface, OBJECT_TYPES} from "../../types/editor";
import TriggerBehaviorUpdater from "./TriggerBehaviorUpdater";

class TriggerBehaviorConverter extends BaseBehaviorConverter<TriggerBehaviorInterface> {
    public static DEFAULT = new TriggerBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: TriggerBehaviorInterface): BehaviorUpdater {
        return new TriggerBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): TriggerBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as TriggerBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have Trigger behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): TriggerBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.TRIGGER,
            if_condition: [generateEmptyTrigger()],
            else_condition: false,
            then_activate: true,
            else_activate: false,
            then_object: "",
            else_object: "",
            delay: 0,
            then_behaviors_on_trigger: [],
            else_behaviors_on_trigger: [],
        };
    }
}

export default TriggerBehaviorConverter;

export const generateEmptyTrigger = () => {
    return {
        id: THREE.MathUtils.generateUUID(),
        player_touches: true,
        object_touches: false,
        pressE: false,
    };
};
