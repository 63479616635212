import {useMediaQuery} from "usehooks-ts";
import {H2} from "../../../common";
import {Game, GamesWrapper, StyledExploreSection} from "./ExploreSection.style";

export const ExploreSection = () => {
    const isTablet = useMediaQuery("(min-width: 1024px) and (max-width: 1279px)");
    const amountOfGames = isTablet ? 9 : 8;

    return (
        <StyledExploreSection>
            <H2>Explore Erth Towns</H2>
            <GamesWrapper>
                {Array.from({length: amountOfGames}).map((_, index) => (
                    <Game key={index} />
                ))}
            </GamesWrapper>
        </StyledExploreSection>
    );
};
