import styled from "styled-components";
import gamePlaceholder from "../../../assets/game-placeholder.svg";

export const StyledExploreSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 16px;
    padding: 0 50px 90px;

    position: relative;
    z-index: 3;
`;

export const GamesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @media only screen and (max-width: 1279px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 1023px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const Game = styled.div`
    background-image: url("${gamePlaceholder}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 320px;
    aspect-ratio: 320 / 180;
    border-radius: 16px;

    @media only screen and (max-width: 1439px) {
        width: 280px;
    }

    @media only screen and (max-width: 1279px) {
        width: 320px;
    }
    @media only screen and (max-width: 767px) {
        width: 80vw;
    }
`;
