import {Hero} from "./Hero/Hero";
import {ExploreSection} from "./ExploreSection/ExploreSection";
import {FeaturesSection} from "./FeaturesSection/FeaturesSection";
import {HeroSectionSchema} from "../../common";

export const MetaverseHomePageLoggedOut = () => {
    return (
        <>
            <HeroSectionSchema>
                <Hero />
                <ExploreSection />
            </HeroSectionSchema>
            <FeaturesSection />
        </>
    );
};
