import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import styled from "styled-components";
import * as THREE from "three";

import global from "../../../../../../../global";
import Application from "../../../../../../../Application";
import {
    OBJECT_TYPES,
    BehaviorInterface,
    CharacterBehaviorInterface,
    CAMERA_TYPES,
} from "../../../../../../../types/editor";

import {flexCenter} from "../../../../../../../assets/style";

import {ItemMenuText, RightClickMenu} from "../../../../../../../ui/common/RightClickMenu/RightClickMenu";
import {PanelCheckbox} from "../../../common/PanelCheckbox";

enum MENU_OPTION_TYPE {
    COPY_ONE = "copyOne",
    COPY_ALL = "copyAll",
    PASTE = "paste",
    DELETE = "delete",
}

const MENU_OPTIONS = [
    {type: MENU_OPTION_TYPE.COPY_ONE, text: "Copy Behavior"},
    {type: MENU_OPTION_TYPE.COPY_ALL, text: "Copy All Behaviors"},
    {type: MENU_OPTION_TYPE.PASTE, text: "Paste Behavior"},
    {type: MENU_OPTION_TYPE.DELETE, text: "Delete"},
];

interface Props {
    onRemoveBehaviorById: (type: string) => void;
    setSelectedBehavior: (behavior: BehaviorInterface) => void;
    copyAllBehaviors: () => void;
    pasteBehavior: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    behavior: BehaviorInterface;
    allBehaviors: BehaviorInterface[];
}

export const SingleBehavior = ({
    onRemoveBehaviorById,
    behavior,
    setSelectedBehavior,
    copyAllBehaviors,
    pasteBehavior,
    allBehaviors,
}: Props) => {
    const app = global.app as Application;
    const editor = app.editor;
    const selected = editor?.selected;
    const ref = useRef<HTMLDivElement>(null);
    const [singleBehaviorMenuOpen, setSingleBehaviorMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{x: number; y: number} | null>(null);

    const handleRemoveBehaviorType = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onRemoveBehaviorById && onRemoveBehaviorById(behavior.id);
    };

    const handleSelectBehavior = () => {
        setSelectedBehavior && setSelectedBehavior(behavior);
    };

    const handleRightClick = (event: any) => {
        setSingleBehaviorMenuOpen(true);
        const x = event.clientX;
        const y = event.clientY;
        setMenuPosition({x, y});
    };

    const closeMenu = () => {
        setSingleBehaviorMenuOpen(false);
        setMenuPosition(null);
    };

    const copyBehavior = () => {
        const behaviorString = JSON.stringify(behavior);
        navigator.clipboard
            .writeText(behaviorString)
            .then(() => toast.success("Behavior copied!"))
            .catch(err => console.error("Failed to copy: ", err));
    };

    const handleMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, type: MENU_OPTION_TYPE) => {
        e.stopPropagation();
        e.preventDefault();
        setSingleBehaviorMenuOpen(false);
        switch (type) {
            case MENU_OPTION_TYPE.COPY_ONE:
                copyBehavior();
                break;

            case MENU_OPTION_TYPE.COPY_ALL:
                copyAllBehaviors();
                break;

            case MENU_OPTION_TYPE.PASTE:
                pasteBehavior(e);
                break;

            case MENU_OPTION_TYPE.DELETE:
                handleRemoveBehaviorType(e);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!behavior.id) {
            behavior.id = THREE.MathUtils.generateUUID();
        }
    }, [behavior]);

    const handleEnabled = () => {
        if (selected && behavior) {
            allBehaviors.forEach(el => {
                if (el.type === behavior.type && el.id !== behavior.id) {
                    el.enabled = false;
                }
            });
            behavior.enabled = !behavior.enabled;

            editor.handleCharacterSettings();
            app.call(`objectChanged`, editor, selected);
            app.call(`objectUpdated`, editor, selected);
        }
    };

    const displayName = behavior.customName || behavior.type;

    return (
        <BehaviorItem onClick={handleSelectBehavior} ref={ref} onContextMenu={handleRightClick} id="behavior">
            <BehaviorName $behaviorDisabled={!behavior.enabled}>
                {displayName}{" "}
                {behavior.type === OBJECT_TYPES.SCRIPT && <span className="details">{`(${behavior.name})`}</span>}{" "}
            </BehaviorName>

            <IconsWrapper onClick={e => e.stopPropagation()}>
                <PanelCheckbox checked={!!behavior.enabled} onChange={handleEnabled} v2 />
            </IconsWrapper>
            {singleBehaviorMenuOpen && menuPosition && (
                <RightClickMenu onClickoutsideCallback={closeMenu} left={menuPosition.x} top={menuPosition.y}>
                    {MENU_OPTIONS.map(({type, text}, index) => (
                        <ItemMenuText
                            key={type + index}
                            onClick={e => handleMenuClick(e, type)}
                            $red={type === MENU_OPTION_TYPE.DELETE}>
                            {text}
                        </ItemMenuText>
                    ))}
                </RightClickMenu>
            )}
        </BehaviorItem>
    );
};

export const BehaviorItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    height: 32px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: #fff;
    background: transparent;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    pointer-events: all;
    &:hover {
        background: #262626;
        color: #fff;
        .panelCheckboxWrapper {
            display: inline-block;
        }
    }

    .details {
        color: var(--theme-font-unselected-color);
    }
`;

export const BehaviorName = styled.span<{$behaviorDisabled: boolean}>`
    color: var(
        ${({$behaviorDisabled}) => ($behaviorDisabled ? "--theme-font-disabled" : "--theme-font-selected-color")}
    );
    ${({$behaviorDisabled}) =>
        $behaviorDisabled &&
        `
    .details {
        color: var(--theme-font-disabled);
    }
    `}
`;

const IconsWrapper = styled.div`
    ${flexCenter};
    .panelCheckboxWrapper {
        display: none;
        pointer-events: all;
    }
`;
