import {useState} from "react";
import {
    CUSTOM_BLOCK_VOLUME_TYPES,
    ICustomVolumeOptions,
    VOLUME_TYPES,
    VolumeBehaviorInterface,
} from "../../../../../types/editor";
import VolumeBehaviorConverter from "../../../../../serialization/behaviours/VolumeBehaviorConverter";
import global from "../../../../../global";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {Heading} from "../common/Heading";
import {NumericInputRow} from "../common/NumericInputRow";
import {StyledButton} from "../../common/StyledButton";

type Props = {
    behavior: VolumeBehaviorInterface;
};

export const VolumeBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = VolumeBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleVolumeTypeChange = (type: VOLUME_TYPES) => {
        if (selected && targetBehavior) {
            targetBehavior.volumeType = type;
            setBehavior({...targetBehavior, volumeType: type});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleCustomVolumeOptionsChange = (value: number, name: keyof ICustomVolumeOptions) => {
        if (selected && targetBehavior) {
            if (!targetBehavior.customVolumeOptions) {
                targetBehavior.customVolumeOptions = {
                    damageAmount: 100,
                    losePoints: 0,
                    loseTime: 0,
                };
            }
            targetBehavior.customVolumeOptions[name] = value;
            setBehavior({...targetBehavior});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleChangeStartOnTrigger = () => {
        if (selected && targetBehavior) {
            targetBehavior.startOnTrigger = !targetBehavior.startOnTrigger;
            setBehavior({...targetBehavior, startOnTrigger: !targetBehavior.startOnTrigger});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleCustomBlockVolumeTypeChange = (type: CUSTOM_BLOCK_VOLUME_TYPES) => {
        if (selected && targetBehavior) {
            const elementExist = !!targetBehavior.customBlockVolumeType?.find(el => el === type);
            let customBlockVolumeType = targetBehavior.customBlockVolumeType ?? [];

            if (elementExist) {
                customBlockVolumeType = customBlockVolumeType.filter(el => el !== type);
            } else {
                customBlockVolumeType.push(type);
            }
            targetBehavior.customBlockVolumeType = customBlockVolumeType;
            setBehavior({...targetBehavior, customBlockVolumeType: customBlockVolumeType});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                v2
                checked={!!behavior.startOnTrigger}
                onChange={handleChangeStartOnTrigger}
            />
            <Separator invisible margin="8px 0" />
            <SelectionOfButtons margin="0 auto">
                {Object.keys(VOLUME_TYPES).map(key => {
                    const type = VOLUME_TYPES[key as unknown as keyof typeof VOLUME_TYPES];
                    if (type === VOLUME_TYPES.TRIGGER_VOLUME) return null;
                    return (
                        <StyledButton
                            width="calc(50% - 3px)"
                            isBlue={behavior.volumeType === type}
                            isActive={behavior.volumeType !== type}
                            onClick={() => handleVolumeTypeChange(type)}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator />
            {behavior.volumeType === VOLUME_TYPES.CUSTOM && (
                <>
                    <Heading margin={"0 0 12px"}>Custom Volume</Heading>
                    <SelectionOfButtons margin="8px auto 12px">
                        {Object.keys(CUSTOM_BLOCK_VOLUME_TYPES).map(key => {
                            const type =
                                CUSTOM_BLOCK_VOLUME_TYPES[key as unknown as keyof typeof CUSTOM_BLOCK_VOLUME_TYPES];
                            return (
                                <StyledButton
                                    width="calc(50% - 3px)"
                                    isBlueSecondary={!!behavior.customBlockVolumeType?.find(el => el === type)}
                                    isActive={!behavior.customBlockVolumeType?.find(el => el === type)}
                                    onClick={() => handleCustomBlockVolumeTypeChange(type)}
                                    key={key}>
                                    <span>{type}</span>
                                </StyledButton>
                            );
                        })}
                    </SelectionOfButtons>
                    <NumericInputRow
                        width="75px"
                        label="Damage Amount"
                        value={behavior.customVolumeOptions ? behavior.customVolumeOptions.damageAmount : 100}
                        setValue={value => handleCustomVolumeOptionsChange(value, "damageAmount")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Lose Points"
                        value={behavior.customVolumeOptions ? behavior.customVolumeOptions.losePoints : 0}
                        setValue={value => handleCustomVolumeOptionsChange(value, "losePoints")}
                    />
                    <NumericInputRow
                        width="75px"
                        label="Lose time"
                        value={behavior.customVolumeOptions ? behavior.customVolumeOptions.loseTime : 0}
                        setValue={value => handleCustomVolumeOptionsChange(value, "loseTime")}
                    />
                </>
            )}
        </>
    );
};
