import * as THREE from "three";
import {useEffect, useState} from "react";

import global from "../../../../../global";
import {CharacterBehaviorConverter} from "../../../../../serialization/behaviours";
import {
    ANIMATION_ACTION_TYPES,
    CharacterBehaviorInterface,
    CharacterOptionsInterface,
    OBJECT_TYPES,
    WEAPON_TYPES,
} from "../../../../../types/editor";
import {
    CHARACTER_MAIN_PROPS,
    CHARACTER_MELEE_ANIMATIONS,
    CHARACTER_MOVEMENT_ANIMATIONS,
    CHARACTER_WEAPON_ANIMATIONS,
} from "./helpers/characterUIData";

import {DefaultWrapper} from "../styles/Behaviors.style";
import {NumericInputRow} from "../common/NumericInputRow";
import {SelectRow} from "../common/SelectRow";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
// import { AiAssistantTest } from "./helpers/AiAssistantTest";

const initialXRotations = [
    {
        key: "none",
        value: "None",
    },
    {
        key: "-x",
        value: "Negative X",
    },
    {
        key: "+x",
        value: "Positive X",
    },
];

type AnimationOption = {
    key: string;
    value: string;
};

const getSceneModels = () => {
    const editor = (global as any).app.editor;
    const sceneModels = {};

    editor.scene.traverse((obj: any) => {
        if (obj.type === "Group" && obj.parent === editor.scene) {
            //@ts-ignore
            sceneModels[obj.name] = obj.name;
        }
    });

    return sceneModels;
};

type Props = {
    behavior: CharacterBehaviorInterface;
};

export const CharacterBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const model = editor.selected;
    const thisModelCharacterBehavior = model.userData.behaviors.find((el: any) => el.type === "Character");
    const targetBehaviorcharacterOptions = thisModelCharacterBehavior?.characterOptions as CharacterOptionsInterface;
    const [behaviorState, setBehaviorState] = useState(behavior);

    const [animations, setAnimations] = useState([{key: "0", value: "none"}]);
    const camera = editor.camera;

    const [targetBehavior, setTargetBehavior] = useState<{characterOptions: CharacterOptionsInterface}>(
        targetBehaviorcharacterOptions
            ? {characterOptions: targetBehaviorcharacterOptions}
            : {
                  characterOptions: {
                      ...CharacterBehaviorConverter.DEFAULT.getDefaultCharacterOptions(getSceneModels()),
                      initialXRotation: "-x",
                  },
              },
    );

    const handleSelectChange = (item: {key: string; value: string}, name: string) => {
        setTargetBehavior((prevState: any) => {
            const newState = {...prevState};
            newState.characterOptions[name] = item.value;
            return newState;
        });
    };

    const handleUpdate = () => {
        if (!model) return;
        const characterBehaviorConverter = CharacterBehaviorConverter.DEFAULT;

        if (!camera.userData.characterOptions || Object.keys(camera.userData.characterOptions).length === 0) {
            camera.userData.characterOptions = characterBehaviorConverter.getDefaultCharacterOptions(getSceneModels());
        }

        characterBehaviorConverter.updateCharacterOptions(model, camera);

        if (model) {
            if (model._obj && model._obj.animations && model._obj.animations.length > 0) {
                const mappedAnimations = model._obj.animations.map((anim: any) => {
                    return {key: `${anim.name}`, value: anim.name};
                });
                setAnimations([{key: "none", value: "none"}, ...mappedAnimations]);
            }
        }
    };

    const updateScene = () => {
        if (camera && targetBehavior && thisModelCharacterBehavior.enabled) {
            camera.userData.characterOptions = targetBehavior.characterOptions;

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const autoDetectAnimations = () => {
        if (model && model._obj && model._obj.animations && model._obj.animations.length > 0) {
            const mappedAnimations = model._obj.animations.map((anim: any) => ({
                key: `${anim.name}`,
                value: anim.name,
            }));
            setAnimations([{key: "none", value: "none"}, ...mappedAnimations]);

            let weaponAnimationPrefix = "";
            editor.scene!.traverse((object: THREE.Object3D) => {
                if (object.userData && object.userData.behaviors) {
                    const weaponBehavior = object.userData.behaviors.find(
                        (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
                    );
                    if (weaponBehavior && weaponBehavior.weaponStarting) {
                        switch (weaponBehavior.weaponType) {
                            case WEAPON_TYPES.SNIPER_RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SCIFI_SNIPER_RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.RIFLE:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.MACHINE_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SUB_MACHINE_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                            case WEAPON_TYPES.SHOT_GUN:
                                weaponAnimationPrefix = WEAPON_TYPES.RIFLE;
                                break;
                        }
                    }
                }
            });

            const findAnimationByKeyword = (keywords: string[], excludeKeywords: string[] = []) => {
                const cleanString = (str: string) => str.toLowerCase().replace(/[^a-z0-9]/g, "");
                const prefix = cleanString(weaponAnimationPrefix);

                for (const keyword of keywords) {
                    const cleanKeyword = cleanString(keyword);

                    const animation = mappedAnimations.find((animation: AnimationOption) => {
                        const cleanValue = cleanString(animation.value);

                        const containsKeyword = cleanValue.includes(cleanKeyword);
                        const containsPrefix = prefix === "" || cleanValue.includes(prefix);

                        const excludesUnwanted = excludeKeywords.every(exclude => {
                            const cleanExclude = cleanString(exclude);
                            const isExcluded = cleanValue.includes(cleanExclude);
                            return !isExcluded;
                        });

                        return containsKeyword && containsPrefix && excludesUnwanted;
                    });

                    if (animation) {
                        return animation.value;
                    }
                }
                return "none";
            };

            setTargetBehavior(prevState => ({
                ...prevState,
                characterOptions: {
                    ...prevState.characterOptions,
                    idleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.IDLE.EXCLUDES,
                    ),
                    walkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.WALK.EXCLUDES,
                    ),
                    runAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.JOG.EXCLUDES,
                    ),
                    jumpAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.JUMP.INCLUDES,
                        ANIMATION_ACTION_TYPES.RUN.EXCLUDES,
                    ),
                    shootIdleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_IDLE.EXCLUDES,
                    ),
                    shootWalkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_WALK.EXCLUDES,
                    ),
                    shootRunAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SHOOT_RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.SHOOT_RUN.EXCLUDES,
                    ),
                    reloadIdleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_IDLE.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_IDLE.EXCLUDES,
                    ),
                    reloadWalkAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_WALK.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_WALK.EXCLUDES,
                    ),
                    reloadRunAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RELOAD_RUN.INCLUDES,
                        ANIMATION_ACTION_TYPES.RELOAD_RUN.EXCLUDES,
                    ),
                    swordSimpleAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.SWORD.INCLUDES,
                        ANIMATION_ACTION_TYPES.SWORD.EXCLUDES,
                    ),
                    punchAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PUNCH.INCLUDES,
                        ANIMATION_ACTION_TYPES.PUNCH.EXCLUDES,
                    ),
                    kickAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.KICK.INCLUDES,
                        ANIMATION_ACTION_TYPES.KICK.EXCLUDES,
                    ),
                    throwAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.THROW.INCLUDES,
                        ANIMATION_ACTION_TYPES.THROW.EXCLUDES,
                    ),
                    pickUpAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PICK_UP.INCLUDES,
                        ANIMATION_ACTION_TYPES.PICK_UP.EXCLUDES,
                    ),
                    carryAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.CARRY.INCLUDES,
                        ANIMATION_ACTION_TYPES.CARRY.EXCLUDES,
                    ),
                    leftDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.LEFT_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.LEFT_DIRECTION.EXCLUDES,
                    ),
                    rightDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.RIGHT_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.RIGHT_DIRECTION.EXCLUDES,
                    ),
                    reverseDirectionAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.REVERSE_DIRECTION.INCLUDES,
                        ANIMATION_ACTION_TYPES.REVERSE_DIRECTION.EXCLUDES,
                    ),
                    fallAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.FALL.INCLUDES,
                        ANIMATION_ACTION_TYPES.FALL.EXCLUDES,
                    ),
                    dieAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.DIE.INCLUDES,
                        ANIMATION_ACTION_TYPES.DIE.EXCLUDES,
                    ),
                    pushAnimation: findAnimationByKeyword(
                        ANIMATION_ACTION_TYPES.PUSH.INCLUDES,
                        ANIMATION_ACTION_TYPES.PUSH.EXCLUDES,
                    ),
                },
            }));
        }
    };

    useEffect(() => {
        updateScene();
        if (thisModelCharacterBehavior) {
            thisModelCharacterBehavior.characterOptions = targetBehavior.characterOptions;
        }
    }, [targetBehavior]);

    useEffect(() => {
        handleUpdate();
        app.on(`objectChanged.characterOptions`, handleUpdate);
    }, []);

    useEffect(() => {
        if (model) {
            const boundingBox = new THREE.Box3().setFromObject(model);
            const modelHeight = boundingBox.max.y - boundingBox.min.y;

            if (!model.userData.autoDetectAnimations) {
                model.userData.autoDetectAnimations = false;
                autoDetectAnimations();
                //AiSuggestAnimoitions("none");
                model.userData.autoDetectAnimations = true;
            }

            if (!model.userData.playerJumpHeightSet) {
                const jumpHeight = Math.round(modelHeight * 2 * 1000) / 1000;

                setTargetBehavior(prevState => ({
                    ...prevState,
                    characterOptions: {
                        ...prevState.characterOptions,
                        jumpHeight: jumpHeight,
                    },
                }));

                model.userData.playerJumpHeightSet = true;
            }
        }
    }, [model]);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
            );

            return objBehavior;
        }
        return null;
    };

    const handleInputChangeCharacter = (value: string | boolean | number, name: string) => {
        if (selected) {
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                objBehavior.characterOptions[name] = value;
            }
            setBehaviorState((prevState: any) => {
                const newState = {...prevState};
                newState.characterOptions[name] = value;
                return newState;
            });

            app.call("objectChanged", app.editor, app.editor.selected);
            app.call("objectUpdated", app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <DefaultWrapper>
                <PanelCheckbox
                    text="Use Demo Weapon"
                    v2
                    isGray
                    regular
                    checked={!!targetBehavior.characterOptions.useDemoWeapon}
                    onChange={() =>
                        handleInputChangeCharacter(!targetBehavior.characterOptions.useDemoWeapon, "useDemoWeapon")
                    }
                />
                <Separator margin="0" invisible />
                <PanelCheckbox
                    text="Auto Forward"
                    v2
                    isGray
                    regular
                    checked={!!targetBehavior.characterOptions.useAutoForward}
                    onChange={() =>
                        handleInputChangeCharacter(!targetBehavior.characterOptions.useAutoForward, "useAutoForward")
                    }
                />
                <Separator margin="0" invisible />
                <div>
                    {CHARACTER_MAIN_PROPS.map(({label, key}) => {
                        if (key === "initialXRotation") {
                            return (
                                <SelectRow
                                    key={key}
                                    label={label}
                                    data={initialXRotations}
                                    value={initialXRotations.find(
                                        rotation => rotation.key === targetBehavior.characterOptions[key],
                                    )}
                                    onChange={item => handleInputChangeCharacter(item.key, key)}
                                />
                            );
                        } else
                            return (
                                <NumericInputRow
                                    key={key}
                                    label={label}
                                    value={
                                        targetBehavior.characterOptions[
                                            key as unknown as keyof CharacterOptionsInterface
                                        ]
                                    }
                                    setValue={value => handleInputChangeCharacter(value, key)}
                                    min={0}
                                />
                            );
                    })}
                </div>
                {/* For future AI assistant, uncomment import as well */}
                {/* <AiAssistantTest /> */}
                <div>
                    Movement Animations
                    <Separator invisible margin="0 0 12px" />
                    {CHARACTER_MOVEMENT_ANIMATIONS.map(({label, key}) => (
                        <SelectRow
                            key={key}
                            label={label}
                            data={animations}
                            value={animations.find(
                                animation => animation.value === targetBehavior.characterOptions[key],
                            )}
                            onChange={value => handleSelectChange(value, key)}
                        />
                    ))}
                    <NumericInputRow
                        label="Fall Delay (Seconds)"
                        value={targetBehavior.characterOptions.fallDelay}
                        setValue={value => handleInputChangeCharacter(value, "fallDelay")}
                    />
                </div>
                <div>
                    Weapon Animations
                    <Separator invisible margin="0 0 12px" />
                    {CHARACTER_WEAPON_ANIMATIONS.map(({label, key}) => (
                        <SelectRow
                            key={key}
                            label={label}
                            data={animations}
                            value={animations.find(
                                animation => animation.value === targetBehavior.characterOptions[key],
                            )}
                            onChange={value => handleSelectChange(value, key)}
                        />
                    ))}
                </div>
                <div>
                    Melee Animations
                    <Separator invisible margin="0 0 12px" />
                    {CHARACTER_MELEE_ANIMATIONS.map(({label, key}) => (
                        <SelectRow
                            key={key}
                            label={label}
                            data={animations}
                            value={animations.find(
                                animation => animation.value === targetBehavior.characterOptions[key],
                            )}
                            onChange={value => handleSelectChange(value, key)}
                        />
                    ))}
                </div>
            </DefaultWrapper>
        </>
    );
};
