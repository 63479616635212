import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";
import gamePlaceholder from "../../../assets/game-placeholder.svg";

export const Wrapper = styled.div`
    ${flexCenter};
    width: 100%;
    aspect-ratio: 1400 / 480;
    position: relative;
    z-index: 3;
    background: #ffffff33;
    border-radius: 32px;
    margin-bottom: 48px;

    @media only screen and (max-width: 1023px) {
        flex-direction: column-reverse;
        padding: 24px;
        aspect-ratio: auto;
        max-width: 600px;
        margin: 0 auto;
    }
`;

export const GameImage = styled.div<{$bgImage?: string}>`
    background-image: url("${({$bgImage}) => $bgImage || gamePlaceholder}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 55%;
    aspect-ratio: 743 / 432;
    border-radius: 16px;
    margin: 24px;
    @media only screen and (max-width: 1023px) {
        width: 100%;
        margin: 16px 0 0 0;
    }
`;

export const Details = styled.div`
    width: 45%;
    height: 100%;
    ${flexCenter};
    flex-direction: column;
    row-gap: 24px;
    padding: 0 16px;

    border-left: 1px solid #ffffff33;

    font-weight: 700;
    line-height: 120%;

    h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .heading {
        font-size: 20px;
        color: #fff;
        margin: 0;
    }

    @media only screen and (max-width: 1023px) {
        width: 100%;
        border-left: none;
        border-bottom: 1px solid #fff3;
        padding: 16px;
        row-gap: 16px;
    }
`;

export const ActionButton = styled.button`
    padding: 12px 16px;
    background: #fafafa;
    border: none;
    border-top: 1px solid #0ea5e9;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;

    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    color: #27272a;
`;
