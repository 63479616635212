import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import { NPCReceiverBehaviorInterface, NPC_TYPES, OBJECT_TYPES } from "../../types/editor";
import NPCReceiverBehaviorUpdater from "./NPCReceiverBehaviorUpdater";


class NPCReceiverBehaviorConverter extends BaseBehaviorConverter<NPCReceiverBehaviorInterface> {
    public static DEFAULT = new NPCReceiverBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: NPCReceiverBehaviorInterface): BehaviorUpdater {
        return new NPCReceiverBehaviorUpdater(target, behavior);
    }

    clearBehaviorPathFinders(): void {
        this.obj.pathFinders = this.obj.pathFinders.filter((pathFinder: any) => !pathFinder.isBehaviorPathFinder);
    }

    reset(): void { }

    getBehavior(target: THREE.Object3D, id: string): NPCReceiverBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as NPCReceiverBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have NPC behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): NPCReceiverBehaviorInterface {
        return {
            type: OBJECT_TYPES.NPC_RECEIVER,
            npcToCall: NPC_TYPES.WAITER,
            enabled: true,
            startOnTrigger: false,
            id,
        };
    }
}

export default NPCReceiverBehaviorConverter;
