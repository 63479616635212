import {useEffect, useState} from "react";
import global from "../../../../../global";
import {RandomizeSpawnerBehaviorInterface, RandomizeSpawnerListInterface} from "../../../../../types/editor";
import RandomizeSpawnerBehaviorConverter, {
    generateEmptyRandomObject,
} from "../../../../../serialization/behaviours/RandomizeSpawnerBehaviorConverter";

import deleteIcon from "../tabs/ObjectBehaviors/icons/delete.svg";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {Label} from "../RightPanel.style";
import {SelectRow} from "../common/SelectRow";
import {NumericInputRow} from "../common/NumericInputRow";
import {PlusRow} from "../common/PlusRow";

export interface IOptions {
    key: string;
    value: string;
    uuid?: string;
}
export type InputChangeType = (value: any, name: keyof RandomizeSpawnerBehaviorInterface) => void;

type Props = {
    behavior: RandomizeSpawnerBehaviorInterface;
};

const emptyOption = {key: "0", value: "none", uuid: ""};

export const RandomizeSpawnerBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([emptyOption]);
    const [currentBehavior, setCurrentBehavior] = useState<RandomizeSpawnerBehaviorInterface>(behavior);
    const targetBehavior = RandomizeSpawnerBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) || behavior;

    useEffect(() => {
        const getObjects = () => {
            const options: IOptions[] = [emptyOption];
            const objects: any[] = [];
            const scene = editor?.scene;

            const collectObjects = (object: any) => {
                object.children.forEach((child: any) => {
                    if (child.name && child.isObject3D && !child.isLight) {
                        options.push({key: `${objects.length + 1}`, value: child.name, uuid: child.uuid});
                        objects.push(child);
                    }
                    if (child.type === "Group") {
                        collectObjects(child);
                    }
                });
            };

            collectObjects(scene);

            setObjectOptions(options);
        };

        getObjects();
    }, [editor?.scene]); // Re-run when scene is updated

    const handleDeleteObject = (id: string) => {
        if (!app || !selected) return;
        const newValue = currentBehavior.oblectList.filter(el => el.id !== id);

        targetBehavior["oblectList"] = newValue;
        setCurrentBehavior({...behavior, oblectList: newValue});

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    const handleObjectChange = (value: RandomizeSpawnerListInterface, newObject: boolean) => {
        if (!app || !selected) return;
        let newValue = [];
        if (selected && targetBehavior) {
            if (newObject) {
                newValue = [...currentBehavior.oblectList, value];
            } else {
                newValue = currentBehavior.oblectList.map(item => (item.id === value.id ? value : item));
            }

            targetBehavior["oblectList"] = newValue;
            setCurrentBehavior({...behavior, oblectList: newValue});

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const addNewObject = () => {
        const totalProbability = currentBehavior.oblectList.reduce((sum, obj) => sum + obj.probability, 0);
        const remainingProbability = Math.max(100 - totalProbability, 0);

        const newObj = generateEmptyRandomObject(remainingProbability);
        handleObjectChange(newObj, true);
    };

    const handleInputChange: InputChangeType = (value, name) => {
        if (!app || !selected) return;
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setCurrentBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator invisible margin="8px 0" />
            {currentBehavior.oblectList.map((el, index) => (
                <div key={el.id}>
                    <Label $regular $withIcon>
                        Random {index + 1}
                        {index !== 0 && (
                            <img src={deleteIcon} className="icon" onClick={() => handleDeleteObject(el.id)} />
                        )}
                    </Label>
                    <RandomObject
                        randomObject={el}
                        objectOptions={objectOptions}
                        handleObjectChange={handleObjectChange}
                        allObjects={currentBehavior.oblectList}
                    />
                    <Separator margin="8px 0" />
                </div>
            ))}
            <PlusRow label="Add a new object to List" callback={addNewObject} />
            <Separator />
        </>
    );
};

interface RandomObjectProps {
    randomObject: RandomizeSpawnerListInterface;
    allObjects: RandomizeSpawnerListInterface[];
    objectOptions: IOptions[];
    handleObjectChange: (value: RandomizeSpawnerListInterface, newObject: boolean) => void;
}

const RandomObject = ({randomObject, objectOptions, handleObjectChange, allObjects}: RandomObjectProps) => {
    const [totalProbability, setTotalProbability] = useState(0);

    const handleProbabilityChange = (value: number) => {
        const remainingProbability = 100 - totalProbability;
        if (value > remainingProbability) {
            value = remainingProbability;
        }
        handleObjectChange({...randomObject, probability: value}, false);
    };

    useEffect(() => {
        const total = allObjects
            .filter(obj => obj.id !== randomObject.id)
            .reduce((sum, obj) => sum + (obj.probability || 0), 0);
        setTotalProbability(total);
    }, [allObjects, randomObject.id]);

    return (
        <>
            <SelectRow
                $margin={"8px 0 0"}
                label="Prefab to call"
                value={objectOptions.find(el => el.uuid === randomObject.objectUUID)}
                onChange={item => handleObjectChange({...randomObject, objectUUID: item.uuid!}, false)}
                data={objectOptions}
            />
            <Separator invisible />
            <NumericInputRow
                label="Probability %"
                value={randomObject.probability}
                setValue={handleProbabilityChange}
                min={0}
                max={100 - totalProbability}
            />
        </>
    );
};
