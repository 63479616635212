import {H1, HeroGame, HeroSection} from "./Hero.style";

export const Hero = () => {
    return (
        <HeroSection>
            <H1>
                Create your <br /> own Erth town
            </H1>
            <HeroGame />
        </HeroSection>
    );
};
