import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "usehooks-ts";

import global from "../../global";
import Application from "../../Application";
import {useAuthorizationContext} from "../../context";
import {ROUTES} from "../../AppRouter";
import {METAVERSE_ROUTES} from "../../metaverse/MetaverseAppRouter";
import logo from "../assets/logo.svg";
import bell from "../assets/bell.svg";

import {StyledButton} from "../../editor/assets/v2/common/StyledButton";
import {Avatar} from "../../editor/assets/v2/Avatar/Avatar";
import {ButtonsWrapper, Explore, HEADER_Z_INDEX, LeftSide, Logo, StyledHeader} from "./Header.style";
import {Search} from "./Search";
import {UserMenu} from "./UserMenu";
import {Link} from "../common/Link/Link";

export const Header = () => {
    const {isAuthorized, dbUser} = useAuthorizationContext();
    const isMobile = useMediaQuery("(max-width: 767px)");
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const headerRef = useRef<HTMLDivElement>(null);
    const [userMenuActive, setUserMenuActive] = useState(false);
    const metaverse = (global.app as Application)?.options.metaverse;

    const handleClick = (e: React.MouseEvent) => {
        if (window.location.pathname === "/") {
            e.preventDefault();
            const container = document.getElementById("container");
            container?.scrollTo({top: 0, behavior: "smooth"});
        } else {
            navigate(ROUTES.HOME);
        }
    };

    useEffect(() => {
        if (metaverse) return;
        const container = document.getElementById("container");
        if (!container) return console.log("Container element missing.");

        const handleZIndex = () => {
            const header = headerRef.current;
            if (!header || !container) return console.log("No header ref.");

            if (container.scrollTop > 0) {
                header.style.zIndex = HEADER_Z_INDEX;
            } else {
                header.style.zIndex = "0";
            }
        };
        container.addEventListener("scroll", handleZIndex);

        return () => {
            container.removeEventListener("scroll", handleZIndex);
        };
    }, []);

    const renderSearchBar = () => {
        if (metaverse) {
            return pathname !== METAVERSE_ROUTES.SETTINGS;
        }
        return true;
    };

    return (
        <>
            {userMenuActive && <UserMenu close={() => setUserMenuActive(false)} />}
            <StyledHeader ref={headerRef} id="header" $metaverse={!!metaverse}>
                <LeftSide>
                    <Logo onClick={() => navigate(ROUTES.HOME)} $metaverse={!!metaverse}>
                        <img src={logo} alt="EARTH.AI" />
                    </Logo>
                    {!metaverse && (
                        <>
                            <Explore className="reset-css" onClick={handleClick}>
                                Explore
                            </Explore>
                            <Link href={ROUTES.DASHBOARD} text="Create" />
                        </>
                    )}
                </LeftSide>

                <ButtonsWrapper $gap={isAuthorized ? "24px" : "12px"}>
                    {!isMobile && renderSearchBar() && <Search />}
                    {isAuthorized ? (
                        <>
                            {!isMobile && (
                                <button className="reset-css" disabled>
                                    <img src={bell} alt="notifications" />
                                </button>
                            )}
                            <button
                                className="reset-css"
                                onClick={() =>
                                    metaverse ? navigate(METAVERSE_ROUTES.SETTINGS) : setUserMenuActive(true)
                                }
                                style={{position: "relative"}}>
                                <Avatar size={40} name={dbUser?.name} image={dbUser?.avatar} />
                            </button>
                        </>
                    ) : (
                        <>
                            <StyledButton
                                width="69px"
                                height="40px"
                                isGreyTertiary
                                onClick={() => navigate(ROUTES.LOGIN, {state: {from: ROUTES.HOME}})}>
                                Login
                            </StyledButton>
                            <StyledButton
                                width="77px"
                                height="40px"
                                isBlue={!metaverse}
                                isPink={metaverse}
                                onClick={() => navigate(ROUTES.SIGN_UP, {state: {from: ROUTES.HOME}})}>
                                Sign Up
                            </StyledButton>
                        </>
                    )}
                </ButtonsWrapper>
            </StyledHeader>
        </>
    );
};
