import {useEffect, useRef, useState} from "react";
import placeholderImage from "./assets/inventoryPlaceholder.svg";
import inventoryIcon from "./assets/inventoryIcon.svg";
import {INVENTORY_TYPES, INVENTORY_UI_CONTAINERS} from "../../../../../../../types/editor";
import {
    IAddToInventoryArgs,
    IDeleteFromInventoryArgs,
    IDeleteFromInventoryProps,
    IInventory,
    addToInventory,
    deleteFromInventory,
    getUserInventoryForGame,
    initInventory,
} from "../../../../../../../api/inventory";
import {EVENTS} from "../../../../../../../api/inventory/inventoryEvents";
import {Label, InventoryItem, Ammount, InventoryContainer, InventoryIconComponent} from "./InventoryUI.style";

interface Props {
    behaviors: any[];
    game: any;
}

export const InventoryRenderer = ({behaviors, game}: Props) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const SceneID = urlSearchParams.get("sceneID");
    const UserID = urlSearchParams.get("UserID");

    const [isOpen, setIsOpen] = useState(false);
    const [inventoryObjects, setInventoryObjects] = useState<IInventory[]>([]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "I" || event.key === "i") {
            setIsOpen(prev => !prev);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (!UserID) {
            return console.error("User data missing.");
        }
        if (!SceneID) {
            return console.error("Scene id missing.");
        }

        const setupBasicInventory = async () => {
            const initialInventory: IInventory[] = [];
            behaviors?.forEach(el => {
                initialInventory.push({UUID: el.uuid, Amount: 1});
            });
            await initInventory({UserID, SceneID, InventoryItems: JSON.stringify(initialInventory)}, () => {
                setInventoryObjects(initialInventory);
            });
        };

        const getInventory = async () => {
            const response = await getUserInventoryForGame({UserID, SceneID});
            if (response) {
                setInventoryObjects(response.inventory);
                if (response.noInitialInventory) {
                    setupBasicInventory();
                }
            }
        };

        getInventory();

        const addItemToInventory = async (e: any) => {
            const inventoryObject: IInventory = e.detail;
            const inventoryObjectToSend: IAddToInventoryArgs = {
                UserID,
                SceneID,
                InventoryItem: JSON.stringify(inventoryObject),
            };
            const response = await addToInventory(inventoryObjectToSend);
            if (response) {
                setInventoryObjects(response);
            }
        };

        const deleteItemFromInventory = async (e: any) => {
            const inventoryObject: IDeleteFromInventoryProps = e.detail;
            const inventoryObjectToSend: IDeleteFromInventoryArgs = {
                UserID,
                SceneID,
                InventoryItemUUID: inventoryObject.InventoryItemUUID,
                AmountToRemove: inventoryObject.AmountToRemove,
            };
            const response = await deleteFromInventory(inventoryObjectToSend);
            if (response) {
                setInventoryObjects(response);
            }
        };

        window.addEventListener(EVENTS.INVENTORY_ADD, addItemToInventory);
        window.addEventListener(EVENTS.INVENTORY_DELETE, deleteItemFromInventory);

        return () => {
            window.removeEventListener(EVENTS.INVENTORY_ADD, addItemToInventory);
            window.removeEventListener(EVENTS.INVENTORY_DELETE, deleteItemFromInventory);
        };
    }, []);

    return (
        <div>
            {isOpen && <InventoryUI inventory={inventoryObjects} game={game} />}
            <InventoryIconComponent $bgImage={inventoryIcon} />
        </div>
    );
};

export const InventoryUI = ({inventory, game}: {inventory: IInventory[]; game: any}) => {
    const [fullInventoryData, setFullInventoryData] = useState<any[]>([]);
    const selectedItemIndexRef = useRef<number>(0);
    const fullInventoryDataRef = useRef<any[]>([]);

    useEffect(() => {
        const refreshedInventory: any[] = [];
        inventory.forEach((el: any) => {
            if (el.uuid) {
                const obj = game?.scene?.getObjectByProperty("uuid", el.uuid);
                const behaviors = obj.userData.behaviors.filter((behavior: any) =>
                    Object.values(INVENTORY_TYPES).includes(behavior.type),
                );
                refreshedInventory.push({amount: el.amount, uuid: el.uuid, ...behaviors[0]});
            }
            refreshedInventory.sort((a: any, b: any) => {
                const typeOrder = [
                    INVENTORY_TYPES.WEAPON_AMMO,
                    INVENTORY_TYPES.WEAPON,
                    INVENTORY_TYPES.CONSUMABLE,
                    INVENTORY_TYPES.THROWABLE,
                ];
                return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
            });
            setFullInventoryData(refreshedInventory);
        });
    }, [inventory]);

    useEffect(() => {
        (fullInventoryDataRef.current = fullInventoryData), [fullInventoryData];
    });

    useEffect(() => {
        const addMouseWheelListener = (event: WheelEvent) => {
            let scrollTimeout: number | null = null;
            event.preventDefault();

            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
            }

            if (event.deltaY > 0) {
                selectInventoryItem("next");
            } else if (event.deltaY < 0) {
                selectInventoryItem("previous");
            }

            scrollTimeout = window.setTimeout(() => {
                scrollTimeout = null;
            }, 500);
        };

        window.addEventListener("wheel", addMouseWheelListener, {passive: false});

        return () => {
            window.removeEventListener("wheel", addMouseWheelListener);
        };
    }, []);

    const selectInventoryItem = (direction: "next" | "previous") => {
        console.log("fullInventoryData", fullInventoryDataRef.current);
        const totalItems = fullInventoryDataRef.current.length;

        if (totalItems === 0) {
            return;
        }

        let indexCounter = selectedItemIndexRef.current;
        if (direction === "next") {
            indexCounter = (indexCounter + 1) % totalItems;
        } else if (direction === "previous") {
            indexCounter = (indexCounter - 1 + totalItems) % totalItems;
        }
        selectedItemIndexRef.current = indexCounter;
        fullInventoryDataRef.current.forEach((el: any) => {
            const item = game?.scene?.getObjectByProperty("uuid", el.uuid);
            if (item) {
                //item.visible = false;
                item.userData.uiInventorySelected = false;
            }
        });

        const selected = fullInventoryDataRef.current[indexCounter];
        const currentObject = game!.scene!.getObjectByProperty("uuid", selected.uuid);
        if (currentObject) {
            currentObject.userData.uiInventorySelected = true;
            currentObject.visible = true;
        }

        const item = document.getElementById(
            INVENTORY_UI_CONTAINERS.ICON_PREFIX + currentObject.uuid,
        ) as HTMLImageElement;
        if (item) {
            const previouslySelectedItem = document.querySelector(".highlighted-inventory-item") as HTMLDivElement;
            if (previouslySelectedItem) {
                previouslySelectedItem.classList.remove("highlighted-inventory-item");
            }
            item.classList.add("highlighted-inventory-item");

            if (indexCounter < 4) {
                const inventoryContainer = document.getElementById("styled-inventory-container");
                if (inventoryContainer) {
                    inventoryContainer.scrollTop = 0;
                }
            }

            if (indexCounter >= totalItems - 4) {
                const inventoryContainer = document.getElementById("styled-inventory-container");
                if (inventoryContainer) {
                    inventoryContainer.scrollTop = inventoryContainer.scrollHeight;
                }
            }
        }
    };

    return (
        <InventoryContainer id="styled-inventory-container" className="hidden-scroll">
            <Label>Inventory</Label>
            {fullInventoryData.length === 0 && (
                <Label $emptyState>
                    No items yet. <br /> Start Gathering.
                </Label>
            )}
            {fullInventoryData?.map((inventory: any, index: number) => {
                const uiImage = inventory.uiImage || placeholderImage;
                const categoryName = (inventory.type + "s").toLowerCase();
                return (
                    <>
                        <InventoryItem
                            key={categoryName + index}
                            className={inventory.type}
                            $bgImage={uiImage}
                            id={INVENTORY_UI_CONTAINERS.ICON_PREFIX + inventory.uuid}>
                            <Ammount>{inventory.amount}</Ammount>
                        </InventoryItem>
                    </>
                );
            })}
        </InventoryContainer>
    );
};
