import styled from "styled-components";
import {StyledButton} from "../../common/StyledButton";
import {MaterialRenderingSection} from "../../RightPanel/sections/MaterialRenderingSection";
import {Separator} from "../../RightPanel/common/Separator";
import {SaveButton} from "../../RightPanel/MainButtons";

export const Container = styled.div`
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    right: 12px;
    top: 12px;
    width: 240px;
    height: calc(100svh - 24px);
    background: var(--theme-grey-bg-tertiary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-font-main-selected-color);
    z-index: 100;
    transition: height 0.2s ease-in-out;

    .propertiesButton {
        cursor: default;
        background: #262626;
        color: #f8fafc;
        &:hover {
            background: #262626;
            color: #f8fafc;
        }
        &:active {
            background: #262626;
            color: #f8fafc;
        }
    }

    .MaterialSection {
        row-gap: 16px;
    }
`;

const Label = styled.div`
    width: 100%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    text-align: left;
    color: #f8fafc;
`;

type Props = {
    onClose: () => void;
};

export const MainPanel = ({onClose}: Props) => {
    return (
        <Container className="hidden-scroll">
            <StyledButton className="propertiesButton">Properties</StyledButton>
            <Separator margin="8px 0 12px" />
            <Label>Texture Settings</Label>
            <Separator margin="12px 0 8px" />
            <MaterialRenderingSection />
            <SaveButton onClick={onClose} label="Save Material" />
        </Container>
    );
};
