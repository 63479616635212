import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {MetaverseAppRouter} from "./MetaverseAppRouter";
import AuthorizationContextProvider from "../context/AuthorizationContext.js";
import AppGlobalContextProvider from "../context/AppGlobalContext";
import HUDContextProvider from "../context/HUDContext";
import CSPMetaTag, {customCSPPolicies} from "../utils/CSPMetaTag";

export const MetaverseAppContainer = () => {
    return (
        <AuthorizationContextProvider>
            <AppGlobalContextProvider>
                <HUDContextProvider>
                    <CSPMetaTag customPolicies={customCSPPolicies} />
                    <MetaverseAppRouter />
                    <ToastContainer position="top-center" theme="dark" />
                </HUDContextProvider>
            </AppGlobalContextProvider>
        </AuthorizationContextProvider>
    );
};
