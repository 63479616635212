import {regularFont} from "../../assets/style";
import styled from "styled-components";

export const FOOTER_MARGIN_TOP = "40px";

export const StyledFooter = styled.footer<{$metaverse: boolean}>`
    position: relative;
    z-index: 1;
    overflow: visible;
    width: 100vw;
    height: 320px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid var(--theme-homepage-grey-bg-primary);
    font-size: 14px;
    padding: 40px;
    margin-top: ${FOOTER_MARGIN_TOP};
    background-color: var(--theme-container-main-dark);

    .copyright {
        color: var(--theme-homepage-placeholder-color);
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        row-gap: 20px;
        height: 240px;
    }
    ${({$metaverse}) =>
        $metaverse &&
        `
    margin-top: 0;
    `};
`;

export const ShadowContainer = styled.div`
    position: absolute;
    z-index: 2;
    top: -250px;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
`;

export const LeftColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 3;
`;

export const MidColumn = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 80px;
    position: relative;
    z-index: 3;
    @media only screen and (max-width: 1023px) {
        column-gap: 42px;
    }
    @media only screen and (max-width: 767px) {
        column-gap: 20px;
        justify-content: space-between;
        height: auto;
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
`;

export const InsideColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 16px;
    position: relative;
    z-index: 3;

    .option,
    .label {
        ${regularFont("s")};

        @media only screen and (max-width: 767px) {
            ${regularFont("xs")};
        }
    }

    .label {
        font-weight: 500;
    }

    .option {
        color: var(--theme-homepage-placeholder-color);
    }
    .disabled {
        cursor: not-allowed;
    }
`;

export const RightColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    z-index: 3;
`;

// mobile
export const MobileRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
`;
