import styled from "styled-components";

export const InventoryContainer = styled.div`
    width: 553px;
    height: 328px;
    padding: 16px;

    display: grid;
    gap: 12px;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(4, 1fr);

    position: fixed;
    bottom: 142px;
    right: 10px;
    z-index: 9999;

    background: #fff;
    border: 4px solid #000;
    border-radius: 16px;

    .highlighted-inventory-item {
        border: 4px solid rgb(0, 255, 195);
    }

    @media (max-width: 1023px) {
        width: 400px;
        height: 280px;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    @media (max-width: 767px) {
        width: 300px;
        height: 240px;
        gap: 8px;
        bottom: 102px;
    }
`;

export const Label = styled.div<{$emptyState?: boolean}>`
    font-family: Inter;
    font-size: 20px;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: -0.011em;
    text-align: center;
    grid-column: 1/-1;

    ${({$emptyState}) =>
        $emptyState &&
        `
    font-weight: 400;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    `}
`;

export const InventoryItem = styled.div<{$bgImage?: string}>`
    position: relative;

    width: 100%;
    height: auto;
    aspect-ratio: 120/120;
    border: 4px solid #000;
    border-radius: 16px;

    border: 4px solid #000;

    ${({$bgImage}) =>
        $bgImage &&
        `
                background-image: url('${$bgImage}');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

              `}
`;

export const InventoryIconComponent = styled(InventoryItem)`
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    width: 120px;
    @media (max-width: 767px) {
        width: 90px;
    }
`;

export const Ammount = styled.div`
    position: absolute;
    top: 4px;
    right: 8px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 900;
    line-height: 150%;
    letter-spacing: -0.011em;
`;
