import * as THREE from "three";
import { CameraBehaviorInterface, CAMERA_TYPES} from "../../types/editor";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import { IPhysics } from "../../physics/common/types";
import global from "../../global";

enum GAME_STATE {
    NOT_STARTED,
    STARTED,
    FINISHED,
    PAUSED,
}

class CameraBehaviorUpdater implements BehaviorUpdater {
    physics?: IPhysics;
    target: THREE.Object3D;
    behavior: CameraBehaviorInterface;
    game?: GameManager;
    camera: THREE.PerspectiveCamera | null;
    cloneCamera: THREE.PerspectiveCamera | null;
    renderTarget: THREE.WebGLRenderer | null;
    cameraCanvas: HTMLCanvasElement | null;
    static cameraInstances: Map<string, HTMLDivElement> = new Map();
    spectatorCamera: boolean = false;
    fixedCamera: boolean = false;
   
    constructor(target: THREE.Object3D, behavior: CameraBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.camera = null;
        this.cloneCamera = null;
        this.renderTarget = null;
        this.cameraCanvas = null;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;

        //TODO use this to add different camera types
        //currently this behavior only handles spectator camera as demo
        const behaviors = this.target.userData.behaviors;

        this.executeCameraHandlers(behaviors);

       //this is the start of re-factor for camera control
        if (this.behavior?.cameraMinDistance !== undefined) {
            this.game!.scene!.userData.cameraMinDistance = this.behavior.cameraMinDistance;
        }
        if (this.behavior?.cameraMaxDistance !== undefined) {
            this.game!.scene!.userData.cameraMaxDistance = this.behavior.cameraMaxDistance;
        }
        if (this.behavior?.cameraFOV !== undefined) {
            this.game!.scene!.userData.cameraFOV = this.behavior.cameraFOV
        }

    }

    executeCameraHandlers(behaviors: any) {
        const handlers = [
            this.handleSpectatorTypeCamera.bind(this),
            this.handleFixedTypeCamera.bind(this),
            this.handleCharacterTypeCamera.bind(this),
        ];

        for (const handler of handlers) {
            if (handler(behaviors)) {
                break;
            }
        }
    }

    handleSpectatorTypeCamera(behaviors: any): boolean {
        const spectatorBehavior = behaviors?.find((behavior: any) => behavior.cameraType === CAMERA_TYPES.SPECTATOR);
        if (spectatorBehavior) {
            this.spectatorCamera = true;
            this.game!.scene!.userData.cameraType = CAMERA_TYPES.SPECTATOR;
            this.physics?.removeCollidableObject(this.target.uuid);
            this.createCamera();
            return true; 
        }
        return false;
    }

    handleFixedTypeCamera(behaviors: any): boolean {
        const fixedBehavior = behaviors?.find((behavior: any) => behavior.cameraType === CAMERA_TYPES.FIXED);
        if (fixedBehavior) {
            this.fixedCamera = true;
            this.game!.scene!.userData.cameraType = CAMERA_TYPES.FIXED;
            this.game!.scene!.userData.cameraHeadHeight = this.behavior.cameraHeadHeight;
            this.target.visible = false;
            this.physics?.removeCollidableObject(this.target.uuid);
            return true;
        }
        return false;
    }

    handleCharacterTypeCamera(behaviors: any): boolean {
        const characterBehavior = behaviors?.find((behavior: any) => behavior.cameraType === CAMERA_TYPES.THIRD_PERSON);
        if (characterBehavior) {
            this.fixedCamera = true;
            this.game!.scene!.userData.cameraType = CAMERA_TYPES.THIRD_PERSON;
            this.game!.scene!.userData.cameraHeadHeight = this.behavior.cameraHeadHeight;
            this.target.visible = false;
            this.physics?.removeCollidableObject(this.target.uuid);
            return true; 
        }
        return false;
    }

    createCamera() {
        this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
        this.camera.position.copy(this.target.position);

        this.createCameraDiv();

        if (this.cameraCanvas) {
            this.renderTarget = new THREE.WebGLRenderer({ canvas: this.cameraCanvas });

            const initialWidth = 300;
            const initialHeight = 300;
            this.renderTarget.setSize(initialWidth, initialHeight);

            const updateCameraAspect = () => {
                const width = this.cameraCanvas!.offsetWidth;
                const height = this.cameraCanvas!.offsetHeight;

                const pixelRatio = window.devicePixelRatio || 1;
                this.cameraCanvas!.width = width * pixelRatio;
                this.cameraCanvas!.height = height * pixelRatio;

                this.camera!.aspect = width / height;
                this.camera!.updateProjectionMatrix();

                this.renderTarget!.setSize(width * pixelRatio, height * pixelRatio);
            };

            updateCameraAspect();

            window.addEventListener('resize', updateCameraAspect);
        }

        this.game!.scene!.add(this.camera);
    }

    createCameraDiv() {
        const cameraId = `camera-render-${this.target.uuid}`;

        if (CameraBehaviorUpdater.cameraInstances.has(cameraId)) {
            console.warn(`Camera div for target ${this.target.uuid} already exists.`);
            return;
        }

        const cameraDiv = document.createElement('div');
        cameraDiv.id = cameraId;

        if (this.fixedCamera) {
            Object.assign(cameraDiv.style, {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '56.25vw', 
                height: '100vh',
                zIndex: '1000',
            });
        } else {
            Object.assign(cameraDiv.style, {
                position: 'absolute',
                top: `${CameraBehaviorUpdater.cameraInstances.size * 3100}px`,
                right: '10px',
                width: '300px',
                height: '300px',
            });
        }

        const nameLabel = document.createElement('div');
        nameLabel.textContent = this.target.name;
        Object.assign(nameLabel.style, {
            position: 'absolute',
            top: '10px',
            left: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '5px',
            borderRadius: '5px',
            zIndex: '10',
        });

        cameraDiv.appendChild(nameLabel);

        document.body.appendChild(cameraDiv);

        const cameraCanvas = document.createElement('canvas');
        cameraDiv.appendChild(cameraCanvas);
        this.cameraCanvas = cameraCanvas;

        const updateCanvasSize = () => {
            const width = cameraDiv.offsetWidth;
            const height = cameraDiv.offsetHeight;
            cameraCanvas.width = width;
            cameraCanvas.height = height;
        };

        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);

        CameraBehaviorUpdater.cameraInstances.set(cameraId, cameraDiv);
    }


    update(clock: THREE.Clock, delta: number): void {
        if (!this.game || !this.target || !this.camera || !this.renderTarget || this.game.state == GAME_STATE.PAUSED) {
            return;
        }

        if (this.spectatorCamera) {
            this.handleDroneTrackingBehaviior();
        }

        if (this.fixedCamera) {
            this.updateFixedCameraPosition();
        }
    }

    handleDroneTrackingBehaviior() {
        const forwardDirection = new THREE.Vector3();
        this.target.getWorldDirection(forwardDirection);

        const offsetDistance = 1.5;
        this.camera!.position.copy(this.target.position).add(forwardDirection.multiplyScalar(offsetDistance));
        this.camera!.lookAt(this.game!.player!.position);
        this.target.lookAt(this.game!.player!.position);

        if (!this.game!.player!.userData.isMoving) {
            this.cloneCamera = this.game!.player!.userData.currentCamera;
            this.cloneCamera!.lookAt(this.game!.player!.position)
            this.cloneCamera!.position.copy(this.camera!.position);
            this.renderTarget!.render(this.game!.scene!, this.cloneCamera!);
        }

        this.renderTarget!.render(this.game!.scene!, this.camera!);
    }

    updateFixedCameraPosition() {
        if (this.game && this.game.player) {

            const playerPosition = this.game.player.position;

            const forwardDirection = new THREE.Vector3();
            this.target.getWorldDirection(forwardDirection);

            const offsetDistance = -5;

            this.camera!.position.x = playerPosition.x + forwardDirection.x * offsetDistance;
            this.camera!.position.z = playerPosition.z + forwardDirection.z * offsetDistance;

            this.camera!.position.y = 3; 

            this.target.visible = false;
            this.camera!.lookAt(this.game.player.position);

            if (!this.game!.player!.userData.isMoving) {
                this.cloneCamera = this.game!.player!.userData.currentCamera;
                this.cloneCamera!.lookAt(this.game!.player!.position);
                this.cloneCamera!.position.copy(this.camera!.position);
                this.renderTarget!.render(this.game!.scene!, this.cloneCamera!);
            }

            //This will render the new fixed camera in another div
            this.renderTarget!.render(this.game!.scene!, this.camera!);

            //TODO interrupt the global renderer and use the fixed camera needs some work
            //const renderer = global!.app!.renderer;
            //renderer.render(this.game!.scene!, this.camera!);
        }
    }




    reset() { }
}

export default CameraBehaviorUpdater;
