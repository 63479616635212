import {useEffect, useState} from "react";
import styled from "styled-components";
import * as THREE from "three";

import {useAuthorizationContext} from "../../../context";
import {HeroSectionSchema} from "../../common";
import {IFRAME_MESSAGES} from "../../../types/editor";
import {IBasicGameInterface} from "../../../v2/pages/types";
import {getGamesByQuery} from "../../../api/getGames";

import {ExploreHeroSection} from "./HeroSection/Hero";
import {Games} from "./Games/Games";
import {Iframe} from "../../../ui/common/IFrame.style";
import {LoadingAnimation} from "../../../ui/progress/LoadingAnimation";

export interface IPlayGame {
    url: string;
    name: string;
    thumbnail?: string;
}

export const METAVERSE_TAG = "metaverse";

export const MetaverseHomePageLoggedIn = () => {
    const {dbUser} = useAuthorizationContext();
    const [showLoding, setShowLoading] = useState(false);
    const [metaverseGames, setMetaverseGames] = useState<IBasicGameInterface[]>([]);
    const [playGameData, setPlayGameData] = useState<IPlayGame>();

    const generateURL = () => {
        let generatedURL;
        if (playGameData) {
            generatedURL =
                playGameData.url +
                `&username=${dbUser?.username || "guest" + THREE.MathUtils.generateUUID()}&metaverse=true&customLogo=${playGameData.name}`;

            if (playGameData.thumbnail) {
                generatedURL += `&thumbnail=${playGameData.thumbnail}`;
            }
        }

        return generatedURL;
    };

    useEffect(() => {
        setShowLoading(true);
        const fetchGames = async () => {
            const games = await getGamesByQuery({tags: METAVERSE_TAG});
            games && setMetaverseGames(games);
            setShowLoading(false);
        };
        fetchGames();

        const handleMessage = (event: any) => {
            const message = event.data;
            if (message === IFRAME_MESSAGES.GAME_CLOSED) {
                setPlayGameData(undefined);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <>
            {playGameData?.url && <Iframe src={generateURL()} />}
            <LoadingAnimation show={showLoding} />
            <HeroSectionSchema>
                <Container>
                    <ExploreHeroSection />
                </Container>
            </HeroSectionSchema>
            <Games metaverseGames={metaverseGames} setPlayGameData={setPlayGameData} />
        </>
    );
};

export const Container = styled.div`
    padding: 20px;
`;
