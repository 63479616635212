import global from "../../../../../global";
import FollowBehaviorConverter from "../../../../../serialization/behaviours/FollowBehaviorConverter";
import {FollowBehaviorInterface} from "../../../../../types/editor";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {NumericInputRow} from "../common/NumericInputRow";
import {Separator} from "../common/Separator";
import {SelectRow} from "../common/SelectRow";
import {useState, useEffect} from "react";

type Props = {
    behavior: FollowBehaviorInterface;
};

interface IOptions {
    key: string;
    value: string;
    uuid?: string;
}

export const FollowBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([{key: "0", value: "none", uuid: ""}]);
    const targetBehavior = FollowBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof FollowBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleTargetChange = (name: string, value: any) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value.uuid;
            setBehavior({...targetBehavior, [name]: value.uuid});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    useEffect(() => {
        const getObjects = async () => {
            const options = [{name: "none", uuid: ""}];
            editor.scene.traverse((child: any) => {
                if ((child && !!child.userData?.isStemObject) || child.isGroup) {
                    options.push({name: child.name, uuid: child.uuid});
                }
            });
            setObjectOptions(
                options.map((option, index) => {
                    return {
                        key: `${index + 1}`,
                        value: option.name,
                        uuid: option.uuid,
                    };
                }),
            );
        };
        getObjects();
    }, []);

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                v2
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator margin="8px 0" invisible />
            <SelectRow
                label="Target"
                value={objectOptions.find(el => el.uuid === behavior.followTarget)}
                onChange={value => handleTargetChange("followTarget", value)}
                data={objectOptions}
            />
            <NumericInputRow
                width="75px"
                label="Distance"
                value={behavior.distance}
                setValue={value => handleInputChange(value, "distance")}
            />
            <NumericInputRow
                width="75px"
                label="Speed"
                value={behavior.speed}
                setValue={value => handleInputChange(value, "speed")}
            />
            <PanelCheckbox
                text="Rotate"
                isGray
                regular
                v2
                checked={!!behavior.rotate}
                onChange={() => handleInputChange(!behavior.rotate, "rotate")}
            />
            <Separator margin="8px 0" />
        </>
    );
};
