import * as THREE from "three";
//behaviors
export enum OBJECT_TYPES {
    VOLUME = "Volume",
    CONSUMABLE = "Consumable",
    ENEMY = "Enemy",
    CHARACTER = "Character",
    CAMERA = "Camera",
    PLATFORM = "Platform",
    SPAWNPOINT = "SpawnPoint",
    JUMPPAD = "JumpPad",
    TELEPORT = "Teleport",
    ANIMATION = "Animation",
    PROP_ANIMATION = "Prop Animation",
    FOLLOW = "Follow",
    WEAPON = "Weapon",
    WEAPON_AMMO = "Weapon Ammo",
    THROWABLE = "Throwable",
    INVENTORY = "Inventory",
    GENERIC_SOUND = "Generic Sound",
    CHARACTER_SOUNDS = "Character Sounds",
    SCRIPT = "Script",
    TRIGGER = "Trigger",
    OBJECT_INTERACTIONS = "Object Interactions",
    AI_NPC = "AI Agent",
    NPC = "NPC",
    NPC_RECEIVER = "NPC Receiver",
    SPRITE = "Sprite",
    MOBILE_TOUCH_CONTROL = "Mobile Touch Control",
    CONTROLS = "Controls",
    MAKE_CHILD = "MakeChild",
    OTHER = "Other",
    LIGHTS = "Lights",
    MATH_FUNCTIONS = "Math Functions",
    PARTICLE_EFFECTS = "Particle Effects",
    SWITCH = "Switch",
    CHECK_POINT = "Check Point", // not available in behaviors list, added in a different way, from left panel assets
    SPAWN_POINT = "Spawn Point", // not available in behaviors list, added in a different way, from left panel assets
    SCENE_VOLUME = "Scene Volume", // not available in behaviors list, added in a different way, from left panel assets
    BILLBOARD = "Billboard", // not available in behaviors list, added in a different way, from left panel assets
    TIMER = "Timer",
    GAME_OVER = "Game Over",
    COUNTER = "Counter",
    TEXT_PROMPT = "Text Prompt",
    CONDITIONS = "Conditions",
    PANNING_TEXTURE = "Panning Texture",
    ON_CLICK = "On Click",
    VEHICLE_SOUND = "Vehicle Sound",
    PROGRESSION = "Progression",
    RANDOMIZE_SPAWNER = "Randomize Spawner",
}

export const OBJECT_TYPES_WHITE_LIST = [
    OBJECT_TYPES.VOLUME,
    OBJECT_TYPES.CONSUMABLE,
    OBJECT_TYPES.ENEMY,
    OBJECT_TYPES.CHARACTER,
    OBJECT_TYPES.CHARACTER_SOUNDS,
    OBJECT_TYPES.GENERIC_SOUND,
    OBJECT_TYPES.CAMERA,
    OBJECT_TYPES.PLATFORM,
    OBJECT_TYPES.INVENTORY,
    OBJECT_TYPES.SPAWNPOINT,
    OBJECT_TYPES.SPAWN_POINT,
    OBJECT_TYPES.MOBILE_TOUCH_CONTROL,
    OBJECT_TYPES.JUMPPAD,
    OBJECT_TYPES.TELEPORT,
    OBJECT_TYPES.ANIMATION,
    OBJECT_TYPES.PROP_ANIMATION,
    OBJECT_TYPES.FOLLOW,
    OBJECT_TYPES.THROWABLE,
    OBJECT_TYPES.WEAPON,
    OBJECT_TYPES.WEAPON_AMMO,
    OBJECT_TYPES.SCRIPT,
    OBJECT_TYPES.TRIGGER,
    OBJECT_TYPES.OBJECT_INTERACTIONS,
    OBJECT_TYPES.AI_NPC,
    OBJECT_TYPES.NPC,
    OBJECT_TYPES.NPC_RECEIVER,
    OBJECT_TYPES.RANDOMIZE_SPAWNER,
    OBJECT_TYPES.SPRITE,
];

export const OBJECT_TYPES_ASSETS_LIST = [
    OBJECT_TYPES.CHECK_POINT,
    OBJECT_TYPES.SPAWN_POINT,
    OBJECT_TYPES.SCENE_VOLUME,
    OBJECT_TYPES.BILLBOARD,
];

export enum GAME_STATE {
    STARTED = 1,
    PAUSED = 0,
}

export enum OBJECT_INTERACTION_OPTIONS {
    PICKUP_DROP = "Pickup and Drop",
    PUSH_PULL = "Push and Pull",
}

export enum NPC_MOVEMENT_TYPES {
    STAND_STILL = "Stand Still",
    ROMA = "Roam",
}

export enum NPC_TYPES {
    WAITER = "Waiter",
    FIREMAN = "Fireman",
    DOCTOR = "Doctor",
    SOLDIER = "Soldier",
    BAKER = "Baker",
    POLICEMAN = "Policeman",
    FARMER = "Farmer",
    SCIENTIST = "Scientist",
    TEACHER = "Teacher",
    ARTIST = "Artist",
    ENGINEER = "Engineer",
    NURSE = "Nurse",
    DRIVER = "Driver",
    SOLDIER_ELITE = "Elite Soldier",
    JOCK = "Jock",
    MERCHANT = "Merchant",
    GUARD = "Guard",
    COOK = "Cook",
    STUDENT = "Student",
    PAINTER = "Painter",
}

export enum SPRITE_TYPES {
    TWO_D = "2D",
    THREE_D = "3D",
    ANIMATED = "ANIMATED", // Example additional type
}

export enum ANIMATION_TYPES {
    REPEAT = "Repeat",
    LOOP = "Loop",
    PLAY_ONCE = "Play Once",
}
export enum PROP_ANIMATION_TYPES {
    LOOP = "Loop",
    PLAY_ONCE = "Play Once",
}

export enum CHARACTER_VERTICAL_STATUS_TYPES {
    JUMPING_UP = "Jumping Up",
    FALLING_DOWN = "Falling Down",
    STATIONARY = "Vertical Stationary",
    LANDED = "Landed",
    HANG_TIME = "Hang Time",
}

export enum EASE_TYPES {
    LINEAR = "linear",
    QUAD_IN = "quadIn",
    QUAD_OUT = "quadOut",
    QUAD_IN_OUT = "quadInOut",
    CUBIC_IN = "cubicIn",
    CUBIC_OUT = "cubicOut",
    CUBIC_IN_OUT = "cubicInOut",
    QUART_IN = "quartIn",
    QUART_OUT = "quartOut",
    QUART_IN_OUT = "quartInOut",
    QUINT_IN = "quintIn",
    QUINT_OUT = "quintOut",
    QUINT_IN_OUT = "quintInOut",
    SINE_IN = "sineIn",
    SINE_OUT = "sineOut",
    SINE_IN_OUT = "sineInOut",
    BACK_IN = "backIn",
    BACK_OUT = "backOut",
    BACK_IN_OUT = "backInOut",
    CIRC_IN = "circIn",
    CIRC_OUT = "circOut",
    CIRC_IN_OUT = "circInOut",
    BOUNCE_IN = "bounceIn",
    BOUNCE_OUT = "bounceOut",
    BOUNCE_IN_OUT = "bounceInOut",
    ELASTIC_IN = "elasticIn",
    ELASTIC_OUT = "elasticOut",
    ELASTIC_IN_OUT = "elasticInOut",
}

export enum VOLUME_TYPES {
    BLOCKING = "Blocking",
    KILL_VOLUME = "Kill Volume",
    DIALOGUE_VOLUME = "Dialogue Volume",
    LOSE_VOLUME = "Lose Volume",
    WIN_VOLUME = "Win Volume",
    CUSTOM = "Custom",
    TRIGGER_VOLUME = "Trigger Volume",
}

export enum CUSTOM_BLOCK_VOLUME_TYPES {
    BLOCK_ENEMIES = "Block Enemies",
    BLOCK_THROWABLE = "Block Throwables",
    BLOCK_CHARACTERS = "Block Characters",
}

export enum ENEMY_TYPES {
    AGGRESIVE = "Aggressive",
    DEFENSIVE = "Defensive",
    PATROLS = "Patrols",
    CUSTOM = "Custom",
}

export enum PLATFORM_RESPAWN_TYPES {
    LOOP = "Loop",
    REPEAT = "Respawn",
    PLAY_ONCE = "Play Once",
}

export enum SPAWNPOINT_TYPES {
    CLONE = "Clone",
    MOVE = "Move",
}

export enum WEAPON_TYPES {
    MACHINE_GUN = "Machine Gun",
    SUB_MACHINE_GUN = "Sub Machine Gun",
    RIFLE = "Rifle",
    SNIPER_RIFLE = "Sniper Rifle",
    SCIFI_SNIPER_RIFLE = "SciFi Sniper Rifle",
    SHOT_GUN = "Shot Gun",
    PISTOL = "Pistol",
    BOW = "Bow",
    HANDS = "Hands",
    SWORD = "Sword",
    KNIFE = "Knife",
    STAFF = "Staff",
    GRENADE = "Grenade",
    BUTTON_PRESS = "Button Press",
}

export type IBehavior =
    | CharacterBehaviorInterface
    | CameraBehaviorInterface
    | AnimationBehaviorInterface
    | PropAnimationBehaviorInterface
    | WeaponBehaviorInterface
    | WeaponAmmoBehaviorInterface
    | EnemyBehaviorInterface
    | PlatformBehaviorInterface
    | SpawnPointBehaviorInterface
    | JumpPadBehaviorInterface
    | TeleportBehaviorInterface
    | VolumeBehaviorInterface
    | ThrowableBehaviorInterface
    | ConsumableBehaviorInterface
    | TriggerBehaviorInterface
    | SpawnPointAssetBehaviorInterface
    | CheckPointAssetBehaviorInterface
    | ObjectInteractionBehaviorInterface
    | AiNPCBehaviorInterface
    | InventoryManagerBehaviorInterface
    | NPCBehaviorInterface
    | NPCReceiverBehaviorInterface
    | SpriteBehaviorInterface
    | MobileTouchControlBehaviorInterface;

export interface BehaviorInterface {
    enabled: boolean;
    id: string;
    type: OBJECT_TYPES;
    customName?: string;
}

export interface CharacterOptionsInterface {
    sceneModels: any;
    selectedModelUUID: string;
    selectedModel: any;
    animationNames: any;
    walkAnimation: string;
    runAnimation: string;
    jumpAnimation: string;
    idleAnimation: string;
    fallDelay: number;
    fallAnimation: string;
    fallingAnimation: string;
    crouchAnimation: string;
    dieAnimation: string;
    leftDirectionAnimation: string;
    rightDirectionAnimation: string;
    reverseDirectionAnimation: string;
    shootIdleAnimation: string;
    shootWalkAnimation: string;
    shootRunAnimation: string;
    reloadIdleAnimation: string;
    reloadWalkAnimation: string;
    reloadRunAnimation: string;
    swordSimpleAnimation: string;
    swordSpecialAnimation: string;
    punchAnimation: string;
    kickAnimation: string;
    throwAnimation: string;
    pickUpAnimation: string;
    carryAnimation: string;
    pushAnimation: string;
    initialXRotation: string;
    walkSpeed: number;
    runSpeed: number;
    jumpHeight: number;
    cameraMinDistance: number;
    cameraMaxDistance: number;
    cameraFov: number;
    overTheShoulder: boolean;
    otsRightShoulderCamera: boolean;
    slopeTolerance: number;
    health: number;
    shield: number;
    age: number;
    lookSpeed: number;
    useDemoWeapon: boolean;
    useAutoForward: boolean;
}

export const ANIMATION_ACTION_TYPES = {
    IDLE: {
        INCLUDES: ["idle", "stand", "wait"],
        EXCLUDES: ["reload", "run", "walk"],
    },
    STAND: {
        INCLUDES: ["idle", "stand", "wait"],
        EXCLUDES: ["reload", "run", "walk"],
    },
    WAIT: {
        INCLUDES: ["idle", "stand", "wait"],
        EXCLUDES: ["reload"],
    },
    WALK: {
        INCLUDES: ["walk", "idle"],
        EXCLUDES: ["reload"],
    },
    RUN: {
        INCLUDES: ["run", "jog"],
        EXCLUDES: ["reload", "walk"],
    },
    JOG: {
        INCLUDES: ["run", "jog"],
        EXCLUDES: ["reload"],
    },
    JUMP: {
        INCLUDES: ["jump", "hop"],
        EXCLUDES: ["reload"],
    },
    HOP: {
        INCLUDES: ["jump", "hop"],
        EXCLUDES: ["reload"],
    },
    SHOOT_IDLE: {
        INCLUDES: ["shoot"],
        EXCLUDES: ["reload", "walk", "run"],
    },
    SHOOT_WALK: {
        INCLUDES: ["shoot"],
        EXCLUDES: ["reload", "idle", "run"],
    },
    SHOOT_RUN: {
        INCLUDES: ["shoot"],
        EXCLUDES: ["reload", "idle", "walk"],
    },
    RELOAD_IDLE: {
        INCLUDES: ["reload"],
        EXCLUDES: ["shoot", "walk", "run"],
    },
    RELOAD_WALK: {
        INCLUDES: ["reload"],
        EXCLUDES: ["shoot", "idle", "run"],
    },
    RELOAD_RUN: {
        INCLUDES: ["reload"],
        EXCLUDES: ["shoot", "idle", "walk"],
    },
    SWORD: {
        INCLUDES: ["sword"],
        EXCLUDES: ["reload"],
    },
    SWING: {
        INCLUDES: ["swing"],
        EXCLUDES: ["reload"],
    },
    PUNCH: {
        INCLUDES: ["punch"],
        EXCLUDES: ["reload"],
    },
    KICK: {
        INCLUDES: ["kick"],
        EXCLUDES: ["reload"],
    },
    THROW: {
        INCLUDES: ["throw"],
        EXCLUDES: ["reload"],
    },
    PICK_UP: {
        INCLUDES: ["pick"],
        EXCLUDES: ["reload"],
    },
    CARRY: {
        INCLUDES: ["carry"],
        EXCLUDES: ["reload"],
    },
    PUSH: {
        INCLUDES: ["push"],
        EXCLUDES: ["reload", "run"],
    },
    LEFT_DIRECTION: {
        INCLUDES: ["walk"],
        EXCLUDES: ["reload"],
    },
    RIGHT_DIRECTION: {
        INCLUDES: ["walk"],
        EXCLUDES: ["reload"],
    },
    REVERSE_DIRECTION: {
        INCLUDES: ["walk"],
        EXCLUDES: ["reload"],
    },
    FALL: {
        INCLUDES: ["fall"],
        EXCLUDES: ["reload"],
    },
    SKYDIVE: {
        INCLUDES: ["sky dive"],
        EXCLUDES: ["reload"],
    },
    DIE: {
        INCLUDES: ["die"],
        EXCLUDES: ["reload"],
    },
};

export interface SideScrollerOptionsInterface {
    sceneModels: any;
    selectedModelUUID: string;
    selectedModel: any;
    animationNames: any;
    walkAnimation: string;
    runAnimation: string;
    jumpAnimation: string;
    idleAnimation: string;
    fallDelay: number;
    fallAnimation: string;
    dieAnimation: string;
    leftDirectionAnimation: string;
    rightDirectionAnimation: string;
    reverseDirectionAnimation: string;
    shootIdleAnimation: string;
    shootWalkAnimation: string;
    shootRunAnimation: string;
    reloadIdledAnimation: string;
    reloadWalkAnimation: string;
    reloadRunAnimation: string;
    swordSimpleAnimation: string;
    punchAnimation: string;
    kickAnimation: string;
    throwAnimation: string;
    pickUpAnimation: string;
    carryAnimation: string;
    pushAnimation: string;
    initialXRotation: string;
    walkSpeed: number;
    runSpeed: number;
    lookSpeed: number;
    jumpHeight: number;
    playerGravity: number;
    cameraMinDistance: number;
    cameraMaxDistance: number;
    slopeTolerance: number;
}

export interface SoundPropInterface {
    id: string;
    name: string;
    url: string;
}

export interface ModelPropInterface {
    id: string;
    name: string;
    url: string;
}

export interface CharacterBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CHARACTER; // Value is a string "Character"
    control: CAMERA_TYPES; // possible options here: "First Person", "Third Person", "Side Scroller", "Vehicle". By default character is "Third Person"
    pickUpWeapons: boolean;
    characterOptions?: CharacterOptionsInterface;
    vehicleOptions?: VehicleOptionsInterface; // vehicleOptions is only valid if character type is "Vehicle"
}

export enum INVENTORY_TYPES {
    CONSUMABLE = OBJECT_TYPES.CONSUMABLE,
    THROWABLE = OBJECT_TYPES.THROWABLE,
    WEAPON = OBJECT_TYPES.WEAPON,
    WEAPON_AMMO = OBJECT_TYPES.WEAPON_AMMO,
}

export enum INVENTORY_UI_CONTAINERS {
    MAIN = "main-game-ui-container",
    MAIN_ACTIVE = "selected-object-container",
    ICONS = "inventory-container",
    ACTIVE_OBJECT = "active-object-container",
    AMMO = "ammo-container-2",
    SECTION_PREFIX = "inv-cat-",
    IMAGE_PREFIX = "inv-img-container-",
    ICON_PREFIX = "inv-icon-",
    AMMO_COUNT = "weapon-ammo-count",
}

export interface InventoryManagerBehaviorInterface extends BehaviorInterface {
    inventoryType: INVENTORY_TYPES;
    startOnTrigger: boolean;
}

export enum CAMERA_OBJECT_INTERACTION {
    TRANSPARENT = "Transparent",
    ZOOM = "Zoom",
}

export interface CameraBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CAMERA; // Value is a string "Camera"
    cameraType: CAMERA_TYPES; // Possible values here: "First Person", "3rd Person", "Top Down", "Side Scroller"
    cameraEffect: CAMERA_EFFECTS; // Possible values here: Pixel, Bokeh, RGB, None
    cameraHeadHeight?: number; // this option can only be valid if cameraType is set to "First Person"
    playerCollisionBox?: number; // this option can only be valid if cameraType is set to "First Person"
    cameraMinDistance?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraMaxDistance?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraFOV: number;
    cameraAngle?: number; // this option can only be valid if cameraType is set to "3rd Person" or "Top Down"
    cameraMinHeight?: number; // this option can only be valid if cameraType is set to "3rd Person"
    cameraMaxHeight?: number; // this option can only be valid if cameraType is set to "3rd Person"
    cameraAxis?: number; // this option can only be valid if cameraType is set to "Side Scroller"
    objectInteraction: CAMERA_OBJECT_INTERACTION; // Possible values here: "Transparent" or "Zoom"
}

export interface AnimationBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.ANIMATION; // value here is a string "Animation"
    animationType: ANIMATION_TYPES; // possible options here: "Repeat", "Loop", "Play Once"
    horizontalX: number; // object movement on X axis during animation
    horizontalZ: number; // object movement on Z axis during animation
    vertical: number; // object movement on Y axis during animation
    rotationX: number; // object rotation on X axis during animation
    rotationY: number; // object rotation on Y axis during animation
    rotationZ: number; // object rotation on Z axis during animation
    scaleX: number; // object scale on X axis during animation
    scaleY: number; // object scale on Y axis during animation
    scaleZ: number; // object scale on Z axis during animation
    easeType: EASE_TYPES;
    speed: number; // animation speed
    startOnTrigger: boolean; // start animation on trigger
}
export interface PropAnimationBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.PROP_ANIMATION; // value here is a string "Prop Animation"
    animationType: PROP_ANIMATION_TYPES; // possible options here: "Loop", "Play Once"
    propAnimation: string; // name of the animation
    animationSpeed: number; // animation speed
    startOnTrigger: boolean; // start animation on trigger
}

export interface FollowBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.FOLLOW; // value here is a string "Follow"
    followTarget: string; // name of the object to follow
    distance: number; // distance to follow
    speed: number; // speed of following
    rotate: boolean; // rotate object to face the target
    startOnTrigger: boolean; // start following on trigger
}

export interface ICustomVolumeOptions {
    damageAmount: number; // percentage
    losePoints: number; // number of points
    loseTime: number; // time in seconds
}
export interface VolumeBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.VOLUME; // value here is a string "Volume"
    volumeType: VOLUME_TYPES; // possible options here: "Blocking", "Kill Volume", "Dialogue Volume", "Lose Volume", "Win Volume", "Custom", "Trigger Volume"
    customBlockVolumeType: CUSTOM_BLOCK_VOLUME_TYPES[]; // // possible options here: "Block Enemies", "Block Throwables", "Block Characters"
    customVolumeOptions: ICustomVolumeOptions; // this is an object with keys: damageAmount, losePoints, loseTime, all of them are of type number
    startOnTrigger: boolean;
}

export interface NPCBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.NPC;
    movementType: string;
    name: string;
    enabled: boolean;
    startOnTrigger: boolean;
    id: string;
    health: number;
    movementSpeed: number;
    animationClips: [];
    idleAnimation: string;
    walkAnimation: string;
    runAnimation: string;
    jumpAnimation: string;
    crouchAnimation: string;
    trippingAnimation: string;
    fallAnimation: string;
    dieAnimation: string;
    attackAnimation: string;
}

export interface NPCReceiverBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.NPC_RECEIVER;
    startOnTrigger: boolean;
    npcToCall: NPC_TYPES;
    id: string;
}

export enum MOBILE_TOUCH_CONTROL_TYPES {
    JOYSTICK_WITH_BUTTONS = "Joystick with Buttons",
    TOUCH_SCREEN_ONLY = "Touching Screen",
}

export interface MobileTouchControlBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.MOBILE_TOUCH_CONTROL;
    mobileTouchControlType: MOBILE_TOUCH_CONTROL_TYPES;
    startOnTrigger: boolean;
    id: string;
}

export interface SpriteBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SPRITE;
    startOnTrigger: boolean;
    playOnStart: boolean;
    loop: boolean;
    currentAnimation: string;
    animations: string[];
    spriteType: string;
    frameRows: number;
    frameColumns: number;
    frameRate: number;
    uiImage: string;
    frameLeftStanding: number;
    frameRightStanding: number;
    frameForwardStanding: number;
    frameBackwardStanding: number;
    moveForwardStart: number;
    moveForwardEnd: number;
    moveBackwardStart: number;
    moveBackwardEnd: number;
    moveBackwarStart: number;
    moveLeftStart: number;
    moveLeftEnd: number;
    moveRightStart: number;
    moveRightEnd: number;
    jumpStart: number;
    jumpEnd: number;
}

export interface SpawnPointAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SPAWN_POINT; // value here is a string "Spawn"
    position: ITransformValue;
}
export interface CheckPointAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CHECK_POINT; // value here is a string "Check Point"
    position: ITransformValue;
}

export interface ObjectInteractionBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.OBJECT_INTERACTIONS; // value here is a string "Object Interactions"
    interaction: OBJECT_INTERACTION_OPTIONS; // options: "Pickup and Drop" or "Push and Pull"
}

export interface ScriptBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SCRIPT; // value here is a string "Script"
    name: string; // name of the script
    scriptType: string; // example: 'javascript'
    source: string; // code of script
}

export interface GenericSoundBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.GENERIC_SOUND; // value here is a string "Generic Sound"
    sound: SoundPropInterface; // this is an object with keys: id, name, url - all are type of string
    soundDistance: number; // distance of sound
    volume: number; // volume of sound
    loop: boolean; // loop sound
    startOnTrigger: boolean;
}

export interface IfConditionInterface {
    id: string;
    player_touches: boolean;
    object_touches: boolean;
    pressE: boolean;
    objectUUID?: string; // only set when object_touches is selected
}

export interface TriggerBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.TRIGGER; // value here is a string "Trigger"
    if_condition: IfConditionInterface[]; // array of conditions that must be met to trigger this behavior
    else_condition: boolean;
    then_activate: boolean;
    else_activate: boolean;
    then_object: string;
    else_object: string;
    delay: number; // delay in seconds
    then_behaviors_on_trigger: Array<{key: OBJECT_TYPES; value: boolean}>; // for example ["Animation": true] - true means it is waiting for the trigger to start/stop, false means this trigger has no effect on it
    else_behaviors_on_trigger: Array<{key: OBJECT_TYPES; value: boolean}>; // for example ["Animation": true] - true means it is waiting for the trigger to start/stop, false means this trigger has no effect on it
}

export enum TRIGGER_ACTIVATION_TYPES {
    PLAYER_TOUCHES = "player_touches",
    OBJECT_TOUCHES = "object_touches",
    PRESS_E = "pressE",
    PRESS_F = "pressF",
}

export interface RandomizeSpawnerListInterface {
    id: string;
    objectUUID: string; // selected object uuid
    probability: number; // percentage value between 1 - 100
}

export interface RandomizeSpawnerBehaviorInterface extends BehaviorInterface {
    startOnTrigger: boolean;
    type: OBJECT_TYPES.RANDOMIZE_SPAWNER; // value here is a string "Randomize Spawner"
    oblectList: RandomizeSpawnerListInterface[]; // array of objects with probablity
}

export enum CONSUMABLE_TYPES {
    INSTANT = "Instant",
    BUTTON_PRESS = "Button Press",
}

export type ConsumableType = CONSUMABLE_TYPES.INSTANT | CONSUMABLE_TYPES.BUTTON_PRESS;
export interface ConsumableBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CONSUMABLE;
    inventoryType: INVENTORY_TYPES;
    pointAmount: number;
    timeAmount: number;
    healthAmount: number;
    ammoAmount: number;
    shieldAmount: number;
    moneyAmount: number;
    scaleAmount: number;
    speedAmount: number;
    jumpAmount: number;
    timeForEffect_jump: number;
    timeForEffect_speed: number;
    collisionSettings: {
        disposable: boolean;
        playerCollision: boolean;
        enemyCollision: boolean;
        throwableCollision: boolean;
        canReappear: boolean;
    };
    timeToShowAgain?: number;
    useBoundingBoxes?: boolean;
    consumableType: ConsumableType; // Possible values are: "Instant", "Button Press"
    uiImage?: string;
}

export interface EnemyBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.ENEMY; // Value is a string "Enemy"
    enemyType: ENEMY_TYPES; // possible options here: "Aggressive", "Defensive", "Patrols"
    enemyEnabled: boolean; // enable enemy
    health: number; // health of enemy
    movementSpeed: number;
    attackDamage: number;
    attackDistance: number;
    attackSpeed: number;
    respawnAmount: number;
    roamDistance: number;
    showRoamArea: boolean;
    rotationSpeed: number;
    fightDistance: number;
    directionDuration: number;
    animationClipName: string;
    fallDelay: number;
    weapon: WEAPON_TYPES;
    animationClips: string[];
    idleAnimation?: string;
    walkAnimation?: string;
    runAnimation?: string;
    attackAnimation?: string;
    jumpAnimation?: string;
    fallAnimation?: string;
    dieAnimation?: string;
    crouchAnimation?: string;
    trippingAnimation?: string;
}

export interface PlatformBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.PLATFORM; // Value is a string "Platform"
    vertical: number; // object movement on Y axis during animation
    horizontalX: number; // object movement on X axis during animation
    horizontalZ: number; // object movement on Z axis during animation
    respawn: PLATFORM_RESPAWN_TYPES; // possible options here: "Respawn", "Loop", "Play Once"
    speed: number; // speed of platform during animation
    startOnTrigger: boolean;
}

export interface JumpPadBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.JUMPPAD; // Value is a string "Platform"
    startOnTrigger: boolean;
    jumpPadStrength: number;
    jumpPadAngle: number;
}

export interface TeleportBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.TELEPORT; // Value is a string "Platform"
    startOnTrigger: boolean;
    teleporterExitObject: string;
}

export interface SpawnPointBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SPAWNPOINT;
    startOnTrigger: boolean;
    objectNameToSpawn: string;
    spawnType: SPAWNPOINT_TYPES;
}

export interface CharacterSoundsBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.CHARACTER_SOUNDS;
    jumping: SoundPropInterface;
    walking: SoundPropInterface;
    running: SoundPropInterface;
    dying: SoundPropInterface;
    volume: number;
}

export interface ThrowableBehaviorInterface extends BehaviorInterface {
    throwableStarting: boolean;
    type: OBJECT_TYPES.THROWABLE;
    inventoryType: INVENTORY_TYPES;
    model: ModelPropInterface;
    powerLevel: number;
    bounceEffect: number;
    aimer: boolean;
    aimerGuide: boolean;
    throwableVisible: boolean;
    uiImage?: string;
    throwableMass: number;
    throwableSpeed: number;
    throwableColor: string;
    throwableLife: number;
    throwablePointAmount: number;
    throwableFriction: number;
    throwableRestitution: number;
    throwableInertia: number;
    throwableDamage: number;
    throwableAmount: number;
    muzzle_flash: boolean; //TODO move to effects behavior
    laser_effect: boolean; //TODO move to effects behavior
    collisionSettings: {
        disposable: boolean;
        playerCollision: boolean;
        enemyCollision: boolean;
        throwableCollision: boolean;
        canReappear: boolean;
    };
}

export interface WeaponAmmoBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.WEAPON_AMMO;
    inventoryType: INVENTORY_TYPES;
    uiImage: string;
    ammoDamage: number;
    ammoBounceEffect: number;
    ammoFireRate: number;
    ammoMass: number;
    ammoLife: number;
    ammoSpeed: number;
    ammoFriction: number;
    ammoInertia: number;
    ammoRestitution: number;
}

export enum DEVICE_TYPES {
    MOBILE = "Mobile",
    DESKTOP = "Desktop",
}

export enum OPERATING_SYSTEM_TYPES {
    MAC_OS = "macOS",
    WINDOWS_OS = "Windows",
    ANDROID_OS = "Android",
    I_OS = "iOS",
    LINUX_OS = "Linux",
}

//TODO consolidate the below movements
const JOYSTICK_MOVEMENT_STATES = {
    FORWARD: "Forward",
    STRAIGHT_FORWARD: "Straight Forward",
    BACKWARD: "Backward",
    STRAIGHT_BACKWARD: "Straight Backward",
    STOPPED: "Stopped",
} as const;

const JOYSTICK_DIRECTION_STATES = {
    LEFT: "Left",
    RIGHT: "Right",
} as const;

export enum MOVEMENT_STATES {
    FORWARD = "Forward",
    STRAIGHT_FORWARD = "Straight Forward",
    BACKWARD = "Backward",
    STRAIGHT_BACKWARD = "Straight Backward",
    STOPPED = "Stopped",
    RIGHT = "Right",
    LEFT = "Left",
    FORWARD_LEFT = "Forward Left",
    FORWARD_RIGHT = "Forward Right",
    BACKWARD_LEFT = "Backward Left",
    BACKWARD_RIGHT = "BackWwrd Right",
    JUMP_RIGHT = "Jump Right",
    JUMP_LEFT = "Jump Left",
}

const BUTTON_ACTION_STATES = {
    JUMP: "Jump",
    INTERACT: "Interact",
    THIRE_PERSON_TOUCH_CAMERA: "3rd Person Touch Camera",
    FIRST_PERSON_TOUCH_CAMERA: "First Person Touch Camera",
    FORTNITE_TOUCH_CAMERA: "Fortnite Touch Camera",
} as const;

export const MOBILE_JOYSTICK_MOVEMENT_STATE = {
    FORWARD: JOYSTICK_MOVEMENT_STATES.FORWARD,
    BACKWARD: JOYSTICK_MOVEMENT_STATES.BACKWARD,
    STRAIGHT_FORWARD: JOYSTICK_MOVEMENT_STATES.STRAIGHT_FORWARD,
    STRAIGHT_BACKWARD: JOYSTICK_MOVEMENT_STATES.STRAIGHT_BACKWARD,
    TOUCH_STOPPED: JOYSTICK_MOVEMENT_STATES.STOPPED,
} as const;

export const MOBILE_JOYSTICK_DIRECTION_STATE = {
    LEFT: JOYSTICK_DIRECTION_STATES.LEFT,
    RIGHT: JOYSTICK_DIRECTION_STATES.RIGHT,
} as const;

export const GAMEPAD_JOYSTICK_MOVEMENT_STATE = {
    FORWARD: JOYSTICK_MOVEMENT_STATES.FORWARD,
    BACKWARD: JOYSTICK_MOVEMENT_STATES.BACKWARD,
    STRAIGHT_FORWARD: JOYSTICK_MOVEMENT_STATES.STRAIGHT_FORWARD,
    STRAIGHT_BACKWARD: JOYSTICK_MOVEMENT_STATES.STRAIGHT_BACKWARD,
    STOPPED: JOYSTICK_MOVEMENT_STATES.STOPPED,
} as const;

export const GAMEPAD_JOYSTICK_DIRECTION_STATE = {
    LEFT: JOYSTICK_DIRECTION_STATES.LEFT,
    RIGHT: JOYSTICK_DIRECTION_STATES.RIGHT,
} as const;

export const MOBILE_BUTTON_ACTION_STATES = {
    JUMP: BUTTON_ACTION_STATES.JUMP,
    INTERACT: BUTTON_ACTION_STATES.INTERACT,
} as const;

export const GAMEPAD_JOYSTICK_ACTION_STATES = {
    JUMP: BUTTON_ACTION_STATES.JUMP,
    INTERACT: BUTTON_ACTION_STATES.INTERACT,
} as const;

export enum MOBILE_BUTTON_ACTION_UI_STATES {
    SHOW = "Show",
    HIDE = "Hide",
    MOBILE_BUTTON_ACTION_CONTAINER_CLASS_NAME = ".character-state-control-button",
}

export enum MOBILE_JOYSTICK_CONTROL_UI {
    MOBILE_JOYSTICK_CONTROL_NAME = "character-joystick-control",
}

export enum PHYSICS_PROXY_UI {
    PHYSICS_MESSAGE_ELEMENT = "physics-loading-message",
}

export enum COLLISION_TYPE {
    UNKNOWN = -1,
    WITH_PLAYER,
    WITH_COLLIDABLE_OBJECTS,
    WITH_ENEMY,
}

export interface ICollisionSettings {
    disposable: boolean;
    playerCollision: boolean;
    enemyCollision: boolean;
    throwableCollision: boolean;
    canReappear: boolean;
}

export interface ITransformValue {
    x: number;
    y: number;
    z: number;
}

export enum TRANSFORMATION_OPTIONS {
    POSITION,
    ROTATION,
    SCALE,
}

export enum TRANSFORM_CONTROLS_MODE {
    TRANSLATE = "translate",
    ROTATE = "rotate",
    SCALE = "scale",
}

export enum WEAPON_AIMERS {
    AIMER_SCREEN_ZINDEX = 998,
}

export interface WeaponBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.WEAPON; // Value is a string "Weapon"
    inventoryType: INVENTORY_TYPES;
    weaponName: string;
    ui_tag: string;
    weaponStarting: boolean;
    uiImage: string;
    weaponType: WEAPON_TYPES;
    weaponDamage: number;
    weaponAimerZindex: number;
    weaponClipAmount: number;
    weaponFireSpeed: number;
    weaponReloadSpeed: number;
    weaponScopeZoom: number;
    aimerUIImage: string;
    aimerUIImageName: string;
    weaponShowHUDAimerInGame: boolean;
    weaponPreviewHUDAimer: boolean;
    weaponHUDAimerSize: number;
    VFXSmallEffect: boolean;
    VFXMediumEffect: boolean;
    VFXBigEffect: boolean;
    VFXLaserEffect: boolean;
    VFXCartoonyEffect: boolean;
    weaponAutoReload: boolean;
    position_x: number;
    position_y: number;
    position_z: number;
    rotation_x: number;
    rotation_y: number;
    rotation_z: number;
    selectedWeaponAmmoName: string;
    weaponAmmoVisible: boolean;
    weaponSelectedCharacterBone: string;
    weaponScale: number;
    weaponMuzzleFlashBrightness: number;
    weaponMuzzleSmokeDensity: number;
    weaponMuzzleSmokeSize: number;
    weaponMuzzleSmokeLife: number;
    weaponMuzzleSmokeOpacity: number;
}

export interface AiNPCBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.AI_NPC; // Value is a string "AI NPC"
    name: string;
    voice_id: string;
    range: number;
    active_in_voice_chat: boolean;
    show_text_chat: boolean;
    bio: string;
    lore: string;
    adjectives: string[];
    social_media_posts: string;
    interests: string[];
    response_style: string;
    miscellaneous: string;
}

export interface SceneVolumeAssetBehaviorInterface extends BehaviorInterface {
    type: OBJECT_TYPES.SCENE_VOLUME; // Value is a string "Scene Volume"
    volumeType: SCENE_VOLUME_TYPES; // type of the scene volume
    options: WaterVolumeOptionsInterface | GravityVolumeOptionsInterface | CustomVolumeOptionsInterface | null;
}

export interface BillboardBehaviorInterface {
    id: string;
    type: OBJECT_TYPES.BILLBOARD; // Value is a string "Billboard"
    billboardMode: BILLBOARD_TYPES; // type of the billboard: video or file, values: "Webpage", "Image" or "YouTube Video"
    loop?: boolean;
    twoSided?: boolean;
    transparent?: boolean;
    faceCamera?: boolean;
    assetFile?: string; // uploaded asset url
    urlLink?: string; // video or webpage url
}

export interface WaterVolumeOptionsInterface {
    swimSpeed: number; // swimming speed values between 1 and 5
    dps: number; // damage per second
}

export interface GravityVolumeOptionsInterface {
    strength: number; // gravity strength in procentage
}

export interface CustomVolumeOptionsInterface {
    type: CUSTOM_VOLUME_TYPES; // possible options here: "Level Changer"
    loseTime: number; // time in seconds to lose
    damageAmount: number; // damage in procentage
    losePoints: number; // points to lose
}

export enum CUSTOM_VOLUME_TYPES {
    LEVEL_CHANGER = "Level Changer",
    CUSTOM = "Custom",
}

export enum BILLBOARD_TYPES {
    WEB = "Webpage",
    YT_VIDEO = "YouTube Video",
    IMAGE = "Image",
}

export enum SCENE_VOLUME_TYPES {
    BLOCKING = "Blocking",
    KILL_VOLUME = "Kill",
    LOSE_VOLUME = "Lose",
    WIN_VOLUME = "Win",
    WATER = "Water",
    GRAVITY = "Gravity",
    CUSTOM = "Custom",
}

export enum SHADER_EFFECTS {
    BOKEH = "Bokeh Effect",
    PIXEL = "Pixel Effect",
    RGB = "RGB",
    NONE = "None",
}

export enum CAMERA_EFFECTS {
    BOKEH = "Bokeh",
    PIXEL = "Pixel",
    RGB = "RGB",
    NONE = "None",
}

//Disable some cameras while continuing re-factor
export enum CAMERA_TYPES {
    FIRST_PERSON = "First Person",
    THIRD_PERSON = "Third Person",
    FORTNITE = "FortNite",
    TOP_DOWN = "Top Down",
    SIDE_SCROLLER = "Side Scroller",
    VEHICLE = "Vehicle",
    SPECTATOR = "Spectator",
    FIXED = "Fixed",
}

export enum WEAPON_EFFECTS {
    GUN_MUZZLE_FLASH_PLANE_NAME = "gun_muzzle_flash_plane",
    WEAPON_HUD_AIMER_IMG_NAME = "weapon-hud-aimer-",
}

export enum MATERIAL_TYPES {
    MESH_BASIC = "MeshBasicMaterial",
    MESH_STANDARD = "MeshStandardMaterial",
    MESH_PHONG = "MeshPhongMaterial",
    MESH_TOON = "MeshToonMaterial",
    MESH_PHYSICAL = "MeshPhysicalMaterial",
}

export enum SHADER_EFFECTS_PROPS {
    BOKEH = "bokeh",
    PIXEL = "pixel",
    RGB = "rgbShift",
    NONE = "none",
}

//physics
enum CollisionType {
    Dynamic = "Dynamic",
    Kinematic = "Kinematic",
    Static = "Static",
}

enum Shape {
    btBoxShape = "BoxShape",
    btSphereShape = "SphereShape",
    btConvexHullShape = "ConvexHullShape",
    btCapsuleShape = "CapsuleShape",
}

interface IPhysics {
    enabled: boolean;
    shape: keyof Shape;
    mass: number;
    inertia: {
        x: number;
        y: number;
        z: number;
    };
    ctype: CollisionType;
    position: {
        x: number;
        y: number;
        z: number;
    };
    scale: {
        x: number;
        y: number;
        z: number;
    };
    rotation: {
        x: number;
        y: number;
        z: number;
    };
    enable_preview: boolean;
}

export enum COLLISION_MATERIAL_TYPE {
    METAL = "Metal",
    DIRT = "Dirt",
    GROUND = "Ground",
    PLASTIC = "Plastic",
    SNOW = "Snow",
    WOOD = "Wood",
    CONCRETE = "Concrete",
    MUD = "Mud",
    ICE = "Ice",
    SLIME = "Slime",
    WATER = "Water",
    SLIPPERY_GROUND = "Slippery ground",
    RUBBER = "Rubber",
    SAND = "Sand",
}

export interface ILightState {
    label?: string;
    show: boolean;
    showColor?: boolean;
    color?: string;
    showIntensity?: boolean;
    intensity?: number;
    showDistance?: boolean;
    showDecay?: boolean;
    distance?: number;
    decay?: number;
    showAngle?: boolean;
    showPenumbra?: boolean;
    angle?: number;
    penumbra?: number;
    showSkyColor?: boolean;
    showGroundColor?: boolean;
    skyColor?: string;
    groundColor?: string;
    showWidth?: boolean;
    showHeight?: boolean;
    showCastShadow?: boolean;
    castShadow?: boolean;
    width?: number;
    height?: number;
    startOnTrigger?: boolean;
    showTarget?: boolean;
    target?: THREE.Object3D<THREE.Object3DEventMap>;
}

export interface VehicleOptionsInterface {
    acceleration: number;
    maxSpeed: number;
    leftFrontWheel: string;
    rightFrontWheel: string;
    leftRearWheel: string;
    rightRearWheel: string;
    steeringWheel: string;
    trackModel: string;
    trackSurface: string;
    trackBoundary: string;
    engineHorsepower: number;
    tireFriction: number;
    brakeForce: number;
    cameraMinDistance: number;
    cameraMaxDistance: number;
    cameraFov: number;
}

//AI responses

export interface IAiTransformResponse {
    scale?: ITransformMessageData;
    rotation?: ITransformMessageData;
    position?: ITransformMessageData;
}

export interface IAiBehaviorsResponse {
    attach?: IBehavior[];
    detach?: OBJECT_TYPES[];
    update?: (Partial<IBehavior> & Pick<IBehavior, "type">)[];
}

export interface IAiTextureResponse {
    prompt: string;
    twoSided: boolean;
    transparent: boolean;
}

export interface IAiResponse {
    modelUUID: string;
    name: string;
    transform?: IAiTransformResponse;
    behaviors?: IAiBehaviorsResponse;
    texture?: IAiTextureResponse;
}

export interface IAiAssistantResponse {
    assistantResponse: IAiResponse[];
}

// messages
export interface ITransformMessageData {
    x: number;
    y: number;
    z: number;
}

export interface ISoundSettings {
    id: string;
    url: string;
    loop: boolean;
    volume: number;
    soundType: "play-now" | "menu-background" | "";
}

export enum IFRAME_MESSAGES {
    GAME_STARTED = "gameStarted",
    GAME_RESUMED = "gameResumed",
    GAME_PAUSED = "gamePaused",
    GAME_ENDED = "gameEnded",
    GAME_CLOSED = "gameClosed",
    GAME_CREATED = "gameCreated",
    GAME_PLAYER_ERROR = "gamePlayerError",
    GAME_MULTIPLAYER_ERROR = "gameMultiplayerError",
    PLAYER_ADDED_LISTENER = "playerAddedListener",
}
