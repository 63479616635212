import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {AI16ZHome} from "./AI16ZHome/AI16ZHome";

export enum ROUTES {
    HOME = "/",
}

export const Ai16zAppRouter = () => {
    const routerFutureSettings = {
        v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_skipActionErrorRevalidation: true,
    };
    const router = createBrowserRouter(
        [
            {
                path: ROUTES.HOME,
                element: <AI16ZHome />,
            },
        ],
        {
            future: routerFutureSettings,
        },
    );

    return (
        <RouterProvider
            router={router}
            future={{
                v7_startTransition: true,
            }}
        />
    );
};
