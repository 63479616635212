import Ajax from "../../../utils/Ajax";
import global from "../../../global";
import Converter from "../../../serialization/Converter";
import {THREE_GetGifTexture} from "threejs-gif-texture";

class SceneLoader {
    constructor() {}

    async fetchScene(url: string) {
        try {
            const res = await Ajax.get({
                url,
            });
            let data;
            if (res?.data.Code) {
                if (res.data.Code !== 200) {
                    return;
                } else {
                    data = res.data.Data;
                }
            } else {
                data = res?.data;
            }
            if (!data) {
                return;
            }

            return data;
        } catch (error: any) {
            console.error("Fetching scene error:", error.message);
        }
    }

    async load(url: string, callback?: (obj: any) => void) {
        try {
            const data = await this.fetchScene(url);
            const converter = new (Converter as any)();
            const obj = await converter.sceneAsGroupFromJson(data, {
                server: global.app?.options.server,
                domWidth: global.app?.editor.renderer.domElement.width,
                domHeight: global.app?.editor.renderer.domElement.height,
            });
            obj.scene.traverse(async (n: any) => {
                if (n.material?.map?.gifUrl) {
                    n.material.map = await THREE_GetGifTexture(n.material.map.gifUrl);
                }
            });
            return obj.scene;
        } catch (error: any) {
            console.error("loading scene in Scene Loader error:", error.message);
        }
    }
}

export default SceneLoader;
