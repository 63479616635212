import BaseAnimationBehaviorConverter from "./BaseAnimationBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import AnimationBehaviorUpdater from "./AnimationBehaviorUpdater";
import {ANIMATION_TYPES, EASE_TYPES, OBJECT_TYPES, AnimationBehaviorInterface} from "../../types/editor";

class AnimationBehaviorConverter extends BaseAnimationBehaviorConverter {
    static readonly ANIMATION_LAYER_ID = "9b08333c-7cf7-42f0-8c31-2df838780726";
    static readonly ANIMATION_LAYER_NAME = "ANIMATIONS";
    public static DEFAULT = new AnimationBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    clearAnimationLayer(target: THREE.Object3D) {
        const animationLayer = super.getAnimationLayer(
            AnimationBehaviorConverter.ANIMATION_LAYER_ID,
            AnimationBehaviorConverter.ANIMATION_LAYER_NAME,
        );
        if (animationLayer?.animations) {
            animationLayer.animations = animationLayer.animations.filter((a: any) => a.target !== target.uuid);
        }
    }

    convert(target: THREE.Object3D, behavior: AnimationBehaviorInterface): BehaviorUpdater {
        const animationLayer = super.getAnimationLayer(
            AnimationBehaviorConverter.ANIMATION_LAYER_ID,
            AnimationBehaviorConverter.ANIMATION_LAYER_NAME,
        );

        //delete existing animation for this object
        animationLayer.animations = animationLayer.animations.filter((a: any) => a.target !== target.uuid);

        if (behavior.speed <= 0) {
            return new AnimationBehaviorUpdater(target, behavior);
        }

        const physics = target.userData.physics;

        if (behavior.type === "Animation" && physics.ctype !== "Kinematic") {
            physics.mass = 0;
            physics.restitution = 0;
            physics.ctype = "Kinematic";
        }

        target.userData.startOnTrigger = false;
        target.userData.startPosition = target.position;

        const normalDuration = 10; // Normal duration is 10 seconds for speed = 1
        const beginTime = 0;
        const endTime = beginTime + normalDuration / behavior.speed;
        const loop = behavior.animationType !== ANIMATION_TYPES.PLAY_ONCE;
        const loopType = behavior.animationType === ANIMATION_TYPES.LOOP ? "reflect" : "restart";

        const displacement = new THREE.Vector3(behavior.horizontalX, behavior.vertical, behavior.horizontalZ);
        displacement.applyQuaternion(target.quaternion);

        const endPositionX = target.position.x + displacement.x;
        const endPositionY = target.position.y + displacement.y;
        const endPositionZ = target.position.z + displacement.z;

        const rotationX = target.rotation.x + THREE.MathUtils.degToRad(behavior.rotationX);
        const rotationY = target.rotation.y + THREE.MathUtils.degToRad(behavior.rotationY);
        const rotationZ = target.rotation.z + THREE.MathUtils.degToRad(behavior.rotationZ);

        const scaleX = target.scale.x + behavior.scaleX;
        const scaleY = target.scale.y + behavior.scaleY;
        const scaleZ = target.scale.z + behavior.scaleZ;

        
        animationLayer.animations.push({
            id: null,
            uuid: THREE.MathUtils.generateUUID(),
            name: "Animation " + target.name,
            target: target.uuid,
            model: target,
            type: "Tween",
            beginTime,
            endTime,
            loop: loop,
            loopType: loopType,
            data: {
                beginStatus: "Custom", //
                beginPositionX: target.position.x,
                beginPositionY: target.position.y,
                beginPositionZ: target.position.z,
                beginRotationX: target.rotation.x,
                beginRotationY: target.rotation.y,
                beginRotationZ: target.rotation.z,
                beginScaleLock: true,
                beginScaleX: target.scale.x,
                beginScaleY: target.scale.y,
                beginScaleZ: target.scale.z,
                ease: behavior.easeType,
                endStatus: "Custom",
                endPositionX: endPositionX,
                endPositionY: endPositionY,
                endPositionZ: endPositionZ,
                endRotationX: rotationX,
                endRotationY: rotationY,
                endRotationZ: rotationZ,
                endScaleLock: true,
                endScaleX: scaleX,
                endScaleY: scaleY,
                endScaleZ: scaleZ,
            },
        });

        return new AnimationBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, behaviorId: string): AnimationBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, behaviorId) as AnimationBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have ANIMATION behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): AnimationBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.ANIMATION,
            animationType: ANIMATION_TYPES.LOOP,
            horizontalX: 0,
            horizontalZ: 0,
            vertical: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,
            scaleX: 0,
            scaleY: 0,
            scaleZ: 0,
            speed: 1,
            easeType: EASE_TYPES.LINEAR,
            startOnTrigger: false,
        };
    }
}

export default AnimationBehaviorConverter;
