import styled from "styled-components";
import {flexCenter} from "../assets/style";

export const MainContainer = styled.div`
    background-color: var(--theme-container-main-dark);
    min-height: 100vh;
    ${flexCenter};
    flex-direction: column;
    main {
        flex-grow: 1;
        width: 100vw;
    }
`;
