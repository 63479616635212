import React, {useRef} from "react";
import {toast} from "react-toastify";
import {useOnClickOutside} from "usehooks-ts";
import I18n from "i18next";

import global from "../../../../../global";
import Ajax from "../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import Application from "../../../../../Application";
import {useAppGlobalContext} from "../../../../../context";

import {Checkbox} from "../../../../../ui/common/Checkbox";
import {Separator} from "../../RightPanel/common/Separator";
import {Shortcut} from "./Shortcut/Shortcut";
import {Container, MenuItem} from "./AppMenu.style";
import {generateProjectLink} from "../../../../../v2/pages/services";

export enum APP_MENU_ITEM {
    NEW_GAME = "New Game",
    DUPLICATE_GAME = "Duplicate Game",
    IMPORT_SCENE = "Import Game",
    EXPORT_SCENE = "Export Game",
    TOGGLE_DEBUG_MODE = "Debug Mode",
    COMMAND_BAR = "Command Bar",
    UNDO = "Undo",
    REDO = "Redo",
    COPY = "Copy",
    CUT = "Cut",
    DUPLICATE = "Duplicate",
    PASTE = "Paste",
    TOGGLE_UI = "Toggle UI",
}
const isMac = /Mac/i.test(navigator.userAgent);
const mainKey = isMac ? "⌘" : "ctrl";

const MENU_ITEMS = [
    {title: APP_MENU_ITEM.NEW_GAME},
    {title: APP_MENU_ITEM.DUPLICATE_GAME},
    {title: APP_MENU_ITEM.IMPORT_SCENE},
    {title: APP_MENU_ITEM.EXPORT_SCENE},
    {title: APP_MENU_ITEM.TOGGLE_DEBUG_MODE, divider: true},
    {title: APP_MENU_ITEM.COMMAND_BAR, divider: true, disabled: true, shortcut: `${mainKey}K`},
    {title: APP_MENU_ITEM.UNDO, divider: true, shortcut: `${mainKey}Z`},
    {title: APP_MENU_ITEM.REDO, shortcut: `${mainKey}⇧Z`},
    {title: APP_MENU_ITEM.COPY, divider: true, shortcut: `${mainKey}C`},
    {title: APP_MENU_ITEM.CUT, shortcut: `${mainKey}X`, disabled: true},
    {title: APP_MENU_ITEM.DUPLICATE, shortcut: `${mainKey}D`},
    {title: APP_MENU_ITEM.PASTE, shortcut: `${mainKey}V`},
    {title: APP_MENU_ITEM.TOGGLE_UI, divider: true, shortcut: `${mainKey}.`},
];

interface Props {
    close: () => void;
    userMenuButtonRef: React.MutableRefObject<HTMLButtonElement | SVGSVGElement | null>;
}

export const AppMenu = ({close, userMenuButtonRef}: Props) => {
    const app = global.app as Application | null;
    const editor = app?.editor;
    const metaverse = (global.app as Application)?.options.metaverse;
    const {setProjectPhase} = useAppGlobalContext();
    const userMenuRef = useRef<HTMLDivElement | null>(null);
    const checkboxRef = useRef<any>(null);

    useOnClickOutside(
        [userMenuRef as React.MutableRefObject<HTMLElement>, userMenuButtonRef as React.MutableRefObject<HTMLElement>],
        close,
    );

    const handleCreateEmptyScene = () => {
        if (!editor) return;
        editor.queryBeforeCreateScene().then(() => {
            setProjectPhase(2);
            app.editor?.clearAndReset(true);
            app.call(`clear`, app.editor, app.editor);
            app.call("sceneLoaded");
            generateProjectLink();
        });
    };

    const handleDuplicate = async () => {
        if (!app?.editor) {
            return console.error("Missing props for duplicate: setShowLoading, reload");
        }
        app.editor.handleLoading(true);
        try {
            const response = await Ajax.post({
                url: backendUrlFromPath(`/api/Scene/Duplicate`),
                data: {
                    ID: app.editor.sceneID,
                },
            });

            const statusCode = response?.data.Code;
            if (!response || (statusCode && statusCode !== 200)) {
                throw new Error(`Error ${statusCode}: ${response?.data.Msg}`);
            }

            app.editor.handleLoading(false);
            toast.success("Scene duplicated");
        } catch (error) {
            app.editor.handleLoading(false);
            app.toast("Request failed.", "error");
        }
    };

    const handleImportScene = () => {
        if (!app || !app.editor) return;
        app.editor.scene.userData.lastSaveTime = new Date().toISOString();
        app.upload(backendUrlFromPath(`/api/Scene/Save`) || "", (obj: any) => {
            if (obj.Code === 200) {
                app.call(`sceneSaved`, this);
            }
            app.toast(I18n.t(obj.Msg));
        });
    };

    const handleMenuItemClick = (event: any, item: APP_MENU_ITEM) => {
        if (checkboxRef.current && checkboxRef.current.contains(event.target)) {
            return;
        }

        switch (item) {
            case APP_MENU_ITEM.NEW_GAME:
                handleCreateEmptyScene();
                break;
            case APP_MENU_ITEM.DUPLICATE_GAME:
                handleDuplicate();
                break;
            case APP_MENU_ITEM.IMPORT_SCENE:
                handleImportScene();
                break;
            case APP_MENU_ITEM.EXPORT_SCENE:
                editor?.handleExportSceneToJson();
                break;
            case APP_MENU_ITEM.TOGGLE_DEBUG_MODE:
                if (!app) return;
                app.storage.debug = !app.storage.debug;
                window.location.reload();
                break;
            case APP_MENU_ITEM.UNDO:
                if (!app) return;
                app.undo();
                break;
            case APP_MENU_ITEM.REDO:
                if (!app) return;
                app.redo();
                break;
            case APP_MENU_ITEM.COPY:
                if (!app) return;
                if (!app.editor?.selected) {
                    return toast.warn("No object selected.");
                }
                app.copy();
                toast.success("Copied!");
                break;
            case APP_MENU_ITEM.PASTE:
                if (!app) return;
                app.paste();
                toast.success("Pasted!");
                break;
            case APP_MENU_ITEM.DUPLICATE:
                if (!app) return;
                if (!app.editor?.selected) {
                    return toast.warn("No object selected.");
                }
                app.duplicateObject();
                toast.success("Duplicated!");
                break;
            case APP_MENU_ITEM.TOGGLE_UI:
                if (!app) return;
                app.toggleUI();
                break;

            default:
                break;
        }
    };

    return (
        <Container ref={userMenuRef}>
            {MENU_ITEMS.map(({title, divider, disabled, shortcut}, index) => {
                if (metaverse) {
                    if (
                        title === APP_MENU_ITEM.DUPLICATE_GAME ||
                        title === APP_MENU_ITEM.IMPORT_SCENE ||
                        title === APP_MENU_ITEM.NEW_GAME
                    ) {
                        return;
                    }
                }
                return (
                    <div key={index} style={{width: "100%"}}>
                        {divider && <Separator margin="8px 0" />}
                        <MenuItem
                            key={index}
                            $disabled={!!disabled}
                            onClick={e => (disabled ? undefined : handleMenuItemClick(e, title))}>
                            {title === APP_MENU_ITEM.TOGGLE_DEBUG_MODE ? (
                                <>
                                    {title}{" "}
                                    <Checkbox
                                        checked={!!app?.storage.debug}
                                        customId={title}
                                        readOnly={true}
                                        refProp={checkboxRef}
                                        invisible={true}
                                    />
                                </>
                            ) : !!shortcut ? (
                                <>
                                    {title} <Shortcut shortcut={shortcut} />
                                </>
                            ) : (
                                title
                            )}
                        </MenuItem>
                    </div>
                );
            })}
        </Container>
    );
};
