import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import CharacterBehaviorUpdater from "./CharacterBehaviorUpdater";
import {
    CAMERA_TYPES,
    CharacterBehaviorInterface,
    OBJECT_TYPES,
    CharacterOptionsInterface,
    VehicleOptionsInterface,
} from "../../types/editor";
import {AnimationClip, Object3D} from "three";

class CharacterBehaviorConverter extends BaseBehaviorConverter<CharacterBehaviorInterface> {
    public static DEFAULT = new CharacterBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    private isModelAnimation(animations: AnimationClip[], animationName: string): boolean {
        return animations?.some(clip => clip.name === animationName);
    }

    convert(target: Object3D, behavior: any): BehaviorUpdater {
        target.userData.player = true;
        //TODO: we support both dynamic and kinematic objects, so physics is not required
        target.userData.physics = target.userData.physics || {
            enabled: true,
            shape: "btBoxShape",
            mass: 0,
            inertia: {x: 0, y: 0, z: 0},
            restitution: 0,
            type: "rigidBody",
            ctype: "Static",
        };
        this.obj.camera.userData.control = behavior.control;
        if (behavior.control === CAMERA_TYPES.THIRD_PERSON) {
            this.obj.camera.userData.characterOptions = this.obj.camera.userData.characterOptions || {
                walkSpeed: 15.0,
                rotationSpeed: 25.0,
                runSpeed: 35.0,
                lookVertical: true,
                activeLook: true,
                heightSpeed: false,
                heightCoef: 1.0,
                heightMin: 0.0,
                heightMax: 1.0,
                constrainVertical: false,
                verticalMin: 0,
                verticalMax: Math.PI,
                initialXRotation: "none",
                idleAnimation: "Idle",
                walkAnimation: "Walk",
                runAnimation: "Run",
                jumpAnimation: "Jump",
                animationNames: {
                    Idle: "Idle",
                    Walk: "Walk",
                    Run: "Run",
                    Jump: "Jump",
                },
            };
            this.obj.camera.userData.characterOptions.selectedModel = target.name;
            this.obj.camera.userData.characterOptions.selectedModelUUID = target.uuid;
        }

        return new CharacterBehaviorUpdater(target, behavior.control, behavior);
    }

    resetCameraControl() {
        this.obj.camera.userData.control = "OrbitControls";
    }
    getBehavior(target: Object3D): CharacterBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, OBJECT_TYPES.CHARACTER) as CharacterBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have CHARACTER behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): CharacterBehaviorInterface {
        return {
            id,
            enabled: true,
            type: OBJECT_TYPES.CHARACTER,
            control: CAMERA_TYPES.THIRD_PERSON,
            pickUpWeapons: true,
        };
    }

    getDefaultCharacterOptions(sceneModels: any): CharacterOptionsInterface {
        return {
            useDemoWeapon: false,
            useAutoForward: false,
            sceneModels: sceneModels || [],
            selectedModelUUID: "",
            selectedModel: "none",
            animationNames: {},
            walkAnimation: "none",
            runAnimation: "none",
            jumpAnimation: "none",
            idleAnimation: "none",
            fallDelay: 0,
            fallAnimation: "none",
            fallingAnimation: "none",
            crouchAnimation: "none",
            dieAnimation: "none",
            leftDirectionAnimation: "none",
            rightDirectionAnimation: "none",
            reverseDirectionAnimation: "none",
            shootIdleAnimation: "none",
            shootWalkAnimation: "none",
            shootRunAnimation: "none",
            reloadIdleAnimation: "none",
            reloadWalkAnimation: "none",
            reloadRunAnimation: "none",
            swordSimpleAnimation: "none",
            swordSpecialAnimation: "none",
            punchAnimation: "none",
            pushAnimation: "none",
            kickAnimation: "none",
            throwAnimation: "none",
            pickUpAnimation: "none",
            carryAnimation: "none",
            initialXRotation: "none",
            walkSpeed: 5,
            runSpeed: 10,
            jumpHeight: 5,
            cameraMinDistance: 10,
            cameraMaxDistance: 10,
            cameraFov: 50,
            overTheShoulder: true,
            otsRightShoulderCamera: true,
            slopeTolerance: 0.15,
            age: 21,
            health: 100,
            shield: 100,
            lookSpeed: 10,
        };
    }

    updateCharacterOptions(model: Object3D, camera: any) {
        const characterOptions = camera.userData.characterOptions;
        if (model && characterOptions) {
            characterOptions.selectedModelUUID = model.uuid;
            characterOptions.selectedModel = model.name;

            const animations = model.animations?.length > 0 ? model.animations : model._obj?.animations;

            if (animations) {
                characterOptions.animationNames = {};

                for (let i = 0; i < animations.length; i++) {
                    characterOptions.animationNames[animations[i].name] = animations[i].name;
                }

                characterOptions.animationNames["none"] = "none";
            }

            if (characterOptions.animationNames) {
                if (
                    characterOptions.animationNames["Idle"] &&
                    (characterOptions.idleAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.idleAnimation))
                ) {
                    characterOptions.idleAnimation = "Idle";
                }

                if (
                    characterOptions.animationNames["Walk"] &&
                    (characterOptions.walkAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.walkAnimation))
                ) {
                    characterOptions.walkAnimation = "Walk";
                }

                if (
                    characterOptions.animationNames["Run"] &&
                    (characterOptions.runAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.runAnimation))
                ) {
                    characterOptions.runAnimation = "Run";
                }

                if (
                    characterOptions.animationNames["Shoot"] &&
                    (characterOptions.shootAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.shootAnimation))
                ) {
                    characterOptions.shootAnimation = "Shoot";
                }

                if (
                    characterOptions.animationNames["Reload"] &&
                    (characterOptions.reloadAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.reloadAnimation))
                ) {
                    characterOptions.reloadAnimation = "Reload";
                }

                if (
                    characterOptions.animationNames["StrafeLeft"] &&
                    (characterOptions.leftDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.leftDirectionAnimation))
                ) {
                    characterOptions.leftDirectionAnimation = "StrafeLeft";
                }

                if (
                    characterOptions.animationNames["StrafeRight"] &&
                    (characterOptions.rightDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.rightDirectionAnimation))
                ) {
                    characterOptions.rightDirectionAnimation = "StrafeRight";
                }

                if (
                    characterOptions.animationNames["Carry"] &&
                    (characterOptions.carryAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.carryAnimation))
                ) {
                    characterOptions.carryAnimation = "Carry";
                }

                if (
                    characterOptions.animationNames["Push"] &&
                    (characterOptions.pushAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.pushAnimation))
                ) {
                    characterOptions.pushAnimation = "Push";
                }

                if (
                    characterOptions.animationNames["BackUp"] &&
                    (characterOptions.reverseDirectionAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.reverseDirectionAnimation))
                ) {
                    characterOptions.reverseDirectionAnimation = "BackUp";
                }

                if (
                    characterOptions.animationNames["Punch"] &&
                    (characterOptions.punchAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.punchAnimation))
                ) {
                    characterOptions.punchAnimation = "Punch";
                }

                if (
                    characterOptions.animationNames["Sword Simple"] &&
                    (characterOptions.swordSimpleAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.swordSimpleAnimation))
                ) {
                    characterOptions.swordSimpleAnimation = "Sword Simple";
                }

                if (
                    characterOptions.animationNames["Jump"] &&
                    (characterOptions.jumpAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.jumpAnimation))
                ) {
                    characterOptions.jumpAnimation = "Jump";
                }

                if (
                    characterOptions.animationNames["Fall"] &&
                    (characterOptions.fallAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.fallAnimation))
                ) {
                    characterOptions.fallAnimation = "Fall";
                }

                if (
                    characterOptions.animationNames["Die"] &&
                    (characterOptions.dieAnimation === "none" ||
                        !this.isModelAnimation(animations, characterOptions.dieAnimation))
                ) {
                    characterOptions.dieAnimation = "Die";
                }
            }
        }
    }

    getDefaultSideScrollerOptions(sceneModels: any): {
        throwAnimation: string;
        otsRightShoulderCamera: boolean;
        runSpeed: number;
        reloadIdleAnimation: string;
        pickUpAnimation: string;
        reloadRunAnimation: string;
        fallAnimation: string;
        shootWalkAnimation: string;
        jumpHeight: number;
        reloadIdledAnimation: string;
        selectedModelUUID: string;
        punchAnimation: string;
        leftDirectionAnimation: string;
        reverseDirectionAnimation: string;
        carryAnimation: string;
        cameraMaxDistance: number;
        reloadWalkAnimation: string;
        initialXRotation: string;
        slopeTolerance: number;
        selectedModel: string;
        overTheShoulder: boolean;
        walkSpeed: number;
        animationNames: {};
        idleAnimation: string;
        jumpAnimation: string;
        lookSpeed: number;
        dieAnimation: string;
        shootRunAnimation: string;
        cameraMinDistance: number;
        cameraFov: number;
        shootIdleAnimation: string;
        swordSimpleAnimation: string;
        runAnimation: string;
        rightDirectionAnimation: string;
        playerGravity: number;
        fallDelay: number;
        pushAnimation: string;
        walkAnimation: string;
        sceneModels: any;
        kickAnimation: string;
    } {
        return {
            reloadIdledAnimation: "",
            sceneModels: sceneModels || [],
            selectedModelUUID: "",
            selectedModel: "none",
            animationNames: {},
            walkAnimation: "none",
            runAnimation: "none",
            jumpAnimation: "none",
            idleAnimation: "none",
            fallDelay: 0,
            fallAnimation: "none",
            dieAnimation: "none",
            leftDirectionAnimation: "none",
            rightDirectionAnimation: "none",
            reverseDirectionAnimation: "none",
            shootIdleAnimation: "none",
            shootWalkAnimation: "none",
            shootRunAnimation: "none",
            reloadIdleAnimation: "none",
            reloadWalkAnimation: "none",
            reloadRunAnimation: "none",
            swordSimpleAnimation: "none",
            punchAnimation: "none",
            pushAnimation: "none",
            kickAnimation: "none",
            throwAnimation: "none",
            pickUpAnimation: "none",
            carryAnimation: "none",
            initialXRotation: "none",
            walkSpeed: 8,
            runSpeed: 12,
            lookSpeed: 1,
            jumpHeight: 5,
            playerGravity: -75,
            cameraMinDistance: 10,
            cameraMaxDistance: 10,
            cameraFov: 50,
            overTheShoulder: true,
            otsRightShoulderCamera: true,
            slopeTolerance: 0.15,
        };
    }

    getDefaultVehicleOptions(): VehicleOptionsInterface {
        return {
            acceleration: 0.003,
            maxSpeed: 0.3,
            leftFrontWheel: "none",
            rightFrontWheel: "none",
            leftRearWheel: "none",
            rightRearWheel: "none",
            steeringWheel: "none",
            trackModel: "none",
            trackSurface: "none",
            trackBoundary: "none",
            engineHorsepower: 4000,
            tireFriction: 1000,
            brakeForce: 100,
            cameraMinDistance: 10,
            cameraMaxDistance: 10,
            cameraFov: 50,
        };
    }
}

export default CharacterBehaviorConverter;
