import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../assets/style";

export const Container = styled.div`
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    right: 12px;
    top: 12px;
    width: 258px;
    height: calc(100svh - 24px);
    background: var(--theme-container-main-dark);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-font-main-selected-color);
    z-index: 100;

    .common-text {
        font-size: var(--theme-font-size-xxs);
        font-weight: var(--theme-font-regular);
        color: var(--theme-font-unselected-color);
        line-height: 120%;
        text-align: left;
    }

    .white-bold {
        font-weight: var(--theme-font-medium);
        color: var(--theme-font-main-selected-color);
    }
`;

export const BorderedWrapper = styled.div<{
    height?: string;
    $isHeader?: boolean;
}>`
    display: flex;
    width: 100%;
    padding: 8px;
    height: ${({height}) => height || "auto"};
    min-height: ${({height}) => height || "48px"};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--theme-container-divider);
    gap: 4px;
    font-weight: 600;
    font-size: var(--theme-font-size-xxs);

    ${({$isHeader}) =>
        $isHeader &&
        `
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  `}

    > div {
        > span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 150px;
        }
    }
`;

export const Label = styled.div<{$regular?: boolean; $isGray?: boolean; $withIcon?: boolean}>`
    ${regularFont("xxs")};
    font-weight: ${({$regular}) => ($regular ? "var(--theme-font-regular)" : "var(--theme-font-medium-plus)")};
    margin-bottom: 8px;
    color: ${({$isGray}) => ($isGray ? "var(--theme-font-unselected-color)" : "var(--theme-font-main-selected-color)")};

    ${({$withIcon}) =>
        $withIcon &&
        `
    ${flexCenter};
    justify-content: space-between;
    width: 100%;
    .icon {
        width: 18px;
        cursor: pointer;
    }
    `}
`;

export const PanelContentWrapper = styled.div<{$isBehaviorOpen: boolean}>`
    width: 100%;
    // padding bottom should be same as top + editor button height
    padding: ${({$isBehaviorOpen}) => ($isBehaviorOpen ? "12px 8px" : "12px 8px calc(12px + 32px)")};
    height: 100%;
`;

export const PanelSectionTitle = styled.div<{$margin?: string}>`
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium-plus);
    text-align: left;
    ${({$margin}) => $margin && `margin: ${$margin}`}
`;

export const PanelSectionTitleSecondary = styled(PanelSectionTitle)`
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
`;

export const Instruction = styled.div`
    width: 100%;
    height: 63px;
    margin: 0 auto;
    border-radius: 8px;
    background: var(--theme-editor-box-bg);
    padding: 10px 11px;
    .text {
        ${regularFont("xs")};
        font-family: "Inter";
        color: #aeaeae;
    }
    .text:first-child {
        margin-bottom: 12px;
    }
`;
