import styled from "styled-components";
import BoringAvatar from "boring-avatars";
import {flexCenter} from "../../../../assets/style";

interface Props {
    name?: string;
    image?: string;
    size?: number;
    onClick?: () => void;
}

export const Avatar = ({name, image, size, onClick}: Props) => {
    return image ? (
        <StyledAvatar $image={image} size={size} onClick={onClick} />
    ) : (
        <BoringAvatarWrapper onClick={onClick}>
            <BoringAvatar name={name} size={size || 45} variant="beam" />
        </BoringAvatarWrapper>
    );
};

const StyledAvatar = styled.div<{$image?: string; size?: number}>`
    width: ${({size}) => (size ? `${size}px` : "45px")};
    height: ${({size}) => (size ? `${size}px` : "45px")};
    flex-shrink: 0;
    ${flexCenter};
    background: transparent;
    border-radius: ${({size}) => (size ? `${size}px` : "4px")};
    ${({$image}) =>
        $image &&
        `
  background-image: url(${$image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  `}
`;

const BoringAvatarWrapper = styled.div`
    ${flexCenter};
`;
