import React, {useEffect} from "react";
import styled from "styled-components";
import {useModelAnimationCombinerContext} from "../../../../context";

import global from "../../../../global";
import {ModelViewer} from "./components/ModelViewer";
import {useOnClickOutside} from "usehooks-ts";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import {RightPanel} from "./components/RightPanel";
import GradientSpinner from "../../../../player/component/GradientSpinner";
import {StyledButton} from "../common/StyledButton";
import arrowLeft from "../../../assets/v2/icons/arrow-left.svg";

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1px);
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    display: flex;
    overflow: hidden;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    box-sizing: border-box;
`;

const LeftWrapper = styled.div`
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    left: 12px;
    top: 12px;
    width: 240px;
    padding: 8px;
    background: var(--theme-grey-bg-tertiary);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-font-main-selected-color);
    z-index: 100;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    button {
        color: white;
        font-weight: 600;
        img {
            filter: brightness(1.5);
        }
    }
`;

type Props = {
    model: any | null;
    onClose: () => void;
};

export const ModelAnimationCombiner = ({model, onClose}: Props) => {
    const app = (global as any).app;
    const {loading, clearState} = useModelAnimationCombinerContext();
    const ref = React.useRef<HTMLDivElement>(null);

    const handleClose = () => {
        onClose();
        clearState();
        app.call("fetchModels");
    };

    useOnClickOutside(ref, () => handleClose());

    const getModelUrl = (model: any) => {
        return backendUrlFromPath(model?.Url);
    };

    const getModelExtension = (model: any) => {
        const url = getModelUrl(model);
        return url?.split(".").pop() || "gltf";
    };

    const handleKeyDown = (event: any) => {
        if (event.key === "Escape") {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Overlay>
            <Container ref={ref}>
                <LeftWrapper>
                    <StyledButton isGreyBlue onClick={handleClose}>
                        <img src={arrowLeft} />
                        &nbsp;Cancel
                    </StyledButton>
                </LeftWrapper>
                <ModelViewer model={model} fileExt={getModelExtension(model?.userData)} handleClose={handleClose} />
                <RightPanel onClose={handleClose} model={model} />
            </Container>
            {loading && (
                <LoadingContainer>
                    <GradientSpinner />
                </LoadingContainer>
            )}
        </Overlay>
    );
};
