import React, {useEffect} from "react";
import {useOnClickOutside} from "usehooks-ts";

import {Container, Overlay} from "../AnimationCombiner/ModelAnimationCombiner";
import {MainPanel} from "./MainPanel/MainPanel";
import {PrimitiveViewer} from "./PrimitiveViewer/PrimitiveViewer";

type Props = {
    onClose: () => void;
};

export const MaterialEditor = ({onClose}: Props) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const handleClose = () => {
        onClose();
    };

    useOnClickOutside(ref, () => handleClose());

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                handleClose();
            }
        };
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <Overlay>
            <Container ref={ref}>
                <PrimitiveViewer />
                <MainPanel onClose={handleClose} />
            </Container>
        </Overlay>
    );
};
