import {useEffect, useState} from "react";
import {List, ListItem} from "../AiImageGenerator.styles";
import {getBulkAssets} from "../AiImageGenerator.service";
import {Asset} from "../AiImageGenerator.types";
import {toast} from "react-toastify";

type Props = {
    assetIds: string[];
    defaultImage?: string;
};

export const ResultAssets = ({assetIds, defaultImage}: Props) => {
    const [assets, setAssets] = useState<Asset[]>([]);

    useEffect(() => {
        const fetchAssets = async () => {
            try {
                const response = await getBulkAssets(assetIds);
                setAssets(response.assets);
            } catch (error) {
                toast.error("Error fetching assets");
            }
        };

        fetchAssets();
    }, []);

    return (
        <List>
            {assets?.map(asset => (
                <ListItem key={asset.id}>
                    <img src={asset.url} alt={asset.metadata.name} />
                </ListItem>
            ))}
            {assets.length === 0 && (
                <ListItem>
                    <img src={defaultImage} alt="Default Image" />
                </ListItem>
            )}
        </List>
    );
};
