import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: center;
    font-size: ${regularFont("xxs")};
    color: #fff;
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    overflow: hidden;

    &:hover {
        color: white;
    }
    .thumbnail {
        border-radius: 16px;
        width: 100%;
        aspect-ratio: 16 / 9;
    }
`;

export const SceneDetailsWrapper = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 8px;
    left: 8px;
    right: 8px;

    height: 40px;
    padding: 0 12px;

    background: #00000066;
    border-radius: 8px;
    backdrop-filter: blur(16px);

    ${flexCenter};
    justify-content: space-between;
    column-gap: 8px;

    line-height: 100%;
`;

export const SceneName = styled.span`
    display: inline-block;
    max-width: 175px;
    text-align: left;
    line-clamp: 1;
    font-size: ${regularFont("s")};
    font-weight: 700;
    line-height: 120%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

export const PlayCount = styled(SceneName)`
    ${flexCenter};
    column-gap: 8px;

    img {
        width: 16px;
        height: 16px;
    }
`;

export const SceneImage = styled.div<{$bgImage?: string}>`
    position: relative;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--theme-grey-bg);
    ${flexCenter};
    .default-img {
        width: 40%;
        max-width: 104px;
    }

    ${({$bgImage}) =>
        $bgImage &&
        `
                background-image: url('${$bgImage}');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

              `}

    .hoverPlayIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 20%;
        aspect-ratio: 1;
        height: auto;
        max-width: 56px;
        max-height: 56px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover::after {
        opacity: 0.5; /* Show overlay on hover */
    }

    &:hover .hoverPlayIcon {
        opacity: 1;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--theme-grey-bg);
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 1;
        border-radius: 16px;
    }
`;
