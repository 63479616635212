import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import {ROUTES} from "../../../../../AppRouter";
import {PAGES} from "../constants";
import {useAuthorizationContext} from "../../../../../context";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {METAVERSE_ROUTES} from "../../../../../metaverse/MetaverseAppRouter";
import arrow from "../../icons/arrow-up-right.svg";
import deleteIcon from "../../icons/delete-icon.svg";
import logoutIcon from "./assets/arrow-up-right.svg";
import successIcon from "../../../../../v2/pages/LoginPage/images/success.svg";
import errorIcon from "../../../../../v2/pages/LoginPage/images/error.svg";
import {StyledButton} from "../../common/StyledButton";
import {
    AccountBox,
    BackButton,
    Container,
    DeleteAccount,
    DeleteBox,
    ErrorMessage,
    Heading,
    InputWrapper,
    LegalBox,
    LogoutBox,
    ValidationInput,
} from "./SettingsPage.style";
import {LoadingAnimation} from "../../../../../ui/progress/LoadingAnimation";

export const SettingsPage = () => {
    const app = global?.app as Application | null;
    const metaverse = app?.options.metaverse;

    const {validateUsername, saveUsernameInFirebase, handleLogOut, isAuthorized, initLoading} =
        useAuthorizationContext();
    const navigate = useNavigate();
    const navigationOptions = {state: {from: `${ROUTES.DASHBOARD}?tab=${PAGES.SETTINGS.toLocaleLowerCase()}`}};
    const {dbUser} = useAuthorizationContext();

    const [username, setUsername] = useState(dbUser?.username ?? "");
    const [email, setEmail] = useState(dbUser?.email ?? "");
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [showValidationBorder, setShowValidationBorder] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Sorry! That username is already taken.");
    const [showLoding, setShowLoding] = useState(false);

    const resetUsernameValidation = () => {
        setShowValidationBorder(false);
        setIsUsernameValid(false);
    };

    useEffect(() => {
        const handlevalidateUsername = async () => {
            const usernameAvailable = await validateUsername(username);
            setIsUsernameValid(usernameAvailable);
            setShowValidationBorder(true);
            if (!usernameAvailable) {
                setErrorMessage("Sorry! That username is already taken.");
            }
        };

        if (username === dbUser?.username) {
            resetUsernameValidation();
            return;
        } else if (username.length >= 3) {
            const timerId = setTimeout(() => {
                handlevalidateUsername();
            }, 300);

            return () => clearTimeout(timerId);
        } else {
            setIsUsernameValid(false);
            setErrorMessage("Username is too short.");
        }
    }, [username]);

    const saveUsername = async () => {
        setShowLoding(true);
        const success = await saveUsernameInFirebase(username);
        success && toast.success("Changes saved!");
        resetUsernameValidation();
        setShowLoding(false);
    };

    useEffect(() => {
        if (dbUser?.username) {
            setUsername(dbUser?.username);
        }
        if (dbUser?.email) {
            setEmail(dbUser?.email);
        }
    }, [dbUser]);

    useEffect(() => {
        if (!isAuthorized && !initLoading && metaverse) {
            navigate(METAVERSE_ROUTES.HOME, {state: {from: location.pathname}});
        }
    }, [isAuthorized, initLoading]);

    return (
        <Container>
            <LoadingAnimation show={showLoding} />
            {metaverse && (
                <BackButton className="reset-css" onClick={() => navigate(METAVERSE_ROUTES.HOME)}>
                    {"<- Back to Home"}
                </BackButton>
            )}
            <AccountBox className="box">
                <Heading>Account Details</Heading>
                <div className={"wrapper"}>
                    <div>
                        <label>Username</label>
                        <InputWrapper>
                            <ValidationInput
                                type="text"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                $valueCorrect={showValidationBorder ? isUsernameValid : undefined}
                            />
                            <StyledButton
                                width="44px"
                                height="24px"
                                isBlue
                                className="saveButton"
                                disabled={!showValidationBorder || !isUsernameValid}
                                onClick={saveUsername}>
                                Save
                            </StyledButton>
                            {showValidationBorder && (
                                <img
                                    className="icon"
                                    src={isUsernameValid ? successIcon : errorIcon}
                                    alt={isUsernameValid ? "username is available" : "username is not available"}
                                />
                            )}
                            {!isUsernameValid && showValidationBorder && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </InputWrapper>
                    </div>
                    <div>
                        <label>Email</label>
                        <ValidationInput type="text" value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                </div>
            </AccountBox>
            <LogoutBox className="box">
                <Heading>Logout</Heading>
                <StyledButton width="88px" onClick={handleLogOut} isGreySecondary>
                    <span className="logout">Logout</span>
                    <img src={logoutIcon} alt="" />
                </StyledButton>
            </LogoutBox>
            <DeleteBox className="box">
                <Heading>Delete Account</Heading>
                <DeleteAccount>
                    <img src={deleteIcon} alt="" /> Delete Account
                </DeleteAccount>
            </DeleteBox>
            <LegalBox className="box">
                <Heading>Legal</Heading>
                <div>
                    <StyledButton
                        isGreySecondary
                        width="144px"
                        onClick={() => navigate(ROUTES.TERMS_OF_SERVICE, navigationOptions)}>
                        Terms of Service <img src={arrow} alt="go to link" />
                    </StyledButton>
                    <StyledButton
                        isGreySecondary
                        width="144px"
                        onClick={() => navigate(ROUTES.PRIVACY_POLICY, navigationOptions)}>
                        Privacy Policy <img src={arrow} alt="go to link" />
                    </StyledButton>
                </div>
            </LegalBox>
        </Container>
    );
};
