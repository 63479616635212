import {useMediaQuery} from "usehooks-ts";
import {Grid, GridWrapper, StyledFeaturesSection, Text} from "./FeaturesSection.style";
import {H2} from "../../../common";
import avatarCreator from "./assets/avatar-creator.png";
import games from "./assets/games.png";
import editor from "./assets/editor.png";

export const FeaturesSection = () => {
    const isTablet = useMediaQuery("(max-width: 1023px)");

    return (
        <StyledFeaturesSection>
            <H2>Features</H2>
            <GridWrapper>
                <Grid>
                    {!isTablet && <img src={avatarCreator} alt="avatar creator" className="screenshot" />}
                    <TextBox
                        title="Create your avatar"
                        description="Express yourself as you adventure through Erth worlds"
                    />
                    {isTablet && <img src={avatarCreator} alt="avatar creator" className="screenshot" />}

                    <TextBox title="Explore worlds" description="Play games and experiences made by others" />
                    <img src={games} alt="our games" className="games" />

                    {!isTablet && <img src={editor} alt="games editor" className="screenshot" />}
                    <TextBox
                        title="Build your own"
                        description="Build an experience of your own to share with the world"
                    />
                    {isTablet && <img src={editor} alt="games editor" className="screenshot" />}
                </Grid>
            </GridWrapper>
        </StyledFeaturesSection>
    );
};

interface TextBoxProps {
    title: string;
    description: string;
}

const TextBox = ({title, description}: TextBoxProps) => {
    return (
        <Text>
            <div className="title">{title}</div>
            <div className="description">{description}</div>
        </Text>
    );
};
