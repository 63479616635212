import {useState, useEffect} from "react";
import {NPC_TYPES, NPCBehaviorInterface, NPC_MOVEMENT_TYPES} from "../../../../../types/editor";
import NPCBehaviorConverter from "../../../../../serialization/behaviours/NPCBehaviorConverter";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {NumericInputRow} from "../common/NumericInputRow";
import {StyledButton} from "../../common/StyledButton";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {Heading} from "../common/Heading";
import {SelectRow} from "../common/SelectRow";

const ANIMATIONS: {
    label: string;
    key: keyof NPCBehaviorInterface;
}[] = [
    {label: "Idle", key: "idleAnimation"},
    {label: "Walking", key: "walkAnimation"},
    {label: "Running", key: "runAnimation"},
    {label: "Jumping", key: "jumpAnimation"},
    {label: "Crouching", key: "crouchAnimation"},
    {label: "Tripping", key: "trippingAnimation"},
    {label: "Falling", key: "fallAnimation"},
    {label: "Dying", key: "dieAnimation"},
    {label: "Attacking", key: "attackAnimation"},
];

type Props = {
    behavior: NPCBehaviorInterface;
};

export const NPCBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;

    const [behavior, setBehavior] = useState<NPCBehaviorInterface>({
        ...initialBehavior,
        startOnTrigger: initialBehavior.startOnTrigger ?? false,
        health: initialBehavior.health ?? 100,
        movementSpeed: initialBehavior.movementSpeed ?? 5,
        movementType: initialBehavior.movementType ?? NPC_MOVEMENT_TYPES.STAND_STILL,
        animationClips: [],
    });

    const targetBehavior = selected ? NPCBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) : null;

    useEffect(() => {
        if (!targetBehavior) {
            console.warn("Target behavior not found for selected object.");
        }
    }, [targetBehavior]);

    //TODO make this a utility there are other behaviors that can use it.
    useEffect(() => {
        const selected = editor.selected;
        if (selected && selected._obj && selected._obj.animations) {
            const loadGLTFAndExtractAnimationClips = () => {
                const animations = selected._obj.animations;
                if (animations && animations.length > 0) {
                    const animationClips = animations.map((clip: any) => clip.name);
                    setBehavior(prevState => ({...prevState, animationClips}));
                }
            };

            loadGLTFAndExtractAnimationClips();
        }
    }, [selected]);

    const handleInputChange = (value: number | string | boolean, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior(prev => ({...prev, [name]: value}));
            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const animationOptions = behavior.animationClips.map(clip => ({
        key: clip,
        value: clip,
    }));

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
                v2
            />
            <Separator invisible margin="8px 0" />
            <Heading margin={"0 0 12px"}>Movement Type</Heading>
            <SelectionOfButtons>
                {Object.keys(NPC_MOVEMENT_TYPES).map(key => {
                    const type = NPC_MOVEMENT_TYPES[key as unknown as keyof typeof NPC_MOVEMENT_TYPES];
                    return (
                        <StyledButton
                            width="calc(50% - 6px)"
                            isBlue={targetBehavior!.movementType === type}
                            isActive={targetBehavior!.movementType !== type}
                            onClick={() => handleInputChange(type, "movementType")}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator />
            <NumericInputRow
                width="75px"
                label="Health"
                value={behavior.health}
                setValue={value => handleInputChange(value, "health")}
            />
            <NumericInputRow
                width="75px"
                label="Movement Speed"
                value={behavior.movementSpeed}
                setValue={value => handleInputChange(value, "movementSpeed")}
            />
            <Separator />
            <Heading margin={"12px 0"}>Animations</Heading>
            {ANIMATIONS.map(element => (
                <SelectRow
                    $margin="0 0 8px 0"
                    label={element.label}
                    data={animationOptions}
                    value={
                        animationOptions.find(item => item.value === behavior[element.key]) || {key: "0", value: "none"}
                    }
                    onChange={item => handleInputChange(item.value, element.key)}
                    key={element.label}
                />
            ))}
            <Separator />
        </>
    );
};
