import React, {useEffect, useRef} from "react";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import resizeWindow from "../../AnimationCombiner/helpers/resizeWindow";
import global from "../../../../../global";
import Application from "../../../../../Application";

export const PrimitiveViewer: React.FC = () => {
    const app = global.app as Application;
    const selected = app.editor?.selected as THREE.Object3D<THREE.Object3DEventMap>;
    const viewer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!viewer.current || !selected) return;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);

        const renderer = new THREE.WebGLRenderer();
        resizeWindow(camera, viewer.current, renderer); // Adjust size based on container

        // Clear previous renderer instances if needed
        if (viewer.current.children.length) {
            viewer.current.removeChild(viewer.current.lastChild as Node);
        }
        viewer.current.appendChild(renderer.domElement);

        // Add ambient and directional light to the scene
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.set(5, 10, 7.5);
        scene.add(directionalLight);

        // Clone the selected object to preserve the original
        const selectedClone = selected.clone();

        // Get bounding box of the selected clone (for camera positioning)
        const bbox = new THREE.Box3().setFromObject(selectedClone);
        const center = bbox.getCenter(new THREE.Vector3()); // Get the center of the object
        const size = bbox.getSize(new THREE.Vector3()).length(); // Calculate the size of the object

        // Set camera position based on the object's size and center
        camera.position.set(center.x, center.y, size * 2); // Set camera distance to show the object fully
        camera.lookAt(center); // Ensure the camera is looking at the center of the object

        // Re-center the clone at the origin (0, 0, 0)
        selectedClone.position.sub(center); // Shift the clone to center it around (0, 0, 0)

        // Add the cloned object to the scene
        scene.add(selectedClone);

        // Add orbit controls for camera interaction
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true; // Enable smooth motion on interactions

        // Animation loop for rendering the scene
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update(); // Update controls for smooth camera movement
            renderer.render(scene, camera); // Render the scene from the camera's perspective
        };
        animate();

        return () => {
            renderer.dispose();
            if (viewer.current) {
                viewer.current.removeChild(renderer.domElement);
            }
        };
    }, [selected]);

    return <div style={{height: "100%", width: "100%"}} ref={viewer} />;
};
