import React, {useEffect} from "react";
import * as THREE from "three";
import {TGALoader} from "three/examples/jsm/loaders/TGALoader.js";

import global from "../../../../../global";
import Application from "../../../../../Application";
import SetMaterialMapCommand from "../../../../../command/SetMaterialMapCommand";

import {ColorRow} from "../common/ColorRow";
import {MaterialEditorButton} from "../MainButtons";

type Props = {
    color: string;
    setColor: React.Dispatch<React.SetStateAction<string | null>>;
    texture: any;
    showMaterialEditor: () => void;
};

export const TextureSection = ({texture, color, setColor, showMaterialEditor}: Props) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const app = (global?.app as Application) || null;
    const editor = app?.editor;

    const handleMaterialChange = (obj: any, name: any) => {
        if (editor?.selected && !(editor.selected instanceof Array)) {
            if (name === "map") {
                editor.execute(new (SetMaterialMapCommand as any)(editor.selected, "map", obj));
            }
        }
        app.call(`objectChanged`, editor?.selected, editor?.selected);
    };

    const update = () => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");
        if (canvas && context) {
            context.clearRect(0, 0, canvas?.width, canvas?.height);
        }

        if (texture !== null && context && canvas) {
            let image = texture?.image;

            if (Array.isArray(image)) {
                image = image[0];
            }

            if (image !== undefined && image?.width > 0) {
                context.drawImage(image, 0, 0, image?.width, image?.height, 0, 0, canvas?.width, canvas?.height);
            } else {
                context.clearRect(0, 0, canvas?.width, canvas?.height);
            }
        } else if (context && canvas) {
            context.clearRect(0, 0, canvas?.width, canvas?.height);
        }
    };

    const handleChange = (data: any) => {
        const name = data.Name;
        const type = data.Type;
        const url = data.Url;

        if (type === "targa") {
            const loader = new TGALoader();
            loader.load(url, (obj: any) => {
                let texture = new THREE.CanvasTexture(obj, (THREE as any).UVMapping);
                (texture as any).sourceFile = name;
                handleMaterialChange(texture, "map");
            });
        } else if (type === "video") {
            let video = document.createElement("video");
            video.setAttribute("src", data.Url);
            video.setAttribute("autoplay", "autoplay");
            video.setAttribute("loop", "loop");
            video.setAttribute("crossorigin", "anonymous");

            let texture = new THREE.VideoTexture(video);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = (THREE as any).RGBFormat;

            handleMaterialChange(texture, "map");
        } else if (type === "cube") {
            const loader = new THREE.CubeTextureLoader();
            loader.load(url.split(";"), (obj: any) => {
                obj.sourceFile = name;
                obj.format = url.endsWith("jpg") || url.endsWith("jpeg") ? (THREE as any).RGBFormat : THREE.RGBAFormat;
                obj.needsUpdate = true;

                handleMaterialChange(obj, "map");
            });
        } else {
            const loader = new THREE.TextureLoader();
            loader.load(url, (obj: any) => {
                obj.sourceFile = name;
                obj.format = url.endsWith("jpg") || url.endsWith("jpeg") ? THREE.RGBAFormat : THREE.RGBAFormat; //MISHA - THREE.RGBFormat was removed, not sure how it should be changed
                obj.needsUpdate = true;

                handleMaterialChange(obj, "map");
            });
        }
    };

    const handleColorChange = (color: string) => {
        setColor(color);
        if (app?.editor?.selected) {
            const selectedObject = app.editor.selected as any;

            //TODO    https://linear.app/dot-earth/issue/DOT-400/add-these-options-to-ui-to-allow-material-type-selection-for-texture

            selectedObject.material.color.set(color);
            app.call(`objectChanged`, app.editor.selected, app.editor.selected);
        }
    };

    useEffect(() => {
        update();
    }, [texture]);

    return (
        <>
            <ColorRow color={color} handleColorChange={handleColorChange} />
            {texture && <canvas title={texture.sourceFile} ref={canvasRef} />}
            <MaterialEditorButton showMaterialEditor={showMaterialEditor} />
        </>
    );
};
