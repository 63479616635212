import {StyledButton} from "../common/StyledButton";
import styled from "styled-components";

interface MainButtonsProps {
    showModelAnimationCombiner: () => void;
}

export const MainButtons = ({showModelAnimationCombiner}: MainButtonsProps) => {
    return (
        <Wrapper>
            <StyledButton onClick={showModelAnimationCombiner} isBlue margin="16px 0 0 0">
                3D Model Editor
            </StyledButton>
        </Wrapper>
    );
};

interface MaterialEditorButtonProps {
    showMaterialEditor: () => void;
}

export const MaterialEditorButton = ({showMaterialEditor}: MaterialEditorButtonProps) => {
    return (
        <Wrapper>
            <StyledButton onClick={showMaterialEditor} isBlue margin="16px 0 0 0">
                Material Editor
            </StyledButton>
        </Wrapper>
    );
};

export const SaveButton = ({onClick, label}: {onClick: () => void; label?: string}) => {
    return (
        <Wrapper style={{position: "sticky", width: "100%", left: "0", right: "0"}}>
            <StyledButton onClick={onClick} isBlue margin="16px 0 0 0">
                {label || "Save"}
            </StyledButton>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: absolute;
    bottom: 12px;
    left: 8px;
    right: 8px;
    z-index: 1;
`;
