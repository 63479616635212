import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { MobileTouchControlBehaviorInterface } from "../../types/editor";

class MobileTouchControlBehaviorUpdater implements BehaviorUpdater {
    game?: GameManager;
    target: THREE.Object3D;
    behavior: MobileTouchControlBehaviorInterface;
    private messageAdded = false; // Ensure message is added only once

    constructor(target: THREE.Object3D, behavior: MobileTouchControlBehaviorInterface) {
        this.target = target;
        this.target.userData.hasPathFinder = true;
        this.behavior = behavior;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.target) return;

        if (!this.messageAdded && this.isTargetFound()) {
            this.addMessageToScreen("Mobile Touch Running");
            this.messageAdded = true;
        }
    }

    private isTargetFound(): boolean {
        return true;
    }

    private addMessageToScreen(message: string) {
        if (!document.getElementById("target-message")) {
            const messageDiv = document.createElement("div");
            messageDiv.id = "target-message";
            messageDiv.innerText = message;
            messageDiv.style.position = "fixed";
            messageDiv.style.bottom = "20px";
            messageDiv.style.left = "50%";
            messageDiv.style.transform = "translateX(-50%)";
            messageDiv.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
            messageDiv.style.color = "white";
            messageDiv.style.padding = "10px 20px";
            messageDiv.style.borderRadius = "5px";
            messageDiv.style.fontSize = "16px";
            messageDiv.style.zIndex = "1000";

            document.body.appendChild(messageDiv);
        }
    }

    reset() { }
}

export default MobileTouchControlBehaviorUpdater;
