import {RefObject} from "react";
import {OBJECT_TYPES, OBJECT_TYPES_WHITE_LIST} from "../../../../../../../types/editor";
import {List, ListItem} from "./AllBehaviorsList.style";

interface Props {
    menuRef: RefObject<HTMLDivElement>;
    addNewBehavior: (type: OBJECT_TYPES) => void;
}

export const AllBehaviorsList = ({addNewBehavior, menuRef}: Props) => {
    const whiteListKeys = Object.keys(OBJECT_TYPES).filter(key =>
        OBJECT_TYPES_WHITE_LIST.includes(OBJECT_TYPES[key as keyof typeof OBJECT_TYPES]),
    );

    const otherKeys = Object.keys(OBJECT_TYPES).filter(
        key => !OBJECT_TYPES_WHITE_LIST.includes(OBJECT_TYPES[key as keyof typeof OBJECT_TYPES]),
    );

    return (
        <List ref={menuRef}>
            {whiteListKeys
                .sort((a, b) => a.localeCompare(b))
                .map(key => {
                    const type = OBJECT_TYPES[key as keyof typeof OBJECT_TYPES];

                    if (type === OBJECT_TYPES.SPAWN_POINT || type === OBJECT_TYPES.CHECK_POINT) return null;
                    return (
                        <ListItem key={key} $inactive={false} onClick={() => addNewBehavior(type)}>
                            {type}
                        </ListItem>
                    );
                })}

            {otherKeys
                .sort((a, b) => a.localeCompare(b))
                .map(key => {
                    const type = OBJECT_TYPES[key as keyof typeof OBJECT_TYPES];
                    if (type === OBJECT_TYPES.BILLBOARD) return;
                    return (
                        <ListItem key={key} $inactive={true} onClick={() => addNewBehavior(type)}>
                            {type}
                        </ListItem>
                    );
                })}
        </List>
    );
};
