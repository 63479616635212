import {OBJECT_TYPES, WeaponBehaviorInterface, WEAPON_EFFECTS, WEAPON_TYPES, WEAPON_AIMERS} from "../../../../../../types/editor";
import global from "../../../../../../global";
import defaultWeaponImage from "../images/default-weapon.png";
import defaultWeaponAimerImage from "../images/default-weapon-aimer-image.png";

export const getDefaultWeaponBehavior = (
    behavior: WeaponBehaviorInterface = {} as WeaponBehaviorInterface,
): WeaponBehaviorInterface => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor.selected;

    return {
        type: OBJECT_TYPES.WEAPON,
        weaponName: behavior.weaponName || (selected ? selected.name : ""),
        ui_tag: behavior.ui_tag || (selected ? selected.id : ""),
        weaponStarting: behavior.weaponStarting ?? true,
        uiImage: behavior.uiImage ?? defaultWeaponImage,
        weaponType: behavior.weaponType ?? "Machine Gun",
        weaponSelectedCharacterBone: behavior.weaponSelectedCharacterBone ?? "RightHand",
        weaponDamage: behavior.weaponDamage ?? 1,
        weaponClipAmount: behavior.weaponClipAmount ?? 10,
        weaponFireSpeed: behavior.weaponFireSpeed ?? 0.08,
        weaponReloadSpeed: behavior.weaponReloadSpeed ?? 1,
        weaponScopeZoom: behavior.weaponScopeZoom ?? 5,
        aimerUIImage: behavior.aimerUIImage ?? defaultWeaponAimerImage,
        aimerUIImageName: WEAPON_EFFECTS.WEAPON_HUD_AIMER_IMG_NAME, 
        weaponShowHUDAimerInGame: behavior.weaponShowHUDAimerInGame ?? true,
        weaponPreviewHUDAimer: behavior.weaponPreviewHUDAimer ?? true,
        weaponHUDAimerSize: behavior.weaponHUDAimerSize ?? 50,
        VFXSmallEffect: behavior.VFXSmallEffect ?? false,
        VFXMediumEffect: behavior.VFXMediumEffect ?? false,
        VFXBigEffect: behavior.VFXBigEffect ?? false,
        VFXLaserEffect: behavior.VFXLaserEffect ?? false,
        VFXCartoonyEffect: behavior.VFXCartoonyEffect ?? false,
        weaponAutoReload: behavior.weaponAutoReload ?? true,
        position_x: behavior.position_x ?? 0.15,
        position_y: behavior.position_y ?? -0.06,
        position_z: behavior.position_z ?? -0.3,
        rotation_x: behavior.rotation_x ?? 0,
        rotation_y: behavior.rotation_y ?? 1.57, 
        rotation_z: behavior.rotation_z ?? 0,
        selectedWeaponAmmoName: behavior.selectedWeaponAmmoName ?? "",
        weaponAmmoVisible: behavior.weaponAmmoVisible ?? true,
        weaponScale: behavior.weaponScale ?? 1
    };
};

