import {useEffect, useState} from "react";
import {GenericSoundBehaviorInterface} from "../../../../../types/editor";
import I18n from "i18next";
import * as THREE from "three";
import {StyledRange} from "../../common/StyledRange";
import global from "../../../../../global";
import GenericSoundBehaviorConverter from "../../../../../serialization/behaviours/GenericSoundBehaviorConverter";
import Ajax from "../../../../../utils/Ajax";
import {backendUrlFromPath} from "../../../../../utils/UrlUtils";
import {Item} from "../../common/BasicCombobox";
import {SelectRow} from "../common/SelectRow";
import {NumericInputRow} from "../common/NumericInputRow";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";

type Props = {
    behavior: GenericSoundBehaviorInterface;
};

export const GenericSoundBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);
    const [soundData, setSoundData] = useState<
        {
            ID: string;
            Name: string;
            Url: string;
        }[]
    >();
    const [soundOptions, setSoundOptions] = useState<Item[]>([]);

    const selectedSound = behavior.sound.id
        ? {
              ID: behavior.sound.id,
              Name: behavior.sound.name,
              Url: behavior.sound.url,
          }
        : undefined;

    const targetBehavior = GenericSoundBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const onSelectAudio = (data: any) => {
        let loader = new THREE.AudioLoader();

        loader.load(data.Url, (buffer: any) => {
            if (targetBehavior) {
                targetBehavior.sound.name = data.Name;
                targetBehavior.sound.url = data.Url;
                targetBehavior.sound.id = data.ID;
                app.call(`objectChanged`, app.editor, app.editor.selected);
                app.call(`objectUpdated`, app.editor, app.editor.selected);
            }
        });
    };

    const fetchSounds = () => {
        Ajax.get({url: backendUrlFromPath(`/api/Audio/List`)}).then(response => {
            const obj = response?.data;
            if (obj.Code !== 200) {
                app.toast(I18n.t(obj.Msg), "warn");
                return;
            }
            setSoundData(obj.Data);
        });
    };

    useEffect(() => {
        fetchSounds();
        app.on(`objectChanged.GenericSoundBehaviors`, fetchSounds);
    }, []);

    useEffect(() => {
        if (soundData) {
            setSoundOptions(
                soundData.map((option: any, index: number) => {
                    return {
                        key: `${index + 1}`,
                        value: option.Name,
                    };
                }),
            );
        }
    }, [soundData]);

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                v2
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator margin="8px 0" invisible />
            <PanelCheckbox
                text="Looping Sound"
                checked={!!behavior.loop}
                v2
                isGray
                regular
                onChange={() => handleInputChange(!behavior.loop, "loop")}
            />
            <Separator margin="8px 0" invisible />
            <SelectRow
                label="Sound File"
                data={soundOptions}
                value={
                    soundOptions.find(item => item.value === targetBehavior?.sound.name) || {key: "0", value: "none"}
                }
                onChange={item => onSelectAudio(soundData?.find(el => el.Name === item.value))}
            />
            <Separator invisible margin="2px 0" />
            <NumericInputRow
                width="75px"
                label="Sound Distance"
                value={behavior.soundDistance}
                setValue={value => handleInputChange(value, "soundDistance")}
            />
            <Separator invisible margin="2px 0" />
            <span className="common-text">Volume {Number(behavior.volume).toFixed(0)}d</span>
            <StyledRange
                volume={+behavior.volume / 100}
                setVolume={value => handleInputChange(value * 100, "volume")}
            />
        </>
    );
};
