import {useState} from "react";
import {InventoryManagerBehaviorInterface} from "../../../../../../types/editor";
import global from "../../../../../../global";
import {PanelCheckbox} from "../../common/PanelCheckbox";
import {InventoryManagerBehaviorConverter} from "../../../../../../serialization/behaviours";

type Props = {
    behavior: InventoryManagerBehaviorInterface;
};

export const InventoryManagerBehavior = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = InventoryManagerBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleChangeStartOnTrigger = () => {
        if (selected && targetBehavior) {
            targetBehavior.startOnTrigger = !targetBehavior.startOnTrigger;
            setBehavior({...targetBehavior, startOnTrigger: !targetBehavior.startOnTrigger});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                checked={!!behavior.startOnTrigger}
                onChange={handleChangeStartOnTrigger}
                v2
            />
        </>
    );
};
