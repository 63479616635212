import {useEffect, useRef, useState} from "react";
import I18n from "i18next";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {BorderedWrapper, BottomBar, BottomItem, Container, TabButton} from "./LeftPanel.style";
import {Section} from "../common/Section";
import {ProjectTab} from "./MainTabs/ProjectTab/ProjectTab";
import {AssetsTab} from "./MainTabs/AssetsTab/AssetsTab";
import global from "../../../../global";
import arrowLeftIcon from "../icons/arrow-left.svg";
import helpIcon from "./icons/help.svg";
import importIcon from "./icons/import.svg";
import libraryIcon from "./icons/library.svg";
import {AppMenu} from "../common/AppMenu/AppMenu";
import {MenuIcon} from "./MenuIcon";
import Application from "../../../../Application";
import {ScriptsPanel} from "./ScriptsPanel/ScriptsPanel";
import {ROUTES} from "../../../../AppRouter";
import {METAVERSE_ROUTES} from "../../../../metaverse/MetaverseAppRouter";

export enum TABS {
    project,
    assets,
}

type Props = {
    scene: any;
    show: boolean;
    showLoading: (show: boolean) => void;
};

export const LeftPanel = ({scene, show, showLoading}: Props) => {
    const app = global.app as Application;
    const metaverse = (global.app as Application)?.options.metaverse;
    const [sceneName, setSceneName] = useState(app.editor?.sceneName || "");
    const [activeTab, setActiveTab] = useState(TABS.project);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScriptPanelOpen, setIsScriptPanelOpen] = useState(false);
    const userMenuButtonRef = useRef<SVGSVGElement | null>(null);
    const navigate = useNavigate();

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const saveEditTime = () => {
            if (!app.editor) return;
            app.editor.scene.userData.lastEditTime = new Date().toISOString();
        };
        if (app.editor) {
            app.on(`objectChanged.LeftPanel`, saveEditTime);
            app.on(`objectUpdated.LeftPanel`, saveEditTime);
            app.on(`objectRemoved.LeftPanel`, saveEditTime);
            app.on(`objectAdded.LeftPanel`, saveEditTime);
            app.on(`geometryChanged.LeftPanel`, saveEditTime);
        }

        return () => {
            app.on(`objectChanged.LeftPanel`, null);
            app.on(`objectUpdated.LeftPanel`, null);
            app.on(`objectRemoved.LeftPanel`, null);
            app.on(`objectAdded.LeftPanel`, null);
            app.on(`geometryChanged.LeftPanel`, null);
        };
    }, [app.editor]);

    useEffect(() => {
        app.on("sceneSaved.LeftPanel", () => {
            showLoading(false);
        });
        app.on("sceneSaveFailed.LeftPanel", () => {
            showLoading(false);
        });
        app.on("sceneNameUpdated.LeftPanel", () => {
            const newName = app.editor?.sceneName;
            newName && setSceneName(newName);
        });

        app.on("clear.LeftPanel", () => setSceneName(app.editor?.sceneName || ""));
        app.on("sceneLoaded.LeftPanel", () => setSceneName(app.editor?.sceneName || ""));
        setSceneName(app.editor?.sceneName || "");
    }, [show]);

    if (!show) {
        return null;
    }

    const checkForUnsavedChanges = () => {
        const editor = app.editor;
        if (!editor) return;
        const lastEditTime = moment(editor.scene.userData.lastEditTime);
        const lastSaveTime = moment(editor.scene.userData.lastSaveTime);
        const hasUnsavedChanges = lastEditTime.isAfter(lastSaveTime);

        if (!hasUnsavedChanges) return;

        return new Promise<void>(resolve => {
            if (editor?.sceneID === null) {
                resolve();
            } else {
                app.confirm({
                    title: I18n.t("Confirm"),
                    content: I18n.t("All unsaved data will be lost. Are you sure?"),
                    onOK: () => {
                        resolve();
                    },
                });
            }
        });
    };

    const handleOpenGamesLibrary = async () => {
        await checkForUnsavedChanges();
        navigate(metaverse ? METAVERSE_ROUTES.HOME : ROUTES.DASHBOARD);
    };

    return (
        <Container>
            <BorderedWrapper>
                <Section $gap="9px" $direction="row" $width="auto" $align="center">
                    <img
                        style={{cursor: "pointer"}}
                        src={arrowLeftIcon}
                        alt="arrow left"
                        onClick={handleOpenGamesLibrary}
                        className="go-back-icon icon"
                    />
                    <span>{sceneName}</span>
                </Section>
                <MenuIcon isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} userMenuButtonRef={userMenuButtonRef} />
            </BorderedWrapper>
            {isScriptPanelOpen ? (
                <ScriptsPanel />
            ) : (
                <>
                    <BorderedWrapper height="48px">
                        <TabButton $isActive={activeTab === TABS.project} onClick={() => setActiveTab(TABS.project)}>
                            Objects
                        </TabButton>
                        <TabButton $isActive={activeTab === TABS.assets} onClick={() => setActiveTab(TABS.assets)}>
                            Assets
                        </TabButton>
                    </BorderedWrapper>
                    <Section $gap="0px" $width="100%" $height="100%" $justify="flex-start">
                        <ProjectTab isVisible={activeTab === TABS.project} activeTab={activeTab} />
                        <AssetsTab scene={scene} isVisible={activeTab === TABS.assets} />
                    </Section>
                </>
            )}
            {activeTab === TABS.project && (
                <BottomBar>
                    <BottomItem>
                        <img src={libraryIcon} className="icon" />
                        Library
                    </BottomItem>
                    <BottomItem>
                        <img src={importIcon} className="icon" />
                        Import
                    </BottomItem>
                    <BottomItem>
                        <img src={helpIcon} className="icon" />
                        Help & Feedback
                    </BottomItem>
                </BottomBar>
            )}

            {isMenuOpen && <AppMenu close={handleCloseMenu} userMenuButtonRef={userMenuButtonRef} />}
        </Container>
    );
};
