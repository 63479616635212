import React, {useState, useRef, useEffect, useCallback} from "react";
import I18n from "i18next";
import global from "../../../../global";
import {Container, Content, Header, Overlay, Title} from "./ScriptEditorPanel.style";
import {StyledButton} from "../common/StyledButton";
import Application from "../../../../Application";
import MonacoEditor, {useMonaco} from "@monaco-editor/react";
import {noSelfAssign} from "./validation";

type CallbackFunc = (uuid: string, name: string, type: string, source: string) => void;

export const ScriptEditorPanel: React.FC = () => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState<string | null>(null);
    const [name, setName] = useState("");
    const [type, setType] = useState("javascript");
    const [source, setSource] = useState("");
    const [callback, setCallback] = useState<CallbackFunc | null>(null);
    const app = global.app as Application;
    const ref = useRef<HTMLDivElement>(null);
    const monaco = useMonaco();

    const handleClose = () => {
        setShow(false);
        setId(null);
        setName("");
        setType("javascript");
        setSource("");
        setCallback(null);
    };

    const handleEditScript = (
        id: string,
        name: string,
        type: string,
        source: string,
        callback: (id: string, name: string, type: string, source: string) => void,
    ) => {
        setCallback(() => callback);
        setShow(true);
        setId(id);
        setName(name);
        setType(type);
        setSource(source);
    };

    const handleSave = useCallback(() => {
        if (id && name && type) {
            callback && callback(id, name, type, source);

            handleClose();
        }
    }, [id, name, type, source]);

    useEffect(() => {
        if (ref.current) {
            app.on("editScript.ScriptPanel", handleEditScript);

            return () => {
                app.on("editScript.ScriptPanel", null);
            };
        }
    }, [ref.current]);

    useEffect(() => {
        if (monaco) {
            monaco.editor.setTheme("vs-dark");

            monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                noSemanticValidation: true,

                noSyntaxValidation: false,
            });

            monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
                target: monaco.languages.typescript.ScriptTarget.ES2015,

                allowNonTsExtensions: true,
            });

            const lintCode = (model: any) => {
                const code = model.getValue();
                const lines = code.split("\n");

                const markers: any[] = [];

                noSelfAssign(lines, markers, monaco);

                monaco.editor.setModelMarkers(model, "customLinter", markers);
            };

            const model = monaco.editor.getModels()[0];

            if (model) {
                model.onDidChangeContent(() => {
                    lintCode(model);
                });

                lintCode(model);
            }
        }
    }, [monaco]);

    const closeScriptPanel = () => {
        app.confirm({
            title: I18n.t("Confirm"),
            content: I18n.t("All unsaved data will be lost. Are you sure?"),
            onOK: () => {
                handleClose();
            },
        });
    };

    return (
        <Overlay
            $show={show}
            onClick={e => {
                e.stopPropagation();
                closeScriptPanel();
            }}>
            <Container
                ref={ref}
                onClick={e => {
                    e.stopPropagation();
                }}>
                <Header>
                    <Title>Script Editor</Title>

                    <StyledButton onClick={handleSave} isBlue width="86px">
                        Save Code
                    </StyledButton>
                </Header>
                <Content>
                    <MonacoEditor
                        height="100%"
                        defaultLanguage={type}
                        value={source}
                        theme="vs-dark"
                        onChange={newValue => {
                            setSource(newValue || "");
                        }}
                    />
                </Content>
            </Container>
        </Overlay>
    );
};

export default ScriptEditorPanel;
