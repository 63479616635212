import {createBrowserRouter, RouterProvider, useLocation} from "react-router-dom";
import {useEffect} from "react";

import {useAuthorizationContext} from "../context";
import {MetaverseHomePageLoggedIn, MetaverseHomePageLoggedOut} from "./MetaverseHome";
import {Create} from "../v2/pages/Create/Create";
import {LoginPage} from "../v2/pages/LoginPage/LoginPage";
import {Header} from "../v2/Header/Header";
import {Footer} from "../v2/Footer/Footer";
import {LoadingAnimation} from "../ui/progress/LoadingAnimation";
import {SettingsPage} from "../editor/assets/v2/GamesDashboard/SettingsPage/SettingsPage";
import {MainContainer} from "./style";

export enum METAVERSE_ROUTES {
    HOME = "/",
    CREATE_PROJECT = "/create/project",
    CREATE_PROJECT_WITH_ID = "/create/project/:projectID",
    SETTINGS = "/settings",
    LOGIN = "/login",
    SIGN_UP = "/sign-up",
}

const Layout = ({children}: {children: React.ReactNode}) => {
    const location = useLocation();

    useEffect(() => {
        const container = document.getElementById("container") || document.body;
        if (container) {
            container.style.overflowY = "auto";
            container.style.position = "relative";

            container.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <MainContainer>
            <Header />
            <main>{children}</main>
            <Footer />
        </MainContainer>
    );
};

export const MetaverseAppRouter = () => {
    const {isAuthorized, initLoading} = useAuthorizationContext();
    const routerFutureSettings = {
        v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_skipActionErrorRevalidation: true,
    };

    const router = createBrowserRouter(
        [
            {
                path: METAVERSE_ROUTES.HOME,
                element: (
                    <Layout>
                        {initLoading ? (
                            <LoadingAnimation show={true} />
                        ) : isAuthorized ? (
                            <MetaverseHomePageLoggedIn />
                        ) : (
                            <MetaverseHomePageLoggedOut />
                        )}
                    </Layout>
                ),
            },
            {
                path: METAVERSE_ROUTES.CREATE_PROJECT,
                element: <Create />,
            },
            {
                path: METAVERSE_ROUTES.CREATE_PROJECT_WITH_ID,
                element: <Create />,
            },
            {
                path: METAVERSE_ROUTES.SETTINGS,
                element: (
                    <Layout>
                        <SettingsPage />
                    </Layout>
                ),
            },
            {
                path: METAVERSE_ROUTES.LOGIN,
                element: <LoginPage />,
            },
            {
                path: METAVERSE_ROUTES.SIGN_UP,
                element: <LoginPage signup />,
            },
        ],
        {
            future: routerFutureSettings,
        },
    );

    return (
        <RouterProvider
            router={router}
            future={{
                v7_startTransition: true,
            }}
        />
    );
};
