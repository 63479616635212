import {useState} from "react";

import {IBasicGameInterface} from "../../../../v2/pages/types";

import {H2, LoadMore} from "../../../common";
import {SingleGame} from "./SingleGame/SingleGame";
import {GamesContainer, NoData, Wrapper} from "./Games.style";
import {IPlayGame} from "../MetaverseHomePageLoggedIn";

const GAMES_VISIBILITY_COUNTER = 12;

interface Props {
    metaverseGames: IBasicGameInterface[];
    setPlayGameData: React.Dispatch<React.SetStateAction<IPlayGame | undefined>>;
}

export const Games = ({setPlayGameData, metaverseGames}: Props) => {
    const [metaverseGamesToLoad, setMetaverseGamesToLoad] = useState<IBasicGameInterface[]>([]);

    return (
        <Wrapper>
            <H2>Explore</H2>
            {metaverseGamesToLoad?.length > 0 ? (
                <GamesContainer>
                    {metaverseGamesToLoad.map(game => (
                        <SingleGame key={game.ID} game={game} setPlayGameData={setPlayGameData} />
                    ))}
                </GamesContainer>
            ) : (
                <NoData>
                    <div className="description">No available games yet.</div>
                </NoData>
            )}
            <LoadMore
                visibilityCounter={GAMES_VISIBILITY_COUNTER}
                itemsToLoad={metaverseGames}
                setVisibleResults={setMetaverseGamesToLoad}
            />
        </Wrapper>
    );
};
