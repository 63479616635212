/* eslint-disable react/no-multi-comp */
import {useEffect, useState} from "react";
import plusIcon from "../../icons/plus-icon.svg";
import {FileData} from "../../types/file";
import {
    DashboardHeading,
    ListItem,
    SceneName,
    StyledSceneList,
    ThumbnailPlaceholder,
    ViewAllRow,
} from "./SceneList.style";
import {PAGES} from "../constants";
import {SceneListItem} from "./SceneListItem";
import {ROUTES} from "../../../../../AppRouter";
import {useNavigate} from "react-router-dom";

const EmptyAsset = ({onClick}: {onClick: () => void}) => {
    return (
        <ListItem onClick={onClick}>
            <ThumbnailPlaceholder>
                <img src={plusIcon} alt="plus" />
            </ThumbnailPlaceholder>

            <SceneName>Default</SceneName>
        </ListItem>
    );
};

export type SceneListProps = {
    data: FileData[];
    selectedItemsIds?: string[];
    onClick: (id: string) => void;
    onDelete?: (id: string) => void;
    onDoubleClick?: (id: string, newTab?: boolean) => void;
    maxHeight?: string;
    onEmptyAssetClick?: () => void;
    isSound?: boolean;
    noOptions?: boolean;
    noEditText?: boolean;
    activePage?: PAGES;
    setActivePage?: (page: PAGES) => void;
    setShowLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    reload?: () => void;
};

export const SceneList = ({
    data,
    selectedItemsIds,
    onClick,
    onDelete,
    onDoubleClick,
    onEmptyAssetClick,
    isSound,
    noOptions,
    noEditText,
    activePage,
    setActivePage,
    setShowLoading,
    reload,
}: SceneListProps) => {
    const [myGames, setMyGames] = useState<FileData[]>([]);
    const [communityGames, setCommunityGames] = useState<FileData[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (activePage === PAGES.DASHBOARD) {
            setCommunityGames(data.filter(el => el.isCommunity === true));
            setMyGames(data.filter(el => !el.isCommunity).slice(0, 5));
        }
    }, [activePage, data]);

    const handleViewAll = () => {
        setActivePage && setActivePage(PAGES.MY_GAMES);
        navigate(ROUTES.MY_GAMES);
    };

    return (
        <StyledSceneList>
            {onEmptyAssetClick && <EmptyAsset onClick={onEmptyAssetClick} />}
            {activePage !== PAGES.DASHBOARD ? (
                data.map((item, index) => (
                    <SceneListItem
                        left={(index + 1) % 5 === 0}
                        key={item.ID + index}
                        onClick={onClick}
                        onDelete={onDelete}
                        onDoubleClick={onDoubleClick}
                        selectedItemsIds={selectedItemsIds}
                        item={item}
                        isSound={isSound}
                        noOptions={noOptions}
                        noEditText={noEditText}
                        isCommunityGame={activePage === PAGES.COMMUNITY}
                        setShowLoading={setShowLoading}
                        reload={reload}
                    />
                ))
            ) : (
                <>
                    {myGames.map((item, index) => (
                        <SceneListItem
                            left={(index + 1) % 5 === 0}
                            key={item.ID + index}
                            onClick={onClick}
                            onDelete={onDelete}
                            onDoubleClick={onDoubleClick}
                            selectedItemsIds={selectedItemsIds}
                            item={item}
                            isSound={isSound}
                            noOptions={noOptions}
                            noEditText={noEditText}
                            setShowLoading={setShowLoading}
                            reload={reload}
                        />
                    ))}
                    <ViewAllRow>
                        <button className="btn reset-css" onClick={handleViewAll}>
                            View All
                        </button>
                    </ViewAllRow>
                    <DashboardHeading>Community</DashboardHeading>
                    {communityGames.map((item, index) => (
                        <SceneListItem
                            left={(index + 1) % 5 === 0}
                            key={item.ID + index}
                            onClick={onClick}
                            onDelete={onDelete}
                            onDoubleClick={onDoubleClick}
                            selectedItemsIds={selectedItemsIds}
                            item={item}
                            isSound={isSound}
                            noOptions={noOptions}
                            noEditText={noEditText}
                            isCommunityGame
                            setShowLoading={setShowLoading}
                            reload={reload}
                        />
                    ))}
                </>
            )}
        </StyledSceneList>
    );
};
