import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import VolumeBehaviorUpdater from "./VolumeBehaviorUpdater";
import {VolumeBehaviorInterface, VOLUME_TYPES, OBJECT_TYPES, CUSTOM_BLOCK_VOLUME_TYPES} from "../../types/editor";

class VolumeBehaviorConverter extends BaseBehaviorConverter<VolumeBehaviorInterface> {
    public static DEFAULT = new VolumeBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: VolumeBehaviorInterface): BehaviorUpdater {
        const physics = target.userData.physics;

        if (behavior.volumeType === VOLUME_TYPES.BLOCKING && !physics.enabled) {
            physics.enabled = true;
            physics.ctype = "Kinematic";
            physics.mass = 0;
        }

        return new VolumeBehaviorUpdater(target, behavior.volumeType);
    }

    getBehavior(target: THREE.Object3D, id: string): VolumeBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as VolumeBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have VOLUME behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): VolumeBehaviorInterface {
        return {
            type: OBJECT_TYPES.VOLUME,
            volumeType: VOLUME_TYPES.BLOCKING,
            enabled: true,
            id,
            startOnTrigger: false,
            customBlockVolumeType: [CUSTOM_BLOCK_VOLUME_TYPES.BLOCK_CHARACTERS],
            customVolumeOptions: {damageAmount: 20, losePoints: 2, loseTime: 2},
        };
    }
}

export default VolumeBehaviorConverter;
