import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import { COLLISION_TYPE, NPCBehaviorInterface } from "../../types/editor";
import { Object3D } from "three";

class NPCBehaviorUpdater implements BehaviorUpdater {
    private game?: GameManager;
    public target: Object3D;
    private usingPhysics: boolean = false;
    private isDynamic: boolean = false;
    private behavior: NPCBehaviorInterface;
  
    constructor(target: Object3D, behavior: NPCBehaviorInterface) {
        this.target = target;
        this.target.userData.hasPathFinder = true;
        this.behavior = behavior;
   
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);
        this.addCollisionListener();
    }


    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: true,
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }

    onCollisionWithPlayer() {
        if (!this.target) return;
    }

    update(clock: THREE.Clock, delta: number): void {
        if (!this.target) return;
    }

    reset() { }
}

export default NPCBehaviorUpdater;