import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import EventBus from "../../behaviors/event/EventBus";
import {Object3D, AnimationMixer} from "three";
import {COLLISION_TYPE, EnemyBehaviorInterface, GAME_STATE} from "../../types/editor";
import global from "../../global";
import Player from "../../player/Player";
import { CollisionFlag, IPhysics } from "../../physics/common/types";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import CameraUtils from "../../utils/CameraUtils";


declare module "three" {
    interface Object3D {
        _obj?: any;
    }
}

enum EnemyState {
    STANDING = "standing",
    APPROACHING = "approaching",
    RETREATING = "retreating",
    ATTACKING = "attacking",
}

class EnemyBehaviorUpdater implements BehaviorUpdater {
    app: Player | null = null;
    clock: THREE.Clock | null;
    game?: GameManager | null;
    target: Object3D;
    behavior: EnemyBehaviorInterface;
    prevPlayerPosition: THREE.Vector3;
    lastPlayerMoveTime: number;
    private mixers: AnimationMixer[] = [];
    animationAction: THREE.AnimationAction | null = null;
    enemyEnabled: boolean;
    moveTimer: number;
    moveDirection: THREE.Vector3;
    stateTimer: number = 0;
    state: EnemyState = EnemyState.STANDING;
    standingDuration: number = Math.random() * 3 + 2;
    movementSpeed: number;
    attackDistance: number;
    attackSpeed: number;
    roamDistance: number;
    rotationSpeed: number;
    fightDistance: number;
    directionDuration: number;
    originalPosition: THREE.Vector3 = new THREE.Vector3();
    distanceToPlayer: number = 0;
    distanceToOriginalPosition: number = 0;
    playerPosition: THREE.Vector3 = new THREE.Vector3();
    deltaTime: number = 0;
    roamObject: THREE.Mesh | null = null;
    currentRotation = new THREE.Quaternion();
    mixer: THREE.AnimationMixer | null = null;
    animations: [];
    lives = 3;
    deathAnimationStarted = false;
    removed = false;
    playerCollisionListenerId: string | undefined;
    bulletCollisionListenerId: string | undefined;
    requestAnimationFrameId: number;
    physics: IPhysics | null = null;
    isPhysicsEnabled: boolean = false;
    smoothingFactor: number | 0.05;

    constructor(target: Object3D, behavior: EnemyBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.clock = new THREE.Clock();
        this.prevPlayerPosition = new THREE.Vector3();
        this.lastPlayerMoveTime = Date.now();
        this.enemyEnabled = true;
        this.requestAnimationFrameId = -1;
        this.moveTimer = 0;
        this.moveDirection = new THREE.Vector3();
        this.movementSpeed = parseFloat(this.behavior.movementSpeed.toString());
        this.attackDistance = parseFloat(this.behavior.attackDistance.toString());
        this.attackSpeed = parseFloat(this.behavior.attackSpeed.toString());
        this.roamDistance = parseFloat(this.behavior.roamDistance.toString());
        this.rotationSpeed = parseFloat(this.behavior.rotationSpeed.toString());
        this.fightDistance = parseFloat(this.behavior.fightDistance.toString());
        this.directionDuration = parseFloat(this.behavior.directionDuration.toString());
        this.isPhysicsEnabled = PhysicsUtil.isPhysicsEnabled(this.target);
        //this.physics = this.game?.behaviorManager?.collisionDetector.physics; //TODO new ticket for collision teting
        this.animations = this.target._obj?.animations;
        this.smoothingFactor = 0.05;
    }

    reset(): void {}

    isDead() {
        return this.lives <= 0;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.app = gameManager.app;
        this.addCollisionListeners(true, true);
        this.initEnemies();
        this.physics = this.game!.scene!.userData.physics
        CameraUtils.disableFromCameraCollision(this.target);
    }

    initEnemies() {
        if (!this.game || !this.game.player || !this.game.scene) return;
        const scene = this.game.scene;
        const circleMaterial = new THREE.MeshBasicMaterial({
            color: 0x00ff00,
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide,
        });

        if (this.target && this.target.userData && this.target.userData.behaviors) {
            const {enemyEnabled, roamDistance, showRoamArea} = this.behavior;
            if (roamDistance !== undefined && !isNaN(roamDistance)) {
                this.enemyEnabled = enemyEnabled;

                this.originalPosition = this.target.position.clone();

                if (!!enemyEnabled && !!showRoamArea) {
                    const bbox = new THREE.Box3().setFromObject(this.target);
                    const center = new THREE.Vector3();
                    bbox.getCenter(center);
                    const geometry = new THREE.CircleGeometry(roamDistance, 32);
                    const circle = new THREE.Mesh(geometry, circleMaterial);
                    circle.position.set(center.x, center.y, center.z);
                    circle.rotation.x = -Math.PI / 2;
                    scene.add(circle);
                    this.roamObject = circle;
                }
            }
            this.physics?.removeCollidableObject(this.target.uuid);
        }
    }

    addCollisionListeners(withPlayer: boolean, withBullet: boolean) {
        if (withPlayer) {
            this.playerCollisionListenerId = this.game?.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_PLAYER,
                    callback: this.onCollisionWithPlayer.bind(this),
                    useBoundingBoxes: false,
                    distanceThreshold: 2.0,
                },
                this.target.userData.physics && this.target.userData.physics.enabled,
            );
        }
        if (withBullet) {
            this.bulletCollisionListenerId = this.game?.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: COLLISION_TYPE.WITH_COLLIDABLE_OBJECTS,
                    callback: this.onCollisionWithThrowable.bind(this),
                    useBoundingBoxes: false,
                    distanceThreshold: 2.0,
                },
                this.target.userData.physics && this.target.userData.physics.enabled,
            );
        }
    }

    onCollisionWithThrowable() {
        if (this.lives > 0) {
            this.lives--;
            if (this.isDead()) {
                this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
            } else {
                this.game!.behaviorManager!.collisionDetector.deleteListener(
                    this.target,
                    this.bulletCollisionListenerId,
                );
                setTimeout(() => {
                    this.addCollisionListeners(false, true);
                }, 500);
            }
        }
    }

    onCollisionWithPlayer() {
        if (this.behavior && this.enemyEnabled) {
            (global as any).app.call("playerFallBack", this, this);
            let attackDamage = parseFloat(this.behavior.attackDamage.toString());
            EventBus.instance.send("game.lives.dec", attackDamage);
            this.game!.behaviorManager!.collisionDetector.deleteListener(this.target, this.playerCollisionListenerId);
            setTimeout(() => {
                this.addCollisionListeners(true, false);
            }, 5000);
        }
    }

    playAnimation(enemy: THREE.Object3D, animationName?: string, forceRestart = false, finishCallback: any = null) {
        if (animationName && (enemy.userData.currentAnimation !== animationName )) {
            let playOnce = this.isDead();
            const animationData = this.app?.animationControl.playAnimation(enemy, animationName, 1, playOnce);
            if (animationData?.mixer) {
                animationData?.mixer.addEventListener("finished", () => {
                  if (finishCallback) finishCallback();
                });
                enemy.userData.currentAnimation = animationName;
            } else {
                console.warn(`Animation clip named "${animationName}" not found.`);
                  if (finishCallback) finishCallback();
            }
        }
    }

    stopAnimation = () => {
        this.app?.animationControl.stopAnimation(this.target);
    };
    
    moveInRandomDirection = (directionDuration: number) => {
        const currentDirectiom = this.moveDirection;
        if (this.moveTimer <= 0) {
            this.moveTimer = Math.random() * directionDuration + directionDuration;
            this.moveDirection = new THREE.Vector3(Math.random() * 2 - 1, 0, Math.random() * 2 - 1).normalize();
        }
        this.moveTimer -= this.deltaTime;
    };

    update(clock: THREE.Clock, delta: number): void {
        if (!this.game || !this.game.player || !this.game.scene || this.removed || this.game.state == GAME_STATE.PAUSED) {
            this.stopAnimation();
            return;
        }

        this.mixer?.update(delta);

        const player = this.game.player;
        this.playerPosition = new THREE.Vector3();
        player.getWorldPosition(this.playerPosition);
        this.deltaTime = this.clock!.getDelta();

        const playerMoved = this.playerPosition.distanceTo(this.prevPlayerPosition) > 0;
        if (playerMoved) {
            this.prevPlayerPosition.copy(this.playerPosition);
            this.lastPlayerMoveTime = Date.now();
        }

        if (this.target && !this.isDead() && this.enemyEnabled) {
            this.distanceToPlayer = this.target.position.distanceTo(this.playerPosition);
            this.distanceToOriginalPosition = this.target.position.distanceTo(this.originalPosition);

            this.stateTimer += this.deltaTime;

            if (this.state === EnemyState.STANDING && this.stateTimer >= this.standingDuration) {
                this.state = EnemyState.APPROACHING;
                this.stateTimer = 0;
            } else if (this.state === EnemyState.APPROACHING && this.stateTimer >= 4) {
                this.state = EnemyState.RETREATING;
                this.stateTimer = 0;
            } else if (this.state === EnemyState.RETREATING && this.stateTimer >= 2) {
                this.state = EnemyState.STANDING;
                this.stateTimer = 0;
                this.standingDuration = Math.random() * 3 + 2;
            }

            if (this.distanceToPlayer <= this.attackDistance) {
                this.state = EnemyState.ATTACKING;
            }

            switch (this.state) {
                case EnemyState.STANDING:
                    this.enemyStand();
                    break;
                case EnemyState.APPROACHING:
                    this.enemyApproach();
                    break;
                case EnemyState.RETREATING:
                    this.enemyRetreat();
                    break;
                case EnemyState.ATTACKING:
                    this.enemyAttack();
                    break;
                default:
                    this.enemyStand();
                    break;
            }
        }

        if (this.isDead() && !this.deathAnimationStarted) {
            this.playAnimation(this.target, this.behavior.dieAnimation, true, () => {
                if (this.target instanceof THREE.Object3D && this.target.parent !== null) {
                    this.game!.app.removePhysicsObject(this.target);
                    this.removed = true;
                }
            });
            this.deathAnimationStarted = true;
        }

        this.roamObject?.position.set(this.target.position.x, this.roamObject.position.y, this.target.position.z);


        const targetRotation = Math.atan2(this.moveDirection.x, this.moveDirection.z);

        if (this.target.userData.currentAnimation === this.behavior.idleAnimation) {
            this.target.rotation.y = THREE.MathUtils.lerp(
                this.target.rotation.y,
                targetRotation,
                this.rotationSpeed * this.deltaTime
            );
        }

    
        this.currentRotation.setFromAxisAngle(new THREE.Vector3(0, 1, 0), targetRotation);


        if (this.distanceToPlayer > this.fightDistance) {
            this.physics!.setRotation(this.target.uuid, this.currentRotation)

            const forwardDirection = new THREE.Vector3(0, 0, 1);
            forwardDirection.applyQuaternion(this.currentRotation);
            forwardDirection.normalize();

            let speed = this.movementSpeed;
            if (this.state == EnemyState.STANDING) {
                speed = 0;
            }

            const forwardVelocity = forwardDirection.multiplyScalar(speed);

            const downwardVelocity = new THREE.Vector3(0, -this.movementSpeed / 2, 0);
            const totalVelocity = forwardVelocity.add(downwardVelocity);

            this.physics!.setLinearVelocity(this.target.uuid, totalVelocity);
        }


    }

    enemyStand() {
        this.playAnimation(this.target, this.behavior.idleAnimation as string);
    }

    enemyApproach() {

        this.playAnimation(this.target, this.behavior.walkAnimation as string);

        if (this.distanceToOriginalPosition > this.roamDistance) {
             this.moveDirection = this.originalPosition.clone().sub(this.target.position).normalize();
        } else {
             this.moveInRandomDirection(this.directionDuration);
        }
    }

    enemyRetreat() {
        this.playAnimation(this.target, this.behavior.walkAnimation as string);

        this.moveDirection = new THREE.Vector3(
            this.target.position.x - this.playerPosition.x,
            0,
            this.target.position.z - this.playerPosition.z,
        ).normalize();

       this.smoothDirectionChange(this.moveDirection)

    }

    enemyAttack() {
        this.playAnimation(this.target, this.behavior.attackAnimation as string);

        const targetDirection = new THREE.Vector3(
            this.playerPosition.x - this.target.position.x,
            0,
            this.playerPosition.z - this.target.position.z
        ).normalize();

        this.smoothDirectionChange(targetDirection)

        if (this.distanceToPlayer > this.attackDistance) {
            this.state = EnemyState.STANDING;
            this.stateTimer = 0;
            this.standingDuration = Math.random() * 3 + 2;
        }
    }

    smoothDirectionChange(targetDirection: THREE.Vector3) {
        const smoothingFactor = this.smoothingFactor;
        this.moveDirection = this.moveDirection.lerp(targetDirection, smoothingFactor);

        const targetRotation = Math.atan2(this.moveDirection.x, this.moveDirection.z);
        this.target.rotation.y = THREE.MathUtils.lerp(this.target.rotation.y, targetRotation, smoothingFactor);
    }


    dispose = () => {
        this.stopAnimation();
        this.mixers.length = 0;

        this.clock = null;
        this.requestAnimationFrameId = -1;
    };
}

export default EnemyBehaviorUpdater;
