import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import {
    OBJECT_TYPES, COLLISION_TYPE, PHYSICS_PROXY_UI, AnimationBehaviorInterface,
    TriggerBehaviorInterface, PropAnimationBehaviorInterface, TRIGGER_ACTIVATION_TYPES
} from "../../types/editor";
import RangeDetector from "../../behaviors/range/RangeDetector";
import { IPhysics } from "../../physics/common/types";

class TriggerBehaviorUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    behavior: TriggerBehaviorInterface;
    game?: GameManager;
    physics?: IPhysics;
    rangeDetector: RangeDetector;
    physicsEnabled = false;
    keyPressed = false;
    mixer: THREE.AnimationMixer | null = null;
    animationPausedAtStart = false;
    animationAction: THREE.AnimationAction | null = null;
    autoInteractPlayer: boolean = false;
    triggerAnimation: boolean = false;
    triggerPropAnimationName: string | null = null;
    animationPlaying: boolean = false;
    playerColliding: boolean = false;
    physicsRemoved: boolean = false;
    removed: boolean = false;
    triggerDelay: number = 0;
    addedCollider: boolean = false;
    triggerAssociated3DObject: THREE.Object3D | undefined;
    triggerObjectTouched: boolean = false;
    triggerTouchedActivateObject: THREE.Object3D | undefined;
    triggerBoundingBox = new THREE.Box3();
    targetBoundingBox = new THREE.Box3();

    constructor(target: THREE.Object3D, behavior: TriggerBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.physicsEnabled = this.target.userData.physics && this.target.userData.physics.enabled;
        this.rangeDetector = null as unknown as RangeDetector;
        this.triggerDelay = 0;
    }

    init(gameManager: GameManager) {

        this.game = gameManager;

        if (gameManager.scene) {
            this.rangeDetector = new RangeDetector(gameManager);
        }

        if (this.behavior && Array.isArray(this.behavior.if_condition)) {
            this.behavior.if_condition.forEach(item => {
                Object.entries(item).forEach(([key, value]) => {
                    if (key === TRIGGER_ACTIVATION_TYPES.PRESS_E) {
                        this.target.userData.pressE = value;
                    } else if (key === TRIGGER_ACTIVATION_TYPES.PLAYER_TOUCHES) {
                        this.target.userData.player_touches = value;
                    } else if (key === TRIGGER_ACTIVATION_TYPES.OBJECT_TOUCHES) {
                        this.target.userData.object_touches = value;
                        if (item.objectUUID) {
                            this.triggerTouchedActivateObject = this.game!.scene!.getObjectByProperty("uuid", item.objectUUID);
                        }
                    } else if (key === TRIGGER_ACTIVATION_TYPES.PRESS_F) {
                        this.target.userData.pressF = value;
                    }
                });
            });
        }


        this.addRangeDetector();
        this.addCollisionListener();

        this.game!.player!.userData.velocityLocked = false;

    }

    update(clock: THREE.Clock, delta: number) {
        if (!this.game || !this.game.scene || !this.target) return;

        this.mixer?.update(delta);
        this.rangeDetector.update();
        this.triggerAssociated3DObject = undefined;

        if (this.target.userData && this.target.userData.behaviors) {
            const triggerBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.TRIGGER,
            ) as TriggerBehaviorInterface;

            if (this.target.userData.player_touches || this.target.userData.pressE) {

                if (triggerBehavior && this.triggerDelay >= 0) {
                    this.triggerDelay = triggerBehavior.delay;
                    const behaviorObjectName = triggerBehavior.then_object || triggerBehavior.else_object;;
                    if (behaviorObjectName) {

                        //get the animation clip from the saved associated clip or movement data if available.
                        this.triggerAssociated3DObject = this.game.scene.getObjectByName(behaviorObjectName) as THREE.Object3D;

                        if (this.triggerAssociated3DObject && this.triggerAssociated3DObject.userData.behaviors) {

                            //handle prop animations
                            const propAnimationBehavior = this.triggerAssociated3DObject?.userData?.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.PROP_ANIMATION,
                            ) as PropAnimationBehaviorInterface;

                            if (propAnimationBehavior && propAnimationBehavior.startOnTrigger && propAnimationBehavior.propAnimation) {
                                this.triggerAnimation = propAnimationBehavior.startOnTrigger;
                                this.triggerPropAnimationName = propAnimationBehavior.propAnimation;
                            }

                            //handle animation movement (example - platforms etc)
                            const animationBehavior = this.triggerAssociated3DObject?.userData?.behaviors.find(
                                (behavior: any) => behavior.type === OBJECT_TYPES.ANIMATION,
                            ) as AnimationBehaviorInterface;

                            if (animationBehavior && animationBehavior.startOnTrigger && !this.triggerAssociated3DObject.userData.triggerMovement) {
                                this.triggerBoundingBox = new THREE.Box3().setFromObject(this.triggerAssociated3DObject);
                                this.targetBoundingBox = new THREE.Box3().setFromObject(this.target);
                                if (this.triggerAssociated3DObject.userData.animationObjectCollision) { return }
                                if (this.triggerBoundingBox.intersectsBox(this.targetBoundingBox)) {
                                    this.triggerAssociated3DObject.userData.triggerMovement = true;
                                    this.triggerAssociated3DObject.userData.startOnTrigger = true;
                                    this.triggerAssociated3DObject.userData.animationObjectCollision = true;
                                    this.game!.behaviorManager!.collisionDetector.deleteListener(this.target);
                                }
                            }
                        }
                    }
                }
            }

            //handle if object touches behavior options
            if (this.target.userData.object_touches) {
                if (this.triggerTouchedActivateObject) {
                    this.triggerBoundingBox = new THREE.Box3().setFromObject(this.triggerTouchedActivateObject);
                    this.targetBoundingBox = new THREE.Box3().setFromObject(this.target);
                    if (this.triggerBoundingBox.intersectsBox(this.targetBoundingBox)) {
                        this.triggerObjectTouched = true;
                    }
                }
            }

            //find any animation clip from the trigger object prop animation if available
            const animationBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.PROP_ANIMATION,
            ) as PropAnimationBehaviorInterface;

            if (animationBehavior && animationBehavior.startOnTrigger && animationBehavior.propAnimation) {
                this.triggerAnimation = animationBehavior.startOnTrigger;
                this.triggerPropAnimationName = animationBehavior.propAnimation;
            }


        }

        this.autoInteractPlayer = this.playerColliding;

        if (!this.addedCollider && !this.triggerAnimation) {
            this.addedCollider = true;
        }

    }

    reset() {

    }

    addRangeDetector() {
        if (this.target.userData.pressE) {
            this.rangeDetector.setPlayer(this.game!.player!);
            this.rangeDetector.addTargets(this.target);
        }
    }

    addCollisionListener() {
        this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
                distanceThreshold: 10.0,
            },
            this.target.userData.physics && this.target.userData.physics.enabled,
        );
    }


    executeTriggerActions() {

        if (!this.target) { return }

        this.keyPressed = this.game!.scene!.userData.pressE

        const actions = [
            {
                // Use PressE on object with no behavior
                condition: !this.triggerAnimation && this.target.userData.pressE,
                action: () => {
                    this.setTriggerTimer(false);
                },
            },
            {
                // Use press E when player touches trigger object
                condition: this.triggerAnimation && this.target.userData.pressE && this.keyPressed,
                action: () => {
                    this.setTriggerTimer(false);
                },
            },
            {
                // Player auto interacts with object, no press E needed
                condition: this.triggerAnimation &&
                    !this.triggerTouchedActivateObject &&
                    this.playerColliding &&
                    !this.target.userData.pressE &&
                    !this.keyPressed &&
                    !this.autoInteractPlayer,
                action: () => {
                    this.setTriggerTimer(true);
                },
            },
            {
                // Handle switch object only
                condition: this.triggerAssociated3DObject && this.target.userData.pressE && this.keyPressed,
                action: () => {
                    this.setTriggerTimer(true);
                },
            },
            {
                // Handle trigger object touch only
                condition: this.triggerAnimation && this.triggerObjectTouched,
                action: () => {
                    this.removeCollisionAndPhysics();
                    this.setTriggerTimer(true);
                },
            },
        ];


        for (const { condition, action } of actions) {
            if (condition) {
                action();
                break;
            }
        }
    }


    setTriggerTimer(autoInteractPlayer: boolean) {
        setTimeout(() => {
            this.resetMixer();
            this.playAnimation();
            this.autoInteractPlayer = autoInteractPlayer;
        }, this.triggerDelay * 1000);
    }

    playAnimation() {

        const targetObject = this.triggerAssociated3DObject || this.target;
        const animations = targetObject?._obj?.animations;

        if (animations && animations.length > 0) {
            if (!this.mixer) {
                this.mixer = new THREE.AnimationMixer(targetObject);
            }

            const targetClip = animations.find(
                (clip: any) => clip.name === this.triggerPropAnimationName
            );

            if (!targetClip) {
                console.warn(`Animation "${this.triggerPropAnimationName}" not found.`);
                return;
            }

            this.animationAction = this.mixer.clipAction(targetClip);

            this.animationAction.loop = THREE.LoopOnce;
            this.animationAction.clampWhenFinished = true;
            this.animationPlaying = true;

            if (this.playerColliding) {
                this.removeCollisionAndPhysics();
                this.addedCollider = false;
            }

            this.animationAction.play();

            this.mixer!.addEventListener("finished", (event: any) => {
                if (event.action === this.animationAction) {
                    if (!this.addedCollider && !this.animationPlaying && !this.autoInteractPlayer) {
                        this.addCollisionAndPhysics();
                        this.addedCollider = true;
                    }
                    this.mixer!.removeEventListener("finished", this);
                    this.animationPlaying = false;
                }
            });
        }
    }


    private addCollisionAndPhysics() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.app.addPhysicsObjectBody(targetObject);
        const overlay = document.getElementById(PHYSICS_PROXY_UI.PHYSICS_MESSAGE_ELEMENT);
        if (overlay) {
            overlay.style.display = "none";
        }
    }

    private removeCollisionAndPhysics() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.app.removePhysicsObjectBody(targetObject);
    }

    private removeCollisionListener() {
        const targetObject = this.triggerAssociated3DObject || this.target;
        this.game!.behaviorManager!.collisionDetector.deleteListener(targetObject);
    }

    resetMixer(): void {
        if (this.mixer) {
            const actions = (this.mixer as THREE.AnimationMixer)._actions;
            actions.forEach((action: THREE.AnimationAction) => {
                action.stop();
                action.reset();
            });
            this.animationAction = null;
            this.mixer.time = 0;
        }
    }

    onCollisionWithPlayer() {
        this.playerColliding = true;
        setTimeout(() => (this.playerColliding = this.triggerObjectTouched = false), 500);
        if (this.animationPlaying) { return };
        this.executeTriggerActions();
    }

}

export default TriggerBehaviorUpdater;