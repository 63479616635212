import {useRef, useState} from "react";
import {Combobox} from "@headlessui/react";
import classNames from "classnames";
import "./css/StyledCombobox.css";
import arrow from "../RightPanel/icons/arrow-down.svg";

export interface Item {
    key: string;
    value: string;
    uuid?: string;
}

type Props = {
    data: Item[];
    value?: Item;
    onChange: (selectedData: Item) => void;
    className?: string;
    showListOnTop?: boolean;
    disableTyping?: boolean;
};

export const BasicCombobox = ({data, value, onChange, className, showListOnTop, disableTyping}: Props) => {
    const [query, setQuery] = useState("");
    const arrowRef = useRef<HTMLButtonElement | null>(null);

    const filteredData =
        query === ""
            ? data
            : data.filter(dataItem => {
                  return dataItem.value.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <div className={classNames("StyledCombobox", className)} onClick={() => arrowRef?.current?.click()}>
            <Combobox
                value={
                    value || {
                        key: "0",
                        value: "none",
                    }
                }
                onChange={onChange}>
                <Combobox.Input
                    className="combobox-input"
                    displayValue={(item: Item) => item.value}
                    onChange={event => setQuery(event.target.value)}
                    readOnly={disableTyping}
                />
                <Combobox.Button className="combobox-button" ref={arrowRef}>
                    <img src={arrow} alt="open list" />
                </Combobox.Button>
                <Combobox.Options
                    className={classNames(
                        "combobox-options-wrapper",
                        showListOnTop && "combobox-options-wrapper--top",
                    )}>
                    {filteredData.map(dataItem => (
                        <Combobox.Option key={dataItem.key} value={dataItem} className="combobox-option">
                            {dataItem.value}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </Combobox>
        </div>
    );
};
