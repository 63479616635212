import styled from "styled-components";
import {BasicCombobox, Item} from "../../common/BasicCombobox";
import {StyledRowWrapper} from "./StyledRowWrapper";

interface Props {
    data: Item[];
    value?: Item;
    onChange: (selectedData: Item) => void;
    label: string;
    $margin?: string;
    showListOnTop?: boolean;
    disableTyping?: boolean;
    width?: string;
}

export const SelectRow = ({data, value, onChange, label, $margin, showListOnTop, width, disableTyping}: Props) => {
    return (
        <Wrapper $margin={$margin} $width={width}>
            <span className="text">{label}</span>
            <BasicCombobox
                showListOnTop={showListOnTop}
                data={data}
                value={value}
                onChange={onChange}
                disableTyping={disableTyping}
            />
        </Wrapper>
    );
};

const Wrapper = styled(StyledRowWrapper)<{$margin?: string; $width?: string}>`
    ${({$margin}) => $margin && `margin: ${$margin}`};

    .text {
        text-wrap: nowrap;
    }
    .StyledCombobox {
        width: 107px;
        width: ${({$width}) => ($width ? $width : "107px")};
        .combobox-input {
            padding: 6px 24px 6px 6px;
        }
    }
`;
