import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import InventoryManagerBehaviorUpdater from "./InventoryManagerBehaviorUpdater";
import {InventoryManagerBehaviorInterface, INVENTORY_TYPES, OBJECT_TYPES} from "../../types/editor";

class InventoryManagerBehaviorConverter extends BaseBehaviorConverter<InventoryManagerBehaviorInterface> {
    public static DEFAULT = new InventoryManagerBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: InventoryManagerBehaviorInterface): BehaviorUpdater {
        return new InventoryManagerBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): InventoryManagerBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as InventoryManagerBehaviorInterface;
        const isInventoryManagerBehaviorInterface = "objectInteraction" in behavior && "enabled" in behavior;
        if (!behavior || !isInventoryManagerBehaviorInterface) {
            console.warn("Object doesn't have INVENTORY behavior: ", target);
            return this.getDefaultBehavior(id);
        }

        return behavior;
    }

    getDefaultBehavior(id: string): InventoryManagerBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.INVENTORY,
            inventoryType: INVENTORY_TYPES.CONSUMABLE,
            startOnTrigger: false,
        };
    }
}

export default InventoryManagerBehaviorConverter;
