import {flexCenter} from "../../../../../../assets/style";
import styled from "styled-components";

export const Grid = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100vw;
    display: grid;
    grid-template-columns: 423px 1fr;
    align-items: start;
    justify-items: stretch;
    padding: 0;
    position: relative;
    z-index: 2;
    overflow: hidden auto;
    pointer-events: all;

    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        button {
            max-width: 300px;
        }
    }
`;

export const ButtonsColumn = styled.div<{
    $panelBg?: string;
    $menuBgColumn?: boolean;
    $isCenter?: boolean;
}>`
    ${flexCenter};
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 24px;
    width: 100%;
    height: 100%;
    padding: 32px 0;

    @media only screen and (max-width: 1919px) {
        padding: 16px 0;
    }

    .marginTop {
        margin: auto auto 0 37px !important;
    }

    .bigButton {
        margin-bottom: 18px;
    }

    background: ${({$panelBg}) => $panelBg || "transparent"};

    ${({$menuBgColumn}) =>
        $menuBgColumn &&
        `
  ${flexCenter};
  grid-column: 2 / span 2;
`};
`;

export const PanelColorWrapper = styled.div`
    width: 285px;
    position: relative;
    .colorPickerWrapper {
        position: absolute;
        right: 0;
        top: unset;
        bottom: 0;
        transform: translateX(100%);
    }
    .text {
        color: #fff;
    }
`;
