import {useState, useEffect} from "react";
import {SpriteBehaviorInterface} from "../../../../../types/editor";
import SpriteBehaviorConverter from "../../../../../serialization/behaviours/SpriteBehaviorConverter";
import global from "../../../../../global";
import {Separator} from "../common/Separator";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {NumericInputRow} from "../common/NumericInputRow";
import {ContentItem} from "../panels/GameSettings/GameSettings.style";
import {UploadField} from "../../common/UploadField/UploadField";
import {PanelSectionTitleSecondary} from "../RightPanel.style";

type Props = {
    behavior: SpriteBehaviorInterface;
};

export const SpriteBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState<SpriteBehaviorInterface>(initialBehavior);

    const targetBehavior = selected ? SpriteBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) : null;

    useEffect(() => {
        if (targetBehavior) {
            setBehavior(targetBehavior);
        }
    }, [targetBehavior]);

    useEffect(() => {
        if (!targetBehavior) {
            console.warn("Target behavior not found for selected object.");
        }
    }, [targetBehavior]);

    const handleInputChange = (value: any, name: keyof SpriteBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior(prevBehavior => ({...prevBehavior, [name]: value}));
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                checked={targetBehavior!.startOnTrigger}
                onChange={() => handleInputChange(!targetBehavior!.startOnTrigger, "startOnTrigger")}
                v2
            />
            <Separator margin="8px 0 0" invisible />
            <ContentItem>
                <PanelSectionTitleSecondary>Sprite Texture Image</PanelSectionTitleSecondary>
                <UploadField
                    width="80px"
                    height="80px"
                    uploadedFile={targetBehavior?.uiImage || null}
                    setUploadedFile={imageUrl => handleInputChange(imageUrl, "uiImage")}
                />
            </ContentItem>
            <Separator margin="8px 0" />
            <NumericInputRow
                width="75px"
                label="Right Standing"
                value={targetBehavior?.frameRightStanding ?? 0}
                setValue={value => handleInputChange(value, "frameRightStanding")}
            />
            <NumericInputRow
                width="75px"
                label="Left Standing"
                value={targetBehavior?.frameLeftStanding ?? 0}
                setValue={value => handleInputChange(value, "frameLeftStanding")}
            />
            <NumericInputRow
                width="75px"
                label="Move Forward Standing"
                value={targetBehavior?.frameForwardStanding ?? 0}
                setValue={value => handleInputChange(value, "frameForwardStanding")}
            />
            <NumericInputRow
                width="75px"
                label="Move Forward Start"
                value={targetBehavior?.moveForwardStart ?? 0}
                setValue={value => handleInputChange(value, "moveForwardStart")}
            />
            <NumericInputRow
                width="75px"
                label="Move Forward End"
                value={targetBehavior?.moveForwardEnd ?? 0}
                setValue={value => handleInputChange(value, "moveForwardEnd")}
            />
            <NumericInputRow
                width="75px"
                label="Move Backward Standing"
                value={targetBehavior?.frameBackwardStanding ?? 0}
                setValue={value => handleInputChange(value, "frameBackwardStanding")}
            />
            <NumericInputRow
                width="75px"
                label="Move Backward Start"
                value={targetBehavior?.moveBackwardStart ?? 0}
                setValue={value => handleInputChange(value, "moveBackwardStart")}
            />
            <NumericInputRow
                width="75px"
                label="Move Backward End"
                value={targetBehavior?.moveBackwardEnd ?? 0}
                setValue={value => handleInputChange(value, "moveBackwardEnd")}
            />
            <NumericInputRow
                width="75px"
                label="Frame Rows"
                value={targetBehavior?.frameRows ?? 0}
                setValue={value => handleInputChange(value, "frameRows")}
            />
            <NumericInputRow
                width="75px"
                label="Frame Columns"
                value={targetBehavior?.frameColumns ?? 0}
                setValue={value => handleInputChange(value, "frameColumns")}
            />
            <NumericInputRow
                width="75px"
                label="Frame Rate"
                value={targetBehavior?.frameRate ?? 0}
                setValue={value => handleInputChange(value, "frameRate")}
            />
            <NumericInputRow
                width="75px"
                label="Move Left Start"
                value={targetBehavior?.moveLeftStart ?? 0}
                setValue={value => handleInputChange(value, "moveLeftStart")}
            />
            <NumericInputRow
                width="75px"
                label="Move Left End"
                value={targetBehavior?.moveLeftEnd ?? 0}
                setValue={value => handleInputChange(value, "moveLeftEnd")}
            />
            <NumericInputRow
                width="75px"
                label="Move Right Start"
                value={targetBehavior?.moveRightStart ?? 0}
                setValue={value => handleInputChange(value, "moveRightStart")}
            />
            <NumericInputRow
                width="75px"
                label="Move Right End"
                value={targetBehavior?.moveRightEnd ?? 0}
                setValue={value => handleInputChange(value, "moveRightEnd")}
            />
            <NumericInputRow
                width="75px"
                label="Jump Start"
                value={targetBehavior?.jumpStart ?? 0}
                setValue={value => handleInputChange(value, "jumpStart")}
            />
            <NumericInputRow
                width="75px"
                label="Jump End"
                value={targetBehavior?.jumpEnd ?? 0}
                setValue={value => handleInputChange(value, "jumpEnd")}
            />
        </>
    );
};
