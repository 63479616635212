import * as THREE from "three";
import {CSS3DObject} from "three/examples/jsm/renderers/CSS3DRenderer";
import { OBJECT_TYPES, CONSUMABLE_TYPES } from "../../types/editor";
import GameManager from "../game/GameManager";


export interface RangeListener {
    id?: string;
    distanceThreshold: number;
    callback: () => void;
}

class RangeDetector {
    private player: THREE.Object3D | null = null;
    private target: THREE.Object3D | null = null;
    private textMesh?: CSS3DObject;
    gameManager: GameManager;

    constructor(gameManager: GameManager) {
        this.gameManager = gameManager;
    }

    setPlayer(player: THREE.Object3D) {
        this.player = player;
    }

    addTargets(arg: THREE.Object3D) {
        this.target = arg;
    }

    update() {
        if (!this.player || !this.target) return;
     
        const distance = this.player!.position.distanceTo(this.target.position);
        if (distance <= 5) {
            if (!this.textMesh) {
                this.createText();
            } else {
                this.textMesh.visible = true;
                this.updateTextPosition();
            }
            if (this.target.userData.isCollected) {
                   this.textMesh!.visible = false;
            }
        } else {
            if (this.textMesh) {
                this.textMesh.visible = false;
            }
        }

    }

    getBehavior(behaviors: any[], type: string) {
        return behaviors.find((behavior: any) => behavior.type === type);
    }

    createText() {
        if (this.textMesh) {
            return;
        }

        // Main div
        const div = document.createElement("div");
        div.style.position = "absolute";
        div.style.color = "white";
        div.style.fontSize = "14px";
        div.style.background = "rgba(0, 0, 0, 0.5)";
        div.style.padding = "5px";
        div.style.borderRadius = "5px";

        // Text container
        const textContainer = document.createElement("span");

        const behaviors = this.target?.userData?.behaviors || [];
        const consumable = behaviors.find(
            (object: any) => object.type === OBJECT_TYPES.CONSUMABLE
        );

        let showEKey = true; // Flag to decide if the E key element should be displayed

        if (consumable) {
            switch (consumable.consumableType) {
                case CONSUMABLE_TYPES.BUTTON_PRESS:
                    textContainer.innerHTML = "To Pick Up";
                    break;
                case CONSUMABLE_TYPES.INSTANT:
                    textContainer.innerHTML = "Touch To Pick Up";
                    showEKey = false; // Hide the E key for INSTANT consumables
                    break;
                default:
                    textContainer.innerHTML = "To Interact";
                    break;
            }
        } else {
            textContainer.innerHTML = "To Interact";
        }

        div.appendChild(textContainer);

        // E key element: Only add if showEKey is true
        if (showEKey) {
            const keyContainer = document.createElement("span");
            keyContainer.innerHTML = "E";
            keyContainer.style.color = "white";
            keyContainer.style.background = "#18181b";
            keyContainer.style.padding = "3px 6px";
            keyContainer.style.borderRadius = "4px";
            keyContainer.style.marginRight = "4px";
            textContainer.insertBefore(keyContainer, textContainer.firstChild);
        }

        // Create the CSS3DObject
        const object = new CSS3DObject(div);
        object.position.set(0, 0, 0);
        object.scale.set(0.02, 0.02, 0.02);
        this.gameManager.scene!.add(object);
        this.textMesh = object;
    }


    updateTextPosition() {
        if (!this.target || !this.textMesh || !this.gameManager.camera) return;

        const targetPosition = new THREE.Vector3();
        this.target.getWorldPosition(targetPosition);

        // const offsetY = 1.5;
        this.textMesh.position.copy(targetPosition);
        // this.textMesh.position.y += offsetY;

        this.textMesh.lookAt(this.gameManager.camera.position);
    }
}

export default RangeDetector;
