import styled from "styled-components";
import {flexCenter, regularFont} from "../../assets/style";

export const HEADER_HEIGHT = "72px";
export const HEADER_Z_INDEX = "99999";

export const StyledHeader = styled.div<{$metaverse: boolean}>`
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: var(--theme-container-main-dark);
    margin: 0 auto;
    width: 100%;
    height: ${HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--theme-container-divider);
    .plusIcon {
        font-size: 20px;
        line-height: 20px;
        height: 22px;
    }

    ${({$metaverse}) =>
        $metaverse &&
        `
    background-color: transparent;
    z-index: 999;
    border-bottom: none;
    `}
`;

export const LeftSide = styled.div`
    ${flexCenter};
    column-gap: 16px;
`;

export const Logo = styled.div<{$metaverse: boolean}>`
    padding: 24px;
    border-right: 1px solid var(--theme-container-divider);
    ${flexCenter};
    cursor: pointer;
    @media only screen and (max-width: 767px) {
        padding: 12px;
    }

    ${({$metaverse}) =>
        $metaverse &&
        `
    border-right: none;
`}
`;

export const ButtonsWrapper = styled.div<{$gap: string}>`
    ${flexCenter};
    column-gap: ${({$gap}) => $gap};
    margin-right: 16px;
    @media only screen and (max-width: 767px) {
        margin-right: 12px;
    }

    .notification {
        margin-left: 8px;
    }
`;

export const Explore = styled.button`
    ${flexCenter};
    margin-right: 8px;
    ${regularFont("xs")};
    font-weight: 500;
`;
