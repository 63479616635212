import React, {useState, useEffect} from "react";
import {ITransformValue, OBJECT_TYPES, WeaponBehaviorInterface} from "../../../../../../types/editor";
import global from "../../../../../../global";
import * as THREE from "three";
import {getDefaultWeaponBehavior} from "../helpers/getDefaultWeaponBehavior";
import {PanelCheckbox} from "../../common/PanelCheckbox";
import {Separator} from "../../common/Separator";
import {WeaponImgSection} from "./GunImageSection";
import {NumericInputRow} from "../../common/NumericInputRow";
import {SelectRow} from "../../common/SelectRow";
import {EFFECTS_KEYS, EFFECTS_NAMES, WEAPON_VFX_OPTIONS} from "./types";
import {Heading} from "../../common/Heading";
import {PositionSection} from "./PositionSection";
import {SelectionOfButtons} from "../../common/SelectionOfButtons";
import {Item} from "../../../common/BasicCombobox";
import styled from "styled-components";
import {StyledButton} from "../../../common/StyledButton";
import {WEAPON_TYPES, WEAPON_AIMERS} from "../../../../../../types/editor";
import {StyledRange} from "../../../common/StyledRange";
import {PanelSectionTitleSecondary} from "../../RightPanel.style";

type Props = {
    behavior: WeaponBehaviorInterface;
};

export const WeaponBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [scopeZoomValue, setScopeZoomValue] = useState<number>(behavior.weaponScopeZoom || 0);
    const [aimerSizeValue, setaimerSizeValue] = useState<number>(behavior.weaponHUDAimerSize || 50);
    const [uiImage, setuiImage] = useState<string>(behavior.uiImage || "");
    const [aimerUIImage, setaimerUIImage] = useState<string>(behavior.aimerUIImage || "");
    const [selectedAmmoOptions, setSelectedAmmoOptions] = useState([{key: "0", value: "none"}]);
    const [selectCharacterBoneOptions, setSelectedCharacterBoneOptions] = useState([{key: "0", value: "none"}]);

    const weaponTypeOptions = [
        {key: "none", value: "none"},
        ...Object.entries(WEAPON_TYPES).map(([key, value]) => ({
            key,
            value,
        })),
    ];

    const [currentBehavior, setCurrentBehavior] = useState<WeaponBehaviorInterface>(behavior);

    const [WEAPONOptions, setWEAPONOptions] = useState(editor.camera.userData?.WEAPONOptions || {});

    const [positionValue, setPositionValue] = useState<ITransformValue>({
        x: currentBehavior.position_x,
        y: currentBehavior.position_y,
        z: currentBehavior.position_z,
    });
    const [rotationValue, setRotationValue] = useState<ITransformValue>({
        x: currentBehavior.rotation_x,
        y: currentBehavior.rotation_y,
        z: currentBehavior.rotation_z,
    });

    const [muzzleFlashBrightness, setMuzzleFlashBrightness] = useState<number>(behavior.weaponMuzzleFlashBrightness);

    const [muzzleSmokeSize, setMuzzleSmokeSize] = useState<number>(behavior.weaponMuzzleSmokeSize);

    const [muzzleSmokeLife, setMuzzleSmokeLife] = useState<number>(behavior.weaponMuzzleSmokeLife);

    const [muzzleSmokeDensity, setMuzzleSmokeDensity] = useState<number>(behavior.weaponMuzzleSmokeOpacity);

    const [muzzleSmokeOpacity, setMuzzleSmokeOpacity] = useState<number>(behavior.weaponMuzzleSmokeOpacity);

    const handleMuzzleFlashBrightnessChange = (value: number) => {
        setMuzzleFlashBrightness(value);
        handleInputChange(value, "weaponMuzzleFlashBrightness");
    };

    const handleMuzzleSmokeDensityChange = (value: number) => {
        setMuzzleSmokeDensity(value);
        handleInputChange(value, "weaponMuzzleSmokeDensity");
    };

    const handleMuzzleSmokeOpacityChange = (value: number) => {
        setMuzzleSmokeOpacity(value);
        handleInputChange(value, "weaponMuzzleSmokeOpacity");
    };

    const handleMuzzleSmokeSizeChange = (value: number) => {
        setMuzzleSmokeSize(value);
        handleInputChange(value, "weaponMuzzleSmokeSize");
    };

    const handleMuzzleSmokeLifeChange = (value: number) => {
        setMuzzleSmokeLife(value);
        handleInputChange(value, "weaponMuzzleSmokeLife");
    };

    useEffect(() => {
        handleInputChange(positionValue.x, "position_x");
        handleInputChange(positionValue.y, "position_y");
        handleInputChange(positionValue.z, "position_z");
    }, [positionValue]);

    useEffect(() => {
        handleInputChange(rotationValue.x, "rotation_x");
        handleInputChange(rotationValue.y, "rotation_y");
        handleInputChange(rotationValue.z, "rotation_z");
    }, [rotationValue]);

    useEffect(() => {
        setMuzzleFlashBrightness(behavior.weaponMuzzleFlashBrightness || 0);
        setMuzzleSmokeDensity(behavior.weaponMuzzleSmokeDensity || 2);
        setMuzzleSmokeOpacity(behavior.weaponMuzzleSmokeOpacity || 0.1);
        setMuzzleSmokeSize(behavior.weaponMuzzleSmokeSize || 0.5);
        setMuzzleSmokeLife(behavior.weaponMuzzleSmokeLife || 1000);
    }, [behavior]);

    useEffect(() => {
        handleInputChange(muzzleFlashBrightness, "weaponMuzzleFlashBrightness");
    }, [muzzleFlashBrightness]);

    useEffect(() => {
        handleInputChange(muzzleSmokeDensity, "weaponMuzzleSmokeDensity");
    }, [muzzleSmokeDensity]);

    useEffect(() => {
        handleInputChange(muzzleSmokeOpacity, "weaponMuzzleSmokeOpacity");
    }, [muzzleSmokeOpacity]);

    useEffect(() => {
        handleInputChange(muzzleSmokeSize, "weaponMuzzleSmokeSize");
    }, [muzzleSmokeSize]);

    useEffect(() => {
        handleInputChange(muzzleSmokeLife, "weaponMuzzleSmokeLife");
    }, [muzzleSmokeLife]);

    const getObjectBehavior = () => {
        if (selected) {
            const obj = editor.objectByUuid(selected.uuid);
            const objBehavior = obj.userData.behaviors?.find((behavior: any) => behavior.type === OBJECT_TYPES.WEAPON);

            const updatedBehavior = {
                ...currentBehavior,
                ui_tag: obj.id,
                weaponName: obj.name,
            };

            setCurrentBehavior(updatedBehavior);

            return objBehavior;
        }
        return null;
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected) {
            if (editor.camera) {
                setWEAPONOptions((prevState: any) => {
                    const newState = {...prevState};
                    newState[name] = value;
                    editor.camera.userData.WEAPONOptions = newState;
                    return newState;
                });
            }
            const objBehavior = getObjectBehavior();
            if (objBehavior) {
                if (name.startsWith("vfx")) {
                    const newBehavior = {...objBehavior, [name]: value};
                    Object.keys(currentBehavior).forEach(key => {
                        if (key.startsWith("vfx") && key !== name) {
                            newBehavior[key] = false;
                        }
                    });
                    setCurrentBehavior(newBehavior);
                } else {
                    objBehavior[name] = value;
                    setCurrentBehavior({...currentBehavior, ...objBehavior});
                }
                app.call(`objectChanged`, app.editor, app.editor.selected);
            }
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        if (e.target && e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = upload => {
                const result = upload.target?.result;
                const base64Image = result as string;
                handleInputChange(base64Image, name);
                if (name === "uiImage") {
                    setuiImage(base64Image);
                } else if (name === "aimerUIImage") {
                    setaimerUIImage(base64Image);
                }
            };
            reader.readAsDataURL(file);
        } else {
            console.error("No file selected");
        }
    };

    const handleWeaponTypeChange = (item: Item) => {
        handleInputChange(item.value, "weaponType");
    };

    const fetchSceneAmmoObjects = () => {
        if (!app || !app.editor || !app.editor.scene) {
            return;
        }

        const scene = app.editor.scene;

        const ammoOptions = ["none"];

        scene.traverse((object: THREE.Object3D) => {
            if (object.userData && object.userData.behaviors) {
                const ammoBehavior = object.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON_AMMO,
                );

                if (ammoBehavior) {
                    ammoOptions.push(object.name);
                }
            }
        });

        setSelectedAmmoOptions(
            ammoOptions.map((option, index) => {
                return {
                    key: `${index + 1}`,
                    value: option,
                };
            }),
        );
    };

    const fetchCharacterBones = () => {
        if (!app || !app.editor || !app.editor.scene) {
            return;
        }

        const scene: THREE.Scene = app.editor.scene;
        const characterBoneOptions: string[] = ["none"];

        scene.traverse((object: THREE.Object3D) => {
            if (object.userData && object.userData.behaviors) {
                const characterBehavior = object.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.CHARACTER,
                );
                if (characterBehavior) {
                    scene.traverse(object => {
                        if (object.children && object.children.length > 0) {
                            object.children.forEach(child => {
                                if (child.type === "Bone") {
                                    const boneName = child.name.replace("mixamorig", "");
                                    characterBoneOptions.push(boneName);
                                }
                            });
                        }
                    });
                }
            }
        });

        setSelectedCharacterBoneOptions(
            characterBoneOptions.map((option, index) => {
                return {
                    key: `${index + 1}`,
                    value: option,
                };
            }),
        );
    };

    useEffect(() => {
        fetchSceneAmmoObjects();
        fetchCharacterBones();

        const defaultBehavior = getDefaultWeaponBehavior(behavior);
        Object.keys(defaultBehavior).forEach(key => {
            handleInputChange(defaultBehavior[key as keyof WeaponBehaviorInterface], key);
        });

        setCurrentBehavior(prevBehavior => ({
            ...defaultBehavior,
            ...behavior,
            ui_tag: selected?.id || prevBehavior.ui_tag,
            weaponName: selected?.name || prevBehavior.weaponName,
        }));

        setuiImage(defaultBehavior.uiImage);
        setaimerUIImage(defaultBehavior.aimerUIImage);
    }, [selected]);

    useEffect(() => {
        const removeAimerImage = () => {
            (global as any).app.call("removeGunAimer", this, this);
        };
        return () => {
            removeAimerImage();
        };
    }, []);

    const getWeaponVFXCurrentValue = () => {
        if (currentBehavior.VFXSmallEffect) {
            return WEAPON_VFX_OPTIONS.find(el => el.value === EFFECTS_NAMES.SMALL);
        } else if (currentBehavior.VFXMediumEffect) {
            return WEAPON_VFX_OPTIONS.find(el => el.value === EFFECTS_NAMES.MEDIUM);
        } else if (currentBehavior.VFXBigEffect) {
            return WEAPON_VFX_OPTIONS.find(el => el.value === EFFECTS_NAMES.BIG);
        } else if (currentBehavior.VFXLaserEffect) {
            return WEAPON_VFX_OPTIONS.find(el => el.value === EFFECTS_NAMES.LASER);
        } else if (currentBehavior.VFXCartoonyEffect) {
            return WEAPON_VFX_OPTIONS.find(el => el.value === EFFECTS_NAMES.CARTOONY);
        } else return WEAPON_VFX_OPTIONS[0];
    };
    const weaponVFXOnChange = (item: Item) => {
        let property: keyof WeaponBehaviorInterface | null = null;
        if (item.value === EFFECTS_NAMES.SMALL) {
            property = "VFXSmallEffect";
        } else if (item.value === EFFECTS_NAMES.MEDIUM) {
            property = "VFXMediumEffect";
        } else if (item.value === EFFECTS_NAMES.BIG) {
            property = "VFXBigEffect";
        } else if (item.value === EFFECTS_NAMES.LASER) {
            property = "VFXLaserEffect";
        } else if (item.value === EFFECTS_NAMES.CARTOONY) {
            property = "VFXCartoonyEffect";
        }

        if (property) {
            handleInputChange(!currentBehavior[property], property);
        } else {
            Object.values(EFFECTS_KEYS).forEach(value => {
                handleInputChange(!currentBehavior[value], value);
            });
        }
    };

    return (
        <>
            {currentBehavior.weaponPreviewHUDAimer && (
                <Aimer src={currentBehavior.aimerUIImage} $size={currentBehavior.weaponHUDAimerSize} />
            )}
            <PanelCheckbox
                text="Starting Weapon"
                v2
                isGray
                regular
                checked={!!currentBehavior.weaponStarting}
                onChange={() => handleInputChange(!currentBehavior.weaponStarting, "weaponStarting")}
            />
            <Separator margin="8px 0" invisible />
            <WeaponImgSection
                label="Weapon UI Image"
                image={uiImage}
                setImage={setuiImage}
                handleImageChange={handleImageChange}
                imageKeyName="uiImage"
                inputId="uiImageInput"
            />
            <SelectRow
                label="Weapon Type"
                data={weaponTypeOptions}
                value={
                    weaponTypeOptions.find(item => item.value === currentBehavior.weaponType) || weaponTypeOptions[0]
                }
                onChange={handleWeaponTypeChange}
            />
            <SelectRow
                label="Hand Attachment"
                data={selectCharacterBoneOptions}
                value={
                    selectCharacterBoneOptions.find(
                        item => item.value === WEAPONOptions.weaponSelectedCharacterBone,
                    ) || {
                        key: "0",
                        value: "none",
                    }
                }
                onChange={item => handleInputChange(item.value, "weaponSelectedCharacterBone")}
            />
            <NumericInputRow
                label="Weapon Scale"
                value={currentBehavior.weaponScale}
                setValue={value => handleInputChange(value, "weaponScale")}
            />
            <NumericInputRow
                label="Weapon Damage"
                value={currentBehavior.weaponDamage}
                setValue={value => handleInputChange(value, "weaponDamage")}
            />
            <NumericInputRow
                label="Weapon Clip Amount"
                value={currentBehavior.weaponClipAmount}
                setValue={value => handleInputChange(value, "weaponClipAmount")}
            />
            <NumericInputRow
                label="Fire Speed"
                value={currentBehavior.weaponFireSpeed}
                setValue={value => handleInputChange(value, "weaponFireSpeed")}
            />
            <NumericInputRow
                label="Reload Speed"
                value={currentBehavior.weaponReloadSpeed}
                setValue={value => handleInputChange(value, "weaponReloadSpeed")}
            />
            <SelectRow
                label="Weapon VFX"
                data={WEAPON_VFX_OPTIONS}
                value={getWeaponVFXCurrentValue() || WEAPON_VFX_OPTIONS[0]}
                onChange={item => weaponVFXOnChange(item)}
            />

            <Separator margin="8px 0" />

            <PanelSectionTitleSecondary>Gun Muzzle Special Effects</PanelSectionTitleSecondary>

            <Separator margin="8px 0" invisible />

            <label>
                <PanelSectionTitleSecondary>Smoke Density ({muzzleSmokeDensity})</PanelSectionTitleSecondary>
                <StyledRange
                    min={0}
                    max={10}
                    step={1}
                    value={muzzleSmokeDensity}
                    setValue={handleMuzzleSmokeDensityChange}
                />
            </label>

            <label>
                <PanelSectionTitleSecondary>Smoke Size ({muzzleSmokeSize})</PanelSectionTitleSecondary>
                <StyledRange
                    min={0}
                    max={10}
                    step={0.25}
                    value={muzzleSmokeSize}
                    setValue={handleMuzzleSmokeSizeChange}
                />
            </label>

            <label>
                <PanelSectionTitleSecondary>Smoke Opacity ({muzzleSmokeOpacity})</PanelSectionTitleSecondary>
                <StyledRange
                    min={0}
                    max={1}
                    step={0.1}
                    value={muzzleSmokeOpacity}
                    setValue={handleMuzzleSmokeOpacityChange}
                />
            </label>

            <label>
                <PanelSectionTitleSecondary>Smoke Life (ms) ({muzzleSmokeLife})</PanelSectionTitleSecondary>
                <StyledRange
                    min={100}
                    max={10000}
                    step={100}
                    value={muzzleSmokeLife}
                    setValue={handleMuzzleSmokeLifeChange}
                />
            </label>

            <label>
                <PanelSectionTitleSecondary>Flash Brightness ({muzzleFlashBrightness})</PanelSectionTitleSecondary>
                <StyledRange
                    min={0}
                    max={1}
                    step={0.1}
                    value={muzzleFlashBrightness}
                    setValue={handleMuzzleFlashBrightnessChange}
                />
            </label>

            <Separator />

            <PanelCheckbox
                text="Auto Reload"
                v2
                isGray
                regular
                checked={!!currentBehavior.weaponAutoReload}
                onChange={() => handleInputChange(!currentBehavior.weaponAutoReload, "weaponAutoReload")}
            />
            <Separator />
            <PositionSection
                positionValue={positionValue}
                setPositionValue={setPositionValue}
                rotationValue={rotationValue}
                setRotationValue={setRotationValue}
            />
            <Separator />
            <NumericInputRow
                $margin="0"
                label="Scope Zoom"
                value={currentBehavior.weaponScopeZoom}
                setValue={value => handleInputChange(value, "weaponScopeZoom")}
            />
            <Separator margin="8px 0" />
            <WeaponImgSection
                label="Aimer UI Image"
                image={aimerUIImage}
                setImage={setaimerUIImage}
                handleImageChange={handleImageChange}
                imageKeyName="aimerUIImage"
                inputId="aimerUIImageInput"
            />
            <NumericInputRow
                label="Aimer Size"
                value={aimerSizeValue}
                setValue={value => {
                    if (!isNaN(value) && value <= 512) {
                        setaimerSizeValue(value);
                        handleInputChange(value, "weaponHUDAimerSize");
                    }
                }}
            />

            <SelectionOfButtons margin="4px auto 0" nowrap>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlueSecondary={currentBehavior.weaponShowHUDAimerInGame}
                    isActive={!currentBehavior.weaponShowHUDAimerInGame}
                    onClick={() =>
                        handleInputChange(!currentBehavior.weaponShowHUDAimerInGame, "weaponShowHUDAimerInGame")
                    }>
                    <span>Show Aimer</span>
                </StyledButton>
                <StyledButton
                    width="calc(50% - 3px)"
                    isBlueSecondary={currentBehavior.weaponPreviewHUDAimer}
                    isActive={!currentBehavior.weaponPreviewHUDAimer}
                    onClick={() => handleInputChange(!currentBehavior.weaponPreviewHUDAimer, "weaponPreviewHUDAimer")}>
                    <span>Preview Aimer</span>
                </StyledButton>
            </SelectionOfButtons>
            <Separator />
            <Heading margin={"0 0 12px"}>Ammo</Heading>
            <PanelCheckbox
                text="Show Ammo"
                v2
                isGray
                regular
                checked={!!currentBehavior.weaponAmmoVisible}
                onChange={() => handleInputChange(!currentBehavior.weaponAmmoVisible, "weaponAmmoVisible")}
            />
            <Separator invisible margin="8px 0" />
            <SelectRow
                $margin="0"
                label="Gun Ammo"
                data={selectedAmmoOptions}
                value={
                    selectedAmmoOptions.find(item => item.value === WEAPONOptions.selectedWeaponAmmoName) || {
                        key: "0",
                        value: "none",
                    }
                }
                onChange={item => handleInputChange(item.value, "selectedWeaponAmmoName")}
            />
        </>
    );
};

const Aimer = styled.img<{$size: number}>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${WEAPON_AIMERS.AIMER_SCREEN_ZINDEX};
    width: ${({$size}) => `${$size}px`};
    height: ${({$size}) => `${$size}px`};
`;
