import {toast} from "react-toastify";
import {IAiResponse, OBJECT_TYPES} from "../../../../types/editor";
import * as THREE from "three";

export const attachBehavior = (object: THREE.Object3D, behavior: any) => {
    if (behavior) {
        if (!object.userData.behaviors) {
            object.userData.behaviors = [];
        }
        behavior.id = THREE.MathUtils.generateUUID();
        behavior.enabled = true;
        object.userData.behaviors.push(behavior);
    }
};

export const detachBehavior = (object: THREE.Object3D, type: string) => {
    if (object.userData.behaviors) {
        object.userData.behaviors = object.userData.behaviors.filter((behavior: any) => behavior.type !== type);
    }
};

export const updateBehavior = (object: THREE.Object3D, behavior: any) => {
    if (object.userData.behaviors) {
        let currentBehavior = object.userData.behaviors.find((b: any) => b.type === behavior.type);
        if (!currentBehavior) {
            toast.error(`Behavior ${behavior.type} is not attached to the object`);
            return;
        }
        if (currentBehavior.type === OBJECT_TYPES.CHARACTER) {
            const options = behavior.characterOptions || behavior.characterOptions || behavior.vehicleOptions;
            currentBehavior = {
                ...currentBehavior,
                ...behavior,
                characterOptions: {
                    ...currentBehavior.characterOptions,
                    ...options,
                },

                vehicleOptions: {
                    ...currentBehavior.vehicleOptions,
                    ...options,
                },
            };
        } else {
            currentBehavior = {
                ...currentBehavior,
                ...behavior,
            };
        }
        detachBehavior(object, behavior.type);
        attachBehavior(object, currentBehavior);
    }
};

export const updatePosition = (object: THREE.Object3D, position: THREE.Vector3) => {
    object.position.set(position.x, position.y, position.z);
};

export const updateScale = (object: THREE.Object3D, scale: THREE.Vector3) => {
    const currentScale = object.scale;
    object.scale.set(scale.x * currentScale.x, scale.y * currentScale.y, scale.z * currentScale.z);
};

export const updateRotation = (object: THREE.Object3D, rotation: THREE.Euler) => {
    const currentRotation = object.rotation;
    object.rotation.set(rotation.x + currentRotation.x, rotation.y + currentRotation.y, rotation.z + currentRotation.z);
};

export const base64ToFile = (base64: string, fileName: string, mimeType = "application/octet-stream") => {
    const base64Data = base64.split(",")[1] || base64;
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new File([byteArray], fileName, {type: mimeType});
};

export const replaceTexture = (object: THREE.Object3D, url: string, isTransparent: boolean, isTwoSided: boolean) => {
    if (object instanceof THREE.Mesh) {
        const texture = new THREE.TextureLoader().load(url);
        object.material = new THREE.MeshBasicMaterial({
            map: texture,
            side: isTwoSided ? THREE.DoubleSide : THREE.FrontSide,
            transparent: isTransparent,
        });
    }
};
