import styled from "styled-components";

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1px);
`;

export const Container = styled.div<{$fullScreen: boolean}>`
    width: ${({$fullScreen}) => ($fullScreen ? "100%" : "90%")};
    height: ${({$fullScreen}) => ($fullScreen ? "100%" : "90%")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    > div {
        width: 100%;
        height: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
        border-radius: ${({$fullScreen}) => ($fullScreen ? "0" : "24px")};
    }
`;

export const LoadingContainer = styled.div<{$fullScreen: boolean}>`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${({$fullScreen}) => ($fullScreen ? "0" : "24px")};
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
`;
