/* eslint-disable react/no-multi-comp */
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FileData} from "../types/file";
import {useOnClickOutside} from "usehooks-ts";
import sceneDefaultImage from "../icons/scene-default.png";
import defaultAssetIcon from "../icons/default-asset-icon.svg";
import plusIcon from "../icons/plus-icon.svg";
import deleteIcon from "../icons/delete-icon.svg";
import classNames from "classnames";
import "./css/AssetsList.css";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import {debounce} from "lodash";
import {StyledSoundImageWrapper} from "../LeftPanel/MainTabs/AssetsTab/AssetsTab.style";
import {SoundIcon} from "./SoundIcon";

type Props = {
    data: FileData[];
    selectedItemsIds?: string[];
    onClick: (id: string) => void;
    onDelete?: (id: string) => void;
    className?: string;
    maxHeight?: string;
    onEmptyAssetClick?: () => void;
    isScene?: boolean;
    isSound?: boolean;
    currentlyPlayingSoundId?: string | null;
    draggable?: boolean;
    onDragStart?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
};

const AssetsListItem = ({
    onClick,
    onDelete,
    selectedItemsIds,
    item,
    isScene,
    isSound,
    currentlyPlayingSoundId,
    draggable,
    onDragStart,
}: Partial<Props> & {item: FileData}) => {
    const isSelected = selectedItemsIds?.includes(item.ID);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsMenuOpen(false));

    const debouncedOnClick = useCallback(
        debounce((id: string) => {
            if (onClick) onClick(id);
        }, 200),
        [onClick],
    );

    useEffect(() => {
        return () => {
            debouncedOnClick.cancel();
        };
    }, [debouncedOnClick]);

    const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        onDelete && onDelete(item.ID);
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        if (draggable) {
            onDragStart && onDragStart(e, item.ID);
        }
    };

    const trimSceneName = (name: string) => {
        const allowedLength = 60;
        return name.length > allowedLength ? name.substring(0, allowedLength) + "..." : name;
    };

    return (
        <div
            className={classNames("assets-item", isSelected ? "selected" : "")}
            onClick={() => debouncedOnClick(item.ID)}
            draggable={!!draggable}
            onDragStart={handleDragStart}
            onContextMenu={onDelete ? () => setIsMenuOpen(true) : () => undefined}>
            {isSelected && <div className="select-border" />}
            {!isSound && !isScene && (
                <img
                    src={
                        item.Thumbnail
                            ? item.Thumbnail.includes("data:image") || item.Thumbnail.includes("src/editor")
                                ? item.Thumbnail
                                : backendUrlFromPath(item.Thumbnail)
                            : sceneDefaultImage
                    }
                    alt={item.Name}
                    className={isSelected ? "selected" : ""}
                />
            )}
            {isScene && (
                <div className="thumbnail-placeholder">
                    <img
                        src={
                            item.Thumbnail
                                ? item.Thumbnail.includes("data:image") || item.Thumbnail.includes("src/editor")
                                    ? item.Thumbnail
                                    : backendUrlFromPath(item.Thumbnail)
                                : defaultAssetIcon
                        }
                        alt={item.Name}
                        className={!item.Thumbnail ? "icon-thumbnail" : "image-thumbnail"}
                    />
                </div>
            )}
            {isSound && (
                <StyledSoundImageWrapper
                    className="sound-image-wrapper"
                    $isPlaying={item.ID === currentlyPlayingSoundId}>
                    <SoundIcon />
                </StyledSoundImageWrapper>
            )}

            <span className="assets-item-name">{trimSceneName(item.Name)}</span>
            {onDelete && !item?.IsAvatar && (
                <div className={classNames("delete-button")} onClick={handleDelete}>
                    <img src={deleteIcon} alt="delete" />
                </div>
            )}

            <div ref={ref} className={classNames("assets-item-menu", isMenuOpen ? "open" : "")}>
                <span onClick={handleDelete}>Delete</span>
            </div>
        </div>
    );
};

const EmptyAsset = ({onClick}: {onClick: () => void}) => {
    return (
        <div className="assets-item" onClick={onClick}>
            <div className="thumbnail-placeholder">
                <img src={plusIcon} alt="plus" />
            </div>

            <span className="assets-item-name">Default</span>
        </div>
    );
};

export const AssetsList = ({
    data,
    selectedItemsIds,
    onClick,
    onDelete,
    className = "",
    onEmptyAssetClick,
    isScene,
    isSound,
    currentlyPlayingSoundId,
    draggable,
    onDragStart,
}: Props) => {
    return (
        <div className={classNames("assets-list hidden-scroll", className)}>
            {onEmptyAssetClick && <EmptyAsset onClick={onEmptyAssetClick} />}
            {data.map(item => (
                <AssetsListItem
                    key={item.ID}
                    onClick={onClick}
                    onDelete={onDelete}
                    selectedItemsIds={selectedItemsIds}
                    item={item}
                    isScene={isScene}
                    isSound={isSound}
                    currentlyPlayingSoundId={currentlyPlayingSoundId}
                    draggable={draggable}
                    onDragStart={onDragStart}
                />
            ))}
        </div>
    );
};
