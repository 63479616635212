/*
 * Copyright 2017-2020 The ShadowEditor Authors. All rights reserved.
 *
 * Use of this source code is governed by a MIT-style
 * license that can be found in the LICENSE file.
 *
 * For more information, please visit: https://github.com/tengge1/ShadowEditor
 * You can also visit: https://gitee.com/tengge1/ShadowEditor
 */
import BaseLoader from "./BaseLoader";
import global from "../../../global";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {KTX2Loader} from "three/examples/jsm/loaders/KTX2Loader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {LoadingManager} from "three";
import {MeshoptDecoder} from "meshoptimizer";

/**
 * GLTFLoader
 *
 */
class _GLTFLoader extends BaseLoader {
    constructor() {
        super();
        this.loadingManager = new LoadingManager();
        this.loader = new GLTFLoader(this.loadingManager);
        this.setUpLoader();
    }

    setUpLoader() {
        const isDifferentExperience =
            process.env.DEPLOYMENT_TYPE === "ai16z" || process.env.DEPLOYMENT_TYPE === "metaverse";
        let dracoLoader = new DRACOLoader(this.loadingManager).setDecoderPath(
            `${isDifferentExperience ? `${process.env.REACT_APP_SERVER_HOST}` : ""}/assets/js/draco/gltf/`,
        );
        let ktxLoader = new KTX2Loader(this.loadingManager).setTranscoderPath(
            `${isDifferentExperience ? `${process.env.REACT_APP_SERVER_HOST}` : ""}/assets/js/basis/`,
        );
        dracoLoader.setDecoderConfig({type: "js"});
        this.loader.setCrossOrigin("anonymous");
        this.loader.setDRACOLoader(dracoLoader);
        this.loader.setKTX2Loader(ktxLoader.detectSupport(global.app.renderer));
        this.loader.setMeshoptDecoder(MeshoptDecoder);
    }

    load(url, options) {
        return new Promise(resolve => {
            this.loader.load(
                url,
                result => {
                    var obj3d = result.scene;

                    obj3d._obj = result;
                    obj3d._root = result.scene;

                    resolve(obj3d);
                },
                undefined,
                () => {
                    resolve(null);
                },
            );
        });
    }

    createScripts(name) {
        return (
            `var mesh = this.getObjectByName('${name}');\n\n` +
            `var obj = mesh._obj;\n\n` +
            `var root = mesh._root;\n\n` +
            `var mixer = new THREE.AnimationMixer(root);\n\n` +
            `mixer.clipAction(obj.animations[0]).play();\n\n` +
            `function update(clock, deltaTime) { \n    mixer.update(deltaTime); \n}`
        );
    }
}

export default _GLTFLoader;
