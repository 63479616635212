import React, {useEffect, useRef, useState} from "react";
import {
    BottomBar,
    ImageWrapper,
    StyledTextArea,
    SubmitButton,
    Title,
    Wrapper,
    Section,
    Row,
    RowTitle,
    RowBox,
} from "../AiImageGenerator.styles";
import {StyledButton} from "../../common/StyledButton";
import submitIcon from "../icons/submit.svg";
import {
    Asset,
    EDIT_TYPES,
    EditImageOptions,
    GenerateFillRequest,
    IMAGE_STYLES,
    IMAGE_TYPES,
    PixelateImageRequest,
    RemoveImageBackgroundRequest,
    UpscaleImageRequest,
} from "../AiImageGenerator.types";
import {BasicCombobox} from "../../common/BasicCombobox";
import {NumericInputRow} from "../../RightPanel/common/NumericInputRow";
import {PanelCheckbox} from "../../RightPanel/common/PanelCheckbox";
import {
    generateFill,
    pixelateImage,
    removeImageBackground,
    uploadImage,
    upscaleImage,
} from "../AiImageGenerator.service";
import {toast} from "react-toastify";
import {ResultAssets} from "../Components/ResultAssets";

type Props = {
    isOpen: boolean;
    assetToEdit: Asset | null;
    setAssetToEdit: (asset: Asset | null) => void;
    setLoadingAI: (loading: boolean) => void;
};
export const ImageEditor = ({isOpen, assetToEdit, setAssetToEdit, setLoadingAI}: Props) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [editType, setEditType] = useState<EDIT_TYPES>(EDIT_TYPES.REMOVE_BACKGROUND);
    const [assetIds, setAssetIds] = useState<string[]>([]);
    const [options, setOptions] = useState<EditImageOptions>({
        prompt: "",
        negativePrompt: "",
        assetId: assetToEdit?.id || "",
        pixelGridSize: 16,
        removeNoise: false,
        removeBackground: false,
        scalingFactor: 1,
        style: IMAGE_STYLES.STANDARD,
        imageType: IMAGE_TYPES.TEXTURE,
        mask: "",
    });

    const inputRef = useRef<HTMLInputElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const maskCanvasRef = useRef<HTMLCanvasElement>(null);
    const isDrawing = useRef(false);

    const editTypesOptions = Object.keys(EDIT_TYPES).map(key => ({
        key: key,
        value: EDIT_TYPES[key as keyof typeof EDIT_TYPES],
    }));

    const imageTypesOptions = Object.keys(IMAGE_TYPES).map(key => ({
        key: key,
        value: IMAGE_TYPES[key as keyof typeof IMAGE_TYPES],
    }));

    const imageStylesOptions = Object.keys(IMAGE_STYLES).map(key => ({
        key: key,
        value: IMAGE_STYLES[key as keyof typeof IMAGE_STYLES],
    }));

    const pixelGridSizes = [16, 32, 64, 128, 256].map(size => ({key: size.toString(), value: size.toString()}));

    const handleEditImage = async () => {
        setLoadingAI(true);
        setAssetIds([]);
        let optionsObj = {...options};
        if (imageSrc) {
            try {
                const response = await uploadImage(imageSrc, "uploaded image.png");
                if (response.asset) {
                    optionsObj.assetId = response.asset.id;
                } else {
                    toast.error("Error uploading image.");
                    setLoadingAI(false);
                    return;
                }
            } catch (error) {
                toast.error("Error uploading image.");
                setLoadingAI(false);
                return;
            }
        } else {
            optionsObj.assetId = assetToEdit?.id || "";
        }

        let promise;
        switch (editType) {
            case EDIT_TYPES.REPLACE_MASK_AREA:
                toast.error("Not implemented yet.");
                break;
            case EDIT_TYPES.PIXELATE:
                promise = pixelateImage(optionsObj as PixelateImageRequest);
                break;
            case EDIT_TYPES.UPSCALE:
                promise = upscaleImage(optionsObj as UpscaleImageRequest);
                break;
            case EDIT_TYPES.REMOVE_BACKGROUND:
                promise = removeImageBackground(optionsObj as RemoveImageBackgroundRequest);
                break;
        }

        if (promise) {
            try {
                const response = await promise;
                if (response && response.assetIds) {
                    setAssetIds(response.assetIds);
                }
            } catch (error) {
                toast.error("Error editing image.");
            } finally {
                setLoadingAI(false);
            }
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImageSrc(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
        isDrawing.current = true;
        draw(event);
    };

    const stopDrawing = () => {
        isDrawing.current = false;
        if (maskCanvasRef.current && canvasRef.current) {
            const maskCtx = maskCanvasRef.current.getContext("2d");
            const canvasCtx = canvasRef.current.getContext("2d");

            if (maskCtx && canvasCtx) {
                //onExport(canvasCtx.canvas.toDataURL(), maskCtx.canvas.toDataURL());
            }
        }
    };

    const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!isDrawing.current || !maskCanvasRef.current) return;

        const canvas = maskCanvasRef.current;
        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        ctx.fillStyle = "white";
        ctx.beginPath();
        ctx.arc(x, y, 10, 0, Math.PI * 2);
        ctx.fill();
    };

    const openPrompt = editType !== EDIT_TYPES.REMOVE_BACKGROUND && editType !== EDIT_TYPES.PIXELATE;

    useEffect(() => {
        if (assetToEdit) {
            setImageSrc(null);
        }
    }, [assetToEdit]);

    useEffect(() => {
        if (imageSrc) {
            setAssetToEdit(null);
        }
    }, [imageSrc]);

    if (!isOpen) {
        return null;
    }

    return (
        <Wrapper>
            {(imageSrc || assetToEdit) && (
                <>
                    {editType === EDIT_TYPES.REPLACE_MASK_AREA && <Title>Draw a mask on the image</Title>}
                    <ImageWrapper $image={imageSrc || assetToEdit?.url || ""}>
                        {editType === EDIT_TYPES.REPLACE_MASK_AREA && (
                            <>
                                <canvas
                                    ref={canvasRef}
                                    onMouseDown={startDrawing}
                                    onMouseMove={draw}
                                    onMouseUp={stopDrawing}
                                    width={574}
                                    height={400}
                                />

                                <canvas ref={maskCanvasRef} style={{pointerEvents: `none`}} width={574} height={400} />
                            </>
                        )}
                    </ImageWrapper>
                </>
            )}
            <StyledButton
                isGreySecondary
                width="100%"
                style={{height: "32px"}}
                onClick={() => inputRef.current?.click()}>
                {imageSrc || assetToEdit ? "Change Image" : "Upload Image"}
            </StyledButton>
            <input ref={inputRef} type="file" accept="image/*" onChange={handleImageUpload} style={{display: "none"}} />
            {(imageSrc || assetToEdit) && (
                <>
                    <Section>
                        <Title>What would you like to change?</Title>
                        <BasicCombobox
                            data={editTypesOptions}
                            value={editTypesOptions.find(item => item.value === editType)}
                            onChange={item => setEditType(item.value as EDIT_TYPES)}
                        />
                    </Section>
                    {openPrompt && (
                        <>
                            <Section>
                                <Title>Prompt</Title>
                                <StyledTextArea
                                    value={options.prompt}
                                    onChange={e => setOptions({...options, prompt: e.target.value})}
                                    placeholder=""
                                />
                            </Section>
                            <Section>
                                <Title>Negative Prompt</Title>
                                <StyledTextArea
                                    value={options.negativePrompt}
                                    onChange={e => setOptions({...options, negativePrompt: e.target.value})}
                                    placeholder=""
                                />
                            </Section>
                        </>
                    )}

                    {editType === EDIT_TYPES.PIXELATE && (
                        <>
                            <Title>Settings</Title>
                            <Row>
                                <RowTitle>Pixel Grid Size</RowTitle>
                                <RowBox>
                                    <BasicCombobox
                                        data={pixelGridSizes}
                                        value={pixelGridSizes.find(
                                            item =>
                                                Number(item.value) === (options as PixelateImageRequest).pixelGridSize,
                                        )}
                                        onChange={item => setOptions({...options, pixelGridSize: Number(item.value)})}
                                    />
                                </RowBox>
                            </Row>
                            <PanelCheckbox
                                v2
                                isGray
                                regular
                                text="Remove Noise"
                                checked={(options as PixelateImageRequest).removeNoise}
                                onChange={() =>
                                    setOptions({
                                        ...options,
                                        removeNoise: !(options as PixelateImageRequest).removeNoise,
                                    })
                                }
                            />
                            <PanelCheckbox
                                v2
                                isGray
                                regular
                                text="Remove Background"
                                checked={(options as PixelateImageRequest).removeBackground}
                                onChange={() =>
                                    setOptions({
                                        ...options,
                                        removeBackground: !(options as PixelateImageRequest).removeBackground,
                                    })
                                }
                            />
                        </>
                    )}
                    {editType === EDIT_TYPES.UPSCALE && (
                        <>
                            <Title>Settings</Title>
                            <NumericInputRow
                                label="Scaling Factor"
                                value={(options as UpscaleImageRequest).scalingFactor}
                                setValue={value => setOptions({...options, scalingFactor: value})}
                                $margin="0"
                                min={1}
                                max={16}
                            />
                            <Row>
                                <RowTitle>Image Style</RowTitle>
                                <RowBox>
                                    <BasicCombobox
                                        data={imageStylesOptions}
                                        value={imageStylesOptions.find(
                                            item => item.value === (options as UpscaleImageRequest).style,
                                        )}
                                        onChange={item => setOptions({...options, style: item.value as IMAGE_STYLES})}
                                    />
                                </RowBox>
                            </Row>
                            <Row>
                                <RowTitle>Image Type</RowTitle>
                                <RowBox>
                                    <BasicCombobox
                                        data={imageTypesOptions}
                                        value={imageTypesOptions.find(
                                            item => item.value === (options as UpscaleImageRequest).imageType,
                                        )}
                                        onChange={item =>
                                            setOptions({...options, imageType: item.value as IMAGE_TYPES})
                                        }
                                    />
                                </RowBox>
                            </Row>
                        </>
                    )}
                </>
            )}

            {assetIds && assetIds.length > 0 && (
                <Section>
                    <Title>Edit Result</Title>
                    <ResultAssets assetIds={assetIds} />
                </Section>
            )}
            <BottomBar>
                <SubmitButton onClick={handleEditImage} disabled={!(imageSrc || assetToEdit)}>
                    <img src={submitIcon} alt="Submit" />
                </SubmitButton>
            </BottomBar>
        </Wrapper>
    );
};

export default ImageEditor;
