import * as THREE from 'three';
import { DEVICE_TYPES, MOBILE_BUTTON_ACTION_STATES, MOBILE_BUTTON_ACTION_UI_STATES, MOBILE_JOYSTICK_CONTROL_UI } from '../types/editor';
import { DetectDevice } from '../utils/DetectDevice';
import { ActionTouchControls } from './ActionTouchControls';
import { JoyStickTouchControls } from './JoyStickTouchControls';

export class MobileTouchCharacterController {
    private character: THREE.Object3D;
    public mobileTouchControlsLoaded: boolean;
    private joystickControls: JoyStickTouchControls | null = null;
    private actionControls: ActionTouchControls | null = null;
    private deviceType = DetectDevice.getDeviceType();
  
    constructor(character: THREE.Object3D) {
        this.character = character;
        this.mobileTouchControlsLoaded = false;

    }

    public setCharacter(character: THREE.Object3D) {
        this.character = character;
    }

    public handleTouchControlBehavior(Character: THREE.Object3D): void {
        if (!this.mobileTouchControlsLoaded) {
            if (this.deviceType === DEVICE_TYPES.MOBILE) {
                this.loadJoyStickTouchControls();
                this.loadActionTouchControls();
                this.mobileTouchControlsLoaded = true;
                Character.userData.isOnMobileDevice = true;
            }
        }

        if (this.character !== Character) {
            this.character = Character;
            this.toggleCharacterTouchControlButtons(MOBILE_BUTTON_ACTION_UI_STATES.SHOW);
        }
    }

    public initializeTouchControlBehavior(Character: THREE.Object3D) {
        this.handleTouchControlBehavior(Character);

        window.addEventListener("resize", () => {
            this.handleTouchControlBehavior(Character);
        });
    }

    private loadJoyStickTouchControls(): void {
        if (!this.joystickControls) {
            this.joystickControls = new JoyStickTouchControls(this.character);
        }
    }

    private loadActionTouchControls(): void {
        if (!this.actionControls) {
            this.actionControls = new ActionTouchControls(this.character);
        }
    }

    private toggleCharacterTouchControlButtons(action: MOBILE_BUTTON_ACTION_UI_STATES.SHOW | MOBILE_BUTTON_ACTION_UI_STATES.HIDE) {
        const mobileJoystickControl = document.getElementById(MOBILE_JOYSTICK_CONTROL_UI.MOBILE_JOYSTICK_CONTROL_NAME) as HTMLElement;
        const mobileButtonsActionContaierClass = document.querySelector(MOBILE_BUTTON_ACTION_UI_STATES.MOBILE_BUTTON_ACTION_CONTAINER_CLASS_NAME) as HTMLElement;

        if (action === MOBILE_BUTTON_ACTION_UI_STATES.SHOW) {
            if (mobileJoystickControl) mobileJoystickControl.style.display = 'block';
            if (mobileButtonsActionContaierClass) mobileButtonsActionContaierClass.style.display = 'block';
        } else if (action === MOBILE_BUTTON_ACTION_UI_STATES.HIDE) {
            if (mobileJoystickControl) mobileJoystickControl.style.display = 'none';
            if (mobileButtonsActionContaierClass) mobileButtonsActionContaierClass.style.display = 'none';
        }
    }

}
