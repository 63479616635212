import * as THREE from "three";
import {Object3D} from "three";
import GameManager from "../../behaviors/game/GameManager";
import {IPhysics} from "src/physics/common/types";
import global from "../../global";
import {COLLISION_TYPE, OBJECT_TYPES, INVENTORY_UI_CONTAINERS} from "../../types/editor";
import EventBus from "../../behaviors/event/EventBus";

class WeaponAmmoBehaviorUpdater {
    target: Object3D;
    removed = false;
    gunAimerImageBase64: string | null = null;
    game?: GameManager;
    usingPhysics = false;
    isDynamic = false;
    physics?: IPhysics;
    isDebug: boolean;
    remainingAmmo: number | null = null;
    ammoCollisionListenerId: string | undefined;

    private totalAmmoCount: number = 0;
    private countedAmmo: Set<Object3D> = new Set();
    private selectedWeapon: Object3D | undefined;

    constructor(target: Object3D) {
        this.target = target;
        this.isDebug = (global.app as any)?.storage?.debug;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.physics = this.game?.behaviorManager?.collisionDetector.physics;
        this.addCollisionListener();

        this.selectedWeapon = this.game?.scene?.children.find((object: Object3D) => object.userData?.isCurrentWeapon);

        if (this.selectedWeapon) {
            const weaponBehavior = this.selectedWeapon.userData.behaviors?.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
            );
            if (weaponBehavior) {
                this.totalAmmoCount = weaponBehavior.weaponClipAmount || 10;
            }
        }

        this.remainingAmmo = this.totalAmmoCount;
        this.game!.scene!.userData.ammoCount = this.totalAmmoCount;
    }

    addCollisionListener() {
        this.ammoCollisionListenerId = this.game!.behaviorManager?.collisionDetector.addListener(
            this.target,
            {
                type: COLLISION_TYPE.WITH_PLAYER,
                callback: this.onCollisionWithPlayer.bind(this),
                useBoundingBoxes: false,
                distanceThreshold: 1,
            },
            this.isDynamic,
        );
    }

    onCollisionWithPlayer() {
        if (!this.game || !this.game.player || !this.game.scene) return;

        const weaponAmmoBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON_AMMO,
        );

        if (weaponAmmoBehavior) {
            EventBus.instance.send("game.lives.dec", Number(weaponAmmoBehavior.ammoDamage));
        }
    }

    update(clock: THREE.Clock, delta: number): void {
        this.checkFiredAmmo();
    }

    reset() {
        if (this.selectedWeapon) {
            const weaponBehavior = this.selectedWeapon.userData.behaviors?.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.WEAPON,
            );
            if (weaponBehavior) {
                this.totalAmmoCount = weaponBehavior.weaponClipAmount || 100;
            }
        }
        this.remainingAmmo = this.totalAmmoCount;
        this.countedAmmo.clear();
    }

    private updateAmmoCount() {
        const ammoCountElement = document.getElementById(INVENTORY_UI_CONTAINERS.AMMO_COUNT);
        if (!ammoCountElement) return;

        ammoCountElement.innerText = this.remainingAmmo!.toString();
        this.game!.scene!.userData.ammoCount = this.remainingAmmo!;
    }

    private checkFiredAmmo() {
        const firedAmmoObjects = this.game?.scene?.children.filter(object => object.userData?.firedAmmo === true);

        if (firedAmmoObjects && firedAmmoObjects.length > 0) {
            this.remainingAmmo = Math.max(0, this.remainingAmmo! - firedAmmoObjects.length);
            this.updateAmmoCount();

            firedAmmoObjects.forEach(object => {
                object.userData.firedAmmo = false;
            });
        }

        if (this.remainingAmmo! <= 0) {
            this.remainingAmmo = this.totalAmmoCount;
            this.updateAmmoCount();
        }
    }

}

export default WeaponAmmoBehaviorUpdater;
