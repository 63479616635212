import {useEffect, useRef, useState} from "react";

import {NumericInput} from "../common/NumericInput";
import magicAI from "../icons/magic-ai.svg";
import plusIcon from "../icons/plus-bold.svg";
import playIcon from "../icons/play.svg";
import search from "../icons/search.svg";
import moveIcon from "./icons/move.svg";
import rotateIcon from "./icons/rotate.svg";
import scaleIcon from "./icons/scale.svg";

import global from "../../../../global";
import Application from "../../../../Application";
import {grantMiddleware} from "../../../../api/grantMiddleware";
import {useAuthorizationContext} from "../../../../context";
import {IFRAME_MESSAGES} from "../../../../types/editor";

import {isInputActive} from "../utils/isInputActive";
import {GameIframe} from "../GameIframe/GameIframe";
import {PlayButton} from "../common/PlayButton/PlayButton.style";
import {ActionButton, Container, InputWrapper, Separator} from "./ActionBar.style";
import {StyledButton} from "../common/StyledButton";
import {toast} from "react-toastify";

interface Props {
    handleZoomChange: (value: number) => void;
    handleAssistanOpen: () => void;
    playerStarted: boolean;
    handleOpenAiImageGenerator: () => void;
}

export const ActionBar = ({handleZoomChange, playerStarted, handleAssistanOpen, handleOpenAiImageGenerator}: Props) => {
    const [zoomValue, setZoomValue] = useState(100);
    const [mode, setMode] = useState("translate");
    const [isPlaying, setIsPlaying] = useState(playerStarted);

    const app = global.app as Application;
    const editor = app?.editor;
    let playcoinsIntervalIdRef = useRef<null | NodeJS.Timeout>(null);
    const {saveUser, dbUser} = useAuthorizationContext();

    const dbUserRef = useRef(dbUser);

    useEffect(() => {
        dbUserRef.current = dbUser;
    }, [dbUser]);

    useEffect(() => {
        handleZoomChange(zoomValue);
    }, [zoomValue]);

    const handlePlaycoinsInGame = () => {
        if (!dbUserRef.current) return;
        playcoinsIntervalIdRef.current = setInterval(() => {
            const updatedUser = grantMiddleware(dbUserRef.current!);
            updatedUser && saveUser(updatedUser);
        }, 10000);
    };

    const clearPlaycoinsInGameInterval = () => {
        if (!playcoinsIntervalIdRef.current) return;
        clearInterval(playcoinsIntervalIdRef.current);
    };

    useEffect(() => {
        const handleMessage = (event: any) => {
            const message = event.data;
            if (message === IFRAME_MESSAGES.GAME_CLOSED) {
                handleGameClose();
            } else if (message === IFRAME_MESSAGES.GAME_STARTED || message === IFRAME_MESSAGES.GAME_RESUMED) {
                handlePlaycoinsInGame();
            } else if (message === IFRAME_MESSAGES.GAME_PAUSED || message === IFRAME_MESSAGES.GAME_ENDED) {
                clearPlaycoinsInGameInterval();
            } else if (message === IFRAME_MESSAGES.GAME_PLAYER_ERROR) {
                handleGameClose();
                toast.error("Failed to find player object. Check your settings");
            } else if (message === IFRAME_MESSAGES.GAME_MULTIPLAYER_ERROR) {
                handleGameClose();
                toast.error("Multiplayer server failed.");
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleGameClose = () => {
        setIsPlaying(false);
        clearPlaycoinsInGameInterval();
    };

    // useEffect(() => {
    //     const calculateZoomPercentage = () => {
    //         const minZoom = 0.1;
    //         const maxZoom = 1;

    //         const percentage =
    //             ((currentZoom - minZoom) / (maxZoom - minZoom)) * 100;

    //         return Math.round(percentage);
    //     };

    //     setZoomValue(calculateZoomPercentage());
    // }, [currentZoom]);

    const handleChangeMode = (mode: string) => {
        setMode(mode);
        app?.call("changeMode", app.editor, mode);
    };

    const handlePlay = (e: any) => {
        e.preventDefault();
        if (isPlaying || !e.clientX || !app || !app.editor) {
            return;
        }
        setIsPlaying(true);
    };

    useEffect(() => {
        if (app?.editor?.transformControls?.mode) {
            setMode(app.editor.transformControls.mode);
        }
    }, []);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (isInputActive()) return;
            switch (event.key) {
                case "1":
                    handleChangeMode("translate");
                    break;
                case "2":
                    handleChangeMode("rotate");
                    break;
                case "3":
                    handleChangeMode("scale");
                    break;
                default:
                    break;
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    return (
        <Container>
            <ActionButton $isBlue disabled>
                <img src={plusIcon} alt="assets library" />
            </ActionButton>
            <Separator />
            <ActionButton $isSelected={mode === "translate"} onClick={() => handleChangeMode("translate")}>
                <img src={moveIcon} alt="translate" />
            </ActionButton>
            <ActionButton $isSelected={mode === "rotate"} onClick={() => handleChangeMode("rotate")}>
                <img src={rotateIcon} alt="rotate" />
            </ActionButton>
            <ActionButton $isSelected={mode === "scale"} onClick={() => handleChangeMode("scale")}>
                <img src={scaleIcon} alt="scale" />
            </ActionButton>
            <InputWrapper>
                <img src={search} alt="zoom" className="zoomIcon" />
                <NumericInput className="zoomInput" value={zoomValue} setValue={value => setZoomValue(value)} />
                <div className="percentage">%</div>
            </InputWrapper>
            <Separator />

            <StyledButton width="62px" isActive onClick={() => editor?.handleExportSceneToJson()}>
                Export
            </StyledButton>
            <PlayButton className="reset-css" onClick={handlePlay}>
                <img src={playIcon} alt="play" />
            </PlayButton>
            <ActionButton $isBlue onClick={handleAssistanOpen}>
                <img src={magicAI} alt="magic AI" />
            </ActionButton>
            <ActionButton $isBlue onClick={handleOpenAiImageGenerator}>
                <img src={magicAI} alt="magic AI" />
            </ActionButton>
            {isPlaying && <GameIframe />}
        </Container>
    );
};
