import {JumpPadBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import JumpPadBehaviorConverter from "../../../../../serialization/behaviours/JumpPadBehaviorConverter";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {NumericInputRow} from "../common/NumericInputRow";
import {useState} from "react";

type Props = {
    behavior: JumpPadBehaviorInterface;
};

export const JumpPadBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);
    const targetBehavior = JumpPadBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };
    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                v2
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator invisible margin="8px 0" />
            <NumericInputRow
                width="75px"
                label="Strength"
                value={behavior.jumpPadStrength}
                setValue={value => handleInputChange(value, "jumpPadStrength")}
            />
            <NumericInputRow
                width="75px"
                label="Angle"
                value={behavior.jumpPadAngle}
                setValue={value => handleInputChange(value, "jumpPadAngle")}
            />
        </>
    );
};
