/* eslint-disable react/no-multi-comp */
import {useEffect, useState} from "react";
import {IfConditionInterface, OBJECT_TYPES, TriggerBehaviorInterface} from "../../../../../../types/editor";
import global from "../../../../../../global";
import {Separator} from "../../common/Separator";
import {PanelCheckbox} from "../../common/PanelCheckbox";
import {Label} from "../../RightPanel.style";
import TriggerBehaviorConverter from "../../../../../../serialization/behaviours/TriggerBehaviorConverter";
import {NumericInputRow} from "../../common/NumericInputRow";
import {IfStatement} from "./IfStatement";
import {CommonFields} from "./CommonFields";
import deleteIcon from "../../tabs/ObjectBehaviors/icons/delete.svg";

type Props = {
    behavior: TriggerBehaviorInterface;
};

export interface IOptions {
    key: string;
    value: string;
    uuid?: string;
}
export type InputChangeType = (value: any, name: keyof TriggerBehaviorInterface) => void;

export const TriggerBehaviors = ({behavior}: Props) => {
    const app = global.app;
    const editor = app?.editor;
    const selected = editor?.selected;
    const [objectOptions, setObjectOptions] = useState<IOptions[]>([{key: "0", value: "none"}]);
    const [currentBehavior, setCurrentBehavior] = useState<TriggerBehaviorInterface>({
        ...behavior,
        if_condition: behavior.if_condition || [],
    });
    const [objectsAvailable, setObjectsAvailable] = useState<any[]>([]);
    const targetBehavior = TriggerBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) || behavior;

    const handleInputChange: InputChangeType = (value, name) => {
        if (!app || !selected) return;
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setCurrentBehavior({...behavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleIfChange = (value: IfConditionInterface, newIf: boolean) => {
        if (!app || !selected) return;

        let newValue: IfConditionInterface[] = [];

        if (selected && targetBehavior) {
            const ifConditionArray = currentBehavior.if_condition || [];
            newValue = newIf
                ? [...ifConditionArray, value]
                : ifConditionArray.map(item => (item.id === value.id ? value : item));

            targetBehavior["if_condition"] = newValue;
            setCurrentBehavior({...behavior, if_condition: newValue});

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleDeleteIf = (id: string) => {
        if (!app || !selected) return;
        const ifConditionArray = currentBehavior.if_condition || [];
        const newValue = ifConditionArray.filter(el => el.id !== id);

        targetBehavior["if_condition"] = newValue;
        setCurrentBehavior({...behavior, if_condition: newValue});

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    const handleObjectTriggerChange = (isElseObject: boolean, behaviorType: OBJECT_TYPES) => {
        if (
            !app ||
            !selected ||
            !targetBehavior ||
            (!targetBehavior.then_behaviors_on_trigger && !isElseObject) ||
            (!targetBehavior.else_behaviors_on_trigger && isElseObject)
        )
            return;

        const targetKey = isElseObject ? "else_behaviors_on_trigger" : "then_behaviors_on_trigger";
        const updatedArray = targetBehavior[targetKey].map(behavior => {
            const key = Object.keys(behavior)[0] as OBJECT_TYPES;
            // @ts-ignore
            const newObj: {key: OBJECT_TYPES; value: boolean} = {[key]: !behavior[key]};
            return key === behaviorType ? newObj : behavior;
        });

        targetBehavior[targetKey] = updatedArray;
        setCurrentBehavior({...behavior, [targetKey]: updatedArray});

        app.call(`objectChanged`, app.editor, app.editor.selected);
        app.call(`objectUpdated`, app.editor, app.editor.selected);
    };

    useEffect(() => {
        const getObjects = () => {
            const options: IOptions[] = [{key: "0", value: "none"}];
            const objects: any[] = [];
            const scene = editor?.scene;

            const collectObjects = (object: any) => {
                object.children.forEach((child: any) => {
                    if (child.name && child.isObject3D) {
                        options.push({key: `${objects.length + 1}`, value: child.name, uuid: child.uuid});
                        objects.push(child);
                    }
                    if (child.type === "Group") {
                        collectObjects(child);
                    }
                });
            };

            collectObjects(scene);

            setObjectsAvailable(objects);
            setObjectOptions(options);
        };

        getObjects();
    }, [editor?.scene]); // Re-run when scene is updated

    return (
        <>
            <NumericInputRow
                value={currentBehavior.delay || 0}
                setValue={value => handleInputChange(value, "delay")}
                label="Trigger Delay"
                unit="s"
                padding="6px 16px 6px 14px"
                min={0}
            />
            {(currentBehavior.if_condition || []).map((el, index) => (
                <div key={el.id}>
                    <Separator invisible margin="8px 0 0" />
                    <Label $regular $withIcon>
                        If:
                        {index !== 0 && <img src={deleteIcon} className="icon" onClick={() => handleDeleteIf(el.id)} />}
                    </Label>
                    <Separator invisible margin="16px 0" />
                    <IfStatement
                        handleIfChange={handleIfChange}
                        ifObject={el}
                        key={el.id}
                        objectOptions={objectOptions}
                    />
                </div>
            ))}
            <Separator invisible margin="12px 0 0" />
            <Label $regular>Then:</Label>
            <CommonFields
                objectOptions={objectOptions}
                handleInputChange={handleInputChange}
                behavior={currentBehavior}
                objectsAvailable={objectsAvailable}
                handleObjectTriggerChange={handleObjectTriggerChange}
            />
            <PanelCheckbox
                text="Else:"
                white
                checked={!!currentBehavior.else_condition}
                onChange={() => handleInputChange(!behavior.else_condition, "else_condition")}
                v2
            />
            <Separator margin="12px 0 0" invisible />
            {currentBehavior.else_condition && (
                <CommonFields
                    objectOptions={objectOptions}
                    handleInputChange={handleInputChange}
                    behavior={currentBehavior}
                    elseSelected
                    objectsAvailable={objectsAvailable}
                    handleObjectTriggerChange={handleObjectTriggerChange}
                />
            )}
        </>
    );
};
