import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import SpriteBehaviorUpdater from "./SpriteBehaviorUpdater";
import { SPRITE_TYPES, SpriteBehaviorInterface, OBJECT_TYPES } from "../../types/editor";

class SpriteBehaviorConverter extends BaseBehaviorConverter<SpriteBehaviorInterface> {
    public static DEFAULT = new SpriteBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: SpriteBehaviorInterface): BehaviorUpdater {
        return new SpriteBehaviorUpdater(target, behavior);
    }

    clearBehaviorScripts(): void {
        this.obj.scripts = this.obj.scripts.filter((script: any) => !script.isBehaviorScript);
    }

    reset(): void {
        
    }

    getBehavior(target: THREE.Object3D, id: string): SpriteBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as SpriteBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have SCRIPT behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): SpriteBehaviorInterface {
        return {
            type: OBJECT_TYPES.SPRITE,
            startOnTrigger: false,
            enabled: true,
            playOnStart: false,
            loop: false,
            currentAnimation: '',
            animations: [],
            spriteType: SPRITE_TYPES.TWO_D,
            id: id,
            uiImage: '',
            frameRows: 0,
            frameColumns: 0,
            frameRate: 20,
            frameLeftStanding: 0,
            frameRightStanding: 0,
            frameForwardStanding: 0,
            frameBackwardStanding: 0,
            moveForwardStart: 0,
            moveForwardEnd: 0,
            moveBackwardStart: 0,
            moveBackwardEnd: 0,
            moveLeftStart: 0,
            moveLeftEnd: 0,
            moveRightStart: 0,
            moveRightEnd: 0,
            moveBackwarStart: 0,
        };
    }

}

export default SpriteBehaviorConverter;

