import styled from "styled-components";
import gamePlaceholder from "../../../assets/game-placeholder.svg";
import {flexCenter} from "../../../../assets/style";

export const HeroSection = styled.section`
    height: 80vh;
    ${flexCenter};
    column-gap: 110px;
    position: relative;
    z-index: 3;

    @media only screen and (max-width: 1279px) {
        column-gap: 40px;
    }

    @media only screen and (max-width: 1023px) {
        flex-direction: column;
        min-height: 65vh;
    }
`;

export const H1 = styled.h1`
    font-family: Inter;
    font-size: 64px;
    font-weight: 700;
    line-height: 121%;
    text-align: left;
    color: #fff;
    width: auto;
    max-width: 496px;

    @media only screen and (max-width: 1279px) {
        font-size: 54px;
    }

    @media only screen and (max-width: 1023px) {
        text-align: center;
    }

    @media only screen and (max-width: 767px) {
        font-size: 48px;
    }
`;

export const HeroGame = styled.div`
    background-image: url("${gamePlaceholder}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 676px;
    aspect-ratio: 676 / 380;
    border-radius: 16px;

    @media only screen and (max-width: 1439px) {
        width: 600px;
    }

    @media only screen and (max-width: 1279px) {
        width: 550px;
    }

    @media only screen and (max-width: 767px) {
        max-width: 90%;
        width: 100%;
    }
`;
