import React, {useEffect, useState} from "react";
import {
    FONT_FAMILIES,
    GAME_HUD_IDS,
    IBannerInterface,
    IComponentInterface,
    IItemButtonInterface,
    IMiniMapInterface,
} from "../editor/assets/v2/HUD/HUDEditView/types";
import global from "../global";

export type GameDataType = {
    [key in GAME_HUD_IDS]?:
        | IBannerInterface
        | IComponentInterface
        | IMiniMapInterface
        | IItemButtonInterface
        | null
        | string
        | number;
};

type HUDGameContextValue = {
    gameLayout: GameDataType | undefined;
    setGameLayout: React.Dispatch<React.SetStateAction<GameDataType | undefined>>;
    isReady: boolean;
    useInitialSetup: () => void;
};

export const HUDGameContext = React.createContext<HUDGameContextValue>(null!);

export interface HUDGameContextProviderProps {
    children: React.ReactNode;
    isReady: boolean;
}

const placeholder: GameDataType = {
    "game-hud-banner": {
        UITag: "You Lose",
        extraUITags: ["Death"],
        fontFamily: FONT_FAMILIES.ROBOTO,
        fontSize: 52,
        fontColor: "#FFF",
    },
};

const HUDGameContextProvider: React.FC<HUDGameContextProviderProps> = ({children, isReady}) => {
    const [gameLayout, setGameLayout] = useState<GameDataType>();
    const app = (global as any).app;
    const sceneId = app?.editor?.scene.uuid;

    useEffect(() => {
        if (app?.editor && isReady && sceneId) {
            setGameLayout(app?.editor.scene.userData?.gameUI?.gameHUD);
            const gameHUD = app?.editor.scene.userData?.gameUI?.gameHUD;

            if (!gameHUD || Object.keys(gameHUD).length === 0) {
                // const logo = drawLogoFromSceneName();
                // const newLogo = logo || logoExample;
                setGameLayout({
                    ...placeholder,
                    // [START_MENU_IDS.LOGO_LEFT]: newLogo,
                });
                if (app?.editor.scene.userData) {
                    app.editor.scene.userData.gameUI = {
                        ...app?.editor.scene.userData.gameUI,
                        gameHUD: {
                            ...placeholder,
                            // [START_MENU_IDS.LOGO_LEFT]: newLogo,
                        },
                    };
                    app.editor.scene.userData.isStartGameMenuDefaultBanner = true;
                }
            } else {
                setGameLayout(app?.editor.scene.userData?.gameUI?.gameHUD);
            }
        }
    }, [isReady, sceneId]);

    const useInitialSetup = () => {
        if (app?.editor && isReady && sceneId && !gameLayout) {
            setGameLayout(app?.editor.scene.userData?.gameUI?.gameHUD);
        }
    };

    return (
        <HUDGameContext.Provider
            value={{
                gameLayout,
                setGameLayout,
                isReady,
                useInitialSetup,
            }}>
            {children}
        </HUDGameContext.Provider>
    );
};

export default HUDGameContextProvider;
