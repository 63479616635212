import {useState, useEffect} from "react";
import {MOBILE_TOUCH_CONTROL_TYPES, MobileTouchControlBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {Heading} from "../common/Heading";
import {SelectRow} from "../common/SelectRow";
import MobileTouchControlBehaviorConverter from "../../../../../serialization/behaviours/MobileTouchControlBehaviorConverter";

type Props = {
    behavior: MobileTouchControlBehaviorInterface;
};

export const MobileTouchBehavior = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = selected
        ? MobileTouchControlBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id)
        : null;

    useEffect(() => {
        if (!targetBehavior) {
            console.warn("Target behavior not found for selected object.");
        }
    }, [targetBehavior]);

    const handleInputChange = (value: string | number | boolean, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior(prev => ({...prev, [name]: value}));
            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    const mobileTouchControlOptions = Object.keys(MOBILE_TOUCH_CONTROL_TYPES).map(key => ({
        key,
        value: MOBILE_TOUCH_CONTROL_TYPES[key as keyof typeof MOBILE_TOUCH_CONTROL_TYPES],
    }));

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
                v2
            />
            <Separator margin="8px 0" invisible />
            <Heading>Select Mobile Controller</Heading>
            <Separator margin="8px 0 0" invisible />
            <SelectRow
                $margin="0 0 8px 0"
                label="Control Type"
                data={mobileTouchControlOptions}
                value={
                    mobileTouchControlOptions.find(item => item.value === behavior.mobileTouchControlType) || {
                        key: "0",
                        value: "none",
                    }
                }
                onChange={item => handleInputChange(item.value, "mobileTouchControlType")}
            />
            <Separator />
        </>
    );
};
