import {useState, useEffect} from "react";
import {NPCReceiverBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {Heading} from "../common/Heading";
import {SelectRow} from "../common/SelectRow";
import NPCReceiverBehaviorConverter from "../../../../../serialization/behaviours/NPCReceiverBehaviorConverter";

type Props = {
    behavior: NPCReceiverBehaviorInterface;
};

export const NPCReceiverBehavior = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);

    const targetBehavior = selected ? NPCReceiverBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id) : null;

    useEffect(() => {
        if (!targetBehavior) {
            console.warn("Target behavior not found for selected object.");
        }
    }, [targetBehavior]);

    const handleInputChange = (value: string | number | boolean, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior(prev => ({...prev, [name]: value}));
            app.call(`objectChanged`, app.editor, app.editor.selected);
        }
    };

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger trest"
                isGray
                regular
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
                v2
            />
            <Separator margin="8px 0" invisible />
        </>
    );
};
