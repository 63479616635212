import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {generateProjectLink} from "../../../../v2/pages/services";
import {getThumbnail} from "../../../../services";
import {useAppGlobalContext, useAuthorizationContext} from "../../../../context";
import {getOneUserGame} from "../../../../api/getGames";
import {IBasicGameInterface} from "../../../../v2/pages/types";

import {H2} from "../../../common";
import {ActionButton, Details, GameImage, Wrapper} from "./Hero.style";

export const ExploreHeroSection = () => {
    const navigate = useNavigate();
    const {setProjectPhase} = useAppGlobalContext();
    const {dbUser} = useAuthorizationContext();

    const [userGame, setUserGame] = useState<IBasicGameInterface>();

    const handleCreateEmptyScene = () => {
        navigate(generateProjectLink(undefined, "metaverse"));
        setProjectPhase(2);
    };

    const handleEditGame = () => {
        if (!userGame) return console.error("Cannot edit this game. No ID provided");
        navigate(generateProjectLink(userGame.ID, "metaverse"));
        setProjectPhase(2);
    };

    useEffect(() => {
        const fetchGame = async () => {
            if (!dbUser) return;
            const game = await getOneUserGame(dbUser.id, "metaverse");
            setUserGame(game);
        };

        fetchGame();
    }, [dbUser]);

    return (
        <Wrapper>
            <GameImage $bgImage={getThumbnail(userGame?.Thumbnail)} />
            <Details>
                <h3 className="heading">{!!userGame ? "Your Experience" : "Welcome to Erth"}</h3>
                <H2 className="gameTitle">{userGame?.Name || "Create your Wrold"}</H2>
                <ActionButton onClick={() => (userGame ? handleEditGame() : handleCreateEmptyScene())}>
                    {!!userGame ? "Edit" : "Create"} your world
                </ActionButton>
            </Details>
        </Wrapper>
    );
};
