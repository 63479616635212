import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import {RandomizeSpawnerBehaviorInterface, OBJECT_TYPES} from "../../types/editor";
import RandomizeSpawnerBehaviorUpdater from "./RandomizeSpawnerBehaviorUpdater";

class RandomizeSpawnerBehaviorConverter extends BaseBehaviorConverter<RandomizeSpawnerBehaviorInterface> {
    public static DEFAULT = new RandomizeSpawnerBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: RandomizeSpawnerBehaviorInterface): BehaviorUpdater {
        return new RandomizeSpawnerBehaviorUpdater(target, behavior);
    }

    getBehavior(target: THREE.Object3D, id: string): RandomizeSpawnerBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as RandomizeSpawnerBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have RandomizeSpawner behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): RandomizeSpawnerBehaviorInterface {
        return {
            enabled: true,
            startOnTrigger: false,
            id,
            type: OBJECT_TYPES.RANDOMIZE_SPAWNER,
            oblectList: [generateEmptyRandomObject()],
        };
    }
}

export default RandomizeSpawnerBehaviorConverter;

export const generateEmptyRandomObject = (probability?: number) => {
    return {
        id: THREE.MathUtils.generateUUID(),
        probability: probability !== undefined ? probability : 100,
        objectUUID: "",
    };
};
