import {useState, useEffect} from "react";
import {SPAWNPOINT_TYPES, SpawnPointBehaviorInterface} from "../../../../../types/editor";
import global from "../../../../../global";
import SpawnPointBehaviorConverter from "../../../../../serialization/behaviours/SpawnPointBehaviorConverter";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {Separator} from "../common/Separator";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {StyledButton} from "../../common/StyledButton";
import {SelectRow} from "../common/SelectRow";

type Props = {
    behavior: SpawnPointBehaviorInterface;
};

interface Item {
    value: string;
    label: string;
}

export const SpawnPointBehaviors = ({behavior: initialBehavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behavior, setBehavior] = useState(initialBehavior);
    const [sceneObjects, setSceneObjects] = useState<string[]>([]);

    const targetBehavior = SpawnPointBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    useEffect(() => {
        const scene = editor?.scene;
        const objects: any = [];
        const collectObjects = (object: any) => {
            object.children.forEach((child: any) => {
                if (child.name && child.isObject3D) {
                    objects.push(child.name);
                }
                if (child.type === "Group") {
                    collectObjects(child);
                }
            });
        };

        collectObjects(scene);
        setSceneObjects(objects);
    }, [editor.scene.children]);

    const handleSpawTypeChange = (type: SPAWNPOINT_TYPES) => {
        if (selected && targetBehavior) {
            targetBehavior.spawnType = type;
            setBehavior({...targetBehavior, spawnType: type});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleInputChange = (value: any, name: string) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehavior({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const objectOptions: Item[] = sceneObjects.map(name => ({value: name, label: name}));

    return (
        <>
            <PanelCheckbox
                text="Start on Trigger"
                isGray
                regular
                v2
                checked={!!behavior.startOnTrigger}
                onChange={() => handleInputChange(!behavior.startOnTrigger, "startOnTrigger")}
            />
            <Separator invisible margin="8px 0" />
            <SelectRow
                label="Spawn Object"
                value={objectOptions.find(el => el.value === behavior.objectNameToSpawn)}
                onChange={item => handleInputChange(item.value, "objectNameToSpawn")}
                data={objectOptions}
            />
            <SelectionOfButtons>
                {Object.keys(SPAWNPOINT_TYPES).map(key => {
                    const type = SPAWNPOINT_TYPES[key as unknown as keyof typeof SPAWNPOINT_TYPES];
                    return (
                        <StyledButton
                            width="calc(50% - 3px)"
                            isBlue={behavior.spawnType === type}
                            isActive={behavior.spawnType !== type}
                            onClick={() => handleSpawTypeChange(type)}
                            key={key}>
                            <span>{type}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator />
        </>
    );
};
