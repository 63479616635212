import styled from "styled-components";
import {flexCenter, regularFont} from "../../../../../assets/style";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: calc(100vh - 64px);
    overflow-y: auto;
    padding: 24px;

    .box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        max-width: 720px;
        margin: 0 auto;
        flex-shrink: 0;
    }
`;

export const Heading = styled.div`
    width: 100%;
    margin-bottom: 4px;
    ${regularFont("s")}
    font-weight: var(--theme-font-bold);
`;

export const AccountBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    label {
        ${regularFont("xs")};
        color: var(--theme-font-disabled);
    }
`;

export const DeleteBox = styled.div`
    margin-bottom: 24px !important;
`;

export const LogoutBox = styled.div`
    margin-bottom: 24px !important;
    margin-top: 24px !important;
`;

export const LegalBox = styled.div`
    padding-top: 44px;
    border-top: 1px solid var(--theme-grey-bg);
    border-radius: 0;
    height: 151px;
    img {
        margin-left: 4px;
    }

    div {
        display: flex;
        gap: 15px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    .icon {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
    }
    .saveButton {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const ValidationInput = styled.input<{$valueCorrect?: boolean}>`
    border: none;
    ${({$valueCorrect}) => $valueCorrect && "border: 2px solid #34D399;"};
    ${({$valueCorrect}) => $valueCorrect === false && "border: 2px solid #FB7185;"};

    color: var(--theme-font-unselected-secondary-color);
    outline: none;
    height: 40px;
    border-radius: 8px;
    width: 100%;
    padding: 8px 12px;
    background-color: var(--theme-grey-bg);
    font-size: 14px;
`;

export const LicensesBox = styled.div`
    height: 198px;
`;

export const LinkButton = styled.div`
    border-top: 1px solid #52525b;
    background: var(--theme-grey-bg-secondary);
`;

export const DeleteAccount = styled.button`
    width: 157px;
    height: 32px;
    padding: 8px 24px;
    border-radius: 8px;
    border: none;
    background: none;
    border-top: 1px solid #f43f5f;
    background: #e11d48;
    margin-top: auto;
    ${regularFont("xxs")};
    font-weight: var(--theme-font-medium);
    ${flexCenter};
    column-gap: 4px;
    cursor: pointer;
`;

export const ErrorMessage = styled.div`
    ${regularFont("xxs")};
    color: var(--theme-font-unselected-secondary-color);
    position: absolute;
    bottom: -4px;
    transform: translateY(100%);
`;

export const BackButton = styled.button`
    width: 142px;
    height: 40px;
    border-radius: 8px;
    background: var(--theme-grey-bg) !important;
    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #fafafa;
`;
