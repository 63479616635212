interface BasicInterface {
    label: string;
    key: string;
}

export const THROWABLE_MAIN_PROPS: BasicInterface[] = [
    {label: "Object Damage", key: "throwableDamage"},
    {label: "Power Level", key: "powerLevel"},
    {label: "Bounce Effect", key: "bounceEffect"},
    {label: "Object Amount", key: "throwableAmount"},
    {label: "Object Mass", key: "throwableMass"},
    {label: "Time Object Shows", key: "throwableLife"},
    {label: "Object Speed", key: "throwableSpeed"},
    {label: "Friction", key: "throwableFriction"},
    {label: "Inertia", key: "throwableInertia"},
];
