import * as THREE from "three";
import BaseBehaviorConverter from "./BaseBehaviorConverter";
import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import JumpPadBehaviorUpdater from "./JumpPadBehaviorUpdater";
import {JumpPadBehaviorInterface, OBJECT_TYPES} from "../../types/editor";

class JumpPadBehaviorConverter extends BaseBehaviorConverter<JumpPadBehaviorInterface> {
    public static DEFAULT = new JumpPadBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: any): BehaviorUpdater {
        return new JumpPadBehaviorUpdater(target, behavior.jumpPadAngle, behavior.jumpPadStrength);
    }

    getBehavior(target: THREE.Object3D, id: string): JumpPadBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as JumpPadBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have JUMPPAD behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): JumpPadBehaviorInterface {
        return {
            enabled: true,
            id,
            type: OBJECT_TYPES.JUMPPAD,
            jumpPadStrength: 50,
            jumpPadAngle: 0,
            startOnTrigger: false,
        };
    }
}

export default JumpPadBehaviorConverter;
