import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import { MobileTouchControlBehaviorInterface, MOBILE_TOUCH_CONTROL_TYPES, OBJECT_TYPES } from "../../types/editor";
import MobileTouchControlBehaviorUpdater from "./MobileTouchControlBehaviorUpdater";

class MobileTouchControlBehaviorConverter extends BaseBehaviorConverter<MobileTouchControlBehaviorInterface> {

    public static DEFAULT = new MobileTouchControlBehaviorConverter(null);

    constructor(obj: any) {
        super(obj);
    }

    convert(target: THREE.Object3D, behavior: MobileTouchControlBehaviorInterface): BehaviorUpdater {
        if (!this.obj) {
            console.error("NPCBehaviorConverter: 'obj' is not defined.");
            return new MobileTouchControlBehaviorUpdater(target, behavior);
        }
        return new MobileTouchControlBehaviorUpdater(target, behavior);
    }

    reset(): void { }

    getBehavior(target: THREE.Object3D, id: string): MobileTouchControlBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as MobileTouchControlBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have NPC behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): MobileTouchControlBehaviorInterface {
        return {
            type: OBJECT_TYPES.MOBILE_TOUCH_CONTROL,
            mobileTouchControlType: MOBILE_TOUCH_CONTROL_TYPES.TOUCH_SCREEN_ONLY,
            enabled: true,
            startOnTrigger: false,
            id,
        };
    }
}

export default MobileTouchControlBehaviorConverter;
