import {useEffect, useState} from "react";
import {CloseBtn, Container, LoadingWrapper, Tab, Tabs, Title} from "./AiImageGenerator.styles";
import {ImageGenerator} from "./Tabs/ImageGenerator";
import {Asset, Model, TABS} from "./AiImageGenerator.types";
import ImageEditor from "./Tabs/ImageEditor";
import {Oval} from "react-loader-spinner";
import {AssetList} from "./Tabs/AssetsList";
import {ModelsList} from "./Tabs/ModelsList";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export const AiImageGenerator = ({isOpen, onClose}: Props) => {
    const [loadingAI, setLoadingAI] = useState(false);
    const [selectedTab, setSelectedTab] = useState<TABS>(TABS.GENERATE_IMAGE);
    const [assetToEdit, setAssetToEdit] = useState<Asset | null>(null);
    const [selectedModel, setSelectedModel] = useState<Model | null>(
        localStorage.getItem("imageGeneration-selectedModel")
            ? JSON.parse(localStorage.getItem("imageGeneration-selectedModel")!)
            : null,
    );

    useEffect(() => {
        if (assetToEdit) {
            setSelectedTab(TABS.EDIT_IMAGE);
        }
    }, [assetToEdit]);

    useEffect(() => {
        if (selectedModel) {
            setSelectedTab(TABS.GENERATE_IMAGE);
            localStorage.setItem("imageGeneration-selectedModel", JSON.stringify(selectedModel));
        }
    }, [selectedModel]);

    const generationTab = selectedTab === TABS.GENERATE_IMAGE || selectedTab === TABS.EDIT_IMAGE;

    return (
        <Container $isOpen={isOpen} onSubmit={e => e.preventDefault()}>
            <Title>AI Image Generator</Title>
            <CloseBtn onClick={onClose}>X</CloseBtn>
            <Tabs>
                {Object.values(TABS).map(tab => (
                    <Tab key={tab} onClick={() => setSelectedTab(tab)} $selected={selectedTab === tab}>
                        {tab}
                    </Tab>
                ))}
            </Tabs>
            {
                <ImageGenerator
                    setLoadingAI={setLoadingAI}
                    isOpen={selectedTab === TABS.GENERATE_IMAGE}
                    selectedModel={selectedModel}
                />
            }
            {
                <ImageEditor
                    isOpen={selectedTab === TABS.EDIT_IMAGE}
                    assetToEdit={assetToEdit}
                    setAssetToEdit={setAssetToEdit}
                    setLoadingAI={setLoadingAI}
                />
            }
            {
                <AssetList
                    isOpen={selectedTab === TABS.ASSETS_LIST}
                    setAssetToEdit={setAssetToEdit}
                    assetToEdit={assetToEdit}
                />
            }
            {
                <ModelsList
                    isOpen={selectedTab === TABS.MODELS_LIST}
                    setSelectedModel={setSelectedModel}
                    selectedModel={selectedModel}
                />
            }
            {loadingAI && generationTab && (
                <LoadingWrapper>
                    <Oval
                        visible
                        height="40"
                        width="40"
                        color="#0284c7"
                        secondaryColor="#333"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass="loaderWrapper"
                    />
                </LoadingWrapper>
            )}
        </Container>
    );
};
