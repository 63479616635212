import {useEffect, useState} from "react";
import I18n from "i18next";
import _ from "lodash";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import {useAppGlobalContext, useAuthorizationContext, useHomepageContext} from "../../../../context";
import {FileData} from "../types/file";
import Ajax from "../../../../utils/Ajax";
import global from "../../../../global";
import {backendUrlFromPath} from "../../../../utils/UrlUtils";
import {PAGES} from "./constants";
import Application from "../../../../Application";
import {ROUTES} from "../../../../AppRouter";
import {generateProjectLink} from "../../../../v2/pages/services";

import {SceneList} from "./SceneList/SceneList";
import {LoadingAnimation} from "../../../../ui/progress/LoadingAnimation";
import {SettingsPage} from "./SettingsPage/SettingsPage";
import {DashboardLayout} from "../DashboardLayout/DashboardLayout";
import {NoData} from "./GamesDashboard.style";
import Confirm, {IConfirmProps} from "../../../../ui/window/Confirm";
import {AdminPanel} from "./AdminPanel/AdminPanel";

const reversePAGES = Object.fromEntries(Object.entries(PAGES).map(([key, value]) => [value, key]));

export const GamesDashboard = () => {
    const {search} = useHomepageContext();
    const {setActivePage, activePage} = useAppGlobalContext();
    const [filteredData, setFilteredData] = useState<FileData[]>();
    const [myGames, setMyGames] = useState<FileData[]>([]);
    const [communityGames, setCommunityGames] = useState<FileData[]>([]);
    const [selectedItemId, setSelectedItemId] = useState("");
    const app = (global as any).app as Application;
    const {authToken, isAuthorized, initLoading, dbUser, isWhitelisted, isAdmin} = useAuthorizationContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab");
    const [showLoding, setShowLoading] = useState(false);
    const [initLoadingState, setInitLoadingState] = useState(true);
    const [confirmProps, setConfirmProps] = useState<IConfirmProps | null>(null);

    const removeErrorParam = () => {
        if (searchParams.has("tab")) {
            searchParams.delete("tab");
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        if (isAdmin || isWhitelisted) {
            setInitLoadingState(false);
        }
    }, [isAdmin, isWhitelisted]);

    useEffect(() => {
        setActivePage(
            PAGES[
                tab
                    ? (tab.toLocaleUpperCase() as keyof typeof PAGES)
                    : (reversePAGES[PAGES.DASHBOARD] as keyof typeof PAGES)
            ],
        );
        removeErrorParam();
    }, []);

    useEffect(() => {
        if (!isAuthorized && !initLoading) {
            navigate(ROUTES.LOGIN, {state: {from: location.pathname}});
        }
    }, [isAuthorized, initLoading]);

    const update = () => {
        if (!dbUser) return;
        Ajax.get({url: backendUrlFromPath(`/api/Scene/List`)})
            .then(response => {
                const obj = response?.data;
                if (obj.Code !== 200) {
                    app.toast(I18n.t(obj.Msg), "warn");
                    return;
                }

                const myGamesData: FileData[] = [];
                const communityGamesData: FileData[] = [];

                obj.Data.forEach((data: FileData) => {
                    const scene = {
                        ...data,
                        DeleteEnabled: true,
                        Thumbnail: data.Thumbnail,
                    };
                    if (data.UserID === dbUser.id) {
                        myGamesData.push(scene);
                    } else if (data.IsPublic) {
                        communityGamesData.push({...scene, isCommunity: true});
                    }
                });

                myGamesData.sort(
                    (a: any, b: any) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime(),
                );
                communityGamesData.sort(
                    (a: any, b: any) => new Date(b.UpdateTime).getTime() - new Date(a.UpdateTime).getTime(),
                );

                setMyGames(myGamesData);
                setCommunityGames(communityGamesData);
            })
            .catch(e => {
                console.error("Fetching scenes error:", e.message);
            });
    };

    const handleLoad = (id: string, newTab?: boolean) => {
        const url = generateProjectLink(id);
        if (newTab) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    };

    const handleDelete = (id: string) => {
        const data = getCurrentData();
        if (data) {
            const scene = data.find(n => n.ID === id);
            if (scene) {
                const props = {
                    title: I18n.t("Confirm"),
                    cancelText: "Cancel",
                    okText: "Delete",
                    children: <span>Are you sure you want to delete {scene.Name}?</span>,
                    onOK: () => {
                        Ajax.post({
                            url: backendUrlFromPath(`/api/Scene/Delete?ID=${scene.ID}`),
                        }).then(response => {
                            const obj = response?.data;
                            if (obj.Code !== 200) {
                                app.toast(I18n.t(obj.Msg), "warn");
                                return;
                            }
                            if (app?.editor?.sceneID === scene.ID) {
                                app.editor.sceneID = null;
                                app.editor.sceneName = null;
                                app.call("clear", app.editor);
                            }

                            update();
                            setConfirmProps(null);
                        });
                    },
                    onCancel: () => setConfirmProps(null),
                };
                setConfirmProps(props);
            }
        }
    };

    const getCurrentData = () => {
        let data;
        if (activePage === PAGES.DASHBOARD) {
            data = myGames?.concat(communityGames);
        } else if (activePage === PAGES.MY_GAMES) {
            data = myGames;
        } else {
            data = communityGames;
        }
        return data;
    };

    useEffect(() => {
        const data = getCurrentData();
        if (!search) {
            setFilteredData(data);
            return;
        } else {
            setFilteredData(
                data?.filter(n => {
                    return n.Name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                }),
            );
        }
    }, [search, activePage, myGames, communityGames]);

    useEffect(() => {
        if (!_.isEmpty(authToken) && dbUser) {
            update();
            app.on(`sceneSaved.ScenePanel`, update);
        }
    }, [authToken, dbUser]);

    useEffect(() => {
        const pathname = window.location.pathname;
        const key = Object.keys(ROUTES).find(n => ROUTES[n as keyof typeof ROUTES] === pathname);
        setActivePage(PAGES[key as keyof typeof PAGES] || PAGES.DASHBOARD);
    }, [location.pathname]);

    return initLoadingState ? (
        <LoadingAnimation show={true} style={{background: "var(--theme-container-minor-dark)"}} />
    ) : (
        <>
            {confirmProps && <Confirm {...confirmProps} />}
            <LoadingAnimation show={showLoding} />
            <DashboardLayout>
                {activePage === PAGES.SETTINGS && <SettingsPage />}
                {activePage === PAGES.ADMIN_PANEL && <AdminPanel />}
                {activePage !== PAGES.SETTINGS && activePage !== PAGES.ADMIN_PANEL && (
                    <>
                        {!!filteredData && filteredData.length > 0 ? (
                            <SceneList
                                activePage={activePage}
                                setActivePage={setActivePage}
                                data={filteredData}
                                selectedItemsIds={[selectedItemId]}
                                onClick={id => setSelectedItemId(id)}
                                onDoubleClick={(id, newTab) => handleLoad(id, newTab)}
                                onDelete={handleDelete}
                                setShowLoading={setShowLoading}
                                reload={update}
                            />
                        ) : (
                            <NoData>
                                <div className="description">
                                    No saved projects yet. Click &quot;New Game&quot; to get started
                                </div>
                            </NoData>
                        )}
                    </>
                )}
            </DashboardLayout>
        </>
    );
};
