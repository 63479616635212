import {getThumbnail} from "../../../services";
import gamePlaceholder from "../../../v2/assets/game-controller.svg";

import {ListItem, PlayCount, SceneDetailsWrapper, SceneImage, SceneName} from "./SingleGame.style";
import {IBasicGameInterface} from "../../../v2/pages/types";
import {formatNumber} from "../../../v2/pages/services";
import {IPlayGame} from "../AI16ZHome";
import playIcon from "../../assets/play-small.svg";
import hoverPlayIcon from "../../assets/play-blue.svg";

type Props = {
    game: IBasicGameInterface;
    setPlayGameData: React.Dispatch<React.SetStateAction<IPlayGame | undefined>>;
};

export const SingleGame = ({game, setPlayGameData}: Props) => {
    const thumbnail = getThumbnail(game.Thumbnail);
    const trimSceneName = (name: string) => {
        const allowedLength = 32;
        return name.length > allowedLength ? name.substring(0, allowedLength) + "..." : name;
    };

    return (
        <ListItem
            className="singleGame"
            onClick={() => {
                setPlayGameData({url: game.GameURL, name: trimSceneName(game.Name), thumbnail});
            }}>
            <SceneImage $bgImage={thumbnail}>
                {!thumbnail && <img className="default-img thumbnail" src={gamePlaceholder} alt="" />}
                <img src={hoverPlayIcon} alt="play" className="hoverPlayIcon" />
            </SceneImage>
            <SceneDetailsWrapper $flex>
                <SceneName>{game.Name}</SceneName>
                <PlayCount>
                    <img src={playIcon} alt="play count" /> {formatNumber(game.PlayCount || 0, 999)}
                </PlayCount>
            </SceneDetailsWrapper>
        </ListItem>
    );
};
