import styled from "styled-components";

export const StyledFeaturesSection = styled.section`
    h2 {
        margin-bottom: 16px;
    }
`;

export const GridWrapper = styled.div`
    padding: 73px;
    background: linear-gradient(180deg, #8508fb 0%, #ca35a1 100%);
    @media only screen and (max-width: 1439px) {
        padding: 40px;
    }
`;

export const Grid = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    background: linear-gradient(180deg, #8508fb 0%, #ca35a1 100%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 104px;

    .screenshot {
        width: 530px;
    }

    .games {
        width: 570px;
    }

    @media only screen and (max-width: 1439px) {
        gap: 64px;
        .screenshot {
            width: 460px;
        }

        .games {
            width: 500px;
        }
    }

    @media only screen and (max-width: 1279px) {
        gap: 56px;
        .screenshot {
            width: 420px;
        }

        .games {
            width: 460px;
        }
    }
    @media only screen and (max-width: 1023px) {
        gap: 56px 0;
        grid-template-columns: 1fr;
        .screenshot,
        .games {
            width: 90%;
            margin-top: -32px;
        }

        .screenshot {
            max-width: 420px;
        }

        .games {
            max-width: 460px;
        }
    }
`;

export const Text = styled.div`
    width: 290px;

    * {
        line-height: 120%;
        text-align: left;
        color: #fff;
    }

    .title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .description {
        font-size: 20px;
        font-weight: 400;
    }

    @media only screen and (max-width: 1023px) {
        * {
            text-align: center;
        }
    }
`;
