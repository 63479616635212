import styled from "styled-components";
import {flexCenter} from "../../../../assets/style";

export const Container = styled.form<{$isOpen?: boolean}>`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    width: 600px;
    min-height: 145px;
    background: var(--theme-grey-bg-tertiary);
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    pointer-events: none;
    transition: transform 0.1s ease;
    position: fixed;
    ${({$isOpen}) =>
        $isOpen &&
        `
      transform: translateX(-50%) scale(1);
      pointer-events: all;
    `};

    .loaderWrapper {
        margin: 4px auto 0;
        height: 58px;
        ${flexCenter};
    }
`;

export const CloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 12px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    color: white;
    cursor: pointer;
`;

export const Title = styled.div<{$isError?: boolean}>`
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    line-height: 16px;
    color: white;

    ${({$isError}) =>
        $isError &&
        `
          color: red;
    `}
`;

export const Tabs = styled.div`
    margin: 12px 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;

export const Tab = styled.div<{$selected?: boolean}>`
    padding: 5px 0;
    text-align: center;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    color: ${({$selected}) => ($selected ? "var(--theme-container-main-blue)" : "white")};
    border-bottom: ${({$selected}) => ($selected ? "2px solid var(--theme-container-main-blue)" : "none")};
    cursor: pointer;
`;

export const StyledTextArea = styled.textarea`
    margin-top: 4px;
    background: var(--theme-grey-bg);
    border: none;
    outline: none;
    resize: none;
    border-radius: 8px;
    padding: 3px 5px;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: #aeaeae;
    height: 58px;
    width: 100%;
`;

export const BottomBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;

export const SubmitButton = styled.button<{disabled?: boolean}>`
    border: none;
    width: 32px;
    height: 32px;
    background: var(--theme-container-main-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    border-top: 1px solid #0ea5e9;
    outline: none;
    ${({disabled}) =>
        disabled &&
        `
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ImageWrapper = styled.div<{$image?: string | null}>`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    position: relative;
    ${({$image}) =>
        $image &&
        `
        border: 1px solid var(--theme-grey-bg-secondary-button);
    border-radius: 8px;
      min-height: 400px;
      background-image: url(${$image});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `}
    > div {
        width: 100%;
        height: 100%;
    }

    canvas {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    padding-top: 4px;
`;
export const LoadingWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    ${flexCenter};
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
`;
export const List = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    overflow-y: auto;
    min-height: 142px;
`;

export const ListItem = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 142px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Menu = styled.div`
    width: 74px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--theme-grey-bg-secondary-button);
    border-radius: 8px;
    padding: 4px 0px;
    z-index: 1;
`;

export const MenuItem = styled.div<{$isDelete?: boolean}>`
    padding: 4px 8px;
    cursor: pointer;
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-bold);
    color: white;
    cursor: pointer;
    &:hover {
        background: var(--theme-grey-bg);
    }

    ${({$isDelete}) =>
        $isDelete &&
        `
          color: red;
    `}
`;

export const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
`;

export const RowTitle = styled.div`
    font-size: var(--theme-font-size-xxs);
    font-weight: var(--theme-font-regular);
    color: var(--theme-font-unselected-color);
    line-height: 120%;
    text-align: left;
    white-space: nowrap;
`;

export const RowBox = styled.div`
    ${flexCenter};
    width: 100px;
`;
