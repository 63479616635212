import React, {useState} from "react";
import {getGames} from "../api/getGames";
import {IBasicGameInterface} from "../v2/pages/types";

interface HomepageContextValue {
    games: IBasicGameInterface[];
    fetchGames: () => void;
    setSearch: (search: string) => void;
    search: string;
    favoriteGames: IBasicGameInterface[];
    setFavoriteGames: React.Dispatch<React.SetStateAction<IBasicGameInterface[]>>;
}

export const HomepageContext = React.createContext<HomepageContextValue>(null!);

export interface HomepageContextProviderProps {
    children: React.ReactNode;
}

const HomepageContextProvider: React.FC<HomepageContextProviderProps> = ({children}) => {
    const [games, setGames] = useState<IBasicGameInterface[]>([]);
    const [favoriteGames, setFavoriteGames] = useState<IBasicGameInterface[]>([]);
    const [search, setSearch] = useState("");

    const fetchGames = async () => {
        const response = await getGames();

        response && setGames(response);
    };

    return (
        <HomepageContext.Provider
            value={{
                games,
                fetchGames,
                setSearch,
                search,
                setFavoriteGames,
                favoriteGames,
            }}>
            {children}
        </HomepageContext.Provider>
    );
};

export default HomepageContextProvider;
