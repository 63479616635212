import {BehaviorUpdater} from "../../behaviors/BehaviorManager";
import GameManager from "../../behaviors/game/GameManager";
import * as THREE from "three";
import CameraUtils from "../../utils/CameraUtils";

class SpawnBehaviorAssetUpdater implements BehaviorUpdater {
    target: THREE.Object3D;
    game?: GameManager;

    constructor(target: THREE.Object3D) {
        this.target = target;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        CameraUtils.disableFromCameraCollision(this.target);
    }

    update(clock: THREE.Clock, delta: number): void {}

    reset() {}
}

export default SpawnBehaviorAssetUpdater;
