export type FileData = {
    AddTim: string;
    CategoryID: string;
    CategoryName: string;
    FileName: string;
    FileSize: string;
    FileType: string;
    ID: string;
    UserID: string;
    Name: string;
    SaveName: string;
    SavePath: string;
    Thumbnail: string;
    Type: string;
    Url: string;
    DeleteEnabled?: boolean;
    LockedItems?: string;
    UpdateTime: string;
    isCommunity?: boolean;
    IsCloneable?: boolean;
    IsPublic?: boolean;
    IsPublished: boolean;
    IsAvatar?: boolean;
};

export const DEFAULT_FILE_DATA: FileData = {
    AddTim: "",
    CategoryID: "",
    CategoryName: "",
    FileName: "",
    FileSize: "",
    FileType: "",
    ID: "",
    Name: "",
    SaveName: "",
    SavePath: "",
    Thumbnail: "",
    Type: "",
    Url: "",
    DeleteEnabled: true,
    UpdateTime: "",
    UserID: "",
    IsCloneable: false,
    IsPublic: false,
    IsPublished: false,
};
