import {backendUrlFromPath} from "./utils/UrlUtils";

export const getThumbnail = (thumbnailUrl: string) => {
    if (thumbnailUrl === "null") return undefined;

    return thumbnailUrl
        ? thumbnailUrl.includes("data:image") || thumbnailUrl.includes("src/editor")
            ? thumbnailUrl
            : backendUrlFromPath(thumbnailUrl)
        : undefined;
};
