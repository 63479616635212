import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Ai16zAppRouter} from "./Ai16zAppRouter";
import AuthorizationContextProvider from "../context/AuthorizationContext.js";
import CSPMetaTag, {customCSPPolicies} from "../utils/CSPMetaTag";

export const Ai16zAppContainer = () => {
    return (
        <AuthorizationContextProvider>
            <CSPMetaTag customPolicies={customCSPPolicies} />
            <Ai16zAppRouter />
            <ToastContainer position="top-center" theme="dark" />
        </AuthorizationContextProvider>
    );
};
