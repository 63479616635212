import BaseBehaviorConverter from "./BaseBehaviorConverter";
import * as THREE from "three";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import NPCBehaviorUpdater from "./NPCBehaviorUpdater";
import { NPCBehaviorInterface, NPC_MOVEMENT_TYPES, OBJECT_TYPES } from "../../types/editor";

class NPCBehaviorConverter extends BaseBehaviorConverter<NPCBehaviorInterface> {
    public static DEFAULT = new NPCBehaviorConverter(null);
    constructor(obj: any) {
        super(obj);
    }
    convert(target: THREE.Object3D, behavior: NPCBehaviorInterface): BehaviorUpdater {
        if (!this.obj) {
            console.error("NPCBehaviorConverter: 'obj' is not defined.");
            return new NPCBehaviorUpdater(target, behavior);
        }

        // Ensure pathFinders is initialized
        if (!Array.isArray(this.obj.pathFinders)) {
            console.warn("Initializing 'pathFinders' as an empty array.");
            this.obj.pathFinders = [];
        }

        if (behavior && behavior.enabled) {
            const filteredPathFinders = this.obj.pathFinders.filter((p: any) => p.uuid !== behavior.id);

            this.obj.pathFinders = [
                ...filteredPathFinders,
                {
                    uuid: behavior.id,
                    name: behavior.name,
                    enabled: behavior.enabled,
                    isBehaviorPathFinder: true,
                    id: null,
                    movementSpeed: 5,
                    movementType: NPC_MOVEMENT_TYPES.STAND_STILL,
                },
            ];
        }
        return new NPCBehaviorUpdater(target, behavior);
    }


    clearBehaviorPathFinders(): void {
        this.obj.pathFinders = this.obj.pathFinders.filter((pathFinder: any) => !pathFinder.isBehaviorPathFinder);
    }

    reset(): void { }

    getBehavior(target: THREE.Object3D, id: string): NPCBehaviorInterface | null {
        if (!target) return null;

        let behavior = super.findBehavior(target, id) as NPCBehaviorInterface;
        if (!behavior) {
            console.warn("Object doesn't have NPC behavior: ", target);
            return null;
        }

        return behavior;
    }

    getDefaultBehavior(id: string): NPCBehaviorInterface {
        return {
            type: OBJECT_TYPES.NPC,
            movementType: NPC_MOVEMENT_TYPES.STAND_STILL,
            name: "NPC",
            enabled: true,
            startOnTrigger: false,
            id,
            health: 100,
            movementSpeed: 5,
            animationClips: [],
            idleAnimation: '',
            walkAnimation: '',
            runAnimation: '',
            jumpAnimation: '',
            crouchAnimation: '',
            trippingAnimation: '',
            fallAnimation: '',
            dieAnimation: '',
            attackAnimation: '',
        };
    }
}

export default NPCBehaviorConverter;
