import {useEffect, useMemo, useRef, useState} from "react";
import {
    BottomBar,
    ImageWrapper,
    Row,
    RowBox,
    RowTitle,
    Section,
    StyledTextArea,
    SubmitButton,
    Title,
    Wrapper,
} from "../AiImageGenerator.styles";
import submitIcon from "../icons/submit.svg";
import {toast} from "react-toastify";
import {generateImage, generateSkybox, generateTexture, getModels} from "../AiImageGenerator.service";
import {BasicCombobox} from "../../common/BasicCombobox";
import {ResultAssets} from "../Components/ResultAssets";
import {GENERATION_TYPES, IMAGE_TYPES, Model, SKYBOX_STYLES} from "../AiImageGenerator.types";
import {StyledButton} from "../../common/StyledButton";

type Props = {
    setLoadingAI: (loading: boolean) => void;
    isOpen: boolean;
    selectedModel: Model | null;
};

const SUPPORTED_FORMATS = [
    {
        format: GENERATION_TYPES.TEXT_TO_IMAGE,
        error: "This model does not support text to image generation.",
    },
    {
        format: GENERATION_TYPES.IMAGE_TO_IMAGE,
        error: "This model does not support image to image generation.",
    },
    {
        format: GENERATION_TYPES.IMAGE_TO_IMAGE_TEXTURE,
        error: "This model does not support image to image texture generation.",
    },
    {
        format: GENERATION_TYPES.TEXT_TO_IMAGE_TEXTURE,
        error: "This model does not support text to image texture generation.",
    },
];

const generateSizeOptions = (minValue: number) => {
    const sizeOptions = [];
    for (let value = minValue; value < 2048; value += 8) {
        sizeOptions.push({
            key: value.toString(),
            value: value.toString(),
        });
    }
    return sizeOptions;
};

export const ImageGenerator = ({setLoadingAI, isOpen, selectedModel}: Props) => {
    const [prompt, setPrompt] = useState("");
    const [negativePrompt, setNegativePrompt] = useState("");
    const [height, setHeight] = useState("512");
    const [width, setWidth] = useState("512");
    const [assetIds, setAssetIds] = useState<string[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [image, setImage] = useState<string>();
    const [imageType, setImageType] = useState<IMAGE_TYPES>(IMAGE_TYPES.SEAMFULL);
    const [skyboxStyle, setSkyboxStyle] = useState<SKYBOX_STYLES>(SKYBOX_STYLES.STANDARD);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const sizeOptions = useMemo(() => generateSizeOptions(imageType === IMAGE_TYPES.SKYBOX ? 1024 : 64), [imageType]);

    const imageTypesOptions = Object.keys(IMAGE_TYPES).map(key => ({
        key: IMAGE_TYPES[key as keyof typeof IMAGE_TYPES],
        value:
            IMAGE_TYPES[key as keyof typeof IMAGE_TYPES] === IMAGE_TYPES.SEAMFULL
                ? "image"
                : IMAGE_TYPES[key as keyof typeof IMAGE_TYPES],
    }));

    const skyboxStylesOptions = Object.keys(SKYBOX_STYLES).map(key => ({
        key,
        value: SKYBOX_STYLES[key as keyof typeof SKYBOX_STYLES] as string,
    }));

    const handleGenerateImage = async () => {
        if (selectedModel) {
            try {
                let response;
                setLoadingAI(true);
                setAssetIds([]);
                switch (imageType) {
                    case IMAGE_TYPES.SKYBOX:
                        response = await generateSkybox({
                            prompt,
                            negativePrompt,
                            width,
                            style: skyboxStyle,
                        });
                        break;
                    case IMAGE_TYPES.SEAMFULL:
                        response = await generateImage({
                            modelId: selectedModel.id,
                            prompt,
                            negativePrompt,
                            height,
                            width,
                            image,
                        });
                        break;
                    case IMAGE_TYPES.TEXTURE:
                        response = await generateTexture({
                            modelId: selectedModel.id,
                            prompt,
                            negativePrompt,
                            height,
                            width,
                            image,
                        });
                        break;
                }

                if (response && response.assetIds) {
                    setAssetIds(response.assetIds);
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error("Error generating image.");
            } finally {
                setLoadingAI(false);
            }
        } else {
            toast.error("Please select a model to generate images.");
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImage(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        let errors: string[] = [];
        if (selectedModel) {
            SUPPORTED_FORMATS.forEach(format => {
                if (!selectedModel.capabilities.includes(format.format)) {
                    errors.push(format.error);
                }
            });
            setErrors(errors);
        }
    }, [selectedModel]);

    useEffect(() => {
        if (imageType === IMAGE_TYPES.SKYBOX) {
            setWidth("1024");
        }
    }, [imageType]);

    const isImage2ImageGenerationSupported =
        selectedModel?.capabilities.includes(GENERATION_TYPES.IMAGE_TO_IMAGE) ||
        selectedModel?.capabilities.includes(GENERATION_TYPES.IMAGE_TO_IMAGE_TEXTURE);

    if (!isOpen) return null;

    return (
        <Wrapper>
            {selectedModel ? (
                <Section>
                    <Title>Selected Model: {selectedModel.name}</Title>
                    <ResultAssets
                        assetIds={selectedModel.exampleAssetIds.slice(0, 4)}
                        defaultImage={selectedModel.thumbnail.url}
                    />
                </Section>
            ) : (
                <Title $isError>Select a model to generate images</Title>
            )}

            {errors && errors.length > 0 && (
                <Section>
                    {errors.map(error => (
                        <Title key={error} $isError>
                            {error}
                        </Title>
                    ))}
                </Section>
            )}
            {isImage2ImageGenerationSupported && (
                <>
                    {image && <ImageWrapper $image={image} />}

                    <StyledButton
                        isGreySecondary
                        width="100%"
                        style={{height: "32px"}}
                        onClick={() => inputRef.current?.click()}>
                        {image ? "Change " : "Upload "}
                        Image
                    </StyledButton>
                    <input
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{display: "none"}}
                    />
                </>
            )}

            <Section>
                <Title>Prompt</Title>
                <StyledTextArea
                    value={prompt}
                    onChange={e => setPrompt(e.target.value)}
                    placeholder="How my image should look like"
                />
            </Section>
            <Section>
                <Title>Negative Prompt</Title>
                <StyledTextArea
                    value={negativePrompt}
                    onChange={e => setNegativePrompt(e.target.value)}
                    placeholder="How my image should not look like"
                />
            </Section>
            <Section>
                <Title>Settings</Title>
                <Row>
                    <RowTitle>Image Type</RowTitle>
                    <RowBox>
                        <BasicCombobox
                            data={imageTypesOptions}
                            value={imageTypesOptions.find(type => type.key === imageType)}
                            onChange={item => setImageType(item.key as IMAGE_TYPES)}
                        />
                    </RowBox>
                </Row>
                {imageType === IMAGE_TYPES.SKYBOX && (
                    <Row>
                        <RowTitle>Style</RowTitle>
                        <RowBox>
                            <BasicCombobox
                                data={skyboxStylesOptions}
                                value={skyboxStylesOptions.find(style => style.value === skyboxStyle)}
                                onChange={item => setSkyboxStyle(item.value as SKYBOX_STYLES)}
                            />
                        </RowBox>
                    </Row>
                )}
                <Row>
                    <RowTitle>Width</RowTitle>
                    <RowBox>
                        <BasicCombobox
                            data={sizeOptions}
                            value={sizeOptions.find(size => size.value === width)}
                            onChange={item => setWidth(item.value)}
                        />
                    </RowBox>
                </Row>
                {imageType !== IMAGE_TYPES.SKYBOX && (
                    <Row>
                        <RowTitle>Height</RowTitle>
                        <RowBox>
                            <BasicCombobox
                                data={sizeOptions}
                                value={sizeOptions.find(size => size.value === height)}
                                onChange={item => setHeight(item.value)}
                            />
                        </RowBox>
                    </Row>
                )}
            </Section>
            {assetIds && assetIds.length > 0 && (
                <Section>
                    <Title>Generated Images</Title>
                    <ResultAssets assetIds={assetIds} />
                </Section>
            )}

            <BottomBar>
                <SubmitButton disabled={!prompt || !selectedModel} onClick={handleGenerateImage}>
                    <img src={submitIcon} alt="Submit" />
                </SubmitButton>
            </BottomBar>
        </Wrapper>
    );
};
