import {useState, useEffect, useRef, useCallback} from "react";
import {deleteAsset, downloadAsset, getAssets, uploadImage} from "../AiImageGenerator.service";
import {BottomBar, List, ListItem, LoadingWrapper, Wrapper} from "../AiImageGenerator.styles";
import {Asset} from "../AiImageGenerator.types";
import {UploadButton} from "../../common/UploadButton";
import {toast} from "react-toastify";
import {Oval} from "react-loader-spinner";
import {AssetItem} from "../Components/AssetItem";
import global from "../../../../../global";
import Application from "../../../../../Application";
import {t} from "i18next";

type Props = {
    isOpen: boolean;
    setAssetToEdit: (asset: Asset | null) => void;
    assetToEdit: Asset | null;
};

export const AssetList = ({isOpen, setAssetToEdit, assetToEdit}: Props) => {
    const app = global.app as Application;
    const [assets, setAssets] = useState<Asset[]>([]);
    const [paginationToken, setPaginationToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [imageToUpload, setImageToUpload] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const observer = useRef<IntersectionObserver | null>(null);
    const lastAssetRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreAssets();
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore],
    );

    const loadMoreAssets = async () => {
        setLoading(true);
        try {
            const response = await getAssets(paginationToken ?? "", "12", []);

            if (response && response.assets) {
                setAssets(prev => [...prev, ...response.assets]);
                setPaginationToken(response.nextPaginationToken || null);
                setHasMore(!!response.nextPaginationToken);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            toast.error("Error loading assets.");
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImageToUpload(reader.result as string);
            reader.readAsDataURL(file);
        }
    };

    const handleUplaod = async () => {
        if (imageToUpload) {
            try {
                const response = await uploadImage(imageToUpload, "Uploaded Image " + new Date().toISOString());
                setImageToUpload(null);
                if (response && response.asset) {
                    setAssets(prev => [response.asset, ...prev]);
                }
            } catch (error) {
                toast.error("Error uploading image.");
            }
        }
    };

    const handleEditAsset = (asset: Asset) => {
        setAssetToEdit(asset);
    };

    const handleDeleteAsset = async (asset: Asset) => {
        try {
            app.confirm({
                title: t("Confirm"),
                content: t("Are you sure you want to delete this asset?"),
                onOK: async () => {
                    try {
                        await deleteAsset(asset.id);
                        setAssets(prev => prev.filter(a => a.id !== asset.id));
                        if (assetToEdit?.id === asset.id) {
                            setAssetToEdit(null);
                        }
                    } catch (error) {
                        toast.error("Error deleting asset.");
                    }
                },
            });
        } catch (error) {
            toast.error("Error deleting asset.");
        }
    };

    const handleDownloadAsset = async (asset: Asset) => {
        downloadAsset(asset);
    };

    useEffect(() => {
        if (isOpen) {
            setAssets([]);
            setPaginationToken(null);
            setHasMore(true);
            loadMoreAssets();
        }
    }, [isOpen]);

    useEffect(() => {
        if (imageToUpload) {
            handleUplaod();
        }
    });

    if (!isOpen) return null;

    return (
        <Wrapper>
            <List className="asset-list">
                {assets.map((asset, index) => {
                    if (assets.length === index + 1) {
                        return (
                            <ListItem ref={lastAssetRef} key={asset.id} className="asset-item">
                                <AssetItem
                                    asset={asset}
                                    onDeleteClick={handleDeleteAsset}
                                    onDownloadClick={handleDownloadAsset}
                                    onEditClick={handleEditAsset}
                                />
                            </ListItem>
                        );
                    } else {
                        return (
                            <AssetItem
                                key={asset.id}
                                asset={asset}
                                onDeleteClick={handleDeleteAsset}
                                onDownloadClick={handleDownloadAsset}
                                onEditClick={handleEditAsset}
                            />
                        );
                    }
                })}
            </List>
            <BottomBar>
                <UploadButton onClick={() => inputRef.current?.click()} />
                <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    onChange={handleImageUpload}
                    style={{display: "none"}}
                />
            </BottomBar>
            {loading && (
                <LoadingWrapper>
                    <Oval
                        visible
                        height="40"
                        width="40"
                        color="#0284c7"
                        secondaryColor="#333"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass="loaderWrapper"
                    />
                </LoadingWrapper>
            )}
        </Wrapper>
    );
};
