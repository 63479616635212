import styled from "styled-components";

export const Iframe = styled.iframe`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999999;
`;
