import {useEffect, useState} from "react";
import styled from "styled-components";
import * as THREE from "three";
import {toast} from "react-toastify";
import global from "../../../../../../global";
import {MainView} from "./MainView";
import {BehaviorDetailsView} from "./BehaviorDetailsView";
import {ItemMenuText, RightClickMenu} from "../../../../../../ui/common/RightClickMenu/RightClickMenu";
import {OBJECT_TYPES} from "../../../../../../types/editor";
import {pasteBehaviorForObject, setBehaviorForObject} from "../../behaviors/helpers/setBehaviorForObject";

export const ObjectBehaviorsTab = () => {
    const app = (global as any).app;
    const editor = app.editor;
    const [selectedBehavior, setSelectedBehavior] = useState<any>(null);
    const [behaviorsMenuOpen, setBehaviorsMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{x: number; y: number} | null>(null);
    const [isBehaviorsListDisplayed, setIsBehaviorsListDisplayed] = useState(false);

    const handleRightClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        let target = event.target as HTMLElement;

        while (target) {
            if (target.id === "behavior") {
                return;
            }
            target = target.parentElement as HTMLElement;
        }

        setBehaviorsMenuOpen(prev => !prev);
        const x = event.clientX;
        const y = event.clientY;

        setMenuPosition({x, y});
    };

    const addNewBehavior = (type: OBJECT_TYPES) => {
        setBehaviorForObject(type, editor, editor.selected, () => {
            setIsBehaviorsListDisplayed(false);
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        });
        const behaviors = editor.selected?.userData?.behaviors;
        if (!behaviors) return;
        setSelectedBehavior(behaviors[behaviors.length - 1] || null);
    };

    const pasteReadyBehavior = (behavior: any) => {
        pasteBehaviorForObject(behavior, editor, editor.selected, () => {
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        });
    };

    const closeMenu = () => {
        setMenuPosition(null);
        setBehaviorsMenuOpen(false);
    };

    const copyAllBehaviors = () => {
        if (!app) return;
        const selected = editor.selected;
        if (!selected) return;

        const obj = editor.objectByUuid(selected.uuid);

        const behaviorString = JSON.stringify(obj.userData.behaviors);
        navigator.clipboard
            .writeText(behaviorString)
            .then(() => toast.success("Behaviors copied!"))
            .catch(err => console.error("Failed to copy: ", err));
        closeMenu();
    };

    const pasteBehavior = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const text = await navigator.clipboard.readText();
            if (!text) {
                return toast.warn("No object to paste.");
            }
            let pastedBehavior;
            try {
                pastedBehavior = JSON.parse(text);
            } catch (parseError) {
                return toast.warn("Invalid data format in clipboard.");
            }
            if (Array.isArray(pastedBehavior)) {
                pastedBehavior.forEach(el => {
                    el.id = THREE.MathUtils.generateUUID();
                    pasteReadyBehavior(el);
                });
            } else if (typeof pastedBehavior === "object") {
                pastedBehavior.id = THREE.MathUtils.generateUUID();
                pasteReadyBehavior(pastedBehavior);
            } else {
                return toast.warn("No object to paste.");
            }
            closeMenu();
        } catch (err) {
            console.error("Failed to paste: ", err);
            closeMenu();
            return toast.warn("No object to paste.");
        }
    };

    useEffect(() => {
        app.on("behaviorAutoUpdate.ObjectBehaviorsTab", () => {
            if (selectedBehavior) {
                const behaviors = editor.selected?.userData?.behaviors;
                if (!behaviors) return;
                const updatedBehavior = behaviors.find((b: any) => b.id === selectedBehavior.id);
                setSelectedBehavior(updatedBehavior || null);
            }
        });

        return () => {
            app.on("behaviorAutoUpdate.ObjectBehaviorsTab", null);
        };
    }, [selectedBehavior]);

    return (
        <Wrapper onContextMenu={handleRightClick}>
            {!selectedBehavior ? (
                <>
                    <MainView
                        isBehaviorsListDisplayed={isBehaviorsListDisplayed}
                        setIsBehaviorsListDisplayed={setIsBehaviorsListDisplayed}
                        addNewBehavior={addNewBehavior}
                        setSelectedBehavior={setSelectedBehavior}
                        selectedBehavior={selectedBehavior}
                        copyAllBehaviors={copyAllBehaviors}
                        pasteBehavior={pasteBehavior}
                    />
                    {behaviorsMenuOpen && menuPosition && (
                        <RightClickMenu onClickoutsideCallback={closeMenu} left={menuPosition.x} top={menuPosition.y}>
                            <ItemMenuText onClick={copyAllBehaviors}>Copy All behaviors</ItemMenuText>
                            <ItemMenuText onClick={pasteBehavior}>Paste Behavior</ItemMenuText>
                        </RightClickMenu>
                    )}
                </>
            ) : (
                <BehaviorDetailsView selectedBehavior={selectedBehavior} setSelectedBehavior={setSelectedBehavior} />
            )}
        </Wrapper>
    );
};

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
`;
