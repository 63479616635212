import styled from "styled-components";

export const HeroSectionSchema = ({children}: {children: React.ReactNode}) => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = typeof window !== "undefined" && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
        <ShadowContainer>
            <Shadow $isChrome={isChrome} $isSafari={isSafari} />
            {children}
        </ShadowContainer>
    );
};

export const ShadowContainer = styled.div`
    position: relative;
`;

export const Shadow = styled.div<{$isChrome: boolean; $isSafari: boolean}>`
    position: absolute;
    width: 80vw;
    height: 65%;
    background: radial-gradient(140.7% 50% at 50% 50%, #8508fb 0%, #ca35a1 100%);
    border-radius: 50% / 100%;
    box-shadow: 0px 0px 300px 140px rgba(189, 0, 228, 0.57);
    top: 12vh;
    left: 28.47vw;
    filter: blur(100px);
    z-index: 2;
    pointer-events: none;

    ${({$isChrome}) =>
        $isChrome &&
        `
        box-shadow: 0px 0px 300px 80px rgba(189, 0, 228, 0.57);
    `}

    ${({$isSafari}) =>
        $isSafari &&
        `
        filter: blur(5vw);
        transform: translateX(20vw);
        left: unset;
        box-shadow: 0 0 0 100px rgba(117, 11, 158, 0.63);
        background: radial-gradient(circle, rgba(133,8,251,1) 0%, rgba(161,19,122,1) 0%, rgba(133,8,251,1) 100%);
    `}
`;
